// import { MenuItem, TextField } from "@mui/material";
// import { useStepperContext, initialState } from './StepperContext';
// import { useState } from "react";

// interface DropDown1Props{
//     label?:string,
// inputId?:number,
//     formName:string,
//     options?:any,
//     title?:any
//     userselectedData?:any,
//     required?:boolean
//     initial?:any

// }

// const YFDropdown:React.FC<DropDown1Props> = ({label,inputId,formName,options,title,userselectedData,initial}) => {
//     const {addUserData,userData}=useStepperContext()

//         const [selectedValues, setSelectedValues] = useState<string[]>([]);  // State for selected options as an array

//       const propertyAvailableFor = userData.find((item) => item.hasOwnProperty('Total Floors'));
//     const totalfloor = propertyAvailableFor?.['Total Floors'];

//     // Generate array of options from -1 to totalfloor
//    const rangeLength = totalfloor ? parseInt(totalfloor) + 2 : 0;
//     const optionsArray = Array.from({ length: rangeLength }, (_, index) => ({
//         id: index,
//         title: (index - 1).toString(),
//     }));

//      const textFieldWidth = label === 'Select'||'Select Your Floor'? '50%' : '100%';

//     const handleChange=(event: React.ChangeEvent<HTMLInputElement | { value: unknown }>)=>{
//           const selectedTitle = event.target.value as string;
//         // setSelectedValues(selectedTitle);
//         const data={
//             id:inputId,
//             [label as string]:selectedTitle,
//             formName:formName
//         }
//         addUserData(data as any)
//     }
//     console.log('selectf',userData)
//     const hasError=userselectedData?.includes(label)
//   return (
//     <div >
//         <TextField variant='standard' className="text-start"  select id={label} label={label} onChange={handleChange} sx={{width:"80%"}}>

//                 {optionsArray.map((option) => (
//                     <MenuItem key={option.id} value={option.title}>
//                         {option.title}
//                     </MenuItem>
//                 ))}
//           {hasError && <div>This field is required</div>}

//         </TextField>
//     </div>
//   )
// }

// export default YFDropdown
import {
  MenuItem,
  TextField,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  Typography,
} from '@mui/material';
import { useStepperContext } from './StepperContext';
import { useState, useEffect } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';

interface DropDown1Props {
  label?: string;
  inputId?: number;
  formName: string;
  options?: any;
  title?: any;
  userselectedData?: any;
  required?: boolean;
  initial?: any;
  setValue:any
  value:any
}

const YFDropdown: React.FC<DropDown1Props> = ({
  label,
  inputId,
  formName,
  options,
  title,
  userselectedData,
  initial,
  setValue,
  value
}) => {
  const { addUserData, userData } = useStepperContext();
  const [selectedValues, setSelectedValues] = useState<string[]>(initial || []); // State for selected options as an array
 
  const propertyAvailableFor = userData.find((item) =>
    item.hasOwnProperty('Total Floors')
  );
  const totalfloor = propertyAvailableFor?.['Total Floors'];

  
  // Generate array of options from -1 to totalfloor
  const rangeLength = totalfloor ? parseInt(totalfloor) + 2 : 0;
  const optionsArray = Array.from({ length: rangeLength }, (_, index) => ({
    id: index,
    title: (index - 1).toString(),
  }));

  const textFieldWidth =
    label === 'Select' || 'Select Your Floor' ? '50%' : '100%';

  useEffect(() => {
    
    if (initial) {
      setSelectedValues(initial);
      // setValue([])
    }
  }, [initial]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedTitles = event.target.value as string[];
    setSelectedValues(selectedTitles);
    setValue(selectedTitles)

    const data = {
      id: inputId,
      [label as string]: selectedTitles,
      formName: formName,
    };
    addUserData(data as any);
  };

  const hasError = userselectedData?.includes(label);

  return (
    <div>
      <FormControl variant="filled" className='w-[90%]'>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          value={value}
          onChange={handleChange}
          renderValue={(selected) => (selected as string[]).join(', ')}
          size="small"
          variant="filled"
          className='w-[100%]'
          sx={{
            width: "100%", borderRadius: "999px", backgroundColor: "#f5f4f8",
            '& .MuiFilledInput-root': {
              '&:before': {
                borderBottom: '0px solid #fff', // Underline color when not focused
              },
              '&:hover:not(.Mui-disabled):before': {
                borderBottom: '0px solid #fff', // Underline color on hover
              },
              '&:after': {
                borderBottom: '0px solid #fff', // Underline color when focused
              },
            },
          }}
        >
          {optionsArray.map((option) => (
            <MenuItem key={option.id} value={option.title}>
              <Checkbox checked={selectedValues.indexOf(option.title) > -1} />
              <ListItemText primary={option.title} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {hasError && <Typography sx={{ fontSize: "12px", color: "#f44336", marginLeft: "20px" }}>
        *This field is required
      </Typography>}
    </div>
  );
};

export default YFDropdown;
