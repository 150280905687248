

import React, { useState } from 'react';
import { useStepperContext } from './StepperContext';
import CustomisedButton from './CustomisedButton';
import TextBox from './TextBox';
import CustomButton from './CustomButton';
import CustomBoxButton from './CustomBoxButton';
import PriceTextField from './PriceTextField';
import PriceTextField1 from './PriceTextField1';
import PriceTextField2 from './PriceTextField2';
import DropBox1 from './DropBox1';
import FDropDown from './FDropDown';
import DropDown1 from './DropDown1';
import DatePick from './DatePick';
import CloseIcon from '@mui/icons-material/Close';

import { Box, Button, IconButton, Typography } from '@mui/material';
import TextAreaBox from './TextAreaBox';
import AccessButton from './AccessButton';
import MultiSelectorButton2 from '../reusablecomponent/MultiSelectorButton2';
import { MdArrowBackIosNew } from "react-icons/md";
import {
  CommerRentRetailnShow,
  CommercialIndSpace,
  CommercialSellIndSp,
  CommercialSellOff,
  CommercialSellRetailnShow,
  CommercialSellWare,
  CommercialrentOffice,
  CommercialrentWareh,
  ResidentialSellPlot,
  Residentialrent,
  Residentialsell,

} from "../data";
import { useDispatch,useSelector } from 'react-redux';
import { setUsers } from '../../../slices/filterSlice';
import { datafinder } from '../../PostForm/component';
import MultiSelectorButton3 from '../reusablecomponent/MultiSelectorButton3';


interface AdditionalDetailsProps {
  // onNext: () => void;
  // onBack: () => void;
  // onReset: () => void;
  setFullData: any
  setFinalValue: any
  passedData?: any
  AdditionalDatails: any
  closeModal:any
}

interface AdditionalDetail {
  Type: string;
  label: string;
  label2?: string;
  data: any;
  inputID: number;
  required: boolean;
  keyboard?: string;
  
}



interface UserData {
  [key: string]: any;
}

const AdditionalDetails: React.FC<AdditionalDetailsProps> = ({ setFullData,closeModal, setFinalValue, AdditionalDatails }) => {
  const { addUserData, userData, customFormDetails, addCustomFormDetails } = useStepperContext();
  const [selectedValues, setSelectedValues] = useState<number[]>([]);
   const passedData = useSelector((state: any) => state.usersInfo.users);
    console.log("redux check add",passedData)
  const [userselectedData, setUserSelectedData] = useState<string[]>([]);
  const [dataB, setDataB] = useState(AdditionalDatails)
  console.log("addditiional array", dataB,passedData)
  const [mypass, setMypass] = useState(passedData)
  const dispatch = useDispatch();
  const generateValidationSchema = (customFormDetails: any, userData: UserData[]) => {
    const requiredLabels = customFormDetails.additional_details
      .filter((item: any) => item.required)
      .map((item: any) => item.label);

    const existingLabels = requiredLabels.filter(
      (label: string) => !userData.some((item: UserData) => item.hasOwnProperty(label))
    );

    if (existingLabels.length > 0) {
      setUserSelectedData(existingLabels);
    } else {
      // onNext();
    }
  };

  const getInitialValue = (label: string) => {
    const entry = AdditionalDatails?.find((obj: UserData) => obj.hasOwnProperty(label));
    return entry ? entry[label] : '';
  };



  
  
  const handleSubmit = () => {


    const update = mypass.map((item: any) => {
      const isResidential = item.res_or_com      === "Residential";
  
      return {
        ...item,
        ...(isResidential
          ? {
              additional_details: {
                
                bathroom: datafinder(dataB as any, "Bathroom"),
                balcony: datafinder(dataB as any, "Balcony"),
                carpet_area: datafinder(dataB as any, "Carpet Area"),
                facing: datafinder(dataB as any, "Facing"),
                maintenance_charges: datafinder(dataB as any, "Maintenance Charges"),
                parking: datafinder(dataB as any, "Parking"),
                preferred_tenant_type: datafinder(dataB as any, "Preferred Tenant Type"),
                property_description: datafinder(dataB as any, "Property Description"),
                servant_room: datafinder(dataB as any, "Servant Room"),
                eligible_for_loan:datafinder(dataB as any,"Loan Availability"),
              }
            }
          : {}), // Remove additional_details if not residential
  
        ...(isResidential
          ? {}
          : {
              amenities: {
                amenities: datafinder(dataB as any, "AMENITIES"),
              }
            }) // Include amenities if not residential
      };
      
    });
   
    console.log("for paassed add",update)
    setMypass(update)
    console.log("fedfcew",mypass)
    dispatch(setUsers(update));
    setFullData(update)
    setFinalValue(mypass)
    closeModal()
  };


  const transformValue = (value: any) => {
    if (value === 'Rent' || value === 'Lease') {
      return 'Rent/Lease';
    } else if (value === 'Sale') {
      return 'Sell';
    } else {
      return value; // or return a default value if needed
    }
  };

  const getInitialValues = (passedData: any): any => {
    const initialValues: any = {};

    // Use Object.entries to iterate over the key-value pairs of the object
    Object.entries(passedData).forEach(([key, value]) => {
      // Apply transformation if needed
      initialValues[key] = transformValue(value);
    });

    return initialValues;
  };

  const initialValues = getInitialValues(passedData[0]);


  const GetProperty = () => {
    if (passedData) {
      const Looking_To =
        initialValues?.["looking_to"] ||
        initialValues?.["rent_or_lease"] ||
        initialValues?.["property_available_for"];

      const select_property_type = initialValues?.["property_type"];
      console.log("look in details", Looking_To, select_property_type)

      if (Looking_To === "Rent/Lease") {
        switch (select_property_type) {
          case "Apartment":
          case "Independent House":
          case "Villa":
            return Residentialrent;
          case "Retail Shop":
          case "Showroom":
            return CommerRentRetailnShow;
          case "Office Space":
            return CommercialrentOffice;
          case "Warehouse":
            return CommercialrentWareh;
          case "Industrial Space":
            return CommercialIndSpace;
          default:
            return null;
        }
      } else if (Looking_To === "Sell") {
        switch (select_property_type) {
          case "Plot / Land":
            return ResidentialSellPlot;
          case "Retail Shop":
          case "Showroom":
            return CommercialSellRetailnShow;
          case "Office Space":
            return CommercialSellOff;
          case "Warehouse":
            return CommercialSellWare;
          case "Industrial Space":
            return CommercialSellIndSp;
          default:
            return Residentialsell;
        }
      }
    }
  };

  const data2 = GetProperty();

  return (
    <div>
      <div className='flex items-center gap-2 justify-center'>
      {/* <IconButton> <MdArrowBackIosNew onClick={ closeModal}  className=' w-8 h-8  z-40  md:flex' /></IconButton> */}
    <Box
      sx={{
        fontWeight: '700',
        fontFamily: 'K2D',
      }}
    >
Additional details    </Box>     <IconButton
onClick={ closeModal}        
      sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                display:"flex",
                color: 'gray.500',
              }}
            >
              <CloseIcon />
            </IconButton>   </div>    
     

      {(data2 as any)?.additional_details?.map((dataItem: AdditionalDetail, index: number) => (
        <div key={index}>
          {dataItem.Type === 'Box Type' && (
            <div className="my-1">
              <Typography className="text-start">{dataItem.label}</Typography>
              <div className="flex gap-7 my-1">
                <Box>
                  <CustomisedButton
                    data={dataItem.data}
                    label={dataItem.label}
                    baseData={dataB}
                    setDataB={setDataB}
                    inputId={dataItem.inputID}
                    formName="Additional Details"
                    required={dataItem.required}
                    initial={getInitialValue(dataItem.label)}
                  />
                </Box>
              </div>
            </div>
          )}
          {dataItem.Type === 'Box Type2' && (
            <div>
              <Typography className="text-start">{dataItem.label}</Typography>
              <div className="flex gap-7 my-1">
                <Box>
                  <CustomButton
                    data={dataItem.data}
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Additional Details"
                    required={dataItem.required}
                    initial={getInitialValue(dataItem.label)}
                  />
                </Box>
              </div>
            </div>
          )}
          {dataItem.Type === 'Box Type11' && (
            <div>
              <Typography className="text-start">{dataItem.label}</Typography>
              <div className="flex gap-7 my-1">
                <Box>
                  <MultiSelectorButton2
                    data={dataItem.data}
                    label={dataItem.label}
                    baseData={dataB}
                    setDataB={setDataB}
                    inputId={dataItem.inputID}
                    formName="Additional Details"
                    required={dataItem.required}
                    initial={getInitialValue(dataItem.label)}
                  />
                </Box>
              </div>
            </div>
          )}
          {dataItem.Type === 'Custom Box Type' && (
            <div>
              <Typography className="text-start">{dataItem.label}</Typography>
              <div className="flex gap-7 my-2">
                <Box>
                  <CustomBoxButton
                    data={dataItem.data}
                    label={dataItem.label}
                    baseData={dataB}
                    setDataB={setDataB}
                    inputId={dataItem.inputID}
                    formName="Additional Details"
                    required={dataItem.required}
                    initial={getInitialValue(dataItem.label)}
                  />
                </Box>
              </div>
            </div>
          )}
          {dataItem.Type === 'Box Type3' && (
            <div>
              <Typography className="text-start">{dataItem.label}</Typography>
              <div className="flex gap-5 my-1">
                <Box>
                  <AccessButton
                    data={dataItem.data}
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    baseData={dataB}
                    setDataB={setDataB}
                    formName="Additional Details"
                    required={dataItem.required}
                    initial={getInitialValue(dataItem.label)}
                  />
                </Box>
              </div>
            </div>
          )}
          {dataItem.Type === 'Text Field' && (
            <div className="my-6">
              <TextBox
                label={dataItem.label}
                inputId={dataItem.inputID}
                baseData={dataB}
                formName="Additional Details"
                setDataB={setDataB}
                label2={dataItem.label2}
                keyboard={dataItem.keyboard}
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'Price Text Field' && (
            <div className="my-6">
              <Typography className="text-start">{dataItem.label}</Typography>
              <PriceTextField
                label={dataItem.label}
                inputId={dataItem.inputID}
                formName="Additional Details"
                baseData={dataB}
                setDataB={setDataB}
                label2={dataItem.label2}
                keyboard={dataItem.keyboard}
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'Price Text Field1' && (
            <div>
              <PriceTextField1
                label={dataItem.label}
                inputId={dataItem.inputID}
                formName="Additional Details"
                label2={dataItem.label2}
                keyboard={dataItem.keyboard}
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'Price Text Field2' && (
            <div>
              <PriceTextField2
                label={dataItem.label}
                inputId={dataItem.inputID}
                formName="Additional Details"
                label2={dataItem.label2}
                baseData={dataB}
                setDataB={setDataB}
                keyboard={dataItem.keyboard}
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'Drop down' && (
            <div>
              <DropBox1
                label={dataItem.label}
                options={dataItem.data}
                inputId={dataItem.inputID}
                formName="Additional Details"
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'FDrop down' && (
            <div>
              <FDropDown
                label={dataItem.label}
                options={dataItem.data}
                inputId={dataItem.inputID}
                baseData={dataB}
                setDataB={setDataB}
                formName="Additional Details"
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'Drop down1' && (
            <div>
              <DropDown1
                label={dataItem.label}
                options={dataItem.data}
                inputId={dataItem.inputID}
                baseData={dataB}
                setDataB={setDataB}
                formName="Additional Details"
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'calender' && (
            <div>
              <DatePick
                label={dataItem.label}
                inputId={dataItem.inputID}
                formName="Additional Details"
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'Text Area' && (
            <div>
              <Typography className="text-start">{dataItem.label}</Typography>
              <TextAreaBox
                label={dataItem.label}
                inputId={dataItem.inputID}
                baseData={dataB}
                setDataB={setDataB}
                passedData={passedData}
                formName="Additional Details"
                required={dataItem.required}
                initial={getInitialValue(dataItem.label)}
              />
            </div>
          )}
          {dataItem.Type === 'AMENITIES' && (
            <div>
              <Typography className="text-start mb-3">{dataItem.label}</Typography>
              <div>
                <MultiSelectorButton3
                  data={dataItem.data}
                  baseData={dataB}
                  setDataB={setDataB}

                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Additional Details"
                  required={dataItem.required}
                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            </div>
          )}
        </div>
      ))}

   
      <Box className=" w-full flex justify-center items-center">
            <button
              type="submit"
              onClick={handleSubmit}
              className="w-[150px] bg-[#1063b5] text-white rounded-[20px]   custom-xxl:ml-[60%] xl:ml-[60%] lg:ml-[65%] md:ml-[60%] sm:ml-[60%] p-2"
            >
              Continue
            </button></Box>
    </div>
  );
};

export default AdditionalDetails;
