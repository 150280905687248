import { Button } from '@mui/material';
import React, { useState } from 'react';

import profile from '../images/profile.png';
import { axiosInstance } from "../api/APIs";
import { useDispatch } from "react-redux";
import { resetUserLoginData, setUserLoginData } from '../../slices/userDataSlice'
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box,  IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { resetUser } from '../../slices/filterSlice';




interface FormErrors {
    name?: string;
    email?: string;
    companyName?: string;
}

interface ListProps {
    onClose?: () => void;
    phone?: any;
    UserId?: any
    token?: any
    encryptedToken?: any
    detail?:any
}

interface UserLoginData {
    user_id: any;
    name: string;
    phone: any;
    email: string;
    category: any;
    lang: string;
    token?: any;
    encryptedToken?: any

}


const LoginDetilas: React.FC<ListProps> = ({}) => {
    
    const loginData = useSelector((item: any) => (item.userData.userLoginData))
    const [details, setDetails] = useState(false);
    const [profileImage, setProfileImage] = useState<string>();
    const [category, setCategory] = useState<string | undefined>();
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const phone=""
    const UserId=""
    const dispatch = useDispatch();
    const Navigate = useNavigate()

    console.log(loginData)

    function handleChange(value: string) {
        if (value !== "Owner") {
            setDetails(true);
        } else {
            setDetails(false);
        }
        setCategory(value);
    }



    function handleName(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value);
    }

    function handleEmail(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail(event.target.value);
    }




    async function handleSubmitForm() {
        console.log("hg");

        let errors: FormErrors = {};

        if (!name) {
            errors.name = 'Please enter your name';
        }

        if (!email && !loginData.email) {
            errors.email = 'Please enter your email';
        } else if (email) {
            
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailPattern.test(email)) {
                errors.email = 'Please enter a valid email address';
            }
        }



        


        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 2000);
            return;
        }

        setFormErrors({});

        const body: UserLoginData = {
            user_id:loginData.user_id,
            name: name,
            email: email,
            phone: loginData.phone,
            lang: "en",
            category: loginData.category,
            token: loginData.token,
            encryptedToken: loginData.encryptedToken
        };

        console.log(body);

        try {
            const response = await axiosInstance.put("/user/update/userNameEmail", body);
            console.log('Response:', response);
            dispatch(setUserLoginData(body)) 
            Navigate('/')
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    function handleback(){
        dispatch(resetUserLoginData())
        dispatch(resetUser())
        Navigate(-1)
    }

  return (
   
<Box
      sx={{
        display: 'flex',
        // display:"flex",
        alignItems: 'center',
        justifyContent:"center",
        position: 'absolute',
        height:"100%",
        width:"100%",
  
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          bgcolor: 'white',
        //   border: '0.5px solid',
          borderColor: 'gray.300',
          borderRadius: '20px',
          mx: { xs: 4, md: 10 }, // Responsive margins
          overflowY: "hidden",
          padding:"30px",
        //   width: { xs: '90vw', sm: '70vw', md: '40vw', lg: '40vw', xl: '40vw' },
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          position: 'relative',
          boxShadow: 3, // Add box shadow here

        }}
      >
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column',height:{xs:"300px",sm:"300px",md:"400px",lg:"400px",xl:"500px"}, }}> 
        <IconButton
    onClick={handleback}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                display:"flex",
                color: 'gray.500',
              }}
            >
              <CloseIcon />
            </IconButton>
         
                <div className='mt-10 sm:flex sm:flex-col justify-center items-center'>
                    <p className='mb-3 font-bold mt-1'>Enter your contact information</p>
                    <div className='mb-3'>
                        <div className='flex gap-3 mb-1'>
                            <p>Name</p>
                            <input onChange={handleName} className='border-none outline-none' />
                        </div>
                        {showAlert && formErrors.name && (
                            <div className='absolute ml-4 z-10 font-semibold text-red-500'>
                                {formErrors.name}
                            </div>
                        )}
                        <div className='border border-1 border-[#1F1F1F] '></div>
                    </div>
                    <div className='mb-3'>
                        <div className='flex gap-3 mb-1'>
                            <p>E-Mail</p>
                            <input onChange={handleEmail} className='border-none lowercase outline-none' />
                        </div>
                        {showAlert && formErrors.email && (
                            <div className='absolute ml-4 z-10 font-semibold text-[#f44336]'>
                                {formErrors.email}
                            </div>
                        )}
                        <div className='border border-1 border-[#1F1F1F] '></div>
                    </div>
                    <div>
                        <div className='flex gap-3 mb-1'>
                            <p>Phone</p>
                            <input value={loginData.phone} className='border-none opacity-70 outline-none' readOnly />
                        </div>
                        <div className='border border-1 border-[#1F1F1F] '></div>
                    </div>
                </div>

 
                <div className='flex  justify-center items-center  mt-5 flex-col'>
                    <div onClick={handleSubmitForm} className='flex justify-center  items-center py-2 px-20 rounded-3xl bg-[#1063b5]'>
                        <button className=' text-white'>Submit</button>
                    </div>
                    <p className='font-light text-sm '>This helps us personalize your experience.</p>
                </div>
            
</Box></Box></Box>
  )
}

export default LoginDetilas