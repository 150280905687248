import React from 'react';
import { useStepperContext } from './StepperContext';
import { InputAdornment, TextField, SxProps, Theme, Typography } from '@mui/material';
import { keyboard } from '@testing-library/user-event/dist/keyboard';
import { maskCurrency } from '../../PostForm/data';

interface PriceTextFieldProps {
  label?: any;
  formName: string;
  inputId?: number;
  label2?: string;
  keyboard: any;
  required?: boolean;
  userselectedData?: any;
  initial?: any;
  sx?: SxProps<Theme>;
}

const PriceTextField: React.FC<PriceTextFieldProps> = ({
  label,
  formName,
  inputId,
  label2,
  keyboard,
  required,
  userselectedData,
  initial,
}) => {
  const { addUserData } = useStepperContext();
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | { value: unknown }>
  ) => {
    const data = {
      id: inputId,
      [label]: maskCurrency(event.target.value as any),
      formName: formName,
      required: required,
    };
    addUserData(data as any);
  };
  console.log('ll', label, initial);
  const hasError = userselectedData?.includes(label);
  console.log(11111)

  const convertToNumber = (currencyString:any) => {
    // Remove currency symbols and commas, then convert to number
    const numericString = currencyString
      .replace(/[^0-9.]/g, '') // Remove non-numeric characters except for decimal points
      .replace(/^0+(?!\.|$)/, ''); // Remove leading zeros
  
    return parseFloat(numericString); // Convert the cleaned string to a number
  };
  const cleanedData = convertToNumber(initial);
  return (
    <div>
      <TextField
        id={label}
        label={label}
        size="small"
        variant="filled"

        type={keyboard ? keyboard : 'text'}
        sx={{ width: "100%",            borderRadius: "999px",
          '& .MuiFilledInput-underline:before': {
                    borderBottom: '0px solid transparent', // Dotted underline
                  },
                  '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: '0px solid transparent', // Dotted underline
                  }, }}
              
        onChange={handleChange}
        defaultValue={cleanedData}
        className="w-[100%]"
        InputProps={{
          style: {
            borderRadius: "999px",
          
            backgroundColor: "#F5F4F8",

          },
          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          endAdornment: label2 && (
            <InputAdornment position="end">{label2}</InputAdornment>
          ),
        }}>
        </TextField>

      {hasError &&  <Typography sx={{ fontSize:"12px" ,color:"#f44336",marginLeft:"20px"}}>
          *This field is required
        </Typography>}
    </div>
  );
};

export default PriceTextField;
