import avoidBrokers from "../../assets/use1.svg";
import freeListing from "../../assets/use2.svg";
import shortListed from "../../assets/use3.svg";
import rentalAgreement from "../../assets/use4.svg";
import Your from "../../assets/use5.svg";

const whyVattara = [
  {
    name: "Direct Owners Contact",
    image: avoidBrokers,
  },
  {
    name: "Free Listing",
    image: freeListing,
  },
  {
    name: "Shortlisted without Visit",
    image: shortListed,
  },
  {
    name: "Property’s Complete Details",
    image: rentalAgreement,
  },
  {
    name: "Guide Your Self ",
    image: Your,
  },
];

const WhyVattara = () => {
  return (
    <section className=" bg-[#F5F5F5]">
      <div className="font-normal text-sm md:text-base flex items-center  sl:px-0">
        <div className="h-[1px] mt-3 bg-black flex-grow"></div>


        <div className="mx-2 text-xl lg:mx-6 mt-3 font-bold whitespace-nowrap">
          Why Use <span className="text-[#1063b5]">Vattara</span>
        </div>

        <div className="h-[1px] mt-3 bg-black flex-grow"></div>
      </div>
      <div className="xl:mx-[20%] msm:mx-2">

        <div className="lg:flex msm:grid msm:grid-cols-2 sm:grid sm:grid-cols-3 items-center lg:justify-between gap-6  my-8   ">
          {whyVattara.map((why) => (
            <div key={why.name} className="flex flex-col gap-1 items-center py-5 ">
              <img
                className="sm:w-20 sm:h-32 msm:w-10 msm:h-16"
                src={why.image}
                alt=""
              />
              <p className=" text-[#404040] msm:text-sm font-medium md:text-sm whitespace-nowrap">{why.name}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyVattara;
