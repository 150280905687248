import React, { useState, useEffect } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { axiosInstance } from "../../compounts/api/APIs";
import { useNavigate } from "react-router-dom";
import { getResponsiveSettings } from "../ReuseableCompounts/CarouselResponsive";
import { Box, Button } from "@mui/material";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

const ProjectsCarousel = () => {
  const [projects, setProjects] = useState<any[]>([]);
  const navigate = useNavigate();
  const CustomRightArrow: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
      <Button 
        onClick={onClick}
        style={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          borderRadius:"20px",
        //   transform: 'translateY(-50%)',
          minWidth: 'auto', // Remove default min-width
          background: '#F5F4F8', // Make background transparent
          padding: '0', // Remove padding
        }}
      >
       <MdOutlineKeyboardArrowRight        size={30} color="#000" />
      </Button>
    );
  };
  const CustomLefttArrow: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
      <Button
        onClick={onClick}
        style={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          borderRadius:"20px",
        //   transform: 'translateY(-50%)',
          minWidth: 'auto', // Remove default min-width
          background: '#F5F4F8', // Make background transparent
          padding: '0', // Remove padding
        }}
      >
       <MdOutlineKeyboardArrowLeft        size={30} color="#000" />
      </Button>
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/api/vattara/app/homescreen/get/UpcomingProject");
        console.log(response.data.Created);
        setProjects(response.data.Created);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };
    fetchData();
  }, []);

  const responsiveSettings = getResponsiveSettings(2.2, 2, 1);

  function handleAD(value: any) {
    navigate('/upcoming-projects', { state: value });
  }

 
  const filteredProjects = projects.filter(item => 
    item.images?.some((img: any) => img.isCover && img.uri)
  );

  return (
    <Box sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
         <div className="relative w-full sm:w-full md:w-full lg:w-[82%] xl:w-[99%] custom-xxl:w-[72%] custom-xl:w-[82%] xs:w-[95%]">
    <div className="xl:mx-[20%] msm:mx-2 max-w-full mt-3">
  <div className='msm:text-[15px] sm:text-xl md:text-xl font-bold text-[#202020] mb-1'>        Our Upcoming Projects
      </div>

      
      {filteredProjects.length > 0 ? (
        <Carousel
          swipeable
          draggable
          showDots={false}
          responsive={responsiveSettings}
          ssr 
          infinite
          autoPlay
          autoPlaySpeed={3000}
          keyBoardControl
          customRightArrow={<CustomRightArrow onClick={function (): void {
            throw new Error('Function not implemented.');
        } }  />}
        customLeftArrow={<CustomLefttArrow onClick={function (): void {
            throw new Error('Function not implemented.');
        } }  />}
          customTransition="transform 500ms ease-in-out"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-20-px" 
        >
          {filteredProjects.map((item) => {
           
            const coverImage = item.images.find((img: any) => img.isCover) || null;

            return (
              <div
                className="flex p-2 justify-center items-center"
                key={item.id} 
              >
                {coverImage && (
                  <img
                    onClick={() => handleAD(item.images)}
                    className="w-[100vw] h-full object-cover rounded-lg cursor-pointer"
                    src={coverImage.uri}
                    alt="Project Cover"
                  />
                )}
              </div>
            );
          })}
        </Carousel>
      ) : (
        <div className="text-center mt-4">No upcoming projects available.</div>
      )}
    </div>
    </div>
    </Box>
  );
};



export default ProjectsCarousel;
