import React, { useState } from 'react';
import { useStepperContext } from './StepperContext';
import { Box, MenuItem, TextField } from '@mui/material';

interface DropBoxProps {
  label: string;
  inputId: number;
  options: any[];
  formName: string;
  required: boolean;
  userselectedData?: any;
  initial: any;
}

const DropBox: React.FC<DropBoxProps> = ({
  label,
  options,
  formName,
  inputId,
  required,
  userselectedData,
  initial,
}) => {
  const { addUserData } = useStepperContext();

  // State to hold the selected value
  const [selectedValue, setSelectedValue] = useState(initial);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | { value: unknown }>
  ) => {
    const value = event.target.value as string;
    setSelectedValue(value); // Update the state with the selected value

    const data = {
      id: inputId,
      [label]: value,
      formName: formName,
      required: required,
    };
    addUserData(data as any);
  };

  const hasError = userselectedData?.includes(label);

  return (
    <div>
      <TextField
        select
        variant="filled"
        label={label}
        id={label}
        size="small"

        value={selectedValue} // Use the controlled value state
        onChange={handleChange}
        className="w-[100%]"

        sx={{ width: "100%",            borderRadius: "999px",
          '& .MuiFilledInput-underline:before': {
                    borderBottom: '0px solid transparent', // Dotted underline
                  },
                  '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: '0px solid transparent', // Dotted underline
                  }, }}
                  InputProps={{
                    style: {
                      borderRadius: "999px",
                    
                      backgroundColor: "#F5F4F8",

                    },
                  }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={label !== 'City' ? option.value : option.title}
          >
            {label !== 'City' ? option.label : option.title}
          </MenuItem>
        ))}
      </TextField>

      {hasError && <Box sx={{ fontSize:"12px" ,color:"#f44336",marginLeft:"20px"}}>*This field is required</Box>}
    </div>
  );
};

export default DropBox;
