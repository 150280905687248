import React, { useState } from 'react'
import editIcon from '../../assets/editForm.svg'
import { Box, Modal } from '@mui/material';
import UploadPhoto from './component/UploadPhoto';
import { useSelector } from 'react-redux';

interface AdditionalDetails {
    bathroom: string;
    balcony: string;
    preferred_tenant_type: string;
    parking: string;
    carpet_area: string;
    // Add other fields if necessary
}

interface User {
    name: string;
    mobile_no: string;
    category: string;
    company_logo: string;
    company_name: string;
}

interface PropertyImage {
    filename: string;
    id: number;
    isCover: boolean;
    name: string;
    type: string;
    uri: string;
}

interface Property {
    active: boolean;
    additional_details: AdditionalDetails;
    available_from: string;
    bhk: string;
    building_name: string;
    built_up_area: string;
    city: string;
    createdAt: string;
    delete_post_reason: string;
    district: string;
    enter_lease_amount: string;
    flat_furnishing: string;
    furnish_type: string;
    home_screen_post: boolean;
    id: number;
    images: PropertyImage[];
    kbl_property: boolean;
    landmark_street: string;
    latitude: string;
    liked: boolean;
    longitude: string;
    monthly_rent: string;
    negotiable: string;
    post_approval: string;
    post_id: string;
    propertyID: string;
    property_available_for: string;
    property_type: string;
    res_or_com: string;
    security_deposit: string;
    shareUrl: string;
    society_amenities: any; // Adjust type based on actual data or make it optional
    state: string | null;
    sublocality: string;
    tbl_user_reg: User;
    updatedAt: string;
    user_id: string;
    view_post_count: number;
    looking_to: any
    price: any
    cost: any
    lease_amount: any
}

interface DetailsProps {
    passedData?: Property;
    PassedData?:Property;
}




const EditImages: React.FC<DetailsProps> = ({ PassedData }) => {
    // const passedData = useSelector((state: any) => state.usersInfo.users);
    // console.log("redux check image",passedData)

    const [formVisible, setFormVisible] = useState<any>(false)
    const [FullData, setFullData] = useState<any>(PassedData)

    console.log("hello array check", FullData)
    const handleForm = () => {
        console.log("check price popup")
        setFormVisible(true);

    };

    const closeModal = () => {
        setFormVisible(false);

    }



    return (
        <div className='mt-3 relative'>
            <div>
                <p className='text-xl font-semibold mb-3 text-[#2E368F]'>Photos</p>
                <img onClick={handleForm} src={editIcon} className='absolute top-0 right-0 cursor-pointer' />
                <div>
                    {FullData ? (
                        <div className='grid sm:grid-cols-3 msm:grid-cols-2 gap-5'>
                            {FullData[0]?.images?.map((item:any) => (
                                <div >
                                    <img className='w-40 h-40 rounded-xl' src={item.uri} />
                                    <p   className="absolute -mt-6 text-black text-xs p-1 rounded-tr-2xl cursor-pointer bg-white"
 >{item.selectedTag?item.selectedTag:""}</p>
                                </div>
                            ))}
                        </div>
                    ) : ""}
                </div>
            </div>
            

            <Modal
                open={formVisible}
                onClose={closeModal}
                aria-labelledby="basic-modal-title"
                aria-describedby="basic-modal-description"
            >
                <Box className="custom-scrollbar"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { md: "80%", lg: "50%", xs: "95%",xl:"40%" },
                        maxHeight: '90vh',
                        bgcolor: 'background.paper',
                        border: '0px solid #fff',
                        borderRadius:"20px",
                        boxShadow: 24,
                        p: { md: 4, xs: 1 },
                        overflowY: 'auto',
                    }}
                >
                    <UploadPhoto closeModal={closeModal} setFullData={setFullData}   />
                </Box>
            </Modal>


        </div>
    )
}

export default EditImages