function valueLabelFormat(value: number) {

    if (value < 99999) {
      return value.toLocaleString();
    }

    if (value >= 1000000000) {
      return (value / 10000000).toFixed(2) + ' Cr';
    }
    if (value >= 10000000) {
      return (value / 10000000).toFixed(2) + ' Cr';
    }
    if (value >= 1000000) {
      return (value / 100000).toFixed(2) + ' Lac';
    }
    if (value >= 100000) {
      return (value / 100000).toFixed(2) + ' Lac';
    }
    if (value >= 10000) {
      return (value / 1000).toFixed(0) + 'k';
    }
    if (value >= 1000) {
      return (value / 1000).toFixed(1) + 'k';
    }
    return value.toString();
  }



  // Handle string price
 export const formatPrice = (price: string | number): string => {
    if (typeof price === 'number') {
      return valueLabelFormat(price);
    }

    // Handle string price
    const numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));
    return valueLabelFormat(numericPrice);
  };
