import React, { useEffect, useState } from 'react'
import { useStepperContext } from './StepperContext'
import { MenuItem, TextField } from '@mui/material'
import { updateValueInArray } from '../../PostForm/component'

interface FDropDownProps {
  label: string,
  inputId?: number,
  formName: string,
  options: any,
  title?: any,
  required?: boolean,
  userselectedData?: any
  initial?: any
  setDataB: any
  baseData: any
  setSelectedValues?:any
}


const FDropDown: React.FC<FDropDownProps> = ({ label, baseData,setSelectedValues, setDataB, inputId, formName, title, required, userselectedData, initial }) => {
  const { addUserData, userData } = useStepperContext()
  const [selectedValue, setSelectedValue] = useState<string>(initial || '');

  useEffect(() => {
    if (initial) {
      setSelectedValue(initial);
    }
  }, [initial]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
    const newValue = event.target.value as string;
    setSelectedValue(newValue);
    console.log("FDrop", newValue)
    const updatedData = updateValueInArray(baseData,label,newValue)
    console.log("FDROP for updateARR",updatedData)
    setDataB(updatedData)
    setSelectedValues([])
    const data = {
      id: inputId,
      [label]: newValue,
      formName: formName,
      required: required
    }
    addUserData(data as any)
  }
  console.log('floor', userData)
  const hasError = userselectedData?.includes(label)

  const options = Array.from({ length: 101 }, (_, index) => ({ id: index, title: index.toString() }));
  // Generate options from 0 to 100
  return (

    <div>
      <TextField
        select
        label={label}
        id={label}
        onChange={handleChange}
        value={selectedValue}
               size="small"
 variant="filled"  
 className='w-[100%]'
 sx={{ width: "100%",            borderRadius: "999px",
  '& .MuiFilledInput-underline:before': {
            borderBottom: '0px solid transparent', // Dotted underline
          },
          '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: '0px solid transparent', // Dotted underline
          }, }} 
          InputProps={{
            style: {
              borderRadius: "999px",
              
  
  fontFamily:"K2D",            backgroundColor: "#F5F4F8",
            },  }}     >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.title}>
            {option.title}
          </MenuItem>
        ))}
      </TextField>

      {hasError && <div>This field is required</div>}


    </div>
  )
}

export default FDropDown