

import React from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MdArrowBackIos, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Box, IconButton } from "@mui/material";


interface Image {
  id: number;
  filename: string;
  name: string;
  uri: string;
  isCover: boolean;
}

const UpComingProjects: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const passedData = location.state as Image[]; 

  console.log('Passed Data:', passedData);

 
  const nonCoverImages = passedData.filter((img: Image) => !img.isCover);

  return (
    <div className="md:py-4 w-full md:w-[90%] xl:w-[70%] mx-auto ">
      <Box  sx={{display:"flex",justifyContent:"flex-start"}}        className="fixed w-full h-15  z-40 bg-[#fff]  lg:pt-3  sm:mt-10 md:flex  justify-center top-0"
 >

 <IconButton
      onClick={() => navigate(-1)} // Inline navigation logic
      aria-label="Back"
      sx={{
        borderRadius: '50%', // Optional: for a circular button
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)', // Optional: change background on hover
        },
      }}
    >
      <MdArrowBackIos className='w-8 h-7 ' color="#000" />
    </IconButton>
  </Box>
      <div className="flex flex-col items-center w-full mt-20 msm:mt-10  sm:mt-20 lg:margin-top: 20px">
        {nonCoverImages.map((image) => (
          <div key={image.id} className="shadow w-[90%] m md:w-[70%] h-full">
            <img
              src={image.uri}
              alt={image.name}
              className="w-full h-auto"
            />
            {/* <p className="text-center mt-2 text-sm">{image.name}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpComingProjects;
