import React, { useEffect, useState } from 'react';
import { useStepperContext } from './StepperContext';
import { MenuItem, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

interface DropDownProps {
  label: string;
  options: any;
  initial: any;
  inputId: number;
  formName: string;
  required: boolean;
  userselectedData?: any;
}

const DropDownBox: React.FC<DropDownProps> = ({
  label,
  options,
  inputId,
  formName,
  required,
  userselectedData,
  initial,
}) => {
  const { addUserData, userData } = useStepperContext();
  
  const Property_Type = userData?.find((item) => item['Property Type'])?.['Property Type'];
  const Looking_To = userData?.find((item) => item['Looking To'])?.['Looking To'];

  let items = [] as any;

  if (Property_Type && Looking_To) {
    let filteredData = [];
    if (Property_Type === 'Residential' && Looking_To === 'Rent/Lease') {
      filteredData = options.slice(1, 4);
    } else if (Property_Type === 'Residential' && Looking_To === 'Sell') {
      filteredData = options.slice(0, 4);
    } else if ((Property_Type === 'Commercial' && Looking_To === 'Rent/Lease') || (Property_Type === 'Commercial' && Looking_To === 'Sell')) {
      filteredData = options.slice(4, 9);
    }

    items = filteredData.map((item: any) => ({ label: item.title, value: item.title }));
  }

  const [dropdownItems, setDropdownItems] = useState(items);
  const [selectedValue, setSelectedValue] = useState(initial);

  useEffect(() => {
    setDropdownItems(items);
  }, [Property_Type, Looking_To, options]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
    const value = event.target.value as string;
    setSelectedValue(value);

    const data = {
      id: inputId,
      [label]: value,
      formName: formName,
      required: required,
    };
    addUserData(data as any);
  };

  const hasError = userselectedData?.includes(label);

  return (
    <div>
      <TextField
        select
        label={label}
        variant="filled"
        id={label}
        value={selectedValue}
        size="small"
        onChange={handleChange}
        className="w-[100%]"

        sx={{ width: "100%",            borderRadius: "999px",
'& .MuiFilledInput-underline:before': {
          borderBottom: '0px solid transparent', // Dotted underline
        },
        '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
          borderBottom: '0px solid transparent', // Dotted underline
        }, }}
        InputProps={{
          style: {
            borderRadius: "999px",
fontFamily:"K2D",            backgroundColor: "#F5F4F8",
          },
        }}
        
      >
        {dropdownItems.map((option: any, index: any) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      {hasError && <Typography sx={{ fontSize:"12px" ,color:"#f44336" ,marginLeft:"20px"}}>*This field is required</Typography>}
    </div>
  );
};

export default DropDownBox;
