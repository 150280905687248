// import React from 'react';
// import { useLocation } from 'react-router-dom';
// import phone from '../compounts/images/Black-phone.png';
// import mail from '../compounts/images/mail.png';
// import call from '../compounts/images/Call.png';
// import { Box, CircularProgress, IconButton, TextField } from "@mui/material";
// import { MdArrowBackIos, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
// import { useNavigate } from "react-router-dom";



// const ContactUser: React.FC = () => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   const data = location.state?.data ?? []; // Ensure data is an array

//   console.log(data);
//   console.log(data.length);

//   return (
//     <div className="">

   
//     <div className="w-full flex flex-col justify-center items-center ">
      
//     <div className="lg:w-[40%] msm:w-full sm:w-full h-full mt-10   ">
//     <Box  sx={{display:"flex",justifyContent:"flex-start"}}        className="fixed w-full h-15  z-40 bg-[#fff]  lg:mt-10  sm:mt-10 md:flex  justify-center items-center top-0"
//    >
  
//    <IconButton
//         onClick={() => navigate(-1)} // Inline navigation logic
//         aria-label="Back"
//         sx={{
//           borderRadius: '50%', // Optional: for a circular button
//           '&:hover': {
//             backgroundColor: 'rgba(0, 0, 0, 0.08)', // Optional: change background on hover
//           },
//         }}
//       >
//         <MdArrowBackIos className='w-8 h-7 ' color="#000" />
//       </IconButton>
//       <div className="font-bold">
// Your Property Contacted people      </div>
//     </Box>
//     <div className='mt-20 flex flex-col gap-6'>
//       {Array.isArray(data) && data.length > 0 ? (
//         data.map((item: any, index: number) => (
//           <div key={index} className='flex gap-10 justify-between bg-[#F5F4F8] items-center p-3 rounded-lg'>
//             <div className='flex flex-col gap-1 ml-2'>
//               <p className='text-lg font-semibold'>{item.name}</p>
//               <p className='text-sm text-gray-500'>{new Date(item.createdAt).toLocaleDateString()}</p>
//               <div className='flex items-center justify-start gap-2'>
//                 <img src={phone} alt="Phone icon" className='w-4 h-4' />
//                 <p>{item.mobile_no}</p>
//               </div>
//               <div className='flex items-center gap-2'>
//                 <img src={mail} alt="Mail icon" className='w-4 h-4' />
//                 <p>{item.email}</p>
//               </div>
//             </div>
//             <div className='flex mr-3 bg-[#1063b5] w-20 h-20 justify-center rounded-full items-center'>
//               <img src={call} alt="Call icon" className='w-10 h-10' />
//             </div>
//           </div>
          
//         ))
//       ) : (
//         <p>No data available</p>
//       )}
//     </div>
//     </div>
//     </div>
//     </div>
//   );
// };

// export default ContactUser;


import React from 'react';
import { useLocation } from 'react-router-dom';
import phone from '../compounts/images/Black-phone.png';
import mail from '../compounts/images/mail.png';
import call from '../compounts/images/Call.png';
import { Box, IconButton } from "@mui/material";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ContactUser: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const data = location.state?.data ?? []; // Ensure data is an array

  return (
    <div className="">
      <div className="w-full flex flex-col justify-center items-center">
        <div className="lg:w-[40%] msm:w-full sm:w-full h-full ">
          <Box
            sx={{ display: "flex", justifyContent: "flex-start" }}
            className="fixed w-full h-15 z-40 bg-[#fff] lg:mt-12 sm:mt-11 msm:mt-1 mx-2 md:flex justify-center items-center top-0"
          >
            <IconButton
              onClick={() => navigate(-1)}
              aria-label="Back"
              sx={{
                borderRadius: '50%', // Optional: for a circular button
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)', // Optional: change background on hover
                },
              }}
            >
              <MdArrowBackIos className='w-8 h-7' color="#000" />
            </IconButton>
            <div className="font-bold">
              Your Property Contacted people
            </div>
          </Box>
          <div className='msm:mt-14 sm:mt-24 lg:mt-24  flex flex-col gap-6'>
            {Array.isArray(data) && data.length > 0 ? (
              data.map((item: any, index: number) => (
                <div key={index} className='flex gap-10 justify-between mx-2 bg-[#F5F4F8] items-center p-3 rounded-lg'>
                  <div className='flex flex-col gap-1 ml-2'>
                    <p className='text-lg font-semibold'>{item.name}</p>
                    <p className='text-sm text-gray-500'>{new Date(item.createdAt).toLocaleDateString()}</p>
                    <div className='flex items-center justify-start gap-2'>
                      <img src={phone} alt="Phone icon" className='w-4 h-4' />
                      <p>{item.mobile_no}</p>
                    </div>
                    <div className='flex items-center gap-2'>
                      <img src={mail} alt="Mail icon" className='w-4 h-4' />
                      <p>{item.email}</p>
                    </div>
                  </div>
                  <IconButton
                    onClick={() => window.location.href = `tel:${item.mobile_no}`} // Dialing logic
                    className='mr-3 bg-[#1063b5] w-20 h-20 justify-center rounded-full'
                    aria-label="Call"
                  >
                    <img src={call} alt="Call icon" className='w-10 h-10' />
                  </IconButton>
                </div>
              ))
            ) : (
              <p>No data available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUser;
