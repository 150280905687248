import React, { useEffect, useState } from "react";
import { useStepperContext } from "./StepperContext";
import { Box, Button, Grid, IconButton, Modal, Typography } from "@mui/material";
import TextBox from "./TextBox";
import CustomisedButton from "./CustomisedButton";
import Popup from "./Popup";

import {
  CommerRentRetailnShow,
  CommercialIndSpace,
  CommercialSellIndSp,
  CommercialSellOff,
  CommercialSellRetailnShow,
  CommercialSellWare,
  CommercialrentOffice,
  CommercialrentWareh,
  ResidentialSellPlot,
  Residentialrent,
  Residentialsell,

} from "../data";
import CustomButton from "./CustomButton";
import CustomBoxButton from "./CustomBoxButton";
import DropBox from "./DropBox";
import DropBox1 from "./DropBox1";
import FDropDown from "./FDropDown";
import DropDown1 from "./DropDown1";
import PriceTextField from "./PriceTextField";
import PriceTextField1 from "./PriceTextField1";
import PriceTextField2 from "./PriceTextField2";
import DatePick from "./DatePick";
import CustomisedButton1 from "./CustomisedButton1";
import AccessButton from "./AccessButton";
import TextAreaBox from "./TextAreaBox";
import YFDropdown from "./YFDropdown";
// import ModalDataForm from "./ModalDataForm";
import MultiSelectorAmenitiesButton from "../reusablecomponent/MultiSelectorAmenitiesButton";
import MultiSelectorButton2 from "../reusablecomponent/MultiSelectorButton2";
import { datafinder } from "../../PostForm/component";
import { useDispatch, useSelector } from 'react-redux';
import { setUsers } from '../../../slices/filterSlice';
import { MdArrowBackIosNew } from "react-icons/md";
import CloseIcon from '@mui/icons-material/Close';
import MultiSelectorButton3 from "../reusablecomponent/MultiSelectorButton3";


interface FormField {
  id: number;
  title: string;
  icon: string;
}

export interface FormOffers {
  formName: string;
  fields: {
    type: string;
    data: FormField[];
    label: string;
    inputId: number;
    required: boolean;
  }[];
}

interface PropertyDetailsProps {
  // onNext?: () => void;
  // onBack?: () => void;
  // onReset: () => void;
  setFullData?: any
  propertyDetail: any
  passedData?: any
  setFinalValue?: any
  closeModal: any
}

interface InitialValues {
  [key: string]: string;
}

const PropertyDetails: React.FC<PropertyDetailsProps> = ({
  // onNext,
  // onBack,
  // onReset,
  setFullData,
  closeModal,
  propertyDetail,
  setFinalValue,


}) => {
  const {
    addUserData,
    userData,
    navprop,
    ammenties,
    addCurrentPage,
    customFormProps,
    addCustomFormDetails,
    customFormDetails,
    addnavprop,
  } = useStepperContext();

  const passedData = useSelector((state: any) => state.usersInfo.users);
  const [userselectedData, setuserSelectedData] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false); // State for modal visibility
  const [mypass, setMypass] = useState(passedData)
  const [selectedValues, setSelectedValues] = useState<string[]>();
  const [dataB, setDataB] = useState(propertyDetail)
  console.log("propertyDetail array", propertyDetail, mypass)
  const dispatch = useDispatch();


  const transformValue = (value: any) => {
    if (value === 'Rent' || value === 'Lease') {
      return 'Rent/Lease';
    } else if (value === 'Sale') {
      return 'Sell';
    } else {
      return value; // or return a default value if needed
    }
  };

  const getInitialValues = (passedData: any): any => {
    const initialValues: any = {};

    // Use Object.entries to iterate over the key-value pairs of the object
    Object.entries(passedData).forEach(([key, value]) => {
      // Apply transformation if needed
      initialValues[key] = transformValue(value);
    });

    return initialValues;
  };

  const initialValues = getInitialValues(passedData[0]);
  const InitialValues = getInitialValues(propertyDetail);
  console.log("initialValues from propertyDetail", initialValues, InitialValues);

  const Construction = initialValues?.["Possession Status"]
  const GetProperty = () => {
    if (passedData) {
      const Looking_To =
        initialValues?.["looking_to"] ||
        initialValues?.["rent_or_lease"] ||
        initialValues?.["property_available_for"];

      const select_property_type = initialValues?.["property_type"];
      console.log("look in details", Looking_To, select_property_type)

      if (Looking_To === "Rent/Lease") {
        switch (select_property_type) {
          case "Apartment":
          case "Independent House":
          case "Villa":
            return Residentialrent;
          case "Retail Shop":
          case "Showroom":
            return CommerRentRetailnShow;
          case "Office Space":
            return CommercialrentOffice;
          case "Warehouse":
            return CommercialrentWareh;
          case "Industrial Space":
            return CommercialIndSpace;
          default:
            return null;
        }
      } else if (Looking_To === "Sell") {
        switch (select_property_type) {
          case "Plot / Land":
            return ResidentialSellPlot;
          case "Retail Shop":
          case "Showroom":
            return CommercialSellRetailnShow;
          case "Office Space":
            return CommercialSellOff;
          case "Warehouse":
            return CommercialSellWare;
          case "Industrial Space":
            return CommercialSellIndSp;
          default:
            return Residentialsell;
        }
      }
    }
  };

  const data2 = GetProperty();
  console.log("my data", data2);
  useEffect(() => {
    if (data2) {
      // addCustomFormDetails(data2)
      addCustomFormDetails(data2);
    }
    if (Construction) {
      setSelectedValue(Construction)
    }
  }, [data2, customFormDetails, Construction]);



  const generateValidationSchema = (customFormDetails: any, userData: any) => {
    let requiredLabels = customFormDetails?.property_details
      ?.filter((item: any) => item.required)
      ?.map((item: any) => item.label);

    // If possession status is 'Under Construction', ensure 'calendar2' is included in required labels
    if (selectedValue === "Under Construction") {
      const calendar2Label = customFormDetails?.property_details?.find(
        (item: any) => item.Type === "calendar2"
      )?.label;
      if (calendar2Label) {
        requiredLabels.push(calendar2Label);
      }
    }

    const existingLabels = requiredLabels?.filter(
      (label: any) => !userData.some((item: any) => item.hasOwnProperty(label))
    );

    if (existingLabels?.length > 0) {
      setuserSelectedData(existingLabels);
    } else {
      // onNext();
    }
  };
  const amenitiesObject = dataB.find((item: any) => item.Amenities !== undefined);

  // Extract the value of 'Amenities'
  const Amenitie = amenitiesObject ? amenitiesObject.Amenities : "";

  const furnishTypeObject = dataB.find((item: any) => item['Furnishing'] !== undefined);

  // Extract the value of 'Furnish Type'
  const flat = furnishTypeObject ? furnishTypeObject['Furnishing'] : null;

  const built_up_area = dataB?.find((item: any) => item.hasOwnProperty('Built up Area'))?.['Built up Area']
  const property_type = dataB?.find((item: any) => item?.hasOwnProperty('property_type'))?.property_type
  const look_to = dataB?.find((item: any) => item?.hasOwnProperty('look_to'))?.['look_to']
  const measure = dataB?.find((item: any) => item?.hasOwnProperty('measure'))?.measure;

  const handleSubmit = () => {





    console.log("proerty DataB", dataB)
    const update = mypass.map((item: any) => {
      const builtUpAreaValue = (item.property_type === "Plot / Land") || (item.property_type === "Industrial Space")
      ? datafinder(dataB as any, "Plot Area")
      : datafinder(dataB as any, "Built up Area" || "Built Up Area");



      const Calculated = (look_to === "Sale") &&(property_type === 'Plot / Land' || item.property_type === 'Industrial Space') &&
        (measure === 'Cent' || measure === 'Acre')
        ? measure === 'Cent'
          ? built_up_area * 435
          : built_up_area * 43500 
        : built_up_area || item.plot_area || '';

      console.log("Calculated", Calculated, measure, built_up_area,look_to)

     


      return {
        ...item,
        building_name: datafinder(dataB as any, "Building/Project(Optional)"),
        bhk: datafinder(dataB as any, "BHK"),
        built_up_area: (item.property_type === "Office Space" ||
          item.property_type === "Warehouse" ||
          item.property_type === "Showroom" ||
          (item.property_type === "Retail Shop" &&
            (item.property_available_for === "Rent" || item.property_available_for === "Lease")))
          ? datafinder(dataB as any, "Built Up Area")
          : String(Calculated),
        furnish_type: datafinder(dataB as any, "Furnish Type"),
        flat_furnishing: datafinder(dataB as any, "Furnishing"),
        society_amenities: datafinder(dataB as any, "Amenities"),
        possession_status: datafinder(dataB as any, "Possession Status"),
        Suitable_for: datafinder(dataB as any, "Suitable For"),
        parking: datafinder(dataB as any, "Parking"),
        washroom: datafinder(dataB as any, "Washroom"),
        total_floors: datafinder(dataB as any, "Total Floors"),
        select_your_floor: datafinder(dataB as any, "Select Your Floor"),
        width: datafinder(dataB as any, "Width"),
        length: datafinder(dataB as any, "Length"), 
        approval_status: datafinder(dataB as any, 'Property Status'),
        patta_available: datafinder(dataB as any, "Patta Available"),
        facing: datafinder(dataB as any, "Facing"),
        carpet_area: datafinder(dataB as any, "Carpet Area"),
        property_condition: datafinder(dataB as any, "Property Condition"),
        meeting_room: datafinder(dataB as any, "Meeting Room"),
        min_num_seat: item.property_available_for === "Rent/Lease" || item.property_available_for === "Rent" || item.property_available_for === "Lease"
          ? datafinder(dataB as any, "Min. Number of seats") : datafinder(dataB as any, "Min.Number of seats"),
        max_num_seat: item.property_available_for === "Rent/Lease" || item.property_available_for === "Rent" || item.property_available_for === "Lease"
          ? datafinder(dataB as any, "Max. Number of seats") : datafinder(dataB as any, "Max.Number of seats"),
        lifts: datafinder(dataB as any, "Lifts"),
        plotArea: item.property_type === "Apartment" && item.property_available_for === "Rent/Lease" || item.property_available_for === "Rent" || item.property_available_for === "Lease" ? null : datafinder(dataB as any, 'Plot Area'),
        measure: (item.property_type === "Plot / Land") || (item.property_type === "Industrial Space") && item.property_available_for || item.looking_to === "Sale" || item.property_available_for || item.looking_to == "Sell" ? datafinder(dataB as any, 'measure') : ""

      }
    })
    console.log("for paassed", update, dataB)
    setFullData(update)
    setMypass(update)
    console.log("fedfcew", mypass)
    dispatch(setUsers(update));
    setFinalValue(mypass)
    closeModal()
    // Log the filled fields 

    // if (selectedValue === "Ready to Move") {

    //   // onNext(); // Go to the next page if possession status is "Ready to Move"
    // } else if (selectedValue === "Under Construction") {
    //   generateValidationSchema(customFormDetails, userData); // Validate if possession status is "Under Construction"
    // } else {
    //   generateValidationSchema(customFormDetails, userData);
    // }
  };


  console.log("userData", userData);

  const getInitialValue = (label: any) => {
    const entry = propertyDetail.find((obj: any) => obj.hasOwnProperty(label));
    return entry ? entry[label] : "";
  };

  const property = initialValues?.["res_or_com"]

  const select_type = initialValues?.["property_type"]


  const furnish = initialValues?.["flat_furnishing"]
  console.log("furnish", furnish)
  const amenity = initialValues?.["society_amenities"]

  console.log("amenities", amenity)


  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  console.log("check for under", selectedValue)
  const handleBoxButtonChange = (value: string) => {
    setSelectedValue(value);
    console.log("check for under", value)
  };

  const filteredPropertyDetails = select_type === 'Apartment'
    ? (data2?.property_details as any).filter((item: any) => item.label !== 'Plot Area')
    : data2?.property_details || [];

  return (
    <>
      <div>

      <div className='flex items-center gap-2 justify-center'>
      {/* <IconButton> <MdArrowBackIosNew onClick={ closeModal}  className=' w-8 h-8  z-40  md:flex' /></IconButton> */}
    <Box
      sx={{
        fontWeight: '700',
        fontFamily: 'K2D',
      }}
    >
      Property Details
    </Box>     <IconButton
onClick={ closeModal}        
      sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                display:"flex",
                color: 'gray.500',
              }}
            >
              <CloseIcon />
            </IconButton>   </div> 

        {filteredPropertyDetails?.map((dataItem: any, index: number) => (
          <Box key={index}>
            {dataItem.Type === "Text Field" && (
              <div className="my-[10px]">
                {/* <label>{item.label}</label> */}
                <TextBox
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  baseData={dataB}
                  setDataB={setDataB}
                  initial={getInitialValue(dataItem.label)}
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  userselectedData={userselectedData}
                />
              </div>
            )}
            {dataItem.Type === "Text Field Built" && (
              <div className="my-[10px]">
                {/* <label>{item.label}</label> */}
                <TextBox
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  baseData={dataB}
                  setDataB={setDataB}
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}
            {dataItem.Type === "Box Type" && (
              <div className="my-1">
                <Typography className="text-start " >
                  {dataItem.label}
                </Typography>
                <div className="flex flex-row flex-wrap ">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <CustomisedButton
                    data={dataItem.data}
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Property Details"
                    baseData={dataB}
                    setDataB={setDataB}
                    required={dataItem.required}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)}
                  />

                  {/* ))} */}
                </div>
              </div>
            )}
            {dataItem.Type === "Custom Box Type2" && (
              <div className="my-[10px]">
                <Typography className="text-start">
                  {dataItem.label}
                  {/* {dataItem.required && (
                    <span style={{ color: "red" }}> *</span>
                  )}{" "} */}
                  {/* Indicate required fields */}
                </Typography>
                <div className="flex flex-row flex-nowrap gap-2 my-2">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <Box>
                    <CustomBoxButton
                      data={dataItem.data}
                      // title={buttonItem.title}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      baseData={dataB}
                      setDataB={setDataB}
                      initial={getInitialValue(dataItem.label)}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      onSelectionChange={handleBoxButtonChange} // Pass the handler
                    />
                  </Box>
                  {/* ))} */}
                </div>
              </div>
            )}

            {dataItem.Type === "Custom Box Type" && (
              <div className="my-[8px]">
                <Typography className="text-start">
                  {dataItem.label}
                  {dataItem.required && (
                    <span style={{ color: "red" }}> *</span>
                  )}{" "}
                  {/* Indicate required fields */}
                </Typography>
                <div className="flex flex-row flex-nowrap gap-2 my-1">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <Box>
                    <CustomBoxButton
                      data={dataItem.data}
                      // title={buttonItem.title}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      baseData={dataB}
                      setDataB={setDataB}
                      formName="Property Details"
                      initial={getInitialValue(dataItem.label)}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      onSelectionChange={handleBoxButtonChange} // Pass the handler
                    />
                  </Box>
                  {/* ))} */}
                </div>
              </div>
            )}
            {dataItem.Type === "Box Type10" && (
              <div className="my-3">
                <Typography className="text-start">
                  {dataItem.label}
                  {dataItem.required && (
                    <span style={{ color: "red" }}> *</span>
                  )}
                  {/* Indicate required fields */}
                </Typography>
                <div className="flex flex-row flex-wrap gap-4 my-5">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <div>
                    <MultiSelectorAmenitiesButton
                      data={dataItem.data}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      initial={getInitialValue(dataItem.label)}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                  {/* ))} */}
                </div>
              </div>
            )}

            {dataItem.Type === "Box Type2" && (
              <div className="my-3">
                <Typography className="text-start">
                  {dataItem.label}
                  {dataItem.required && (
                    <span style={{ color: "red" }}> *</span>
                  )}{" "}
                  {/* Indicate required fields */}
                </Typography>
                <div className="flex flex-row flex-wrap gap-4 my-5">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <div>
                    <CustomButton
                      data={dataItem.data}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      initial={getInitialValue(dataItem.label)}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                  {/* ))} */}
                </div>
              </div>
            )}

            {dataItem.Type === "calendar2" &&
              selectedValue === "Under Construction" && (
                <div>
                  <DatePick
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Property Details"
                    baseData={dataB}
                    setDataB={setDataB}
                    required={dataItem.required}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)}
                  />
                </div>
              )}

            {dataItem.Type === "Box Type11" && (
              <div>
                <Typography className="text-start">{dataItem.label}</Typography>
                <div className="flex my-2">
                  {/* {dataItem.data && dataItem.data.map((buttonItem,buttonIndex)=>( */}
                  <Box>
                    <MultiSelectorButton2
                      data={dataItem.data}
                      label={dataItem.label}
                      baseData={dataB}
                      setDataB={setDataB}
                      initial={getInitialValue(dataItem.label)}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      required={dataItem.required}
                    />
                  </Box>
                  {/* ))} */}
                </div>
              </div>
            )}
            {dataItem.Type === "Box Type3" && (
              <div>
                <Typography sx={{ marginY: "5px" }} className="text-start">
                  {dataItem.label}
                </Typography>
                <div className="flex flex-row gap-1">
                  <div className="my-1">
                    <AccessButton
                      data={dataItem.data}
                      label={dataItem.label}
                      baseData={dataB}
                      setDataB={setDataB}
                      inputId={dataItem.inputID}
                      formName="Property Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  </div>
                  {/* ))} */}
                </div>
              </div>
            )}
            {dataItem.Type === "Drop down" && (
              <div>
                <DropBox1
                  label={dataItem.label}
                  options={dataItem.data}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  required={dataItem.required}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}

            <div style={{ display: 'flex', flexDirection: 'row',gap:"5%",marginTop:"5px" }}>
              <div style={{ width: "50%" }}>
                {dataItem.Type === "FDrop down" && (
                  <div className="text-" >
                    <FDropDown
                      label={dataItem.label}
                      options={dataItem.data}
                      inputId={dataItem.inputID}
                      setSelectedValues={setSelectedValues}
                      baseData={dataB}
                      setDataB={setDataB}
                      formName="Property Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  </div>
                )}
              </div>
              <div style={{ width: "50%" }}>
                {dataItem.Type === "Drop down1" && (
                  <div className="-mt-[52px]" >
                    <YFDropdown
                      label={dataItem.label}
                      options={dataItem.data}
                      inputId={dataItem.inputID}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                      baseData={dataB}
                      setDataB={setDataB}
                      formName="Property Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  </div>
                )}
              </div>
            </div>
            {dataItem.Type === "Price Text Field" && (
              <div>
                {/* <label>{item.label}</label> */}
                <PriceTextField
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  baseData={dataB}
                  setDataB={setDataB}
                  formName="Property Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}
                />
              </div>
            )}
            {dataItem.Type === "Price Text Field1" && (
              <div>
                {/* <label>{item.label}</label> */}
                <PriceTextField1
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}
                />
              </div>
            )}
            {dataItem.Type === "Price Text Field2" && (
              <div>
                {/* <label>{item.label}</label> */}
                <PriceTextField2
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  baseData={dataB}
                  setDataB={setDataB}
                  formName="Property Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}
                />
              </div>
            )}
            {dataItem.Type === "calender" && (
              <div>
                <DatePick
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  required={dataItem.required}
                  userselectedData={userselectedData}
                />
              </div>
            )}

            {dataItem.Type === "Text Area" && (
              <div>
                <Typography className="text-start">{dataItem.label}</Typography>
                <TextAreaBox
                  label={dataItem.label}
                  baseData={dataB}
                  setDataB={setDataB}
                  inputId={dataItem.inputID}
                  formName="Property Details"
                  required={dataItem.required}
                  userselectedData={userselectedData}
                />
              </div>
            )}
            <></>
          </Box>
        ))}

        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 10 }}
        >
  <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            overflowY:"auto",
            transform: 'translate(-50%, -50%)',
            width: { md: "80%", lg: "70%", xs: "95%",xl:"60%" },
            bgcolor: 'background.paper',
            border: '0px solid #fff',
            borderRadius:"20px",
            maxHeight: '90vh',

            boxShadow: 24,
            p: { md: 4, xs: 1 },
          }}
        >   
         <IconButton
onClick={() => { setModalOpen(!modalOpen) }}      sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                display:"flex",
                color: 'gray.500',
              }}
            >
              <CloseIcon />
            </IconButton>
                 {data2?.property_details?.map((dataItem: any, index: any) => (
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {dataItem.Type === "Furnishing" && (
                  <div key={index}>
                    <Typography className="text-start text-black font-bold">
                      {dataItem.label}
                    </Typography>
                    <div style={{ display: "flex", flexWrap: "wrap", marginTop: 10, marginBottom: 10 }}>
                      <MultiSelectorButton3
                        data={dataItem.data}
                        label={dataItem.label}
                        inputId={dataItem.inputID}
                        baseData={dataB}
                        setDataB={setDataB}
                        formName="Property Details"
                        required={dataItem.required}
                        userselectedData={userselectedData}
                        initial={getInitialValue(dataItem.label)}
                      />
                    </div>
                  </div>
                )}
                {dataItem.Type === "AMENITIES" && (
                  <div key={index}>
                    <Typography className="text-start">
                      {dataItem.label}
                    </Typography>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <MultiSelectorButton3
                        data={dataItem.data}
                        label={dataItem.label}
                        inputId={dataItem.inputID}
                        baseData={dataB}
                        setDataB={setDataB}
                        formName="Property Details"
                        required={dataItem.required}
                        userselectedData={userselectedData}
                        initial={getInitialValue(dataItem.label)}
                      />
                    </div>
                  </div>
                )}
              </Box>
            ))}
                      <Box className=" w-full flex justify-center items-center">

            <button              className="w-[150px] bg-[#1063b5] text-white rounded-[20px]   custom-xxl:ml-[60%] xl:ml-[60%] lg:ml-[65%] md:ml-[60%] sm:ml-[60%] p-2"
  onClick={() => { setModalOpen(!modalOpen) }}>Save</button></Box>
          </Box>
        </Modal>


        {/* <Typography variant="h6">Built Up Area</Typography>
      <Typography>{getInitialValue("Built up Area")}</Typography>
      <Typography>{getInitialValue("Plot Area")}</Typography> */}
        {property !== "Commercial" && select_type !== "Plot / Land" && (
          <>
            <button style={{ color: "#0C3D5A", fontWeight: "400" }} onClick={() => setModalOpen(!modalOpen)}>+ Add Furnishings / Amenities</button>
            <Typography sx={{ my: 1 }}><span style={{ fontWeight: 600 }}>Furnishing:</span>{`${flat.length > 0 ? flat : furnish} `}</Typography>
            <Typography ><span style={{ fontWeight: 600 }}>Amenities:</span>{`${Amenitie.length > 0 ? Amenitie : amenity}`}</Typography>
          </>)}


      </div>

      <Box className=" w-full flex justify-center items-center mt-3">
            <button
              type="submit"
              onClick={handleSubmit} 
              className="w-[150px] bg-[#1063b5] text-white rounded-[20px]   custom-xxl:ml-[60%] xl:ml-[60%] lg:ml-[65%] md:ml-[60%] sm:ml-[60%] p-2"
            >
              Continue
            </button></Box>
    </>
  );
};

export default PropertyDetails;
