
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useStepperContext } from './StepperContext';
import { blueGrey } from '@mui/material/colors';
import residentialSelectedIcon from "../../images/maki_residential-community.png";
import residentialDefaultIcon from "../../images/Vectorn.png";
import commercialSelectedIcon from "../../images/Vector.png";
import commercialDefaultIcon from "../../images/Vectorw.png";
import { Typography } from '@mui/material';

interface CustomisedButtonProps1 {
  title?: string;
  label: string;
  inputId: number;
  formName: string;
  id?: number;
  isActive?: boolean;
  onClick?: () => void;
  required: boolean;
  generateValidationSchema?: (data: any) => void;
  userselectedData?: any;
  data?:any
  initial:any
}

const CustomisedButton1: React.FC<CustomisedButtonProps1> = ({
  label,
  inputId,
  formName,
  data,
  required,
  generateValidationSchema,
  initial,
  userselectedData,
}) => {
  const { addUserData, userData } = useStepperContext();
  const [activeButton, setActiveButton] = useState<number | null>(null);

  useEffect(() => {
    const initValue = data.find((item:any) => item.title === initial);
    setActiveButton(initValue?.id || null);
  }, [initial, data]);

  const handleClick = (item: any) => {
    setActiveButton(item.id);

    const data = {
      id: inputId,
      [label]: item.title,
      formName: formName,
      required: required,
    };
    addUserData(data as any);
  };

  const getIcon = (title: string) => {
    switch (title) {
      case 'Residential':
        return activeButton === 1 ? residentialSelectedIcon : residentialDefaultIcon;
      case 'Commercial':
        return activeButton === 2 ? commercialDefaultIcon : commercialSelectedIcon;
      default:
        return null;
    }
  };

  const hasError = userselectedData?.includes(label);
  const shouldDisable = initial === "Commercial" || initial === "Residential" || initial === "Sell"|| initial === "Rent/Lease" ;
  return (
    <div className='flex gap-3' >
      {data.map((item:any) => (
        <Button
          key={item.id}
          onClick={() => handleClick(item)}
          disabled={shouldDisable} 
          sx={{
            width:"auto",
            px:2,
           
            borderRadius: '999px',
            bgcolor: activeButton === item.id ? '#0cb7d1' : "#F5F4F8",
            color: activeButton === item.id ? 'white' : 'black',
            textTransform: 'capitalize',
            '&:hover': {
              bgcolor: activeButton === item.id ? '#0cb7d1' : blueGrey[100],
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
         {['Residential', 'Commercial'].includes(item.title) && (
      // Ensure `getIcon` is not null before using it
      (() => {
        const iconSrc = getIcon(item.title);
        if (iconSrc) {
          return (
            <img
              src={iconSrc}
              alt={item.title}
              style={{ width: '24px', height: '24px', marginRight: '8px' }}
            />
          );
        }
        return null; // Return null if no valid icon is found
      })()
    )}
          {item.title}
        </Button>
      ))}
      {hasError && <Typography sx={{ fontSize:"12px" ,color:"#f44336",marginLeft:"20px",marginTop:'5px'}}>
          *This field is required
        </Typography>}
    </div>
  );
};

export default CustomisedButton1;
