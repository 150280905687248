import React from 'react';
import { useStepperContext } from './StepperContext';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { keyboard } from '@testing-library/user-event/dist/keyboard';
import { maskCurrency } from '../../PostForm/data';
interface PriceTextField2Props {
  label: string;
  formName: string;
  inputId?: number;
  label2?: string;
  keyboard?: any;
  required?: boolean;
  userselectedData?: any;
  initial?: any;
}

const PriceTextField2: React.FC<PriceTextField2Props> = ({
  label,
  formName,
  inputId,
  label2,
  keyboard,
  required,
  initial,
  userselectedData,
}) => {
  const { addUserData, userData } = useStepperContext();
  console.log("initialPrice",initial)
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | { value: unknown }>
  ) => {
    const data = {
      id: inputId,
      [label]: maskCurrency(event.target.value as any),
      formName: formName,
      required: required,
    };
    addUserData(data as any);
  };
  console.log('lease', userData);
  const hasError = userselectedData?.includes(label);
  console.log(333333)
  const convertToNumber = (currencyString:any) => {
    // Remove currency symbols and commas, then convert to number
    const numericString = currencyString
      .replace(/[^0-9.]/g, '') // Remove non-numeric characters except for decimal points
      .replace(/^0+(?!\.|$)/, ''); // Remove leading zeros
  
    return parseFloat(numericString); // Convert the cleaned string to a number
  };
  const cleanedData = convertToNumber(initial);

  return (
    <div>
      <TextField
        id={label}
        type={keyboard ? keyboard : 'text'}
        defaultValue={cleanedData}
        label={label}
        onChange={handleChange}
     variant="filled"
              size="small"
        sx={{                  borderRadius: "999px",
          width:'100%',
               '& .MuiFilledInput-underline:before': {
                         borderBottom: '0px solid transparent', // Dotted underline
                       },
                       '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
                         borderBottom: '0px solid transparent', // Dotted underline
                       }, }} 
        InputProps={{
          style: {
            borderRadius: "999px",
fontFamily:"K2D",            backgroundColor: "#F5F4F8",
          },
          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          endAdornment: label2 && (
            <InputAdornment position="end">{label2}</InputAdornment>
          ),
        }}
      ></TextField>

      {hasError &&  <Typography sx={{ fontSize:"12px" ,color:"#f44336",marginLeft:"20px"}}>
          *This field is required
        </Typography>}
    </div>
  );
};

export default PriceTextField2;
