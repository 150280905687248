import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { blueGrey } from '@mui/material/colors';
import { useStepperContext } from '../component/StepperContext';
import { updateValueInArray } from '../../PostForm/component';
import { Typography } from '@mui/material';

interface MultiSelectorButton2Props {
  data: any;
  label: string;
  inputId?: number;
  formName: string;
  userselectedData?: any;
  initial?: any;
  required?:boolean;
  title?:string;
  setDataB:any
  baseData:any
}

const MultiSelectorButton2: React.FC<MultiSelectorButton2Props> = ({
  data,
  label,
  inputId,
  baseData,
  setDataB,
  formName,
  userselectedData,
  required,
  initial,
  title
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const { addUserData, userData } = useStepperContext();

  // console.log("initial",label==="Amenities"?initial:"")
  console.log("myufdag",data)
  // Initialize selected values from userselectedData if provided
  useEffect(() => {
    if (initial) {
      const initialSelectedValues = initial.split(',').map((item:any) => item.trim());
      setSelectedValues(initialSelectedValues);
    }
  }, [initial]);

  // Update the context with the new selected values
  const handleButtonClick = (itemData: { title: string }) => {
    const isSelected = selectedValues.includes(itemData.title);

    const newSelectedValues = isSelected
      ? selectedValues.filter(item => item !== itemData.title)
      : [...selectedValues, itemData.title];

    setSelectedValues(newSelectedValues);

    const updatedData = updateValueInArray(baseData, label, newSelectedValues.join(','));
    console.log("amFu",updatedData)
    setDataB(updatedData)


    // Prepare data object to save in addUserData
    const newDataObject = {
      id: inputId,
      [label]: newSelectedValues.join(','), // Save selected titles as a comma-separated string under the specified label
      formName: formName,
    };

    // Update userData context with newDataObject
    addUserData(newDataObject as any);
  };

  const hasError = userselectedData?.includes(label);

  return (
    <div className='flex flex-wrap gap-2'>
      {data.map((itemData :any , index:number) => (
        <div key={index}>
          <Button
            onClick={() => handleButtonClick(itemData)}
            className='h-10'
            sx={{
              borderRadius: '999px',
              padding: '20px',
              marginBottom:"10px",
              bgcolor: selectedValues.includes(itemData.title) ? '#0cb7d1' :"#F5F4F8", // Adjust bgcolor logic as per your theme
              color: selectedValues.includes(itemData.title) ? 'white' : 'black',
              textTransform: 'capitalize',
              '&:hover': {
                bgcolor: selectedValues.includes(itemData.title) ? '#0cb7d1' : "#F5F4F8", // Hover background color
              },
            }}
          >
            
            {itemData.title}
          </Button>
        </div>
      ))}
 {hasError && <Typography sx={{ fontSize:"12px" ,color:"#f44336",marginLeft:"20px",marginTop:"5px"}}>
          *This field is required
        </Typography>}    </div>
  );
};

export default MultiSelectorButton2;
