import { FaRegEdit } from "react-icons/fa";
import editIcon from '../../assets/editForm.svg'
import { propertyDetails } from "./dataJson";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useState } from "react";
import PropertyDetails from './component/PropertyDetails';
import { useSelector } from 'react-redux';
import { Card, Typography, Button ,IconButton} from '@mui/material';


interface AdditionalDetails {
  bathroom: string;
  balcony: string;
  preferred_tenant_type: string;
  parking: string;
  carpet_area: string;
  // Add other fields if necessary
}

interface User {
  name: string;
  mobile_no: string;
  category: string;
  company_logo: string;
  company_name: string;
}

interface PropertyImage {
  filename: string;
  id: number;
  isCover: boolean;
  name: string;
  type: string;
  uri: string;
}

interface Property {
  approval_status: any
  active: boolean;
  additional_details: AdditionalDetails;
  available_from: string;
  bhk: string;
  building_name: string;
  built_up_area: string;
  city: string;
  createdAt: string;
  delete_post_reason: string;
  district: string;
  enter_lease_amount: string;
  flat_furnishing: string;
  furnish_type: string;
  home_screen_post: boolean;
  id: number;
  images: PropertyImage[];
  kbl_property: boolean;
  landmark_street: string;
  latitude: string;
  liked: boolean;
  longitude: string;
  monthly_rent: string;
  negotiable: string;
  post_approval: string;
  post_id: string;
  propertyID: string;
  property_available_for: string;
  property_type: string;
  res_or_com: string;
  security_deposit: string;
  shareUrl: string;
  society_amenities: any; // Adjust type based on actual data or make it optional
  state: string | null;
  sublocality: string;
  tbl_user_reg: User;
  updatedAt: string;
  user_id: string;
  view_post_count: number;
  looking_to: any
  length: any
  width: any
  patta_available: any
  facing: any
  possession_status: any
  carpet_area: any
  property_condition: any
  total_floors: any
  select_your_floor: any
  lifts: any
  parking: any
  washroom: any
  meeting_room: any
  max_num_seat: any
  min_num_seat: any
  plot_area: any
  Suitable_for: any
  setFinalValue?: any

}

interface DetailsProps {
  passedData?: Property; // Use the Property interface here
  setFinalValue: any
  PassedData?: Property
}

const EditFormDetails: React.FC<DetailsProps> = ({ PassedData, setFinalValue }) => {
  // const passedData = useSelector((state: any) => state.usersInfo.users);
  // console.log("react check",passedData)

  const [formVisible, setFormVisible] = useState<any>(false)
  const [FullData, setFullData] = useState<any>(PassedData)
  setFinalValue(FullData)
  // console.log("redux check details", passedData)

  const handleForm = () => {
    setFormVisible(true);

  };

  const closeModal = () => {
    setFormVisible(false);

  };



  const handlePropertyDetails = () => {
    const fullData = FullData[0] || {};
    const Calculated = (fullData.property_type === 'Plot / Land' || fullData.property_type === 'Industrial Space') &&
          (fullData.measure === 'Cent' || fullData.measure === 'Acre') ? fullData.measure === 'Cent'
            ? fullData.built_up_area / 435
            :  fullData.built_up_area / 43500
          : fullData.built_up_area ||FullData[0].plot_area || '';
          console.log(fullData.property_type,fullData.measure,fullData.built_up_area,Calculated)
    return Object.keys(propertyDetails).map((key) => { 
     


      




      switch (key) {
        case "ResrCom":
          return { [key]: fullData.res_or_com || "" }
        case "look_to":
          return { [key]: fullData.looking_to || fullData.rent_or_lease || fullData.property_available_for || '' };
        case "BHK":
          return { [key]: fullData.bhk || "" };
        case "Built up Area":
        case "Built Up Area":
          return { [key]: String(Calculated) || "" };
        case "Furnish Type":
          return { [key]: fullData.furnish_type || "" };
        case "Amenities":
          return { [key]: fullData.society_amenities || "" };
        case "Furnishing":
          return { [key]: fullData.flat_furnishing || "" };
        case "Building/Project(Optional)":
          return { [key]: fullData.building_name || "" };
        case "Plot Area":
          return { [key]:String(Calculated)|| FullData[0].plot_area || FullData[0].built_up_area || "" };
        case "Length":
          return { [key]: fullData.length || "" };
        case "Width":
          return { [key]: fullData.width || "" };
        case "Property Status":
          return { [key]: fullData.approval_status || "" };
        case "Patta Available":
          return { [key]: fullData.patta_available || "" };
        case "Facing":
          return { [key]: fullData.facing || "" };
        case "Possession Status":
          return { [key]: fullData.possession_status || "" };
        case "calendar2":
          return { [key]: fullData.available_from || "" };
        case "Suitable For":
          return { [key]: fullData.Suitable_for || "" };
        case "Carpet Area":
          return { [key]: fullData.carpet_area || "" };
        case "Total Floors":
          return { [key]: fullData.total_floors || "" };
        case "Select Your Floor":
          return { [key]: fullData.select_your_floor || "" };
        case "Parking":
          return { [key]: fullData.parking || "" };
        case "Washroom":
          return { [key]: fullData.washroom || "" };
        case "Available From":
          return { [key]: fullData.available_from || fullData.possession_status || "" };
        case "Property Condition":
          return { [key]: fullData.property_condition || "" };
        case "Lifts":
          return { [key]: fullData.lifts || "" };
        case "Meeting Room":
          return { [key]: fullData.meeting_room || "" };
        case "Min.Number of seats":
        case "Min. Number of seats":
          return { [key]: fullData.min_num_seat || "" };
        case "Max.Number of seats":
        case "Max. Number of seats":
          return { [key]: fullData.max_num_seat || "" };
        case 'measure':
          return { [key]: fullData.measure || "" }
        case 'property_type':
          return { [key]: fullData.property_type || "" }
        default:
          return { [key]: '' };
      }
    });
  }

  // Instead of assigning to a variable, just call the function where it's needed
  // Now this should work without error
  const propertyDetail: any = handlePropertyDetails();
  console.log("propertyDetails", propertyDetail);

  return (
    <div className="mt-1 relative p-1">
  <Typography className="text-[15px] mb-2" sx={{color:"#1063b5",fontFamily:"K2D",fontWeight:"bold"}}>
    Property Details
  </Typography>      <img onClick={handleForm} src={editIcon} className='absolute cursor-pointer right-0 top-0' />
      <div>
        {FullData[0].res_or_com === 'Residential' &&
          (FullData[0].property_type === 'Independent House' ||
            FullData[0].property_type === 'Apartment' ||
            FullData[0].property_type === 'Villa') &&
          FullData[0].looking_to === 'Sale' && (
            <div className="flex flex-col gap-2">
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Building/Project</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].building_name}</p>
              </div>

              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">BHK</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].bhk}</p>
              </div>

              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Built Up Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].built_up_area}</p>
              </div>
              <div className={` ${FullData[0].property_type === "Apartment" ? "hidden" : "flex"}`}>
                <p className="text-sm text-[#000] font-semibold w-[50%]">Plot Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].plotArea}</p>
              </div>

              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Furnish Type</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].furnish_type}</p>
              </div>

              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Furnishings</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].flat_furnishing}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Amenities</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].society_amenities}</p>
              </div>

            </div>
          )}
      </div>
      <div>
        {FullData[0].res_or_com === 'Residential' &&
          (FullData[0].property_type === 'Independent House' ||
            FullData[0].property_type === 'Apartment' ||
            FullData[0].property_type === 'Villa') &&
          (FullData[0].property_available_for === 'Rent' || FullData[0].property_available_for === "Lease") && (
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Building/Project</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].building_name}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">BHK</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].bhk}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Built Up Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].built_up_area}</p>
              </div>

              <div className="flex">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Furnish Type</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].furnish_type}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Furnishings</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].flat_furnishing}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Amenities</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].society_amenities}</p>
              </div>

            </div>
          )}
      </div>

      <div>
        {FullData[0].res_or_com === "Residential" &&
          FullData[0].property_type === "Plot / Land" &&
          FullData[0].looking_to === "Sale" && (
            <div className="flex flex-col gap-2">
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Building/Project</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].building_name}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Plot Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].built_up_area}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Length</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].length}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Width</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].width}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Property Status</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].post_approval}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Patta Available</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].patta_available}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Facing</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].facing}</p>
              </div>
            </div>
          )}
      </div>


      <div>
        {FullData[0].res_or_com === "Commercial" &&
          FullData[0].property_type === "Office Space" &&
          (FullData[0].property_available_for === "Rent" ||
            FullData[0].property_available_for === "Lease") && (
            <div className="flex flex-col gap-2">
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Building/Project</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].building_name}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Possession Status</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].possession_status}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Property Condition</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].property_condition}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Built Up Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].built_up_area}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Carpet Area</p>
                <p>{FullData[0].carpet_area}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Total Floors</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].total_floors}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Your Floors</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].select_your_floor}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Lift</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].lifts}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Parking</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].parking}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Washroom</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].washroom}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Meeting Room</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].meeting_room}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Min. Number of Seats</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].min_num_seat}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Max. Number of Seats</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].max_num_seat}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Facing</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].facing}</p>
              </div>
            </div>
          )}
      </div>


      <div>
        {FullData[0].res_or_com === "Commercial" &&
          FullData[0].property_type === "Industrial Space" &&
          (FullData[0].property_available_for === "Rent" ||
            FullData[0].property_available_for === "Lease") && (
            <div className="flex flex-col gap-2">
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Building/Project</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].building_name}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Possession Status</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].possession_status}</p>
              </div>

              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Polt Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].built_up_area}</p>
              </div>
              <div className={`${FullData[0].property_available_for === "Rent" ? "flex" : "hidden"}`}>
                <p className="text-sm text-[#000] font-semibold w-[50%]">Facing</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].facing}</p>
              </div>
            </div>
          )}
      </div>

      <div>
        {FullData[0].res_or_com === "Commercial" &&
          FullData[0].property_type === "Warehouse" &&
          (FullData[0].property_available_for === "Rent" ||
            FullData[0].property_available_for === "Lease") && (
            <div className="flex flex-col gap-2">
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Building/Project</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].building_name}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Possession Status</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].possession_status}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Built Up Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].built_up_area}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Carpet Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].carpet_area}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Parking</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].parking}</p>
              </div>
              <div className={`flex`}>
                <p className="text-sm text-[#000] font-semibold w-[50%]">Facing</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].facing}</p>
              </div>
            </div>
          )}
      </div>


      <div>
        {FullData[0].res_or_com === "Commercial" &&
          (FullData[0].property_type === "Retail Shop" || FullData[0].property_type === "Showroom") &&
          (FullData[0].property_available_for === "Rent" ||
            FullData[0].property_available_for === "Lease") && (
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Building/Project</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].building_name}</p>
              </div>
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Possession Status</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].possession_status}</p>
              </div>
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Suitable For</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].Suitable_for}</p>
              </div>
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Built Up Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].built_up_area}</p>
              </div>
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Carpet Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].carpet_area}</p>
              </div>
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Total Floors</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].total_floors}</p>
              </div>
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Your Floors</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].select_your_floor}</p>
              </div>
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Parking</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].parking}</p>
              </div>
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Washroom</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].washroom}</p>
              </div>
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Facing</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].facing}</p>
              </div>
            </div>
          )}
      </div>

      <div>
        {FullData[0].res_or_com === "Commercial" &&
          FullData[0].property_type === "Office Space" &&
          (FullData[0].looking_to === "Sale") && (
            <div className="flex flex-col gap-2">
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Building/Project</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].building_name}</p>
              </div>
              <div className="flex">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Possession Status</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].possession_status}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Property Condition</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].property_condition}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Built Up Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].built_up_area}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Carpet Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].carpet_area}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Total Floors</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].total_floors}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Your Floors</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].select_your_floor}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Lift</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].lifts}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Parking</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].parking}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Washroom</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].washroom}</p>
              </div>
              <div className="flex">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Meeting Room</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].meeting_room}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Min. Number of Seats</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].min_num_seat}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Max. Number of Seats</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].max_num_seat}</p>
              </div>
              <div className="flex ">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Facing</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].facing}</p>
              </div>
            </div>
          )}
      </div>


      <div>
        {FullData[0].res_or_com === 'Commercial' &&
          (FullData[0].property_type === "Warehouse") &&
          FullData[0].looking_to === 'Sale' && (
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Building/Project</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].building_name}</p>
              </div>

              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Possession Status</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].possession_status}</p>
              </div>

              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Built Up Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].built_up_area}</p>
              </div>

              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Carpet Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].carpet_area}</p>
              </div>

              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Parking</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].parking}</p>
              </div>
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Facing</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].facing}</p>
              </div>
            </div>
          )}
      </div>

      <div>
        {FullData[0].res_or_com === 'Commercial' &&
          (FullData[0].property_type === "Industrial Space") &&
          FullData[0].looking_to === 'Sale' && (
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Building/Project</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].building_name}</p>
              </div>

              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Possession Status</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].possession_status}</p>
              </div>

              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Plot Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].plot_area || FullData[0].built_up_area}</p>
              </div>




              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Facing</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].facing}</p>
              </div>
            </div>
          )}
      </div>


      <div>
        {FullData[0].res_or_com === 'Commercial' &&
          (FullData[0].property_type === "Retail Shop" || FullData[0].property_type === "Showroom") &&
          FullData[0].looking_to === 'Sale' && (
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Building/Project</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].building_name}</p>
              </div>

              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Possession Status</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].possession_status}</p>
              </div>

              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Suitable For</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].Suitable_for}</p>
              </div>
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Built Up Area</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].built_up_area}</p>
              </div>



              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Total Floors</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].total_floors}</p>
              </div>

              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Your Floors</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].select_your_floor}</p>
              </div>
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Parking</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].parking}</p>
              </div>
              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Washroom</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].washroom}</p>
              </div>





              <div className="flex gap-2">
                <p className="text-sm text-[#000] font-semibold w-[50%]">Facing</p>
                <p className="text-sm text-gray-700 w-[50%]">{FullData[0].facing}</p>
              </div>
            </div>
          )}
      </div>
      <div className='border-1 border-[#8E8E8E] mt-3 '></div>

      <Modal
        open={formVisible}
        onClose={closeModal}
        aria-labelledby="basic-modal-title"
        aria-describedby="basic-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { md: "80%", lg: "50%", xs: "95%",xl:"40%" },
            maxHeight: '90vh',
            bgcolor: 'background.paper',
            border: '0px solid #fff',
            borderRadius:"20px",
            boxShadow: 24,
            p: { md: 4, xs: 1 },
            overflowY: 'auto',
          }}
        >
          <PropertyDetails setFullData={setFullData} closeModal={closeModal} setFinalValue={setFinalValue} propertyDetail={propertyDetail} />
        </Box>
      </Modal>

    </div>
  );
};

export default EditFormDetails;
