import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { ErrorMessage, Form, Formik } from 'formik';
import CustomisedButton from './CustomisedButton';
import { useStepperContext } from './StepperContext';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessIcon from '@mui/icons-material/Business';
import TextBox from './TextBox';
import { basic_details } from '../data';
import DropBox from './DropBox';
import DropDownBox from './DropDownBox';
import CustomisedButton1 from './CustomisedButton1';
import { tndistrict } from './index';
import { MdArrowBackIosNew } from 'react-icons/md';
import { useNavigate } from "react-router-dom";

const icons = {
  ApartmentIcon: <ApartmentIcon />,
  BusinessIcon: <BusinessIcon />,
};

interface NextProps {
  onNext: () => void;
  onBack: () => void;
  onReset: () => void;
}

const Basic: React.FC<NextProps> = ({ onNext, onBack, onReset }) => {
  const { userData } = useStepperContext(); // Access currentPage from StepperContext
  const [userselectedData, setuserSelectedData] = useState([]);
  const navigate = useNavigate()

  const generateValidationSchema = (basic_details: any, userData: any) => {
    // Filter required labels from form fields
    const requiredLabels = basic_details.fields
      .filter((field: any) => field.required)
      .map((field: any) => field.label);
    console.log('require', requiredLabels);
    // const missingLabels=requiredLabels
    // console.log('Missing Labels:', missingLabels);
    const existingLabels = requiredLabels.filter(
      (label: any) =>
        // Check if any object in userData has a property matching the current label
        !userData.some((item: any) => item.hasOwnProperty(label))
    );
    console.log('exist', existingLabels);

    if (existingLabels.length > 0) {
      setuserSelectedData(existingLabels);
    } else {
      onNext();
    }
  };

  const initialValues = {
    selectedPropertyType: '',
    lookingTo: '',
    city: '',
    locality: '',
    landMark: '',
  };

  const getInitialValue = (label: any) => {
    const entry = userData.find((obj) => obj.hasOwnProperty(label));
    return entry ? entry[label] : '';
  };

  return ( 
    <div className="w-[100%] p-2  gap-2 custom-scrollbar">
<Box sx={{ display: "flex", flexDirection: "row", marginBottom: "10px", alignItems: "center" }}>
  <IconButton 
    onClick={() => navigate(-1)} // Inline navigation logic
    sx={{ marginRight: "8px" }} // Space between the icon and the text
  >
    <MdArrowBackIosNew className='w-5 h-5' />
  </IconButton>
  <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
    <Box
      sx={{
        fontWeight: '700',
        fontFamily: 'K2D',
      }}
    >
      Basic Details
    </Box>
  </Box>
</Box>

      <Formik
    
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          generateValidationSchema(basic_details, userData);

          console.log(values);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit }) => (
          <Form  onSubmit={handleSubmit} className="form custom-scrollbar lg:mx-[10%] msm:mx-[1%]   overflow-Y-scroll">
            {basic_details.fields.map((basicItem, basicIndex) => (
              <div key={basicIndex}>
                {basicItem.type === 'button' && (
                  <div className=" mt-2 ">
                    <Typography                       sx={{fontSize:"15px" }}
 className="text-start gap-28  ">
                      {basicItem.label}
                    </Typography>
                    <div className="flex w-[100px]">
                      <CustomisedButton1
                        data={basicItem.data}
                        label={basicItem.label}
                        initial={getInitialValue(basicItem.label)}
                        inputId={basicItem.inputId}
                        formName={'Basic Details'}
                        required={basicItem.required}
                        userselectedData={userselectedData}
                      />
                    </div>
                  </div>
                )}
                {basicItem.type === 'DropDownBox' && (
                  <div className="w-[100%] mt-2">
                    <Typography
                      sx={{ marginY: '12px' ,fontSize:"15px"}}
                      className="text-start w-[100%]  gap-28"
                    >
                      {basicItem.label}
                    </Typography>
 
                    <DropDownBox
                      label={basicItem.label}

  // label={basicItem.label === "Property Subtype" ? "select property type" : basicItem.label}
  options={basicItem.data}
                      // title={basicItem.title}
                      inputId={basicItem.inputId}
                      initial={getInitialValue(basicItem.label)}
                      formName={'Basic Details'}
                      required={basicItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                )}

                {basicItem.type === 'DropDown' && (
                  <div>
                    <Typography
                      sx={{fontSize:"15px" , marginY: '12px'}}
                      className="text-start w-[100%] mt-2"
                    >
                      {basicItem.title}
                    </Typography>

                    <DropBox
                      label={basicItem.label}
                      options={tndistrict.districts}
                      // title={basicItem.title || ''}
                      inputId={basicItem.inputId}
                      formName={'Basic Details'}
                      initial={getInitialValue(basicItem.label)}
                      required={basicItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                )}

                {basicItem.type === 'textField' && (
                  <div className="my-2">
                    <TextBox
                      label={basicItem.label}
                      inputId={basicItem.inputId}
                      // defaultValue={initialValues.locality}
                      formName={'Basic Details'}
                      initial={getInitialValue(basicItem.label)}
                      required={basicItem.required}
                      userselectedData={userselectedData}
                    />
                  </div>
                )}
              </div>
            ))}
<Box className=" w-full flex justify-center items-center">
            <button
              type="submit"
              className="w-[150px] bg-[#1063b5] text-white rounded-[20px]  mt-6 custom-xxl:ml-[60%] xl:ml-[60%] lg:ml-[65%] md:ml-[60%] sm:ml-[60%] p-2"
            >
              Continue
            </button></Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Basic;
