


import React from 'react';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';

interface SliderComponentProps {
  onUpdateSlider: (event: Event, newValue: number | number[]) => void;
  selectedLook: string | null;
  Sub: string | null;
}
function valueLabelFormat(value: number) {
  if (value >= 1000 && value < 100000) {
    return (value / 1000).toFixed(1) + 'k';
  }
  if (value >= 10000000) {
    return (value / 10000000).toFixed(2) + ' Cr';
  } else if (value >= 100000) {
    return (value / 100000).toFixed(2) + ' Lac';
  }
  return value.toString();
}

const SliderComponent: React.FC<SliderComponentProps> = ({ onUpdateSlider, selectedLook, Sub }) => {
  const [value, setValue] = React.useState<[number, number]>([0, 100000000]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as [number, number]);
    onUpdateSlider(event, newValue);
  };

  let min, max;
  if (selectedLook === "Buy") {
    min = 0;
    max = 100000000;
  } else if (selectedLook === "Rent") {
    min = 0;
    max = 5000000;
  } else if (selectedLook === "Lease") {
    min = 0;
    max = 100000000;
  } else if (Sub === "Buy") {
    min = 0;
    max = 100000000;
  } else if (Sub === "Rent") {
    min = 0;
    max = 100000000;
  } else if (Sub === "Lease") {
    min = 0;
    max = 100000000;
  }

  return (
    <Box sx={{ width: {lg:"90%",sm:"99%",xs:"97%"}, marginTop: "5px" }}>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        step={1000000}

        valueLabelFormat={valueLabelFormat}
        min={min}
        max={max}
        sx={{
          '& .MuiSlider-track': {
            backgroundColor: '#0cb7d1',
            color: '#0cb7d1'
          },
          '& .MuiSlider-thumb': {
            color: 'white'
          },
          '& .MuiSlider-valueLabel': {
            backgroundColor: 'white',
            color: 'black'
          }
        }}
      />
    </Box>
  );
};

export default SliderComponent;
