import React, { useEffect, useState } from 'react';
import { useStepperContext } from './StepperContext';
// import {data} from '../data'
import { Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import CustomisedButton from './CustomisedButton';
import TextBox from './TextBox';
import DatePick from './DatePick';
import DropBox from './DropBox';
import CustomButton from './CustomButton';
import PriceTextField2 from './PriceTextField2';
import PriceTextField from './PriceTextField';
import PriceTextField1 from './PriceTextField1';
import CustomBoxButton from './CustomBoxButton';
import DropBox1 from './DropBox1';
import FDropDown from './FDropDown';
import DropDown1 from './DropDown1';
import TextAreaBox from './TextAreaBox';
import AccessButton from './AccessButton';
import CustomisedButton1 from './CustomisedButton1';
import { Form, Formik } from 'formik';
import BackComponent from './icon/BackComponent';
import { MdArrowBackIosNew } from 'react-icons/md';



interface PriceProps {
  onNext: () => void;
  onBack: () => void;
  onReset: () => void;
}

const Price: React.FC<PriceProps> = ({ onNext, onBack, onReset }) => {
  const {
    addUserData,
    userData,
    customFormDetails,
    addAmmenties,
    addPropImage,
    addCurrentPage,
    addCustomProps,
    removeUserDataByKeys,
    addCostPerBuiltUpArea2,
    addCostPerBuiltUpArea3,
    addCostPerBuiltUpArea,
  } = useStepperContext();
  const [userselectedData, setuserSelectedData] = useState([]);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const RentrLease = userData.find(
    (item) => item?.['Property available for']
  )?.['Property available for'];
  const propertyDescription =
    userData.find((item) => item?.['Property Description'])?.[
    'Property Description'
    ] || '';
  const Construction = userData.find((item) => item?.['Construction Status'])?.[
    'Construction Status'
  ];
  const MonthlyRent = userData.find((item) => item?.['Monthly Rent'])?.[
    'Monthly Rent'
  ];
  const SecurityDep = userData.find((item) => item?.['Select'])?.['Select'];
  const LeaseAmt = userData.find((item) => item?.['Lease Amount'])?.[
    'Lease Amount'
  ];

  useEffect(() => {
    const property = RentrLease;

    if (property === 'Lease') {
      if (MonthlyRent !== undefined && MonthlyRent !== null) {
        removeUserDataByKeys(['Monthly Rent']); // Ensure payload is an array
      }
      if (SecurityDep !== undefined && SecurityDep !== null) {
        removeUserDataByKeys(['Select']); // Ensure payload is an array
      }
    } else if (property === 'Rent') {
      if (LeaseAmt !== undefined && LeaseAmt !== null) {
        removeUserDataByKeys(['Lease Amount']); // Ensure payload is an array
      }
    }
    if (Construction) {
      setSelectedValue(Construction);
    }
  }, [Construction]);

  const filters = () => {
    if (
      !customFormDetails ||
      !Array.isArray((customFormDetails as any).price_details)
    ) {
      return [];
    }

    if (RentrLease === 'Rent') {
      return (customFormDetails as any).price_details.filter(
        (item: any) => item.label !== 'Lease Amount'
      );
    } else if (RentrLease === 'Lease') {
      return (customFormDetails as any).price_details.filter(
        (item: any) => item.label !== 'Monthly Rent' && item.label !== 'Select'
      );
    }

    // Default to returning the entire list if no filter criteria are met
    return (customFormDetails as any).price_details;
  };

  // Usage example:
  const filteredResult = filters();

  const generateValidationSchema = (filteredResult: any, userData: any) => {
    const requiredLabels = filteredResult
      .filter((item: any) => item?.required)
      .map((item: any) => item.label);

    const existingLabels = requiredLabels.filter(
      (label: any) => !userData.some((item: any) => item.hasOwnProperty(label))
    );

    if (existingLabels.length > 0) {
      setuserSelectedData(existingLabels); // Assuming this function is defined
    } else {
      onNext(); // Assuming this function is defined
    }
  };

  const getInitialValue = (label: any) => {
    const entry = userData.find((obj) => obj.hasOwnProperty(label));
    return entry ? entry[label] : '';
  };

  const [costPerBuiltUpArea, setCostPerBuiltUpArea] = useState<any>();
  const [costPerBuiltUpArea2, setCostPerBuiltUpArea2] = useState<any>();
  const [costPerBuiltUpArea3, setCostPerBuiltUpArea3] = useState<any>();

  console.log("rgre", userData)


  const convertToNumber = (currencyString: any) => {
    // Remove currency symbols and commas, then convert to number
    const numericString = currencyString
      .replace(/[^0-9.]/g, '') // Remove non-numeric characters except for decimal points
      .replace(/^0+(?!\.|$)/, ''); // Remove leading zeros

    return parseFloat(numericString); // Convert the cleaned string to a number
  };


  const PlotValue = userData.find((item) => item['Plot Area'])?.['Plot Area']  
  const Property = userData.find((item) => item['Property Type'])?.['Property Type'];
  const PlotMeasureType = userData.find((item) => item['measure'] )?.['measure']  ;
  const PlotinSqft = PlotMeasureType === 'Cent' ? PlotValue * 435 : PlotMeasureType === 'Acre' ? PlotValue * 100 * 435 : PlotValue;
  const BuildUp = userData.find((item) => item['Built up Area'])?.['Built up Area'] 
  const initialValue =userData.find((item)=>item['Cost'])?.['Cost']
  const PlotPrice = userData.find((item)=>item['Plot Price'])?.['Plot Price']
  const CPrice = userData.find((item)=>item['Price'])?.['Price']

  const strippedString = initialValue?.replace(/[^\d.]/g, '') || PlotPrice ?.replace(/[^\d.]/g, '') || CPrice?.replace(/[^\d.]/g, '')
  const pricepersqft = (() => {
    if (PlotinSqft !== undefined && PlotinSqft !== null && PlotinSqft !== 0) {
      return Math.round(strippedString / PlotinSqft);
    } else if (BuildUp !== undefined && BuildUp !== null && BuildUp !== 0) {
      return Math.round(strippedString / BuildUp);
    } else {
      return 0;
    }
  })();
  console.log("priceSQ",PlotValue,Property,PlotMeasureType,PlotinSqft,pricepersqft,strippedString)


  useEffect(() => {
    const builtUpArea = parseFloat(
      userData.find((item) => item?.['Built up Area'])?.['Built up Area'] || 0
    );

    const cost = parseFloat(
      userData.find((item) => item?.['Cost'])?.['Cost'] || 0
    );


    const calculatedCostPerBuiltUpArea =
      builtUpArea > 0 ? (cost / builtUpArea).toFixed(2) : '';

    const foundItem = userData.find(item => item?.['Price'] !== undefined || item?.['Cost'] !== undefined);

    const price = parseFloat(
      (foundItem?.['Price'] ?? foundItem?.['Cost'] ?? '0') // Use Price if available, otherwise Cost, default to '0'
        .replace(/[^0-9.]/g, '') // Remove non-numeric characters except dots
    );

    
    const calculatedCostPerBuiltUpArea2 =
      builtUpArea > 0 ? (price / builtUpArea).toFixed(2) : '';

    const plotArea = parseFloat(
      userData.find((item) => item?.['Plot Area'])?.['Plot Area'] || 0
    );

    const plotPrice = parseFloat(
      userData.find((item) => item?.['Plot Price'])?.['Plot Price'] || 0
    );
    console.log("cost value", cost, price)
    const calculatedCostPerBuiltUpArea3 =
      plotArea > 0 ? (plotPrice / plotArea).toFixed(2) : '';

    // const totalCost = cost + price + plotPrice;
    // const calculatedCostPerBuiltUpArea = builtUpArea > 0 ? (totalCost / builtUpArea).toFixed(2) : 'N/A';
    setCostPerBuiltUpArea(calculatedCostPerBuiltUpArea);
    setCostPerBuiltUpArea2(calculatedCostPerBuiltUpArea2);
    setCostPerBuiltUpArea3(calculatedCostPerBuiltUpArea3);

    addCostPerBuiltUpArea(calculatedCostPerBuiltUpArea as any);
    addCostPerBuiltUpArea2(calculatedCostPerBuiltUpArea2 as any);
    addCostPerBuiltUpArea3(calculatedCostPerBuiltUpArea3 as any);
  }, [userData]);

  const handleSubmit = () => {
    if (selectedValue === 'Ready to Move') {
      onNext(); // Go to the next page if possession status is "Ready to Move"
    } else if (selectedValue === 'Under Construction') {
      generateValidationSchema(filteredResult, userData); // Validate if possession status is "Under Construction"
    } else {
      generateValidationSchema(filteredResult, userData);
    }
  };

  const handleBoxButtonChange = (value: string) => {
    setSelectedValue(value);
  };

  const renderCost = (value: number | undefined) => {
    return value && value !== 0 ? `${value} per sq. ft.` : '';
  };
  
  const costString = [
    renderCost(costPerBuiltUpArea),
    renderCost(costPerBuiltUpArea2),
    renderCost(costPerBuiltUpArea3)
  ].filter(Boolean).join(' || ');


  
  return (
    <div className="custom-scrollbar   overflow-Y-scroll p-2 ">
      {/* <Button
        onClick={onBack}
        style={{
          position: 'relative',
          right: '20px',
        }}
      >
        <BackComponent />
      </Button>
      <Typography
        style={{
          fontWeight: '700',
          marginLeft: '40%',
          position: 'relative',
          bottom: '30px',
        }}
      >
        Price Details
      </Typography> */}
      <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "10px", alignItems: "center" }}>
  <IconButton 
        onClick={onBack}
        sx={{ marginRight: "8px" }} // Space between the icon and the text
  >
    <MdArrowBackIosNew className='w-5 h-5' />
  </IconButton>
  <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
    <Box
      sx={{
        fontWeight: '700',
        fontFamily: 'K2D',
      }}
    >
        Price Details
        </Box>
  </Box>
</Box>
      {/* <Formik  
       onSubmit={(values, { setSubmitting }) => {
          // handle form submission
          generateValidationSchema(basic_details, userData);
         
          console.log(values);
          setSubmitting(false);
        }}
      
      
      >
         <Form onSubmit={handleSubmit}  className='form'> */}
      {(customFormDetails as any).price_details.map(
        (dataItem: any, dataIndex: any) => (
          <Box key={dataIndex} my={2}  className=" lg:mx-[10%] msm:mx-[1%] " >
            {/* Render based on RentrLease */}
            {/* {(RentrLease === 'Rent' || RentrLease === 'Lease') && ( */}
            <>
              {dataItem.Type === 'Custom Box Type' && (
                <Box my={3}>
                  <Typography className="text-start" sx={{ marginY: '12PX' }}>
                    {dataItem.label}
                    {dataItem.required && (
                      <span style={{ color: 'red' }}> *</span>
                    )}{' '}
                    {/* Indicate required fields */}
                  </Typography>
                  <Grid container spacing={2}>
                    {/* {dataItem.data && dataItem.data.map((buttonItem, buttonIndex) => ( */}
                    <Grid item>
                      <CustomBoxButton
                        data={dataItem.data}
                        label={dataItem.label}
                        inputId={dataItem.inputID}
                        formName="Price Details"
                        required={dataItem.required}
                        userselectedData={userselectedData}
                        initial={getInitialValue(dataItem.label)}
                        onSelectionChange={handleBoxButtonChange} // Pass the handler
                      />
                    </Grid>
                    {/* ))} */}
                  </Grid>
                </Box>
              )}
              {dataItem.Type === 'calendar2' &&
                selectedValue === 'Under Construction' && (
                  <div>
                    <DatePick
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Price Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  </div>
                )}

              {dataItem.Type === 'Text Field' && (
                <div>
                  <TextBox
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Price Details"
                    label2={dataItem.label2}
                    keyboard={dataItem.keyboard}
                    required={dataItem.required}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)}
                  />
                </div>
              )}
              {dataItem.Type === 'Price Text Field' && (
                <div>
                  <label>{dataItem.label}</label>
                  <PriceTextField
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Price Details"
                    label2={dataItem.label2}
                    keyboard={dataItem.keyboard}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)}
                  />
                  <Typography
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    Cost per sq.ft.:{""} {Number.isNaN(pricepersqft) ?"0":pricepersqft}
                    {""} 
                    {/* {costPerBuiltUpArea2}
                    {costPerBuiltUpArea3} */}
                     
                  </Typography>
                </div>
              )}
              {dataItem.Type === 'Price Text Fieldcom' && (
                <div>
                  <label>{dataItem.label}</label>
                  <PriceTextField
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Price Details"
                    label2={dataItem.label2}
                    keyboard={dataItem.keyboard}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)}
                  />
                  <Typography>
                    Cost Per sq.ft.:
                    {Number.isNaN(pricepersqft) ?"0":pricepersqft}
                    {/* {costPerBuiltUpArea2}
                    {costPerBuiltUpArea3} */}
                     
                  </Typography>
                </div>
              )}
              {dataItem.Type === 'Text Area' && (
                <div>
                  <Typography className="text-start">
                    {dataItem.label}
                  </Typography>
                  <TextAreaBox
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Price Details"
                    
                    label2={dataItem.label2}
                    required={dataItem.required}
                    initial={getInitialValue('Property Description')}
                  />
                </div>
              )}

              {dataItem.Type === 'Drop down' && (
                <Box my={2}>
                  <Typography className="text-start">
                    {dataItem.label}
                  </Typography>
                  <DropBox1
                    label={dataItem.label}
                    options={dataItem.data}
                    inputId={dataItem.inputID}
                    formName="Price Details"
                    required={dataItem.required}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)}
                  />
                </Box>
              )}
              {dataItem.Type === 'Price Text Field1 ' && (
                <PriceTextField2
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Price Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}
                />
              )}
              {dataItem.Type === 'Price Text Field2' && (
                <PriceTextField2
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Price Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}
                  initial={getInitialValue(dataItem.label)}
                />
              )}
              {dataItem.Type === 'Box Type3' && (
                <Box my={3}>
                  <Typography className="text-start" sx={{ marginY: '12px' }}>
                    {dataItem.label}
                  </Typography>
                  <Grid container spacing={2}>
                    {/* {dataItem.data && dataItem.data.map((buttonItem, buttonIndex) => ( */}
                    <Grid item>
                      <AccessButton
                        data={dataItem.data}
                        label={dataItem.label}
                        inputId={dataItem.inputID}
                        formName="Price Details"
                        required={dataItem.required}
                        userselectedData={userselectedData}
                        initial={getInitialValue(dataItem.label)}
                      />
                    </Grid>
                    {/* ))} */}
                  </Grid>
                </Box>
              )}
              {dataItem.Type === 'Box Type' && (
                <Box>
                  <CustomisedButton
                    label={dataItem.label}
                    data={dataItem.data}
                    inputId={dataItem.inputID}
                    formName="Price Details"
                    required={dataItem.required}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)}
                  />
                </Box>
              )}
              {dataItem.Type === 'Box Type2' && (
                <Box>
                  <CustomButton
                    label={dataItem.label}
                    data={dataItem.data}
                    inputId={dataItem.inputID}
                    formName="Price Details"
                    required={dataItem.required}
                    userselectedData={userselectedData}
                    initial={getInitialValue(dataItem.label)}
                  />
                </Box>
              )}

              {RentrLease === 'Rent' && (
                <>
                  {dataItem.Type === 'Price Text Field4' && (
                    <PriceTextField1
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Price Details"
                      label2={dataItem.label2}
                      keyboard={dataItem.keyboard}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  )}
                  {dataItem.Type === 'calendar1' && (
                    <DatePick
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Price Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  )}
                  {dataItem.Type === 'Drop down5' && (
                    <Box my={2}>
                      <Typography className="text-start">
Security Deposit                      </Typography>
                      <DropDown1
                        label={dataItem.label}
                        options={dataItem.data}
                        inputId={dataItem.inputID}
                        formName="Price Details"
                        required={dataItem.required}
                        userselectedData={userselectedData}
                        initial={getInitialValue(dataItem.label)}
                      />
                    </Box>
                  )}
                  {dataItem.Type === 'Box Type4' && (
                    <Box my={3}>
                      <Typography
                        className="text-start"
                        sx={{ marginY: '12px' }}
                      >
                        {dataItem.label}
                      </Typography>
                      <Grid container spacing={2}>
                        {/* {dataItem.data && dataItem.data.map((buttonItem, buttonIndex) => ( */}
                        <Grid item>
                          <AccessButton
                            data={dataItem.data}
                            label={dataItem.label}
                            inputId={dataItem.inputID}
                            formName="Price Details"
                            required={dataItem.required}
                            userselectedData={userselectedData}
                            initial={getInitialValue(dataItem.label)}
                          />
                        </Grid>
                        {/* ))} */}
                      </Grid>
                    </Box>
                  )}
                  {/* {
                    dataItem.Type==='Text Area' && (
                      <div> <Typography className='text-start'>{dataItem.label}</Typography>
                      <TextAreaBox
                                                    data={dataItem.data}
                                                    label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName='Price Details'
                      // label2={dataItem.label2}
                      keyboard={dataItem.keyboard}
                      userselectedData={userselectedData}

                      required={dataItem.required}
                       initial={getInitialValue(dataItem.label)}
                      
                      />
                      </div>
                    )
                  } */}
                </>
              )}

              {RentrLease === 'Lease' && (
                <>
                  {dataItem.Type === 'Price Text Field5' && (
                    <PriceTextField2
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Price Details"
                      label2={dataItem.label2}
                      keyboard={dataItem.keyboard}
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  )}
                  {dataItem.Type === 'calendar1' && (
                    <DatePick
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Price Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}
                      initial={getInitialValue(dataItem.label)}
                    />
                  )}
                  {dataItem.Type === 'Box Type4' && (
                    <Box my={3}>
                      <Typography
                        className="text-start"
                        sx={{ marginY: '12px' }}
                      >
                        {dataItem.label}
                      </Typography>
                      <Grid container spacing={2}>
                        {/* {dataItem.data && dataItem.data.map((buttonItem, buttonIndex) => ( */}
                        <Grid item>
                          <AccessButton
                            data={dataItem.data}
                            label={dataItem.label}
                            inputId={dataItem.inputID}
                            formName="Price Details"
                            required={dataItem.required}
                            userselectedData={userselectedData}
                            initial={getInitialValue(dataItem.label)}
                          />
                        </Grid>
                        {/* ))} */}
                      </Grid>
                    </Box>
                  )}
                  {/* {
                    dataItem.Type==='Text Area' && (
                      <div> <Typography className='text-start'>{dataItem.label}</Typography>
                      <TextAreaBox 
                                                    data={dataItem.data}
                                                    label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName='Price Details'
                      // label2={dataItem.label2}
                      // keyboard={dataItem.keyboard}
                      required={dataItem.required}
                      userselectedData={userselectedData}

                        initial={getInitialValue(dataItem.label)}
                    />
                                            
                      </div>                    )
                  } */}
                </>
              )}
            </>
          </Box>
        )
      )}{' '}
    <Box className=" w-full flex justify-center items-center">
   
   <button
     onClick={handleSubmit}
     className="w-[150px] bg-[#1063b5] text-white rounded-[20px]  mt-6 custom-xxl:ml-[60%] xl:ml-[60%] lg:ml-[65%] md:ml-[60%] sm:ml-[60%] p-2"
     >
     Continue
   </button>
   </Box>
    </div>
  );
};

export default Price;
