import { useState } from "react";
import jobs from "./jobs";
import JobDetailCard from "./JobDetailCard";


interface Job {
  _id: string;
  jobTitle: string;
  location: string;
  experience: string;
  postedOn: string;
  description?: string; // Optional property, as some jobs do not have a description
  responsibilities?: string[]; // Optional property, as some jobs do not have responsibilities
}

const CurrentOpenings = () => {
  const [showAllOpenings, setShowAllOpenings] = useState(false);

  const displayJobs = showAllOpenings ? jobs : jobs.slice(0, 6);
  return (
    <section className="font-K2D bg-gray-100 p-1 md:p-0">
      <div className="font-semibold text-center py-2 md:py-4 lg:py-8">
        <div className=" text-[#2E368F] text-2xl md:text-3xl tracking-tighter md:tracking-normal">
          Explore Career Opportunities
        </div>
       <div className="flex w-full justify-center items-center mt-3"> <div className=" rounded-[30px] font-normal p-4 w-[80%] md:w-[250px] lg:w-[300px] cursor-context-menu relative" style={{background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)' }}>
      <h4 className="text-[#fff] pb-2 text-base md:text-lg lg:text-xl">
      <div className="flex flex-wrap gap-6 items-center justify-center w-[95%] md:w-[75%] mx-auto">
        Share Your Resume at careers@vattara.com
      </div>      </h4></div></div></div>
        {/* <div className="text-[#3C3B3B] text-xs md:text-sm lg:text-base my-4">
          Check out our current job openings and apply for a rewarding career at Vattara.
        </div>
        <div className="text-lg md:text-xl lg:text-2xl bg-gradient-to-r from-[#4C3329] to-[#B27760] inline-block text-transparent bg-clip-text">
          List of Current Openings
        </div> */}
      {/* <div className="flex flex-wrap gap-6 items-center justify-center w-[90%] md:w-[75%] mx-auto">
        {displayJobs.map((item:any, i:any) => (
          <JobDetailCard key={i} jobDetail={item} />
        ))}
      </div> */}
      {/* <div
        onClick={() => {
          setShowAllOpenings(!showAllOpenings);
        }}
        className="text-[#0167A0] font-semibold text-center py-4 cursor-pointer"
      >
        {showAllOpenings ? "Hide" : "View More"}
      </div> */}
    </section>
  );
};

export default CurrentOpenings;
