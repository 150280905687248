import { InputAdornment, TextField, Typography } from '@mui/material';
import React from 'react';
import { useStepperContext } from './StepperContext';
import { maskCurrency } from '../../PostForm/data';
interface PriceTextField1Props {
  label: string;
  formName: string;
  inputId?: number;
  label2?: string;
  keyboard?: any;
  required?: boolean;
  userselectedData?: any;
  initial?: any;
}

const PriceTextField1: React.FC<PriceTextField1Props> = ({
  label,
  formName,
  inputId,
  label2,
  keyboard,
  initial,
  required,
  userselectedData,
}) => {
  const { addUserData } = useStepperContext();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | { value: unknown }>
  ) => {
    const data = {
      id: inputId,
      [label]: maskCurrency(event.target.value as any),
      formName: formName,
    };
    addUserData(data as any);
  };
  const hasError = userselectedData?.includes(label);
  const convertToNumber = (currencyString:any) => {
  
    const numericString = currencyString
      .replace(/[^0-9.]/g, '') 
      .replace(/^0+(?!\.|$)/, ''); 
  
    return parseFloat(numericString); 
  };
  const cleanedData = convertToNumber(initial);
  console.log(22222)
  return (
    <div className="w-full">
      <TextField
        id={label}
        defaultValue={cleanedData}
              variant="filled"
              size="small"
        type={keyboard ? keyboard : 'text'}
        label={label}
        onChange={handleChange}
        sx={{                  borderRadius: "999px",
          width:'100%',
               '& .MuiFilledInput-underline:before': {
                         borderBottom: '0px solid transparent', // Dotted underline
                       },
                       '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
                         borderBottom: '0px solid transparent', // Dotted underline
                       }, }}        InputProps={{
          style: {
            borderRadius: "999px",
fontFamily:"K2D",            backgroundColor: "#F5F4F8",
          },
          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          endAdornment: label2 && (
            <InputAdornment position="end">{label2}</InputAdornment>
          ),
        }}
      ></TextField>

      {hasError && <Typography sx={{ fontSize:"12px" ,color:"#f44336",marginLeft:"20px",marginTop:"5px"}}>
          *This field is required
        </Typography>}
    </div>
  );
};

export default PriceTextField1;
