


import React, { useEffect, useState } from 'react';

import { useStepperContext } from '../component/StepperContext';
import { Button, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';


interface MultiSelectorButtonProps {
  label: string;
  inputId?: number;
  formName: string;
  title?: string;
  data: any;
  userselectedData?:any;
  initial?:any;
  required?:boolean
  // selectedValues: string[];
  // setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
}

interface ItemData {
  title: any; // Ensure that title is of type string
}
const MultiSelectorAmenitiesButton: React.FC<MultiSelectorButtonProps> = ({
  label,
  inputId,
  formName,
  title,
  data,
  userselectedData,
  initial
 
}) => {
  const { addUserData,userData } = useStepperContext();
  const[selectedValues,setSelectedValues]=useState([])
 
  useEffect(() => {
    const initValue = data.find((item:any) => (item.title === initial))
    setSelectedValues(initValue?.id||null)
  })

  const handleButtonClick = (itemData: any) => {
    const typedSelectedValues = selectedValues as any;

    const isSelected = typedSelectedValues.includes(itemData.title);
    const newSelectedValues = isSelected
      ? selectedValues.filter((value) => value !== itemData.title)
      : [...selectedValues, itemData.title];
    setSelectedValues(newSelectedValues as any);
    

  

const newData = {
      id: inputId,
      [label]: newSelectedValues.join(','),
      formName: formName,
    };

    addUserData([newData] as any); // Store newData as an array
  };
console.log('adduser',userData)

const hasError=userselectedData?.includes(label)





  // const buttonVariant = (itemData) =>
  //   Array.isArray(selectedValues) && selectedValues.includes(itemData.title) ? '#0CB7D1':  blueGrey[50];

  return (
    <div className='flex gap-5'>
      {data.map((itemData:any,index:number) => (
        <div key={index} className=''>
          
          <Button  onClick={() => handleButtonClick(itemData)}  
            className='h-10 '
                    defaultValue={initial}

           sx={{
              borderRadius: '999px',
              padding: '20px',
              // bgcolor: buttonVariant(itemData), // Dynamically set background color
              color: (selectedValues as any ).includes(itemData.title) ? 'white' : 'black', // Text color based on selection
              textTransform: 'capitalize',
              '&:hover': {
                bgcolor: (selectedValues as any ).includes(itemData.title) ? '#0CB7D1' : blueGrey[100], // Hover background color
              },
            }}>
            {itemData.title}
          </Button>
        </div>
      ))}
      {hasError && <Typography sx={{ fontSize:"12px" ,color:"#f44336",marginLeft:"20px"}}>
       *This field is required
     </Typography>}
    </div>
  );
};

export default MultiSelectorAmenitiesButton;























