import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { useStepperContext } from './StepperContext'; // Adjust path if necessary

interface CustomBoxProps {
  title?: string;
  label: string;
  inputId?: number;
  formName: string;
  required?: boolean;
  userselectedData?: any;
  data: any;
  initial: string;
  onSelectionChange?: (selectedValue: string) => void;
}

const CustomBoxButton: React.FC<CustomBoxProps> = ({
  label,
  inputId,
  formName,
  data,
  required,
  userselectedData,
  initial,
  onSelectionChange,
}) => {
  const { addUserData } = useStepperContext(); // Ensure this is available

  const [activeButton, setActiveButton] = useState<number | null>(null);

  useEffect(() => {
    const initValue = data.find((item: any) => item.title === initial);
    setActiveButton(initValue?.id || null);
  }, [initial, data]);

  const handleClick = (item: { id: number; title: string }) => {
    setActiveButton(item.id);

    const data = {
      id: inputId,
      [label]: item.title,
      formName: formName,
      required: required,
    };
    addUserData(data as any); // Call addUserData

    if (onSelectionChange) {
      onSelectionChange(item.title);
    }
  };

  const hasError = userselectedData?.includes(label);

  return (
 <div>
     <div className="flex flex-wrap gap-4 ">
      {data.map((item: any) => {
        // Debugging output
        console.log(`Item title: ${item.title}`);
        return (
          <Button
            key={item.id}
            className="h-10"
            onClick={() => handleClick(item)}
            sx={{
              borderRadius: '999px',
              padding: '20px',
              width:"auto",
              fontFamily:"K2D",
              // width:
              //   item.title === 'Ready to Move' ||
              //   item.title === 'Under Construction'
              //     ? '170px'
              //     : '100px',
              bgcolor: activeButton === item.id ? '#0CB7D1' : '#F5F4F8',
              color: activeButton === item.id ? 'white' : 'black',
              textTransform: 'capitalize',
              '&:hover': {
                bgcolor: activeButton === item.id ? '#0CB7D1' : '#F5F4F8',
              },
            }}
          >
            {item.title}
          </Button>
        );
      })}
   
    </div>
    <div className='mt-2'>
       {hasError &&  <Typography sx={{ fontSize:"12px" ,color:"#f44336",marginLeft:"20px"}}>
       *This field is required
     </Typography>}</div></div>
  );
};

export default CustomBoxButton;
