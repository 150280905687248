import { Button } from "@mui/material";
import Details from "./EditFrom/EditFormDetails";
import Header from "./EditFrom/EditFormHeader";
import propertyImg from "../assets/propertyImage.png"
import { useLocation } from "react-router-dom";
import EditBaisc from "./EditFrom/EditBaisc";
import FormPrice from "./EditFrom/FormPrice";
import EditAdditionalDetails from "./EditFrom/EditAdditionalDetails";
import EditImages from "./EditFrom/EditImages";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { setUsers } from '../slices/filterSlice';
import { useSelector } from 'react-redux';
import { axiosInstance } from "./api/APIs";
import { hasJSDocParameterTags } from "typescript";
import EditFormPost from "./EditFrom/EditFormPost";

const EditForm: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const passedData = useSelector((state: any) => state.usersInfo.users);
  // const passedData = location.state
  console.log(passedData)
  // dispatch(setUsers([passedData]));
  const [finalValue, setFinalValue] = useState<any>(passedData)

  console.log("call form final", finalValue)

  // useEffect(() => {


  // }, [handlesumbit])

  const property = passedData.find((item: any) => (item.res_or_com)).res_or_com
  const property_type = passedData.find((item: any) => item.property_type).property_type;
  const lookingTo = (passedData.find((item: any) => item) || {}) as any;
  const looking_to = lookingTo.property_available_for || lookingTo.looking_to || lookingTo.rent_or_lease;
  console.log("review setup", property_type, property, looking_to)

  // async function handlesumbit() {

  //   let url = ''
  //   if (property === "Residential") {
  //     if (looking_to === "Rent" || looking_to === "Lease") {
  //       url = "/rent/resRentDetail";
  //     } else if (looking_to === "Sale") {
  //       url = '/sell/resSellDetail'

  //     }
  //   }
  //   else if (property === "Commercial") {
  //     if (looking_to === "Rent" || looking_to === "Lease") {
  //       switch (property_type) {
  //         case "Office Space":
  //           url = "/rent/comOffRent"
  //           break
  //         case "Retail Shop":
  //         case "Showroom":
  //           url = "/rent/comShopRent"
  //           break;
  //         case "Warehouse":
  //           url = "/rent/comWarehouseRent"
  //           break;
  //         case "Industrial Space":
  //           url = "/rent/comLandRent"
  //           break;
  //         default:
  //           console.error(
  //             "Unsupported subtype for Commercial Rent/Lease:",
  //             property_type
  //           );
  //       }
  //     }
  //     else if (looking_to === "Sell") {
  //       switch (property_type) {
  //         case "Office Space":
  //           url = "sell/comOfficeSellPost"
  //           break;
  //         case "Retail Shop":
  //         case "Showroom":
  //           url = "/comShopSellPost"
  //           break;
  //         case "Warehouse":
  //           url = "/comWarehouseSellPost"
  //           break;
  //         case "Industrial Space":
  //           url = "/sell/comLandSellPost"
  //           break;
  //         default:
  //           console.error(
  //             "Unsupported subtype for Commercial Sell:",
  //             property_type
  //           );
  //       }
  //     }

  //   }





  //   try {
  //     const response = await axiosInstance.post(url, passedData[0])
  //     console.log("response", response.data)

  //   }
  //   catch (e) {
  //     console.log("error", e)
  //   }

  // }





  return (
  <div className="bg-[#f5f4f8]">  <div className="bg-[#fff] flex justify-center items-center lg:mx-[20%]  xl:mx-[27%]  md:my-8 sm:p-4 msm:p-1 md:p-6 rounded-t-3xl md:rounded-3xl font-K2D">
      <div>
      <Header PassedData={finalValue} />
      <EditBaisc PassedData={finalValue} setFinalValue={setFinalValue} />
      <Details PassedData={finalValue} setFinalValue={setFinalValue} />
      <FormPrice PassedData={finalValue} setFinalValue={setFinalValue} />
      <EditAdditionalDetails PassedData={finalValue} setFinalValue={setFinalValue} />
      <EditImages PassedData={finalValue} />
      <EditFormPost />
      
      </div>
    </div></div>
  );
};

export default EditForm;
