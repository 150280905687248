import { Box, IconButton } from '@mui/material';
import React from 'react'
import { MdArrowBackIosNew } from "react-icons/md";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



const PrivacyPolicy = () => {

  const navigate = useNavigate();


  return (
    <div className="bg-[#F5f4f8]" >
      <div className="lg:mx-[20%] msm:mx-[2%] lg:mt-8 sm:mt-8 bg-[#F5F4F8] pb-10">
      <Box className='flex items-center py-4 ' sx={{marginTop:{xs:0,sm:2},}}>
      <button 
    onClick={() => navigate(-1)} // Inline navigation logic
    className="absolute z-40"
  >
     <IconButton>
     <MdArrowBackIosNew className='w-8 h-8 sm:flex msm:flex md:flex' /></IconButton>
  </button>
  <p className='text-xl font-bold flex justify-center items-center flex-1'>Vattara Privacy and Policy</p>

        </Box>
      
      <section className="bg-[#FFFFFF] p-6 rounded-lg shadow-md mb-6">
        <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">1. Introduction</h2>
        <p>
          Welcome to Vattara. Your privacy is important to us, and this Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application and website ("Application"). By accessing or using Vattara, you agree to the terms of this Privacy Policy. If you do not agree with any part of this Privacy Policy, you must discontinue use of our Services immediately.
        </p>
      </section>
      
      <section className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">2. Information We Collect</h2>
        <div className="ml-4">
          <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mb-2">2.1 Personal Information</h3>
          <p>
            When you register for an account or use our Services, we may collect personal information that can identify you directly or indirectly, including but not limited to:
            <ul className="list-disc list-inside ml-4">
              <li>Name</li>
              <li>Email address</li>
              <li>Contact number</li>
              <li>Address</li>
              <li>Payment information</li>
            </ul>
          </p>
          <h3 className="lg:text-2xl msm:text-lg sm:text-xl font-semibold mt-4 mb-2">2.2 Non-Personal Information</h3>
          <p>
            We may also collect non-personal information about your interaction with the Application. This information does not directly identify you and may include:
            <ul className="list-disc list-inside ml-4">
              <li>Device information (e.g., device model, operating system)</li>
              <li>IP address</li>
              <li>Browser type</li>
              <li>Usage data (e.g., features used, time spent on the Application)</li>
              <li>Cookies and similar tracking technologies</li>
            </ul>
          </p>
        </div>
      </section>
      
      <section className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">3. How We Use Your Information</h2>
        <p>
          We use the information we collect for various purposes, including:
          <ul className="list-disc list-inside ml-4">
            <li>To provide, operate, and maintain our Services</li>
            <li>To improve, personalize, and expand our Services</li>
            <li>To process transactions and send related information</li>
            <li>To communicate with you, including sending you updates, notifications, and other information related to the Services</li>
            <li>To monitor and analyze usage and trends to improve your experience</li>
            <li>To prevent fraudulent transactions, monitor against theft, and protect against criminal activity</li>
            <li>To comply with legal obligations</li>
          </ul>
        </p>
      </section>
      
      <section className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">4. Sharing Your Information</h2>
        <p>
          We may share your information in the following circumstances:
          <ul className="list-disc list-inside ml-4">
            <li>Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, and customer service.</li>
            <li>Business Transfers: If we are involved in a merger, acquisition, or asset sale, your information may be transferred as part of that transaction.</li>
            <li>Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
            <li>Protection of Rights: We may disclose your information to enforce our Terms and Conditions, protect our rights, privacy, safety, or property, and/or that of you or others.</li>
          </ul>
        </p>
      </section>
      
      <section className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">5. Security of Your Information</h2>
        <p>
          We take the security of your information seriously and use appropriate technical and organizational measures to protect it from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no security measures are perfect or impenetrable, and we cannot guarantee the absolute security of your information.
        </p>
      </section>
      
      <section className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">6. Your Data Protection Rights</h2>
        <p>
          Depending on your location, you may have the following rights regarding your personal information:
          <ul className="list-disc list-inside ml-4">
            <li>Access: You have the right to request copies of your personal information.</li>
            <li>Correction: You have the right to request correction of any information you believe is inaccurate.</li>
            <li>Deletion: You have the right to request deletion of your personal information, under certain conditions.</li>
            <li>Restriction: You have the right to request that we restrict the processing of your personal information, under certain conditions.</li>
            <li>Objection: You have the right to object to our processing of your personal information, under certain conditions.</li>
            <li>Data Portability: You have the right to request that we transfer the information that we have collected to another organization, or directly to you, under certain conditions.</li>
          </ul>
          To exercise these rights, please contact us at [Your Contact Information]. We will respond to your request within a reasonable timeframe.
        </p>
      </section>
      
      <section className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">7. Cookies and Tracking Technologies</h2>
        <p>
          We use cookies and similar tracking technologies to track the activity on our Application and hold certain information. Cookies are files with a small amount of data that are stored on your device. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Application.
        </p>
      </section>
      
      <section className="bg-white p-6 rounded-lg shadow-md">
        <h2 className="lg:text-2xl msm:text-lg sm:text-xl font-bold mb-4">8. Third-Party Links</h2>
        <p>
          Our Application may contain links to third-party websites or services that are not operated by us. If you click on a third-party link, you will be directed to that third party's site.
        </p>
      </section>
    </div>
  </div>
  )
}

export default PrivacyPolicy