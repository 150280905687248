import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { blueGrey } from '@mui/material/colors';
import { useStepperContext } from '../component/StepperContext';
import { Typography } from '@mui/material';

interface MultiSelectorButtonProps {
  data: any;
  label: string;
  inputId?: number;
  formName: string;
  userselectedData?: any;
  initial?: any;
  required?: boolean;
  title?: string;
}

const MultiSelectorButton: React.FC<MultiSelectorButtonProps> = ({
  data,
  label,
  inputId,
  formName,
  userselectedData,
  required,
  initial,
  title,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const { addUserData, userData } = useStepperContext();

  // Initialize selected values from userselectedData if provided
  useEffect(() => {
    if (initial) {
      const initialSelectedValues = initial
        .split(',')
        .map((item: any) => item.trim());
      setSelectedValues(initialSelectedValues);
    }
  }, [initial]);

  // Update the context with the new selected values
  const handleButtonClick = (itemData: { title: string }) => {
    const isSelected = selectedValues.includes(itemData.title);

    const newSelectedValues = isSelected
      ? selectedValues.filter((item) => item !== itemData.title)
      : [...selectedValues, itemData.title];

    setSelectedValues(newSelectedValues);

    // Prepare data object to save in addUserData
    const newDataObject = {
      id: inputId,
      [label]: newSelectedValues.join(','), // Save selected titles as a comma-separated string under the specified label
      formName: formName,
    };

    // Update userData context with newDataObject
    addUserData(newDataObject as any);
  };

  const hasError = userselectedData?.includes(label);

  return (
    <div className="flex flex-wrap gap-1 ">
      {data.map((itemData: any, index: number) => (
        <div key={index}>
          <Button
            onClick={() => handleButtonClick(itemData)}
            className="h-10"
            sx={{
              borderRadius: '999px',
              padding: '14px',
              marginTop: '6px',
              fontSize:"13px",
              fontFamily:"K2D",
              bgcolor: selectedValues.includes(itemData.title)
                ? '#0CB7D1'
                : blueGrey[50], // Adjust bgcolor logic as per your theme
              color: selectedValues.includes(itemData.title)
                ? 'white'
                : 'black',
              textTransform: 'capitalize',
              '&:hover': {
                bgcolor: selectedValues.includes(itemData.title)
                  ? '#0CB7D1'
                  : blueGrey[100], // Hover background color
              },
            }}
          >
          <img 
    src={itemData.image} 
    alt={itemData.title} // Add alt text for accessibility
    style={{ width: '16px', height: '18px', marginRight: '8px' }} // Adjust size and margin as needed
  />   {itemData.title}      
            

          </Button>
        </div>
      ))}
      {hasError && <Typography sx={{ fontSize:"12px" ,color:"#f44336",marginLeft:"20px"}}>
          *This field is required
        </Typography>}
    </div>
  );
};

export default MultiSelectorButton;
