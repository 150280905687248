
const PeopleComponent = (props) => (
  <svg
    width={25}
    height={25}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.38 4.375C21.027 4.76389 21.5623 5.31366 21.9338 5.97081C22.3054 6.62796 22.5004 7.3701 22.5 8.125C22.5004 8.8799 22.3054 9.62204 21.9338 10.2792C21.5623 10.9363 21.027 11.4861 20.38 11.875M27.5 26.25V25.5C27.5 22.7 27.5 21.3 26.955 20.23C26.4756 19.2892 25.7108 18.5244 24.77 18.045M11.875 12.5C12.4495 12.5 13.0184 12.3868 13.5492 12.167C14.08 11.9471 14.5623 11.6248 14.9686 11.2186C15.3748 10.8123 15.6971 10.33 15.917 9.79924C16.1368 9.26844 16.25 8.69953 16.25 8.125C16.25 7.55047 16.1368 6.98156 15.917 6.45076C15.6971 5.91996 15.3748 5.43766 14.9686 5.03141C14.5623 4.62515 14.08 4.30289 13.5492 4.08303C13.0184 3.86316 12.4495 3.75 11.875 3.75C10.7147 3.75 9.60188 4.21094 8.78141 5.03141C7.96094 5.85188 7.5 6.96468 7.5 8.125C7.5 9.28532 7.96094 10.3981 8.78141 11.2186C9.60188 12.0391 10.7147 12.5 11.875 12.5ZM2.5 25.5V26.25H21.25V25.5C21.25 22.7 21.25 21.3 20.705 20.23C20.2256 19.2892 19.4608 18.5244 18.52 18.045C17.45 17.5 16.05 17.5 13.25 17.5H10.5C7.7 17.5 6.3 17.5 5.23 18.045C4.28923 18.5244 3.52436 19.2892 3.045 20.23C2.5 21.3 2.5 22.7 2.5 25.5Z"
      stroke="#404040"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default PeopleComponent;
