import { useNavigate } from 'react-router-dom';
import { useState, useEffect, Profiler, useRef } from 'react';
import MessageIcon from './images/MessageIcon.png';
import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Modal, Typography, } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel'; import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DifferenceIcon from '@mui/icons-material/Difference';
import { Warning as WarningIcon } from '@mui/icons-material'; // Adjust the icon import as necessary


import Default from './images/default.png';
import AD from './images/AD.png';
import phone from './images/phone.png';
import goldtick from './images/goldtick.png';
import { useSelector, useDispatch } from 'react-redux';
import { axiosChat, axiosInstance } from '../compounts/api/APIs';
import view from './images/view.png';
import heart from '../compounts/images/Heart.png';
import LikedHeart from '../compounts/images/LikedHeart.png';
import { truncateString } from './ReuseableCompounts/TruncateString';
import star from './images/star.png';
import { formatPrice } from '../compounts/ReuseableCompounts/PriceFormat';
import { formatSQPrice } from '../compounts/ReuseableCompounts/PriceSQFormat';
import DefultScroll from '../compounts/images/defaultScroll.png';
import noPer from './images/noProperty.png';
// import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getResponsiveSettings } from '../compounts/ReuseableCompounts/CarouselResponsive';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import profile from './images/profile.png';
import chat from './images/chat.svg';
import { handleChange, handleLinkClick } from './ReuseableCompounts/PostDetail';
import loaction from './images/location.png';
import axios from 'axios';
import { useChat } from '../compounts/context/ChatContext'
import { MdArrowBackIosNew } from "react-icons/md";
import { FaGreaterThan } from "react-icons/fa6";
import { setUsers } from '../slices/filterSlice';
import { GoHeart } from "react-icons/go";
import { GoHeartFill } from "react-icons/go";
import { Phone } from '@mui/icons-material';

// import { fetchUsers } from "./data";
// import { RootState, AppDispatch } from '../App/store'

interface SimilarProperty {
  message: Property[];
  userDetails: {
    company_logo: string;
    company_name: string;
    bhk(
      user_id: any,
      post_id: (
        user_id: any,
        post_id: any,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      looking_to: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      district: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        property_type: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: (
              user_id: any,
              post_id: (
                user_id: any,
                post_id: any,
                looking_to: any,
                district: any,
                building_name: any,
                property_type: any,
                bhk: any
              ) => void,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          property_type: any,
          bhk: any
        ) => void,
        bhk: any
      ) => void,
      building_name: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      property_type: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        property_type: any,
        bhk: any
      ) => void,
      bhk: any
    ): void;
    district(
      user_id: any,
      post_id: (
        user_id: any,
        post_id: any,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      looking_to: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      district: any,
      building_name: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      property_type: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        property_type: any,
        bhk: any
      ) => void,
      bhk: any
    ): void;
    property_type(
      user_id: any,
      post_id: (
        user_id: any,
        post_id: any,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      looking_to: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      district: any,
      building_name: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      property_type: any,
      bhk: any
    ): void;
    building_name(
      user_id: any,
      post_id: (
        user_id: any,
        post_id: any,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      looking_to: (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      district: any,
      building_name: any,
      property_type: any,
      bhk: any
    ): void;
    looking_to(
      user_id: any,
      post_id: (
        user_id: any,
        post_id: any,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ) => void,
      looking_to: any,
      district: any,
      building_name: any,
      property_type: any,
      bhk: any
    ): void;
    post_id(
      user_id: any,
      post_id: any,
      looking_to: any,
      district: any,
      building_name: any,
      property_type: any,
      bhk: any
    ): void;
    user_id: any;
    name: any;
    mobile_no: any;
    category: any;
    user_pic: any;
  };
}
interface Property {
  sublocality: string;
  // district(
  //   user_id: any,
  //   post_id: number,
  //   looking_to: string | undefined,
  //   district: string,
  //   building_name: string,
  //   property_type: any,
  //   bhk: string
  // ): void;
  district: string;
  id: number;
  post_id: number;
  property_type?: any;
  looking_to?: string;
  rent_or_lease?: string;
  property_available_for?: string;
  images?: Image[];
  view_post_count: number;
  bhk: string;
  city: string;
  building_name: string;
  landmark_street: string;
  build_up_area?: string;
  built_up_area?: string;
  plot_area?: string;
  PriceperSqft?: string;
  price?: number;
  cost?: number;
  monthly_rent?: number;
  enter_lease_amount?: number;
  lease_amount?: any;
  liked?: any;
  user_id?: any;
  uri?: any;
  kbl_property?: any;
}
interface scroll {
  value: any;
  selectedPostId: number | null;

}
interface Image {
  filename: string;
  id: string;
  isCover: boolean;
  isvisible: boolean;
  name: string;
  type: string;
  uri: any;
}

const Scroll: React.FC<scroll> = ({ value, selectedPostId }) => {
  const users = useSelector((state: any) => state.usersInfo.users);
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  const navigate = useNavigate();
  const [ad, setAd] = useState<any[]>([]);
  const [receivedValue, setReceivedValue] = useState<any>(value);
  const [sortedUsers, setSortedUsers] = useState<Property[]>([]);
  const [likedState, setLikedState] = useState<Record<number, boolean>>({});
  const [like, setlike] = useState<any>(users.map((item: any) => item.liked));
  const [chatvalue, setChatValue] = useState<any>("")
  const { setSelectedChat } = useChat();
  const [loadingCardId, setLoadingCardId] = useState<number | null>(null); // Track loading card ID
  const [dialogOpen, setDialogOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedPostId && containerRef.current) {
      const card = document.querySelector(`.card-${selectedPostId}`) as HTMLElement;
      if (card) {
        const container = containerRef.current;
        const cardRect = card.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        const offset = cardRect.top - containerRect.top - (containerRect.height / 2) + (cardRect.height / 2);
        container.scrollTo({
          top: container.scrollTop + offset,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedPostId]);


  const [similarProperty, setsimilarProperty] = useState<SimilarProperty>({
    message: [],
    userDetails: {
      user_id: '',
      name: '',
      mobile_no: '',
      category: '',
      user_pic: '',
      bhk: function (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: (
              user_id: any,
              post_id: (
                user_id: any,
                post_id: any,
                looking_to: any,
                district: any,
                building_name: any,
                property_type: any,
                bhk: any
              ) => void,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          property_type: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: (
              user_id: any,
              post_id: (
                user_id: any,
                post_id: any,
                looking_to: any,
                district: any,
                building_name: any,
                property_type: any,
                bhk: any
              ) => void,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            district: any,
            building_name: (
              user_id: any,
              post_id: (
                user_id: any,
                post_id: any,
                looking_to: any,
                district: any,
                building_name: any,
                property_type: any,
                bhk: any
              ) => void,
              looking_to: (
                user_id: any,
                post_id: (
                  user_id: any,
                  post_id: any,
                  looking_to: any,
                  district: any,
                  building_name: any,
                  property_type: any,
                  bhk: any
                ) => void,
                looking_to: any,
                district: any,
                building_name: any,
                property_type: any,
                bhk: any
              ) => void,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            property_type: any,
            bhk: any
          ) => void,
          bhk: any
        ) => void,
        building_name: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        property_type: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: (
              user_id: any,
              post_id: (
                user_id: any,
                post_id: any,
                looking_to: any,
                district: any,
                building_name: any,
                property_type: any,
                bhk: any
              ) => void,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          property_type: any,
          bhk: any
        ) => void,
        bhk: any
      ): void {
        throw new Error('Function not implemented.');
      },
      district: function (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        property_type: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: (
              user_id: any,
              post_id: (
                user_id: any,
                post_id: any,
                looking_to: any,
                district: any,
                building_name: any,
                property_type: any,
                bhk: any
              ) => void,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          property_type: any,
          bhk: any
        ) => void,
        bhk: any
      ): void {
        throw new Error('Function not implemented.');
      },
      property_type: function (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: (
            user_id: any,
            post_id: (
              user_id: any,
              post_id: any,
              looking_to: any,
              district: any,
              building_name: any,
              property_type: any,
              bhk: any
            ) => void,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        property_type: any,
        bhk: any
      ): void {
        throw new Error('Function not implemented.');
      },
      building_name: function (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: (
          user_id: any,
          post_id: (
            user_id: any,
            post_id: any,
            looking_to: any,
            district: any,
            building_name: any,
            property_type: any,
            bhk: any
          ) => void,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ): void {
        throw new Error('Function not implemented.');
      },
      looking_to: function (
        user_id: any,
        post_id: (
          user_id: any,
          post_id: any,
          looking_to: any,
          district: any,
          building_name: any,
          property_type: any,
          bhk: any
        ) => void,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ): void {
        throw new Error('Function not implemented.');
      },
      post_id: function (
        user_id: any,
        post_id: any,
        looking_to: any,
        district: any,
        building_name: any,
        property_type: any,
        bhk: any
      ): void {
        throw new Error('Function not implemented.');
      },
      company_logo: '',
      company_name: ''
    },
  });


  const [open, setOpen] = useState<any>(false);
  const Bview = "Sview"

  const handleClose = () => {
    setOpen(false);
  };
  console.log(loginData.token);
  console.log(loginData);





  useEffect(() => {
    setSortedUsers([...users]);

    const initialLikedState = users.reduce(
      (acc: Record<number, boolean>, user: Property) => {
        acc[user.post_id] = user.liked || false;
        return acc;
      },
      {}
    );
    setLikedState(initialLikedState);
  }, [users]);


  // const isMobileOrTablet = () => {
  //   const userAgent = window.navigator.userAgent;
  //   return /Mobile|Android|iPhone|iPad|Tablet/.test(userAgent);
  // };
  //   const handleChange = async (propertyView: any) => {

  //   const body = {
  //     post_id: propertyView.postId,
  //     selected_property_type: propertyView.property_type,
  //     looking_to: propertyView.looking,
  //     user_id: propertyView.userId
  //   };

  //   console.log(body, propertyView.Bview);
  //   console.log(propertyView, propertyView.postId);

  //   try {
  //     setLoadingCardId( propertyView.postId); // Set the loading card ID

  //     const response = await axiosInstance.post("/filter/get/postDetail", body);

  //     const queryString = new URLSearchParams({
  //       data: JSON.stringify(response.data),
  //       look: propertyView.looking || '',
  //       Bview: propertyView.Bview
  //     }).toString();

  //     if (isMobileOrTablet()) {
  //       // If it's a mobile or tablet, navigate in the same window
  //       window.location.href = `/PropertyDetails?${queryString}`;
  //     } else {
  //       // For desktop, open a new window
  //       const newWindow = window.open(`/PropertyDetails?${queryString}`, '_blank');
  //       if (newWindow) {
  //         newWindow.focus();
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }    finally {
  //     setLoadingCardId(null); // Reset the loading card ID
  // }
  // };


  useEffect(() => {
    const handleChildData = () => {
      console.log('hello');

      console.log(receivedValue);

      if (value === 'Price (Low to High)') {
        const sortedItems = [...users].sort((a, b) => {
          const costA = parseInt(
            String(
              a.cost ||
              a.price ||
              (a.monthly_rent == null || a.monthly_rent == ''
                ? a.lease_amount || a.enter_lease_amount
                : a.monthly_rent)
            ).replace(/[^0-9.-]+/g, ''),
            10
          );
          const costB = parseInt(
            String(
              b.cost ||
              b.price ||
              (b.monthly_rent == null || b.monthly_rent == ''
                ? b.lease_amount || b.enter_lease_amount
                : b.monthly_rent)
            ).replace(/[^0-9.-]+/g, ''),
            10
          );

          return costA - costB || 0;
        });
        setSortedUsers(sortedItems);
      } else if (value === 'Price (High to Low)') {
        const sortedItems = [...users].sort((a, b) => {
          const costA = parseInt(
            String(
              a.cost ||
              a.price ||
              (a.monthly_rent == null || a.monthly_rent == ''
                ? a.lease_amount || a.enter_lease_amount
                : a.monthly_rent)
            ).replace(/[^0-9.-]+/g, ''),
            10
          );
          const costB = parseInt(
            String(
              b.cost ||
              b.price ||
              (b.monthly_rent == null || b.monthly_rent == ''
                ? b.lease_amount || b.enter_lease_amount
                : b.monthly_rent)
            ).replace(/[^0-9.-]+/g, ''),
            10
          );

          // Reverse the order
          return costB - costA || 0; // Add fallback to handle undefined or NaN values
        });
        setSortedUsers(sortedItems);
      } else if (value === 'Newest Listings') {
        const sortedItems = [...users].sort((a, b) => {
          // Check for createdAt property and convert to Date objects
          const dateA = a.createdAt ? new Date(a.createdAt) : null;
          const dateB = b.createdAt ? new Date(b.createdAt) : null;

          // Compare dates
          return dateA && dateB ? dateB.getTime() - dateA.getTime() : 0; // Add fallback to handle undefined dates
        });
        setSortedUsers(sortedItems);
      } else if (value === 'Older Listings') {
        const sortedItems = [...users].sort((a, b) => {
          const dateA = a.createdAt ? new Date(a.createdAt) : null;
          const dateB = b.createdAt ? new Date(b.createdAt) : null;

          // Compare dates in reverse order
          return dateB && dateA ? dateA.getTime() - dateB.getTime() : 0; // Add fallback to handle undefined dates
        });
        setSortedUsers(sortedItems);
      } else {
        setSortedUsers([...users]);
      }
    };
    handleChildData();
    console.log(sortedUsers);
  }, [value]);

  async function handleContact(
    property: any,
    looking: any,
    user: any,
    post: any,
    dis: any,
    buld: any,
    bhk: any,
    image: any,
  ) {
    console.log(property, looking);



    if (!loginData || loginData.phone === '' || loginData.phone === null) {
      navigate('/contact');
    } else {
      console.log('work');

      const chatBody: any = {
        property: property,
        user: user,
        post: post,
        looking_to: looking,
        district: dis,
        building_name: buld,

        Bhk: bhk,
        image: image,
      };

      setChatValue(chatBody)
      console.log("chat check", chatvalue)
      // setOpen(true);

      try {
        const Response = await axiosInstance.get(
          `/api/ads/allAds/${'Property Contact View Ads'}`
        );
        setAd(Response.data.message);
        console.log(Response.data.message);
      } catch (error) {
        console.log('error', error);
      }
      console.log('Ad', ad);

      const body = {
        user_id: loginData.user_id,
        property_type: property,
        looking_to: looking,
        post_id: post,
        post_user_id: user,
      };
      console.log(body);

      try {
        const response = await axiosInstance.post(
          '/filter/contactUserDetail',
          body
        );
        setsimilarProperty(response.data);

        console.log(response.data);
        setOpen(true);

        console.log('hello naga', similarProperty);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }

  console.log('hello', similarProperty);

  const handleSaved = async (id: number, property: any, look: any, index: any) => {

    if (loginData.user_id === undefined) {
      navigate('/contact')
    }
    else {
      const newLikedState = !likedState[id];
      setLikedState((prevState) => ({ ...prevState, [id]: newLikedState }));
      if (like === index) {
        setlike(false);
      } else {
        setlike(index);
      }
      const body = {
        post_id: id,
        liked_user_id: loginData.user_id,
        property_type: property,
        rent_or_sell: look,
        liked: newLikedState,
      };

      try {
        const response = await axiosInstance.post('/like/postLike', body);
        console.log('response', response);
        const updatedProperties = users.map((item: any) => {
          if (item.post_id === id) {
            console.log(`Updating item with ID ${id}:`, { ...item, liked: newLikedState });
            return { ...item, liked: newLikedState }; // Return a new object with the updated liked state
          }
          return item; // Return the unchanged item
        });
        dispatch(setUsers(updatedProperties))
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const handleChat = async (
    userid: any,
    postid: any,
    look: any,
    dis: any,
    build: any,
    prop: any,
    bhk: any,

  ) => {
    const userId = userid;
    const postId = postid;
    const looking_to = look;
    const district = dis;
    const building_name = build;
    const property_type = prop;
    const Bhk = bhk;


    console.log('User ID:', userId, postId, look, dis, chatvalue.post);

    try {
      const response = await axiosChat.post('/api/chat', {
        userId,
        req_user_id: loginData.user_id,
      });
      console.log('chat click', response.data);
      if (response.status === 200) {
        console.log('Chat created successfully');
        console.log('ss', sortedUsers);
        console.log('gg', response);

        const data = response.data.users.filter(
          (item: any) => item.user_id !== loginData.user_id
        );
        console.log("call me naga pambu", data);

        setSelectedChat({
          chatId: response.data.id,
          userId: data[0].user_id,
          username: data[0].name,
          userpic: data[0].user_pic,
          companyname: data[0].company_name

        });

        navigate('/chatscreen', {
          state: {
            chatId: response.data.id,
            userId,
            postId: chatvalue.post,
            username: response.data.users[0].name,
            userpic: response.data.users[0].user_pic,
            initialMessageText: `${chatvalue.building_name !== null ? chatvalue.building_name : ""} ${chatvalue.property} for ${chatvalue.looking_to} in ${chatvalue.district}`,
            details: {
              userId: chatvalue.user,
              postId: chatvalue.post,
              looking_to: chatvalue.looking_to,
              district: chatvalue.dis,
              building_name: chatvalue.buld,
              property_type: chatvalue.property,
              Bhk: chatvalue.bhk,
              image: chatvalue.image?chatvalue.image:"https://vattara-img.s3.us-east-1.amazonaws.com/photo_2024-12-10_16-48-19.jpg" ,
              // image: chatvalue.image,
            },
          },
        });
      } else {
        console.error('Failed to create chat. Status code:', response.status);
      }
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  };

  console.log(sortedUsers)





  async function handleReq(userId: any, postid: any, property: any, looking: any, dis: any, buld: any, bhk: any, image: any,) {

    if (!loginData || loginData.phone === '' || loginData.phone === null) {
      navigate('/contact');
    } else {


      console.log('chat click', postid);
      const chatBody: any = {
        property: property,
        user: userId,
        post: postid,
        looking_to: looking,
        district: dis,
        building_name: buld,

        Bhk: bhk,
        image: "https://vattara-img.s3.amazonaws.com/image_1727274600329.jpg",
      };

      setChatValue(chatBody)
      if (loginData === undefined) {
        navigate('/contact')
      } else {
        console.log('chat click', chatvalue);
        try {
          const response = await axiosChat.post('/api/chat', {
            userId,
            req_user_id: loginData.user_id,
          });
          console.log('chat click', response.data);
          const data = response.data.users.filter(
            (item: any) => item.user_id !== loginData.user_id
          );

          console.log("company", response.data, data);
          if (response.status === 200) {

            console.log('Chat created successfully');
            console.log('ss', sortedUsers);
            console.log('gg', response);


            setSelectedChat({
              chatId: response.data.id,
              userId: data[0].user_id,
              username: data[0].name || data[0].company_name,
              userpic: data[0].user_pic,
              companyname: data[0].company_name
            });

            navigate('/chatscreen', {
              state: {
                chatId: response.data.id,
                userId: userId,
                postId: chatvalue.post,
                username: response.data.users[0].name,
                userpic: response.data.users[0].user_pic,
                initialMessageText: `Request Image for Your ${property === 'Plot / land' ? 'Plot' : property} in ${dis}`,

                // initialMessageText: `Request Image for this property ${buld !== null ? buld : ""} ${property}  ${dis}`,
                details: {
                  userId: userId,
                  postId: postid,
                  looking_to: looking,
                  district: dis,
                  building_name: buld,
                  property_type: ` ${chatvalue.property}`,
                  Bhk: bhk,
                  image: "https://vattara-img.s3.amazonaws.com/image_1727274600329.jpg",
                },
              },
            });
          } else {
            console.error('Failed to create chat. Status code:', response.status);
          }
        } catch (error) {
          console.error('Error creating chat:', error);
        }
      }
    }
  }
  return (
    <div className='w-full p-1 ' style={{ overflow: "auto" }}>

      <Box className="fixed w-full h-15 left-1 z-40 bg-[#fff] lg:hidden md:flex items-center justify-center top-0"
      >

        <IconButton
          onClick={() => navigate(-1)} // Inline navigation logic
          aria-label="Back"
          sx={{
            borderRadius: '50%', // Optional: for a circular button
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)', // Optional: change background on hover
            },
          }}
        >
          <MdArrowBackIosNew className='w-8 h-7' />
        </IconButton>
      </Box>
      <Box sx={{ marginTop: { xs: 5, sm: 6, md: 0, lg: 0 } }} ref={containerRef}
        style={{ overflow: "auto" }}
        className="custom-scrollbar grid grid-cols-2 lg:grid-cols-2 sm:grid-cols-2 msm:grid-cols-1 custom-lg:grid-cols-2  custom-xl:grid-cols-2 custom-xxl:grid-cols-3 md:grid-cols-2 gap-3  rounded-xl relative lg:overflow-y-scroll lg:whitespace-nowrap bg-white h-screen  md:h-[85vh]   p-2" >
        {sortedUsers && sortedUsers.length > 0 && users !== 'No property' ? (
          sortedUsers.map((item: Property, index: number) => {
            const isSelected = selectedPostId === item.post_id;
            // const formatPrice = (item: Property) => {
            //   const price =
            //     item.price ||
            //     item.cost ||
            //     item.enter_lease_amount ||
            //     item.lease_amount ||
            //     (item.monthly_rent ? `${item.monthly_rent} / Month` : null);

            //   if (item.enter_lease_amount) {
            //     return `${item.enter_lease_amount} / Lease`;
            //   } else if (item.lease_amount) {
            //     return `${item.lease_amount} / Lease`;
            //   } else if (item.monthly_rent) {
            //     return `${item.monthly_rent} / Monthly`;
            //   } else if (price) {
            //     return price; // Return the first valid price found
            //   }

            //   return null; // Return null if no price found
            // };
            const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;

            // Usage
            const displayPrice = formatPrice(price);
            const coverimg: any = item.images && item.images.length > 0 ? item.images?.find(img => img.isCover) || "" : "";
            const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
            const priceSQTV = formatSQPrice(sqtv);

            const propertyTypeDisplay = item.property_type === "Plot / Land" ? "Plot" : item.property_type;
            const locality = truncateString(item.city, 28)
            const propertyView = {
              postId: item.post_id,
              property_type: item.property_type,
              looking: item.looking_to || item.rent_or_lease || item.property_available_for,
              userId: loginData.user_id,
              Bview: Bview
            }
            const type = encodeURIComponent(propertyView.property_type);
            const url = `/PropertyDetails/${propertyView.postId}/${type}/${propertyView.looking}/${propertyView.Bview}`;

            console.log(propertyView)

            return (

              <div className={`cursor-pointer card-${item.post_id}`} key={item.post_id}>
                <div
                  style={{
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    padding: "4px"
                  }}
                  className={`bg-[#F5F5F5] relative w-full lg:w-full xl:w-full 2xl:w-full rounded-2xl ${isSelected ? 'border-2 border-[#1063b5]' : ''} 
    ${window.innerWidth >= 968 ? 'hover:shadow-lg hover:scale-95' : ''}`}
                >
                  <div className='relative'>
                    <Link to={url} onClick={(event) => { handleLinkClick(event, url) }}>
                      <img
                        className='w-full h-[230px] sm:h-[300px] lg:h-[200px] xl:h-[240px]'
                        //  onClick={() => { handleChange(propertyView) }}
                        style={{ borderRadius: "15px" }}
                        src={typeof coverimg === 'object' && coverimg?.uri ? coverimg.uri : DefultScroll}

                        alt={`Property ${index} Image`}
                      />
                    </Link>
                    {loadingCardId === item.post_id && (
                      <div className="absolute inset-0 flex justify-center items-center bg-opacity-50 bg-gray-800 z-10" style={{ borderRadius: "20px" }}>
                        <CircularProgress color="inherit" /> {/* Loader overlay */}
                      </div>
                    )}
                    <div style={{ padding: "5px" }} onClick={() => {
                      handleReq(item.user_id, item.post_id, item.property_type,
                        item.looking_to ||
                        item.rent_or_lease ||
                        item.property_available_for,
                        item.district,
                        item.building_name,

                        item.bhk,
                        coverimg.uri)
                    }}
                    className={`
                      ${coverimg === "" 
                        ? "flex gap-1 items-center cursor-pointer bg-[#F5F4F880] text-[#252B5C] border-[#252B5C] border-1 left-[50%] transform -translate-x-[50%] rounded-full" 
                        : "hidden"
                      }
                      absolute bottom-4 
                      ${item.user_id === loginData.user_id ? "hidden" : "block"}
                    `}>
                      <Box sx={{ fontSize: "10px", color: "#252B5C" }}>Request Images</Box>
                      <FaGreaterThan style={{ width: '14px', height: '14px' }} />

                    </div>

                    <div className={`${item.kbl_property ? "flex" : "hidden"}  items-center  absolute bottom-0  p-1 left-0`} style={{ borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)' }}>
                      <img className="w-4 h-4" src={star} />
                      <div className="text-white mr-1" style={{ fontSize: "8px" }}>VATTARA VERIFIED</div>
                    </div>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 5,
                        left: 5,

                        cursor: 'pointer',
                        backgroundColor: '#D9D9D9',
                        borderRadius: '20px',
                        padding: '3px',
                        opacity: 0.8,
                        transition: 'opacity 0.3s',
                        '&:hover': {
                          opacity: 1,
                        },
                      }}
                      onClick={() => handleSaved(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for, index)}
                    >
                      <> {likedState[item.post_id] ? (
                        <GoHeartFill className='text-red-500' size={25} style={{ marginTop: "2px" }} />
                      ) : (
                        <GoHeart className='text-[#000]' size={25} style={{ marginTop: "2px" }} />
                      )}</>
                    </Box>
                  </div>
                  <div className='absolute rounded-bl-xl h-6 top-7 right-1 flex items-center justify-center text-white bg-[#234F68B2] opacity-90 p-1'>
                    <img src={view} className='mr-0.5 w-4 h-4' alt="View Icon" />
                    <div style={{ fontSize: "10px" }}>{item.view_post_count} Viewers</div>
                  </div>
                  <div>
                    <div className='w-[100%] justify-between items-center mt-1 flex'>
                    <div                      
                        className="capitalize w-[75%] text-[#252B5C] ml-1 justify-center flex flex-col cursor-pointer"
                      >
                                            <Link to={url} onClick={(event) => { handleLinkClick(event, url) }}                      
><Box
                          sx={{
                            display: 'flex',
                            // backgroundColor: "red",
                            flexDirection: "row",
                            alignItems: 'center',
                            position: 'relative',
                            justifyContent: "flex-start",
                            overflow: 'hidden', // Prevent overflow
                            maxWidth: '100%', // Ensure the Box respects the parent width
                          }}
                        >
                          <img src={goldtick} alt="Gold Tick" className='w-5 h-5' />
                          <Typography
                            sx={{
                              fontSize: '16px', // Adjust font size
                              fontWeight: 'bold',
                              overflow: 'hidden',
                              fontFamily: 'K2D, sans-serif', // Apply K2D font family
                              color: "#000",
                              whiteSpace: 'nowrap', // Ensure text does not wrap
                              textOverflow: 'ellipsis', // Add ellipsis for overflow
                              '&:hover': {
                                color: 'rgb(37, 99, 235)', // This is Tailwind's blue-600 color
                              },
                            }}
                          >
                            {item.bhk} {propertyTypeDisplay} for {item.looking_to}{item.property_available_for} in {item.sublocality}
                          </Typography>
                        </Box></Link>
                        <div >
                          <div className='text-[14px] capitalize overflow-ellipsis whitespace-normal line-clamp-2 '>
                            {item.building_name ? `${item.building_name}, ` : ''}{item.city}
                          </div>
                        </div>
                      </div>

                      <div className='flex gap-1 ' style={{ width: "25%", height: "70px", display: "flex", alignItems: "center" }}>
                        {/* <Divider orientation="vertical" variant="middle" sx={{backgroundColor:"#000",color:"#000", width:"1px",height:"50px"}} /> */}

                        <div className="border-solid border-0.5 border-l border-[#404040] h-10 lg:h-10"></div>
                        <div className='flex flex-col  ' style={{ right: 0, left: 0 }}>
                          <div style={{ display: "flex", justifyContent: "left", flexWrap: "wrap", alignItems: "center" }}>
                            <div className='text-[#404040] ' style={{ fontSize: "15px", textAlign: "left", display: "flex", marginRight: "2px" }}>{item.build_up_area || item.built_up_area} {item.plot_area}{" "}</div>
                            <div className='text-[#404040]' style={{ fontSize: "12px" }}> sq.ft.</div>
                          </div>
                          <div style={{ display: "flex", justifyContent: "left", flexWrap: "wrap", alignItems: "center" }}>
                            {item.PriceperSqft && (
                              <div className='text-[#404040]' style={{ fontSize: "15px" }}>
                                ₹ {priceSQTV} <span style={{ fontSize: "10px" }}>/ sq.ft</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='flex justify-between ml-2 items-center mb-1 mt-2'>
                      <div>
                        <p className='text-xl font-medium '>₹ {displayPrice}</p>
                      </div>
                      <div className='flex items-center  gap-3'>
                        {/* <div
                          onClick={() =>
                            handleChat(
                              item.user_id,
                              item.post_id,
                              item.looking_to,
                              item.district,
                              item.building_name,
                              item.property_type,
                              item.bhk
                            )
                          }
                          className="flex bg-[#D9D9D9] p-2 rounded-lg gap-1 items-center"
                        >
                          <img className='h-4' src={MessageIcon} alt='Chat Icon' />
                        </div> */}
                        <button onClick={() =>
                          handleContact(
                            item.property_type,
                            item.looking_to ||
                            item.rent_or_lease ||
                            item.property_available_for,
                            item.user_id,
                            item.post_id,
                            item.district,
                            item.building_name,

                            item.bhk,
                            coverimg.uri
                          )
                        } className='h-7 p-2  rounded-lg flex gap-1 items-center  bg-[#1063b5] ' >
                          <p className='font-normal text-base text-[#FFF] opacity-95'>Contact</p>
                          <img src={phone} alt='Phone Icon' className='h-4 w-4' />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className='flex absolute top-4 justify-center items-center h-[50vh] w-full '>
            <img src={noPer} style={{ height: "60vh", marginTop: "30%" }} />
            <p className='absolute bg-[#D1D1D1] py-3 px-6 rounded-3xl'>Can’t found properties nearby you</p>
          </div>
        )}
        <div className='md:hidden sm:hidden hidden lg:hidden xl:grid fixed z-10'>

        </div>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="contact-modal-title"
        aria-describedby="contact-modal-description"
      >

        <Box
          className=" msm:w-[370px] sm:w-[420px] md:w-[420px] lg:w-[500px] xl::w-[500px] custom-lg:w-[550px]
   absolute 
   rounded-2xl 
   bg-white 
   outline-none 
   top-1/2 
   left-1/2 
   
   transform 
   -translate-x-1/2 
   -translate-y-1/2 
   bg-background 
   shadow-2xl"
        >                       <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 3, top: -4 }}>
            <CancelIcon />             </IconButton>
          <Box className='p-2' >            <Box className='flex msm:flex-col sm:flex-col md:flex-row lg:flex-row  w-[95%] mt-1  ' sx={{ marginTop: { xs: '10px', sm: '13px', md: "13px", lg: "20px", xl: "20px" } }}>
            <div className='flex flex-row  msm:w-[100%] sm:w-[100%] md:w-[70%] lg:w-[70%] px-2 '>
              <Box className='msm:w-[20%] sm:w-[20%] lg:w-[20%] ' sx={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                {similarProperty.userDetails?.company_logo ? (
                  <Avatar alt={similarProperty.userDetails?.name} src={similarProperty.userDetails.company_logo} sx={{ width: 70, height: 70, bgcolor: "#fff" }} />
                ) : (
                  <Box
                    className='bg-yellow-300 rounded-full w-16 h-16 flex items-center justify-center capitalize'
                    style={{ fontSize: '2rem', fontWeight: 'bold' }} // Adjust size and weight as needed
                  >
                    {similarProperty.userDetails?.company_name ? similarProperty.userDetails?.company_name.charAt(0) : similarProperty.userDetails?.name.charAt(0)}
                  </Box>
                )}
              </Box>
              <div className='msm:w-[75%] sm:w-[75%] lg:w-[80%] flex flex-col items-center justify-center h-full'>
                <Box className='text-lg font-semibold capitalize' sx={{
                  width: "90%",
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginLeft: "5px",
                  WebkitLineClamp: 1 // Limit to 2 lines

                }}>           {similarProperty.userDetails?.company_name
                  ? similarProperty.userDetails?.company_name
                  : similarProperty.userDetails?.name}
                </Box>
                <Box sx={{
                  marginLeft: "5px", width: "90%",
                }}>{similarProperty.userDetails?.category}</Box>
                {/* <Box className={`text-lg font-semibold ${similarProperty.userDetails?.category !== "Owner" ? "flex" : "hidden"}`}>
                                {`+91 ${similarProperty.userDetails?.mobile_no?.slice(3)}`}
                            </Box> */}
              </div>                        <div className='hidden md:block border-[#404040] h-[80px] mt-2 border-l'></div>
            </div>


            <Box className='flex  msm:w-[100%] sm:w-[100%] md:w-[30%] lg:w-[30%]  msm:mt-2 sm:mt-2 lg:mt-0'  >
              {similarProperty.userDetails?.user_id !== loginData.user_id ? (
                <>
                  <Box sx={{ width: "100%", justifyContent: "space-evenly", alignItems: "center", display: "flex" }}>
                    {similarProperty.userDetails.category === "Owner" ? (
                      <Box className=" px-2 py-2 msm:w-[150px] sm:w-[150px] lg:w-[140px] xl:w-[140px]  rounded-3xl gap-2" sx={{ backgroundColor: '#1063b5', display: 'flex', justifyContent: 'center', alignItems: 'center' }}

                        onClick={() =>
                          handleChat(
                            similarProperty.userDetails.user_id,
                            similarProperty.userDetails.post_id,
                            similarProperty.userDetails.looking_to,
                            similarProperty.userDetails.district,
                            similarProperty.userDetails.building_name,
                            similarProperty.userDetails.property_type,
                            similarProperty.userDetails.bhk,

                          )
                        }
                      >
                        <img src={chat} alt="Chat" />
                        <Box sx={{ color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          Chat
                        </Box>
                      </Box>
                    ) : (
                      <div className='flex justify-center items-center w-full gap-2 msm:flex-row sm:flex-row md:flex-col  lg:flex-col'>
                        <Box className=" px-2 py-[8px] msm:w-[150px] sm:w-[150px] lg:w-[140px] xl:w-[140px]  rounded-3xl gap-2"
                          onClick={() => {
                            setPhoneNumber(`${similarProperty.userDetails?.mobile_no}`);
                            setDialogOpen(true);
                          }}
                          sx={{ backgroundColor: '#1063b5', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          <Phone sx={{ color: 'white' }} />
                          <Box sx={{ color: 'white' }}>Call</Box>
                        </Box>

                        <Modal
                          open={dialogOpen} onClose={() => setDialogOpen(false)}
                          aria-labelledby="logout-modal-title"
                          aria-describedby="logout-modal-description"
                        >
                          <Box
                            style={{ background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)', fontSize: "13px" }}
                            sx={{
                              position: "absolute",
                              left: "50%",
                              top: "50%",
                              transform: "translate(-50%, -50%)",
                              width: 300,
                              borderRadius: "20px",
                              bgcolor: "background.paper",
                              boxShadow: 24,
                              display: "flex",
                              flexDirection: "row",
                              p: 4,
                              textAlign: 'center'
                            }}
                          >



                            <Typography variant="h6" sx={{ color: "#fff" }}>

                              {phoneNumber}
                            </Typography>
                            <Button
                              href={`tel:${phoneNumber}`}

                              sx={{ color: "#fff" }}

                            >
                              <DifferenceIcon sx={{ fontSize: "20px" }} />
                            </Button>



                            <IconButton

                              onClick={() => setDialogOpen(false)} style={{
                                position: "absolute",
                                top: "8px",
                                right: "8px",
                                color: "#000",
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          </Box>
                        </Modal>
                        <Box className=" px-2 py-[8px] msm:w-[150px] sm:w-[150px] lg:w-[140px] xl:w-[140px]  rounded-3xl gap-2"
                          onClick={() =>
                            handleChat(
                              similarProperty.userDetails.user_id,
                              similarProperty.userDetails.post_id,
                              similarProperty.userDetails.looking_to,
                              similarProperty.userDetails.district,
                              similarProperty.userDetails.building_name,
                              similarProperty.userDetails.property_type,
                              similarProperty.userDetails.bhk,

                            )
                          } sx={{
                            backgroundColor: '#1063b5',

                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img src={chat} alt="Chat" />
                          <Box sx={{ color: 'white' }}>Chat</Box>
                        </Box>
                      </div>
                    )}
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box className="rounded-3xl"

                    sx={{
                      // width: '60%', 
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      padding: "6px",
                      backgroundColor: "red",
                      gap: 2, // Equivalent to gap: 10 (you can adjust it based on your needs)
                      alignItems: 'center',
                      border: '0.6px solid red',
                    }}
                  >
                    <WarningIcon sx={{ color: '#fff', fontSize: 15 }} />
                    <Typography variant="body1" sx={{ color: '#fff', fontWeight: 'bold', fontSize: "15px", fontFamily: "K2D" }}>
                      It's your Post
                    </Typography>
                  </Box>
                </Box>)}
            </Box>



          </Box>


            <Box className="border-solid border-1 border-t mt-2 mx-3 border-[#DBDBDB] mr- msm:text-right"></Box>

            <Carousel className="mt-2 px-1 w-[100%]" indicators={false} interval={1000}>
              {ad.map((item) => (
                <Carousel.Item key={item.id} interval={2000}>
                  {item ? (
                    <div className='msm:h-[150px] sm:h-[150px] lg:h-[150px] xl:h-[180px]'>
                      <a href={item.link} target="_blank" rel="noopener noreferrer">
                        <img
                          className="w-full h-full  rounded-2xl"
                          src={item.image}
                          alt={`Project ${item.id}`}
                        />
                      </a></div>
                  ) : (
                    <Box className="bg-gray-200 w-full h-full flex items-center justify-center">
                      <Box>No Image Available</Box>
                    </Box>
                  )}
                </Carousel.Item>
              ))}
            </Carousel>



            {/*        <div>
                {similarProperty.message?.length > 0 && (
                  <p className='text-xl font-semibold my-4 ml-5'>Similar Properties</p>
                )}
                <div className='flex gap-3 mx-3  overflow-x-scroll w-[94%]'>
                  {similarProperty.message?.map((item: Property, index: number) => {
                    const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
                    const formattedPrice = formatPrice(price);
                    const coverim: any = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
                    const locality = truncateString(item.city, 20)
                    const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
                    const priceSQTV = formatSQPrice(sqtv);

                    const propertyView = {
                      postId: item.post_id,
                      property_type: item.property_type,
                      looking: item.looking_to || item.rent_or_lease || item.property_available_for,
                      userId: loginData.user_id,
                      Bview: Bview
                    }
                    return (
                      <div key={index} className=' '>
                        <div className='relative mt- bg-[#FFFFFF]   h-[31vh  w-[15vw] border-[#000080] border-opacity-30 border-2 p-2 rounded-2xl  '>
                          <div className='relative '>

                            <p className='absolute right-0 mr-1  top-3 bg-[#2E368F] text-white py-1 px-3 rounded-2xl '>{item.looking_to || item.rent_or_lease || item.property_available_for}</p>
                            <img onClick={() => {  handleChange(propertyView)  }}
                              className='w-[15vw] h-[20vh]  flex justify-center bg-black items-center object-contain  rounded-3xl ' src={typeof coverim === 'object' && coverim?.uri ? coverim.uri : ''} />

<div 
  className={`${item.kbl_property ? "flex" : "hidden"} items-center absolute bottom-0 rounded-bl-3xl p-1 rounded-tr-3xl left-0`} 
  style={{ background: 'linear-gradient(90deg, #6135DD, #9B5CDA)' }}
>
  <img className="w-5 h-5" src={star} alt="Star" />
  <p className="text-white text-sm mr-1">VATTARA VERIFIED</p>
</div>                          </div>
                          <div className='ml-3'>
                            <div className='flex items-center '>
                              <p className='text-base flex font-bold'>{item.bhk} {item.property_type}   </p>
                              <img src={goldtick} />
                            </div>
                            <div className='flex gap-2 items-center'>
                              <img className="h-5" src={loaction} />
                              <p className='text-sm capitalize'>{locality}</p>

                            </div>

                            <div className='flex items-center gap-2 '>
                              <p className='text-xl font-bold text-[#404040] '>₹ {formattedPrice} </p>
                              <div className='border-[#404040] h-6 border-1 border-l  '></div>
                              <div className='ml-2'>
                                <div className='flex flex-wra items-center text-sm font-medium text-[#404040]  '>
                                  <p>{item.built_up_area || item.plot_area} </p>
                                  {item.built_up_area || item.plot_area ? <p className='text-sm'>sq.ft</p> : ""}
                                </div>
                                <div className='flex items-center text-sm  font-medium text-[#404040]'>

                                  {item.PriceperSqft ? <p>₹ {priceSQTV}</p> : ""}
                                  {item.PriceperSqft ? <p>/sq.ft</p> : ""}
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    )
                  })
                  }
                </div>
              </div> */}

            <Box>
              {similarProperty.message?.length > 0 && (
                <Box className='text- msm:text-[15px] sm:text-[15px] lg:text-[15px] xl:text-[17px] font-bold my-2 ml-2'>Similar Properties</Box>
              )}
              <Box className='flex gap-2 mx-1 overflow-x-scroll custom-scrollbar'>
                {similarProperty.message?.map((item: Property, index: number) => {
                  const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
                  const formattedPrice = formatPrice(price);

                  const coverim: any = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
                  const locality = truncateString(item.city, 20);
                  const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
                  const priceSQTV = formatSQPrice(sqtv);
                  const propertyView = {
                    postId: item.post_id,
                    property_type: item.property_type,
                    looking: item.looking_to || item.rent_or_lease || item.property_available_for,
                    userId: loginData.user_id,
                    Bview: Bview
                  }
                  const type = encodeURIComponent(propertyView.property_type);
            const url = `/PropertyDetails/${propertyView.postId}/${type}/${propertyView.looking}/${propertyView.Bview}`;


                  return (
                    <Box key={index} className='mb-2'>
                      <Box className="relative bg-[#f5f4f8]  rounded-2xl p-1 w-[190px] " onClick={() => { handleChange(propertyView) }}>
                        {loadingCardId === item.post_id && (
                          <div className="absolute inset-0 flex justify-center items-center bg-opacity-50 bg-gray-800 z-10" style={{ borderRadius: "20px" }}>
                            <CircularProgress color="inherit" /> {/* Loader overlay */}
                          </div>
                        )} <Box className='relative'>

                          <div className='absolute right-0 mr-2 top-3 text-white  px-2 rounded-2xl' style={{ background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)', fontSize: "13px" }}>
                            {item.looking_to || item.rent_or_lease || item.property_available_for}
                          </div>
                          <Link to={url} onClick={(event) => { handleLinkClick(event, url) }}>
                          <img
                            className='w-full msm:h-[140px] sm:h-[140px] md:h-[150px] lg:h-[150px] xl:h-[150px] flex justify-center items-center object-cover rounded-[15px]'
                            src={typeof coverim === 'object' && coverim?.uri ? coverim.uri : DefultScroll} /></Link>
                          <div className={`${item.kbl_property ? "flex" : "hidden"}  items-center  absolute bottom-0  p-1 left-0`} style={{ borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)' }}>
                            <img className="w-4 h-4" src={star} />
                            <div className="text-white mr-1" style={{ fontSize: "8px" }}>VATTARA VERIFIED</div>
                          </div>
                        </Box>
                        <Box className=''>
                          <Box className='flex items-center'>
                            <img src={goldtick} className="w-5 h-5" />
                            <div className='msm:text-[12px] sm:text-[13px] lg:text-[13px] xl:text-[15px]  flex font-bold overflow-hidden whitespace-nowrap truncate'>{item.bhk} {item.property_type}</div>
                          </Box>
                          <Box className='flex gap-1 items-center'>
                            <img className="h-4 w-4" src={loaction} />
                            <Box className='msm:text-[12px] sm:text-[13px] lg:text-[13px] xl:text-[15px]  capitalize' sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}>{item.sublocality}, {item.district}</Box>
                          </Box>
                          <div className='flex items-center ml-1'>
                            <Box className="msm:text-[12px] sm:text-[13px] lg:text-[13px] xl:text-[15px]" sx={{ fontWeight: 800, width: { xs: "90px", md: "80px", lg: "80px" } }}>₹ {formattedPrice}</Box>
                            <div className='border-[#404040] h-6 border-l'></div>
                            <div className='ml-1'>
                              <Box sx={{ fontSize: { xs: "8px", lg: "11px", sm: "11px", md: "11px" } }} className='flex items-center text-sm font-sm text-[#404040]' >
                                <div className='truncate  msm:text-[11px] sm:text-[12px] lg:text-[13px] xl:text-[13px]'>{item.built_up_area || item.plot_area}</div>
                                {item.built_up_area || item.plot_area ? <div className='text-sm ml-1'>sq.ft</div> : ""}
                              </Box>
                              <Box className='flex items-center text-[#404040] msm:text-[11px] sm:text-[12px] lg:text-[13px] xl:text-[13px]'>
                                {item.PriceperSqft ? <div>₹ {priceSQTV}</div> : ""}
                                {item.PriceperSqft ? <div>/sq.ft</div> : ""}
                              </Box>
                            </div>
                          </div>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Scroll;