import React from 'react';
import image1 from "../images/ser1.png";
import image2 from "../images/ser2.png";
import image3 from "../images/ser3.png";
import { MdArrowBackIos, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";

import {  getResponsiveSettings  } from "../ReuseableCompounts/CarouselResponsive";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Button } from '@mui/material';

const ImageCarousel = () => {
  // Step 1: Store the images in an array
  const images = [
    { src: image1, alt: "Image 1" },
    { src: image2, alt: "Image 2" },
    { src: image3, alt: "Image 3" },
   
  ];



  const responsiveSettings = getResponsiveSettings(2.2, 2, 1);

  const CustomRightArrow: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
      <Button 
        onClick={onClick}
        style={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          borderRadius:"20px",
        //   transform: 'translateY(-50%)',
          minWidth: 'auto', // Remove default min-width
          background: '#F5F4F8', // Make background transparent
          padding: '0', // Remove padding
        }}
      >
       <MdOutlineKeyboardArrowRight        size={30} color="#000" />
      </Button>
    );
  };
  const CustomLefttArrow: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
      <Button
        onClick={onClick}
        style={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          borderRadius:"20px",
        //   transform: 'translateY(-50%)',
          minWidth: 'auto', // Remove default min-width
          background: '#F5F4F8', // Make background transparent
          padding: '0', // Remove padding
        }}
      >
       <MdOutlineKeyboardArrowLeft        size={30} color="#000" />
      </Button>
    );
  };
  return (
    <div className='mx-2 xl:mx-0'>
      <Carousel
        swipeable
        draggable
        showDots={false}
        responsive={ responsiveSettings }
        ssr // means to render carousel on server-side.
        infinite
        autoPlay
        autoPlaySpeed={3000}
        keyBoardControl
        customTransition="transform 500ms ease-in-out"
        transitionDuration={500}
        customRightArrow={<CustomRightArrow onClick={function (): void {
          throw new Error('Function not implemented.');
      } }  />}
      customLeftArrow={<CustomLefttArrow onClick={function (): void {
          throw new Error('Function not implemented.');
      } }  />}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {/* Step 2: Use .map() to render the images */}
        {images.map((image, index) => (
          <div key={index} className='mx-1'>                    
            <img className="w-[100vw] h-full object-cover rounded-lg" src={image.src} alt={image.alt} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default ImageCarousel;
