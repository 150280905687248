import React from 'react';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';

interface SliderComponentProps {
  onUpdateSlider: (event: Event, newValue: number | number[]) => void;
 
}
function valueLabelFormat(value: number) {
  if (value >= 1000 && value < 100000) {
    return (value / 1000).toFixed(1) + ' SQ.ft';
  }
  if (value >= 10000000) {
    return (value / 10000000).toFixed(2) + ' SQ.ft';
  } else if (value >= 100000) {
    return (value / 100000).toFixed(2) + ' SQ.ft';
  }
  return value.toString();
}

const AreaSlider: React.FC<SliderComponentProps> = ({ onUpdateSlider,  }) => {
  const [value, setValue] = React.useState<[number, number]>([0, 500000]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as [number, number]);
    onUpdateSlider(event, newValue);
  };

  

  return (
    <Box sx={{ width: {lg:"90%",sm:"99%",xs:"97%"}, marginTop: "5px" }}>
      <Slider
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        step={1000}

        valueLabelFormat={valueLabelFormat}
        min={0}
        max={500000}
        sx={{
          '& .MuiSlider-track': {
            backgroundColor: '#0cb7d1',
            color: '#0cb7d1'
          },
          '& .MuiSlider-thumb': {
            color: 'white'
          },
          '& .MuiSlider-valueLabel': {
            backgroundColor: 'white',
            color: 'black'
          }
        }}
      />
    </Box>
  );
};

export default AreaSlider;
