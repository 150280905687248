/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Box,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
  Avatar,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Phone as PhoneIcon,
  Send as SendIcon,
} from '@mui/icons-material';
import { io, Socket } from 'socket.io-client';
import axios from 'axios';
import { Message as ChatMessage } from 'react-chat-ui'; // Import Message type
import { useChat } from '../context/ChatContext';
import { useSelector } from 'react-redux';
import { MdArrowBackIos, MdArrowBackIosNew, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { handleChange } from '../ReuseableCompounts/PostDetail';



// const ENDPOINT = 'https://chat.vattaram.in';

const ENDPOINT = 'https://chat.vattaram.in';

interface ExtendedMessage {
  id: string;
  message: string;
  createdAt?: Date;
  senderName?: string;
  senderPic?: string;
  image?: string | null;
  post_id?: any;
}

interface ChatProps {
  chat: {
    chatId: string;
    userId: string;
    username: string;
    userpic: string;
  };
  initialMessageText?: string;
  details?: any;
  postId?: string | null;
  setShowChatList: any

}

const SingleChat: React.FC<ChatProps> = ({
  chat,
  initialMessageText,
  details,
  postId,
  setShowChatList

}) => {
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  const [socketConnected, setSocketConnected] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [newMessage, setNewMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [messages, setMessages] = useState<ExtendedMessage[]>([]);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [imageDisplayed, setImageDisplayed] = useState<boolean>(false);

  const { selectedChat, setSelectedChat } = useChat(); // Import setSelectedChat
  console.log('select naga', messages,);

  const chatId = selectedChat.chatId;
  const username = selectedChat.companyname || selectedChat.username;
  const userpic = selectedChat.companylogo;
  const userId = selectedChat.userId;
  const userna =selectedChat.username

  if (!selectedChat) {
    return <div>No chat selected</div>;
  }

  const imageUrl = details?.image;
  console.log('image Check ', imageUrl);

  const selectedChatCompare = useRef<string | null>(null);

  useEffect(() => {
    console.log('chatId:', chatId);
    console.log('userId:', userId);
    if (!userId) return;

    const newSocket = io(ENDPOINT);
    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('WebSocket connected');
      newSocket.emit('setup', loginData.user_id);
      setSocketConnected(true);
    });

    newSocket.on('typing', () => {
      setIsTyping(true);
    });

    newSocket.on('stop typing', () => {
      setIsTyping(false);
    });

    newSocket.on('message received', (newMessageReceived: any) => {
      console.log("message received",newMessageReceived)
      if (newMessageReceived.chatId === chatId) {
        const formattedMessage: ExtendedMessage = {
          id: newMessageReceived.id,
          message: newMessageReceived.content,
          createdAt: new Date(newMessageReceived.createdAt),
          senderName: newMessageReceived.sender?.name || 'Unknown',
          senderPic: newMessageReceived.sender?.user_pic || '',
          image: newMessageReceived.image || null,
        };

console.log("message received",newMessageReceived)
        setMessages((prevMessages) => [...prevMessages, formattedMessage]);
      }

    });

    if (chatId) {
      fetchMessages();
      selectedChatCompare.current = chatId;
    }

    return () => {
      if (newSocket) {
        newSocket.emit('leave room', chatId);
        newSocket.off('message received');
        newSocket.off('typing');
        newSocket.off('stop typing');
      }
    };
  }, [userId, chatId]);



  const fetchMessages = useCallback(async () => {
    if (!chatId) return;

    try {
      const { data } = await axios.get(`${ENDPOINT}/api/message/${chatId}`);
      console.log('Fetched messages:', data);
      const formattedMessages = data.map((message: any) => ({
        id: message.id,
        PostId: message.post_id,
        message: message.content,
        createdAt: new Date(message.createdAt),
        senderName: message.sender?.name || 'Unknown',
        senderPic: message.sender?.user_pic || '',
        image: message.image || null,
      }));

      const sortedMessages = formattedMessages.sort(
        (a: ExtendedMessage, b: ExtendedMessage) => {
          const dateA = a.createdAt ? new Date(a.createdAt) : new Date(0);
          const dateB = b.createdAt ? new Date(b.createdAt) : new Date(0);
          return dateA.getTime() - dateB.getTime();
        }
      );

      setMessages(sortedMessages);
      console.log("message received",sortedMessages,formattedMessages)
      setLoading(false);

      
      if (!imageDisplayed && (initialMessageText || imageUrl)) {
        handleSend([
          {
            message: initialMessageText || '',
            image: imageUrl,
            id: chatId,
          },
        ]);
        setImageDisplayed(true);
      }

      if (socket) {
        socket.emit('join chat', chatId);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      setLoading(false);
    }
  }, [chatId, initialMessageText, imageUrl, socket, imageDisplayed]);

  const handleSend = async (newMessages: ExtendedMessage[] = []) => {
    if (newMessages.length === 0) return;

    const newMessage = newMessages[0];

    try {
      const messageData = {
        content: newMessage.message || '',
        chatId,
        image: newMessage.image || null,
        req_user_id: loginData.user_id,
        senderName: username,
        senderPic: userpic,
        post_id: details?.postId,
      };
      // console.log("body", messageData,details.postId)
      const response = await axios.post(`${ENDPOINT}/api/message`, messageData);
      const formattedMessage: any = {
        id: response.data.newMessage.id,
        PostId:  response.data.newMessage.post_id,
        message: response.data.newMessage.content,
        createdAt: response.data.newMessage.createdAt
          ? new Date(response.data.newMessage.createdAt)
          : new Date(), // Fallback to current time if undefined
        senderName:
          response.data.newMessage.sender?.name || username || 'Unknown',
        senderPic: response.data.newMessage.sender?.user_pic || userpic || '',
        image: response.data.newMessage.image || null,
      };

      setMessages((prevMessages) => [...prevMessages, formattedMessage]);
      console.log("message received",formattedMessage,response)

      if (socket) {
        // Send the message to the specific chat room
        console.log("col", response.data.newMessage, chatId)
        socket.emit('new message', { ...response.data.newMessage, chatId });
        socket.emit('stop typing', chatId);

      }

    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(event.target.value);
    if (socketConnected && userId) {
      socket?.emit('typing', chatId);
    }
  };

  function handleNext(message: any) {
    console.log("Received message:", message);

    // Define a regex pattern to match the property types
    const pattern = /Apartment|Independent House|Villa|Plot \/ Land|Plot/g;


    // Extract property types using the regex
    const matches = message.message.match(pattern);

    // Get unique property types
    const uniquePropertyTypes = matches ? [...new Set(matches)] : [];

    const PropertyTypes = uniquePropertyTypes[0]

    const keywords = ['Sale', 'Rent', 'Lease'];
    const foundTypes: any = [];

    // Convert the message to lower case for case-insensitive matching


    // Check for the presence of each keyword in the message
    keywords.forEach(keyword => {
      if (message.message.includes(keyword)) {
        foundTypes.push(keyword);
      }
    });

    console.log("Extracted Property Types:", PropertyTypes,message, foundTypes);
    const Bview = "Sview"

    const propertyView = {
      postId: message.PostId,
      property_type: PropertyTypes,
      looking: foundTypes[0],
      userId: loginData.user_id,
      Bview: Bview
    }

     handleChange(propertyView) 
  }



  const handleSubmit = () => {
    if (newMessage.trim()) {
      handleSend([
        {
          message: newMessage,
          id: chatId,
        },
      ]);
      setNewMessage('');
    }
  };

  const handleBackToChatList = () => {
    setShowChatList(true); // Show chat list when back is clicked
    setSelectedChat(null); // Clear selectedChat state

  };

  const renderHeader = () => (
    <Box className="rounded-tr-[20px] p-[10px]"
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: "#f2f2f2",
        borderBottom: '1px solid #ddd',
      }}
    >
      <div className='msm:flex sm:hidden lg:hidden'><IconButton

        onClick={handleBackToChatList}

        // onClick={handleBackToChatList2}  
        aria-label="Back"
        sx={{
          borderRadius: '50%', // Optional: for a circular button
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)', // Optional: change background on hover
          },
        }}
      >
        <MdArrowBackIos className='w-8 h-7' /></IconButton></div>

      <div className='w-[10%]'>  {userpic ? (
        <Avatar src={userpic} sx={{
          bgcolor: "#fff", marginRight: 2, height: '40px',
          width: '40px',
          borderRadius: '50%',
          borderColor: 'white',
        }} />
      ) : (
        <Box
          className=' rounded-full w-16 h-16 flex items-center justify-center font-bold'
          sx={{
            bgcolor: "#0CB7D1", marginRight: 2, height: '40px',
            width: '40px',
            borderRadius: '50%',
            fontSize: "17px",

            borderColor: 'white',
          }}            >
          {username.charAt(0)}
        </Box>
      )}</div>
      <Box className='w-[80%]' sx={{ marginLeft: "13px" }}>
        <div
          className='msm:text-[18px] sm:text-[18px] lg:text-[16px] font-bold  capitalize overflow-hidden whitespace-nowrap text-ellipsis '>{username}</div>
        {isTyping && (
          <Typography variant="body2" color="textSecondary">
            Typing...
          </Typography>
        )}
      </Box>
      {/* <IconButton>
        <PhoneIcon />
      </IconButton> */}
    </Box>
  );

  const renderMessage = (message: ExtendedMessage) => {
    console.log("messagess",message,username)
    const isSender = message.senderName !== userna;

    return (
      <Box
        key={message.id}
        sx={{
          display: 'flex',
          justifyContent: isSender ? 'flex-end' : 'flex-start',
          marginBottom: 1,

        }}
      >
        <Box
          sx={{
            maxWidth: '60%',
            padding: 1,
            borderRadius: isSender ? "10px 10px 0px 10px" : "10px 10px 10px 0px",
            backgroundColor: isSender ? '#e6f7fa' : '#fff',
            boxShadow: 1,
          }}
        >
          <div className='msm:text-[16px] sm:text-[16px] lg:text-[14px] break-words' >{message.message}</div>
          {message.image && (
            <img
              onClick={() => handleNext(message)}
              src={message.image}
              alt="Message"
              style={{ maxWidth: '100%' }}
            />
          )}
        </Box>
      </Box>
    );
  };

  return (
    <div className="flex flex-col msm:h-full sm:h-full lg:h-[90vh] xl:h-[93vh]">
      {renderHeader()}
      <Box className="custom-scrollbar sm:h-[78vh] msm:h-[85vh] lg:h-[80vh] xl:h-[80vh]" sx={{ padding: 2, overflowY: 'auto' }}>
        {loading ? (
          <CircularProgress />
        ) : (
          messages.map((message) => renderMessage(message))
        )}
      </Box>
      <div className="flex items-center p-1 shadow-lg rounded-3xl mx-3 my-2">

        <TextField
          value={newMessage}
          onChange={handleInputChange}
          placeholder="Type a message"
          variant="standard"
          sx={{ marginX: "20px", width: "95%" }}
        />
        <IconButton onClick={handleSubmit} color="primary" >
          <SendIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default SingleChat;
