

export const basics = {
    ["Property Type"]: "",
    ["Looking To"]: "",
    ["Property Subtype"]: "",
    ["City"]: "",
    ["Locality"]: "",
    ["Landmark"]: "",
    ["latitude"]:"",
    ["longitude"]:"",
    ["sublocality"]:"",
}


export const propertyDetails = {
    ["ResrCom"]: "",
    ["BHK"]: "",
    ["Built up Area"]: "",
    ["Furnish Type"]: "",
    ["Next, add price details"]: "",
    ["Amenities"]: "",
    ["Furnishing"]: "",
    ["Building/Project(Optional)"]: '',
    ["Plot Area"]: "",
    ["Length"]: "",
    ["Width"]: "",
    ["Property Status"]: "",
    ["Patta Available"]: "",
    ["Facing"]: "",
    ["Possession Status"]: "",
    ["calendar2"]: "",
    ["Suitable For"]: "",
    ["Carpet Area"]: "",
    ["Total Floors"]: "",
    ["Select Your Floor"]: "",
    ["Parking"]: "",
    ["Washroom"]: "",
    ["Available From"]: "",
    ["Property Condition"]: "",
    ["Lifts"]: "",
    ["Meeting Room"]: "",
    ["Min.Number of seats"]: "",
    ["Max.Number of seats"]: "",
    ["Built Up Area"]: "",
    ["Min. Number of seats"]: "",
    ["Max. Number of seats"]: "",
    ["look_to"]: "",
    ["measure"]:"",
    ['property_type']:''






}

export const PriceDetails = {
    ["change"]:"",
    ["ResrCom"]: "",
    ["Property available for"]: "",
    ["Monthly Rent"]: "",
    ["Lease Amount"]: "",
    ["Available From"]: "",
    ["Select"]: "",
    ["Negotiable"]: "",
    ["Construction Status"]: "",
    ["Property Description"]: "",
    ["Price"]: "",
    ["price"]: "",
    ["Cost"]: "",
    ["Plot Price"]: "",
    ["look_to"]: "",
    ["Possession Status"]:""

}


export const AdditionalDatails = {
    ["Bathroom"]: "",
    ["Balcony"]: "",
    ["Preferred Tenant Type"]: "",
    ["Parking"]: "",
    ["Carpet Area"]: '',
    ["Maintenance Charges"]: "",
    ["Facing"]: "",
    ["Servant Room"]: "",
    ["Property Description"]: "",
    ["Loan Availability"]: "",
    ["AMENITIES"]: ""

}

