import { Box, Radio } from "@mui/material";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { reportReasons } from "../data";
import { axiosInstance } from "../api/APIs";
import { IconButton } from "@mui/material";

type RadioValue = string

type OtherReasonValue = string;

interface props {
    look: any;
    id: any;
    property: any
    setVisable:any
    setRender:any
    render:any
}


interface ReportPopUpProps {
    showReport: boolean;
    setReportSumit: (value: boolean) => void;
    handleShowReport: () => void;
}

const DeletePopUp: React.FC<props> = ({ look, id, property ,setVisable,setRender,render}) => {
    const [selectedValue, setSelectedValue] = useState<RadioValue>("");
    const [otherReason, setOtherReason] = useState<OtherReasonValue>("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showReport, setShowReport] = useState(true);
    const [reportSubmit, setReportSubmit] = useState(false);
    const handleRadioChange = (value: RadioValue) => {
        setSelectedValue(value);
        setShowErrorMessage(false);
    };

    const handleOtherReasonText = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setOtherReason(event.target.value);
    };

    const handleSummit = async () => {
        if (selectedValue === "other" &&otherReason=="" ) {
            setShowErrorMessage(true);
            console.log(
                `Selected reason for the report : ${selectedValue} (${otherReason})`
            );
            setReportSubmit(true);
        } else if (selectedValue.length === 0) {
            setShowErrorMessage(true);
        } else {
            console.log("Selected reason for the report : ", selectedValue);
            setShowReport(true);
        
        const body:any = {
            post_id: id,
            property_type: property,
            rent_or_sell: look,
            delete_reason:selectedValue==="other"?otherReason:selectedValue
        }
        try {

            const response = await axiosInstance.delete("/delete/post/deactive",{data:body})
            console.log(response);
           
            setVisable(false)
            setRender(!render)

            
        } catch (error) {
            console.log(error);
        }
    }
    };


    function handleShowReport() {
        setVisable(false)
        setRender(!render)
    }




    return (
        <Box
        className="msm:w-[95%] sm:w-[60%] lg:w-[40%] xl:[40%]"

        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius:"20px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          textAlign: 'center'
        }}
      >
                    <span onClick={handleShowReport} className="absolute top-1 right-0 md:right-4 msm:right-4 lg:right-4 text-gray-500 text-2xl cursor-pointer"><IconButton><IoClose /></IconButton></span> 

        <div
            className={`${showReport && "opacity-100 relative"
                }  opacity-0 bg-white w-full     `}
        >
            <header className=" text-[#202020] text-center pb-2 border-b-[0.5px] border-gray-300">
             <div className=" msm:pt-6 md:pb-2  text-[#FF3131] font-bold">
                    Please let us know the reason for deleting the listing
                </div>

            </header>
            <main className="mx-[1%]"> 

                <div className="my-2 lg:mx-[10%]">
                    {reportReasons.map((report) => (
                        <div onClick={() => handleRadioChange(report.value as RadioValue)} className={` flex justify-start items-start cursor-pointer`} key={report.value}>
                            <Radio
                                value={report.value}
                                checked={selectedValue === report.value}
                                sx={{
                                    color: "#80848E",
                                    "&.Mui-checked": { color: "#2E368F" },
                                    "& svg": {
                                        fontSize: 20,
                                    },
                                }}
                            />
                            <div className=" mt-1 text-left">
                                <p className="font-semibold">
                                    {report.reason}
                                </p>

                            </div>
                        </div>
                    ))}
                </div>
                <div
                    className={`${selectedValue === "other"
                        ? "max-h-96 opacity-100"
                        : "max-h-0 opacity-0"
                        } transition-all duration-700 my-1 overflow-hidden`}
                >
                    <textarea
                        onChange={handleOtherReasonText}
                        placeholder="Describe the issue in detail"
                        className="rounded-lg border-2 transition-colors duration-100 w-full h-[150px] border-gray-300 focus:border-gray-400 outline-none p-2"
                    ></textarea>
                </div>
                <div
                    className={`${showErrorMessage && "opacity-100 max-h-8"
                        } opacity-0 max-h-0 overflow-hidden text-red-600 text-sm text-center font-semibold transition-opacity duration-200`}
                >
                    Please select any reason
                </div>
                <div className="flex justify-center">
                    <button
                        onClick={()=>{handleSummit()}}
                        className="bg-[#1063b5] hover:bg-opacity-95 active:bg-opacity-90 text-[#f5f5f5] p-2 w-[90%] lg:w-[75%]  mx-auto rounded-full"
                    >
                        Confirm
                    </button>
                </div>
            </main>
        </div></Box>
    );
};

export default DeletePopUp;
