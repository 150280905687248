import React, { useEffect, useState } from 'react'
import less from "../images/Less.png";
import computers from "../images/Computers.png";
import mobile from '../images/phoneLog.png'
import laptop from '../images/laptop.png'
import amm from '../images/amm.png'
import { axiosInstance } from '../api/APIs';
import { CircularProgress, IconButton } from '@mui/material';

import { useSelector } from 'react-redux';
import { setUserLoginData } from "../../slices/userDataSlice";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";
import { Link } from 'react-router-dom';
import { Box, Button, Modal, Typography } from '@mui/material';
import LoginDetilas from './LoginDetilas';


const LoginHistory = () => {
    const location = useLocation();
    const { id, os, name, last_login } = location.state || {}
    const loginData = useSelector((state: any) => state.userData.userLoginData)
    const [Data, setData] = useState<any>([])
    const dispatch = useDispatch()
    const [detail, setDetail] = useState<any>()
    const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({}); 
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()

    console.log("lodinData", loginData.user_id)

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {


        fetch(); 

    }, []);

    const fetch = async () => {
        try {
            if (loginData?.user_id) {  
                const response = await axiosInstance(`/user/login/deviceGet/${loginData.user_id}`);

                if (response.status === 200) {
                    setData(response.data.Data)
                    console.log("apiHistory", response.data.Data)
                }
            }
        } catch (error) {
            console.error("Error fetching device history:", error);
        }
    };
    function timeAgo(timestamp: any) {
        const now = new Date();
        const past = new Date(timestamp);
        const diffMs = (now as any) - (past as any); 

        const diffSec = Math.floor(diffMs / 1000); 
        const diffMin = Math.floor(diffSec / 60); 
        const diffHour = Math.floor(diffMin / 60); 

        if (diffSec < 60) {
            return `${diffSec} seconds ago`;
        } else if (diffMin < 60) {
            return `${diffMin} minutes ago`;
        } else if (diffHour < 24) {
            return `${diffHour} hours ago`;
        } else {
            const days = Math.floor(diffHour / 24);
            return `${days} days ago`;
        }
    }

    const handleLogout = async (item: any) => {
        setLoadingStates((prev) => ({ ...prev, [item.id]: true })); 
        const body = {
            logout_device_id: item.id,
            new_login_deviceName: { id: id, os: os, name: name, last_login: last_login },
            mobile_no: loginData.phone,
        }
        console.log("bodyuy", body)
        try {
            const response = await axiosInstance.post("/user/login/removeDevice", body);
            if (response.status === 200) {
                fetch()
                console.log("responseBY", response.data)
                const verf = response.data
                setDetail(verf)
                console.log("verf", detail)

                const reduxSent = {
                    category: verf.userCategory,
                    token: verf.encryptedToken,
                    encryptedToken: verf.encryptedToken,
                    name: verf.userName,
                    phone: verf.userMobileno,
                    email: verf.userEmail,
                    company_logo: verf.company_logo,
                    company_name: verf.company_name,
                    lang: verf.lang,
                    user_id: verf.UserId,
                    Id: id
                }


                dispatch(setUserLoginData(reduxSent))

                if (verf.userName !== "") {
                    const reduxSent = {
                        category: verf.userCategory,
                        token: verf.encryptedToken,
                        encryptedToken: verf.encryptedToken,
                        name: verf.userName,
                        phone: verf.userMobileno,
                        email: verf.userEmail,
                        company_logo: verf.company_logo,
                        company_name: verf.company_name,
                        lang: verf.lang,
                        user_id: verf.UserId,
                        Id: id

                    }
                    dispatch(setUserLoginData(reduxSent))
                    navigate('/')
                } else {
                    setOpen(true)
                }




            }
        } catch (error) {
            console.error('Error fetching data:', error);

        }
        finally {
            setLoadingStates((prev) => ({ ...prev, [item.id]: false })); 
        }
    }
    const filter = Data.filter((item: any) => item.id !== loginData.Id)

    return (
        <div className="bg-[#F5f4f8] h-screen" >
      <div className="lg:mx-[20%] msm:mx-[2%] lg:mt-8 sm:mt-8 bg-[#F5F4F8] pb-10">
             
            <Box className='flex items-center py-4 ' sx={{marginTop:{xs:0,sm:2},}}>
                    
  <button 
    onClick={() => navigate(-1)} 
    className="absolute  z-40"
  >
    <IconButton><MdArrowBackIosNew className='w-8 h-8 sm:flex msm:flex md:flex' /></IconButton>
  </button>
  <p className='text-xl font-bold flex justify-center items-center flex-1'>Login History</p>

        </Box>
                <div className=' flex lg:flex-row  mt-2 msm:flex-col sm:flex-col '>
                    <div className='flex flex-col items-center lg:mr-11  msm:mb-9'>
                        <img src={computers} alt="computers icon" />
                        <p className='font-semibold lg:text-sm msm:text-xs sm:text-lg'>To log in on a new device, you must log out from one of the</p>
                        <p className='font-semibold lg:text-sm msm:text-xs sm:text-lg'>devices you are currently logged in on.</p>
                    </div>
                    <div>
                        <p className='font-bold lg: text-xl opacity-90 sm:text-2xl'>Current Devices</p>
                        {filter.length === 0 ? (
                            <div className='mt-4 text-center font-bold'>Your Device Only Logged In</div>
                        ) : (
                            filter.map((item: any, index: any) => (
                                <div key={index} className={`flex items-center msm:justify-between lg:gap-4 msm:gap-1 mt-4 sm:gap-1 ${item.id===loginData.Id?"hidden":"flex"}`}>
                                    {item.os === "Windows" ? (
                                        <img className='' src={laptop} alt="laptop icon" />
                                    ) : (
                                        <img className=' ' src={mobile} alt="mobile icon" />
                                    )}
                                    <div className='lg:w-60 msm:w-36 sm:w-56'>
                                        <div >
                                            {item.os === "Windows" ? (
                                                <p className='lg:font-semibold msm:font-bold lg:text-sm msm:text-xs'>{item.id} Windows OS Web Browser</p>
                                            ) : (
                                                <p className='lg:font-semibold msm:font-bold lg:text-sm msm:text-xs'>{item.id} Android Phone/Tablet</p>
                                            )}

                                        </div>
                                        <p className='opacity-80 sm:text-base  text-sm'>Last Login: {timeAgo(item.last_login)}</p>
                                    </div>
                                    <button
                                        onClick={() => handleLogout(item)}
                                        className='text-white py-2 px-3  rounded-xl lg:w-32   bg-[#1063b5]'
                                        disabled={loadingStates[item.id]} 
                                        >
                                            {loadingStates[item.id] ? <CircularProgress size={24} color="inherit" /> : "Log Out"}
                                        </button>

                                </div>
                            ))
                        )}

                      
                    </div>
                </div>
            </div>
            <div className='bottom-8 w-[100%]'>
                <div className='border-1 border-black lg:mx-10 lg:ml-14 '></div>
                <div className='lg:flex justify-center items-center lg:mt-2 lg:gap-2 lg:text-base sm:text-base msm:text-xs msm:flex msm:mt-2 sm:flex '>
                    <img className='sm:ml-2 ' src={amm} />
                    <p className='text-center msm:text-left  sm:bottom-1 '>You can be logged in on up to 4 devices at a time. To log in on a new device, please log out from one of the devices listed above.</p>
                </div>
            </div>
            <Modal
                open={open}
              
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                        
                        <LoginDetilas  />
            </Modal>
        </div>
    );
};

export default LoginHistory;


// import React, { useEffect, useState } from 'react'
// import less from "../images/Less.png";
// import computers from "../images/Computers.png";
// import mobile from '../images/phoneLog.png'
// import laptop from '../images/laptop.png'
// import amm from '../images/amm.png'
// import { axiosInstance } from '../api/APIs';
// import { useSelector } from 'react-redux';
// import { setUserLoginData } from "../../slices/userDataSlice";
// import { useDispatch } from "react-redux";
// import { useNavigate, useLocation } from "react-router-dom";
// import { MdArrowBackIosNew } from "react-icons/md";
// import { Link } from 'react-router-dom';
// import { Box, Button, Modal, Typography } from '@mui/material';
// import LoginDetilas from './LoginDetilas';


// const LoginHistory = () => {
//     const location = useLocation();
//     const { id, os, name, last_login } = location.state || {}
//     const loginData = useSelector((state: any) => state.userData.userLoginData)
//     const [Data, setData] = useState<any>([])
//     const dispatch = useDispatch()
//     const [detail, setDetail] = useState<any>()
//     const [open, setOpen] = useState(false);
//     const navigate = useNavigate()

//     console.log("lodinData", loginData.user_id)

//     const handleOpen = () => setOpen(true);
//     const handleClose = () => setOpen(false);

//     useEffect(() => {


//         fetch(); // Call the async function

//     }, []);

//     const fetch = async () => {
//         try {
//             if (loginData?.user_id) {  // Ensure loginData and user_id exist before making the request
//                 const response = await axiosInstance(`/user/login/deviceGet/${loginData.user_id}`);

//                 if (response.status === 200) {
//                     setData(response.data.Data)
//                     console.log("apiHistory", response.data.Data)
//                 }
//             }
//         } catch (error) {
//             console.error("Error fetching device history:", error);
//         }
//     };
//     function timeAgo(timestamp: any) {
//         const now = new Date();
//         const past = new Date(timestamp);
//         const diffMs = (now as any) - (past as any); // Difference in milliseconds

//         const diffSec = Math.floor(diffMs / 1000); // Difference in seconds
//         const diffMin = Math.floor(diffSec / 60); // Difference in minutes
//         const diffHour = Math.floor(diffMin / 60); // Difference in hours

//         if (diffSec < 60) {
//             return `${diffSec} seconds ago`;
//         } else if (diffMin < 60) {
//             return `${diffMin} minutes ago`;
//         } else if (diffHour < 24) {
//             return `${diffHour} hours ago`;
//         } else {
//             const days = Math.floor(diffHour / 24);
//             return `${days} days ago`;
//         }
//     }

//     const handleLogout = async (item: any) => {
//         const body = {
//             logout_device_id: item.id,
//             new_login_deviceName: { id: id, os: os, name: name, last_login: last_login },
//             mobile_no: loginData.phone,
//         }
//         console.log("bodyuy", body)
//         try {
//             const response = await axiosInstance.post("/user/login/removeDevice", body);
//             if (response.status === 200) {
//                 fetch()
//                 console.log("responseBY", response.data)
//                 const verf = response.data
//                 setDetail(verf)
//                 console.log("verf", detail)

//                 const reduxSent = {
//                     category: verf.userCategory,
//                     token: verf.encryptedToken,
//                     encryptedToken: verf.encryptedToken,
//                     name: verf.userName,
//                     phone: verf.userMobileno,
//                     email: verf.userEmail,
//                     company_logo: verf.company_logo,
//                     company_name: verf.company_name,
//                     lang: verf.lang,
//                     user_id: verf.UserId,
//                     Id: id
//                 }


//                 dispatch(setUserLoginData(reduxSent))

//                 if (verf.userName !== "") {
//                     const reduxSent = {
//                         category: verf.userCategory,
//                         token: verf.encryptedToken,
//                         encryptedToken: verf.encryptedToken,
//                         name: verf.userName,
//                         phone: verf.userMobileno,
//                         email: verf.userEmail,
//                         company_logo: verf.company_logo,
//                         company_name: verf.company_name,
//                         lang: verf.lang,
//                         user_id: verf.UserId,
//                         Id: id

//                     }
//                     dispatch(setUserLoginData(reduxSent))
//                     navigate('/')
//                 } else {
//                     setOpen(true)
//                 }




//             }
//         } catch (error) {
//             console.error('Error fetching data:', error);

//         }
//     }
//     const filter = Data.filter((item: any) => item.id !== loginData.Id)

//     return (
//         <div className='sm:min-h-screen w-full bg-[#F5F4F8]'>
//             <div className='lg:mx-[15%] pt-1 pb-4 msm:mx-[5%]'>
//                 <div className='flex sm:gap-2 msm:gap-2 items-center '>
//                     <Link to={'/Profile'}>
//                         <MdArrowBackIosNew className=' w-8 h-8  absolute   lg:hidden left-1 z-40 sm:flex msm:flex   md:flex' />
//                     </Link>
//                     <p className='text-xl sm:mt-8 msm:ml-2 msm:mt-8 font-bold'>Login History</p>
//                 </div>
//                 <div className=' flex lg:flex-row  mt-2 msm:flex-col sm:flex-col '>
//                     <div className='flex flex-col items-center lg:mr-11  msm:mb-9'>
//                         <img src={computers} alt="computers icon" />
//                         <p className='font-semibold lg:text-sm msm:text-xs sm:text-lg'>To log in on a new device, you must log out from one of the</p>
//                         <p className='font-semibold lg:text-sm msm:text-xs sm:text-lg'>devices you are currently logged in on.</p>
//                     </div>
//                     <div>
//                         <p className='font-bold lg: text-xl opacity-90 sm:text-2xl'>Current Devices</p>
//                         {filter.length === 0 ? (
//                             <div className='mt-4 text-center font-bold'>Your Device Only Logged In</div>
//                         ) : (
//                             filter.map((item: any, index: any) => (
//                                 <div key={index} className={`flex items-center msm:justify-between lg:gap-4 msm:gap-1 mt-4 sm:gap-1 ${item.id === loginData.Id ? "hidden" : "flex"}`}>
//                                     {item.os === "Windows" ? (
//                                         <img className='' src={laptop} alt="laptop icon" />
//                                     ) : (
//                                         <img className=' ' src={mobile} alt="mobile icon" />
//                                     )}
//                                     <div className='lg:w-60 msm:w-36 sm:w-56'>
//                                         <div >
//                                             {item.os === "Windows" ? (
//                                                 <p className='lg:font-semibold msm:font-bold lg:text-sm msm:text-xs'>{item.id} Windows OS Web Browser</p>
//                                             ) : (
//                                                 <p className='font-semibold sm:text-lg msm:text-xs'>{item.id} Android Phone/Tablet</p>
//                                             )}

//                                         </div>
//                                         <p className='opacity-80 sm:text-base  text-sm'>Last Login: {timeAgo(item.last_login)}</p>
//                                     </div>
//                                     <button
//                                         onClick={() => handleLogout(item)}
//                                         className='text-white py-2 px-3  rounded-xl lg:w-32   bg-[#2E368F]'
//                                     >
//                                         Log Out
//                                     </button>

//                                 </div>
//                             ))
//                         )}

//                         {/* <div className='flex items-center justify-between  mt-4'>
//                             <img className='' src={laptop} alt="mobile icon" />
//                             <div className=''>
//                                 <p className='font-semibold'>Android Phone/Tablet</p>
//                                 <p className='opacity-80'>Last Login: Today</p>
//                             </div>
//                             <button className='text-white py-2 px-3 rounded-xl  ml-14 bg-[#2E368F]'>Log Out</button>
//                         </div> */}
//                     </div>
//                 </div>
//             </div>
//             <div className='md:absolute bottom-8 w-[100%]'>
//                 <div className='border-1 border-black lg:mx-10 lg:ml-14 '></div>
//                 <div className='lg:flex justify-center items-center lg:mt-2 lg:gap-2 lg:text-base sm:text-base msm:text-xs msm:flex msm:mt-2 sm:flex '>
//                     <img className='sm:ml-2 mb-3' src={amm} />
//                     <p className='text-center msm:text-left  sm:bottom-1 '>You can be logged in on up to 4 devices at a time. To log in on a new device, please log out from one of the devices listed above.</p>
//                 </div>
//             </div>

//             <Modal
//                 open={open}
              
//                 aria-labelledby="modal-title"
//                 aria-describedby="modal-description"
//             >
//                 <Box sx={{ bgcolor: 'background.paper', p: 4, borderRadius: 1, boxShadow: 3 }}>
//                     <div>
//                         {/* Pass the required props to LoginHistory */}
//                         <LoginDetilas  />
//                     </div>
//                 </Box>
//             </Modal>
//         </div>
//     );
// };

// export default LoginHistory;
