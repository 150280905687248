import { useSelector } from "react-redux";
import { axiosInstance } from "../api/APIs";

const isMobileOrTablet = () => {
  const userAgent = window.navigator.userAgent;
  return /Mobile|Android|iPhone|iPad|Tablet/.test(userAgent);
};

export const handleChange = async (propertyView: any) => {
  const body = {
    post_id: propertyView.postId,
    selected_property_type: propertyView.property_type,
    looking_to: propertyView.looking,
    user_id: propertyView.userId
  };

  console.log(body, propertyView.Bview);
  console.log(propertyView, propertyView.postId);

  
    // const response = await axiosInstance.post("/filter/get/postDetail", body);

    // console.log("response",response)
    
    // if(response.data.message !=="No property found for this Post ID, or the property is not approved."){
    //   const queryString = new URLSearchParams({
    //     data: JSON.stringify(response.data),
    //     look: propertyView.looking || '',
    //     Bview: propertyView.Bview
    //   }).toString();

    const type =encodeURIComponent(propertyView.property_type)

      if (isMobileOrTablet()) {
        // If it's a mobile or tablet, navigate in the same window
        window.location.href = `/PropertyDetails/${propertyView.postId}/${type}/${propertyView.looking}/${propertyView.Bview}`;
      } else { 
        console.log(propertyView)       
        // For desktop, open a new window
        const newWindow = window.open(`/PropertyDetails/${propertyView.postId}/${type}/${propertyView.looking}/${propertyView.Bview}`);
        if (newWindow) {
          newWindow.focus();
        }
      }
    // } else {
    //   window.alert('Full view of this property is not available.');
    
  // } catch (error) {
  //   console.error('Error fetching data:', error);
  // }
};


export const handleLinkClick = (e: React.MouseEvent,url:any) => {
  e.preventDefault();
 

  if (isMobileOrTablet()) {
    
    window.location.href = url;
  } else {        
    
    const newWindow = window.open(url);
    if (newWindow) {
      newWindow.focus();
    }
  }
};