export const tndistrict = {
  districts: [
    {
      id: 1,
      title: "Ariyalur",
      name_tamil: "அரியலூர்",
    },
    {
      id: 2,
      title: "Erode",
      name_tamil: "ஈரோடு",
    },
    {
      id: 3,
      title: "Nilgiris",
      name_tamil: "உதகமண்டலம்",
    },
    {
      id: 4,
      title: "Cuddalore",
      name_tamil: "கடலூர்",
    },
    {
      id: 5,
      title: "Karur",
      name_tamil: "கரூர்",
    },
    {
      id: 6,
      title: "Kallakurichi",
      name_tamil: "கள்ளக்குறிச்சி",
    },
    {
      id: 7,
      title: "Kanchipuram",
      name_tamil: "காஞ்சிபுரம்",
    },
    {
      id: 8,
      title: "Krishnagiri",
      name_tamil: "கிருஷ்ணகிரி",
    },
    {
      id: 9,
      title: "Coimbatore",
      name_tamil: "கோயம்புத்தூர்",
    },
    {
      id: 10,
      title: "Sivaganga",
      name_tamil: "சிவகங்கை",
    },
    {
      id: 11,
      title: "Chengalpattu",
      name_tamil: "செங்கல்பட்டு",
    },
    {
      id: 12,
      title: "Chennai",
      name_tamil: "சென்னை",
    },
    {
      id: 13,
      title: "Salem",
      name_tamil: "சேலம்",
    },
    {
      id: 14,
      title: "Thanjavur",
      name_tamil: "தஞ்சாவூர்",
    },
    {
      id: 15,
      title: "Dharmapuri",
      name_tamil: "தர்மபுரி",
    },
    {
      id: 16,
      title: "Dindigul",
      name_tamil: "திண்டுக்கல்",
    },
    {
      id: 17,
      title: "Tiruchirappalli",
      name_tamil: "திருச்சி",
    },
    {
      id: 18,
      title: "Tirunelveli",
      name_tamil: "திருநெல்வேலி",
    },
    {
      id: 19,
      title: "Thirupattur",
      name_tamil: "திருப்பத்தூர்",
    },
    {
      id: 20,
      title: "Tiruppur",
      name_tamil: "திருப்பூர்",
    },
    {
      id: 21,
      title: "Tiruvannamalai",
      name_tamil: "திருவண்ணாமலை",
    },
    {
      id: 22,
      title: "Tiruvallur",
      name_tamil: "திருவள்ளூர்",
    },
    {
      id: 23,
      title: "Thiruvarur",
      name_tamil: "திருவாரூர்",
    },
    {
      id: 24,
      title: "Thoothukudi",
      name_tamil: "தூத்துக்குடி",
    },
    {
      id: 25,
      title: "Tenkasi",
      name_tamil: "தென்காசி",
    },
    {
      id: 26,
      title: "Theni",
      name_tamil: "தேனி",
    },
    {
      id: 27,
      title: "Nagapattinam",
      name_tamil: "நாகப்பட்டினம்",
    },
    {
      id: 28,
      title: "Kanniyakumari",
      name_tamil: "நாகர்கோயில்",
    },
    {
      id: 29,
      title: "Namakkal",
      name_tamil: "நாமக்கல்",
    },
    {
      id: 30,
      title: "Pudukkottai",
      name_tamil: "புதுக்கோட்டை",
    },
    {
      id: 31,
      title: "Perambalur",
      name_tamil: "பெரம்பலூர்",
    },
    {
      id: 32,
      title: "Madurai",
      name_tamil: "மதுரை",
    },
    {
      id: 33,
      title: "Mayiladuthurai",
      name_tamil: "மயிலாடுதுறை",
    },
    {
      id: 34,
      title: "Ranipet",
      name_tamil: "ராணிப்பேட்டை",
    },
    {
      id: 35,
      title: "Ramanathapuram",
      name_tamil: "ராமநாதபுரம்",
    },
    {
      id: 36,
      title: "Virudhunagar",
      name_tamil: "விருதுநகர்",
    },
    {
      id: 37,
      title: "Viluppuram",
      name_tamil: "விழுப்புரம்",
    },
    {
      id: 38,
      title: "Vellore",
      name_tamil: "வேலூர்",
    },
  ],
};