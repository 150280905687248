import React, { useEffect, useState } from 'react'

//box2
import { Interface } from 'readline'
import { useStepperContext, initialState } from './StepperContext';
import { title } from 'process'
import { Button } from '@mui/material'
import { blueGrey } from '@mui/material/colors';
import { updateValueInArray } from '../../PostForm/component';

interface CustomBoxProps{
    title?:string,
    label:string,
    inputId?:number,
    formName:string,
    values?:string,
    required?:boolean,
    data:any,
    initial:any,
    userselectedData?:any
    baseData:any
    setDataB:any
}

const AccessButton:React.FC<CustomBoxProps> = ({title,baseData,setDataB,label,inputId,formName,data,required,initial}) => {
const {addUserData,userData,addCustomFormDetails}=useStepperContext()
// const[buttonColor,setButtonColor]=useState('transparent')
const [activeButton, setActiveButton] = useState<number | null>(null);

 useEffect(() => {
    const initValue = data.find((item:any) => (item.title === initial))
    setActiveButton(initValue?.id||null)
  },[initial,data])

const handleClick=(item:any)=>{
    setActiveButton(item.id)
    const data={
        id:inputId,
        [label]:item.title,
        formName:formName,
        required:required

    }
    const update =updateValueInArray(baseData,label,item.title)
    console.log("parking",update)
    setDataB(update)
    addUserData(data as any)
    // setButtonColor(prev=>prev==='transparent'?'#515AA8':'transparent')
}


  return (
    <div className='flex gap-2 '>
    {data.map((item:any)=>(<Button                     key={item.id}
 onClick={()=>handleClick(item)}  className='h-10 w-4'
     sx={{borderRadius:"999px",padding:"20px",                             bgcolor: activeButton === item.id ? '#0cb7d1' : "#F5F4F8"      , // Active or default background color
          color: activeButton===item.id ? 'white' : 'black', // Text color
          textTransform: 'capitalize', // Capitalize button text
          '&:hover': {
            bgcolor: activeButton === item.id ? '#0cb7d1' : "#F5F4F8",
          },  }}>
        {item.title}

    </Button>
))}



    </div>
  )
}

export default  AccessButton