import CurrentOpenings from "../Career/CurrentOpenings"
import Hero from "../Career/Hero"
import WhoWeAre from "../Career/WhoWeAre"
import Footer from "../Footer/Footer"

const Career = () => {
  return (
    <div>
      <Hero />
      <CurrentOpenings />
      <WhoWeAre />
      <Footer/>
    </div>
  )
}

export default Career