// import { Box, Radio } from "@mui/material";
// import React, { useState } from "react";
// import { IoClose } from "react-icons/io5";
// import { axiosInstance } from "../compounts/api/APIs";
// import { useNavigate } from "react-router-dom";
// type RadioValue = string

// type OtherReasonValue = string;

// const reportReasons = [
//   {
//     reason: "Incorrect Information",
//     description: "The listing contains false or misleading information",
//     value: "incorrect_information",
//   },
//   {
//     reason: "Duplicate Listing",
//     description: "This property is listed more than once",
//     value: "duplicate_listing",
//   },
//   {
//     reason: "Inappropriate Content",
//     description: "The listing contains inappropriate or offensive content",
//     value: "inappropriate_content",
//   },
//   {
//     reason: "Fraudulent Listing",
//     description: "I suspect this listing is a scam or fraudulent",
//     value: "fraudulent_listing",
//   },
//   {
//     reason: "Other",
//     description: "(Please specify)",
//     value: "other",
//   },
// ];

// interface ReportPopUpProps {

//   setShowReport: any
//   showReport: any
//   property: any
//   user: any
//   post: any
//   look: any

// }

// const ReportPopUp: React.FC<ReportPopUpProps> = ({ showReport, setShowReport, property, user, post, look }) => {
//   const [selectedValue, setSelectedValue] = useState<RadioValue>("");
//   const [otherReason, setOtherReason] = useState<OtherReasonValue>("");
//   const [showErrorMessage, setShowErrorMessage] = useState(false);
//   const Navigate = useNavigate()
//   const handleRadioChange = (value: RadioValue) => {
//     setSelectedValue(value);
//     setShowErrorMessage(false);
//   };

//   const handleOtherReasonText = (
//     event: React.ChangeEvent<HTMLTextAreaElement>
//   ) => {
//     setOtherReason(event.target.value);
//   };

//   const handleSummit = async () => {
//     if (selectedValue === "other") {
//       console.log(
//         `Selected reason for the report : ${selectedValue} (${otherReason})`
//       );

//     } else if (selectedValue.length === 0) {
//       setShowErrorMessage(true);
//     } else {
//       console.log("Selected reason for the report : ", selectedValue);
//       const body = {
//         user_id: user,
//         post_id: post,
//         property_type: property,
//         looking_to: look,
//         post_report: selectedValue === "other" ? otherReason : selectedValue
  
  
//       }
//     console.log(body)
//       try {
//         const response = await axiosInstance.post("/delete/post/report", body)
//         console.log(response);
//         // Navigate('/PropertyDetails')
//         setShowReport(false)
  
//       } catch (error) {
//         console.log("reportApi ", error)
//       }

//     }
    
//   }
//   function handleClose() {
//     setShowReport(false)
//   }
//   ;
//   return (
//     <Box className="msm:w-[95%] sm:w-[60%] lg:w-[40%] xl:[40%] "
//     sx={{
//       position: "absolute",
//       left: "50%",
//       top: "50%",
//       transform: "translate(-50%, -50%)",
//       borderRadius:"20px",
//       bgcolor: "transparent",
//       boxShadow: 24,
//       p: 2,
//       mt:3,
//       overflow:"auto",
//       textAlign: 'center'
//     }}
//   >
//       <header className="relative text-[#202020] text-center pb-2 border-b-[0.5px] border-gray-300">
//         <div className="text-md md:text-lg lg:text-xl font-bold">
//           Report an Issue <span onClick={handleClose} className="absolute top-0 right-0 md:right-2 lg:right-4 text-gray-500 text-2xl cursor-pointer"><IoClose /></span>
//         </div>
//         <div className="text-sm md:text-md lg:text-lg font-semibold mt-2">
//           Help us maintain a high-quality listing
//         </div>
//       </header>
//       <main >
//         <div className="text-xs md:text-sm lg:text-md mt-2 font-normal text-black text-center">
//           Please select the reason for reporting this property. Your feedback is
//           important to us.
//         </div>
//         <div className="msm:ml-0 sm:ml-4 md:ml-4 lg:ml-10">
//           {reportReasons.map((report) => (
//             <div onClick={() => handleRadioChange(report.value as RadioValue)} className="flex msm:mt-3 lg:mt-3 xl:mt-5 justify-start items-start cursor-pointer" key={report.value}>
//               <Radio
//                 value={report.value}
//                 checked={selectedValue === report.value}
//                 sx={{
//                   color: "#80848E",
//                   "&.Mui-checked": { color: "#2E368F" },
//                   "& svg": {
//                     fontSize: 20,
//                   },
//                   marginLeft: 2,
//                 }}
//               />
//               <div className="msm:ml-1 lg:ml-8 sm:ml-8">
//                 <h3 className="font-bold text-sm md:text-md lg:text-lg text-left">
//                   {report.reason}
//                 </h3>
//                 <p className="text-xs md:text-sm lg:text-md font-normal text-left">
//                   {report.description}
//                 </p>
//               </div>
//             </div>
//           ))}
//         </div>
//         <div
//           className={`${selectedValue === "other"
//             ? "max-h-96 opacity-100"
//             : "max-h-0 opacity-0"
//             } transition-all duration-700 my-4 overflow-hidden`}
//         >
//           <textarea
//             onChange={handleOtherReasonText}
//             placeholder="Describe the issue in detail"
//             className="rounded-lg border-2 transition-colors duration-100 w-full h-[150px] border-gray-300 focus:border-gray-400 outline-none p-2"
//           ></textarea>
//         </div>
//         <div
//           className={`${showErrorMessage && "opacity-100 max-h-8"
//             } opacity-0 max-h-0 overflow-hidden text-red-600 text-sm text-center font-semibold transition-opacity duration-200`}
//         >
//           Please select any reason
//         </div>
//         <div className="flex justify-center msm:mt-0">
//           <button
//             onClick={handleSummit}
//             className="bg-[#2E368F] hover:bg-opacity-95 active:bg-opacity-90 text-[#f5f5f5] px-20 py-3    mx-auto rounded-full"
//           >
//             Submit Report
//           </button>
//         </div>
//       </main>
//     </Box>
//   );
// };

// export default ReportPopUp;


import { Box, Radio, Dialog, DialogTitle, DialogContent, DialogActions,Modal, Button } from "@mui/material";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { axiosInstance } from "../compounts/api/APIs";
import { useNavigate } from "react-router-dom";

type RadioValue = string;
type OtherReasonValue = string;

const reportReasons = [
  {
    reason: "Incorrect Information",
    description: "The listing contains false or misleading information",
    value: "incorrect_information",
  },
  {
    reason: "Duplicate Listing",
    description: "This property is listed more than once",
    value: "duplicate_listing",
  },
  {
    reason: "Inappropriate Content",
    description: "The listing contains inappropriate or offensive content",
    value: "inappropriate_content",
  },
  {
    reason: "Fraudulent Listing",
    description: "I suspect this listing is a scam or fraudulent",
    value: "fraudulent_listing",
  },
  {
    reason: "Other",
    description: "(Please specify)",
    value: "other",
  },
];

interface ReportPopUpProps {
  setShowReport: (show: boolean) => void;
  showReport: boolean;
  property: any;
  user: any;
  post: any;
  look: any;
}

const ReportPopUp: React.FC<ReportPopUpProps> = ({ showReport, setShowReport, property, user, post, look }) => {
  const [selectedValue, setSelectedValue] = useState<RadioValue>("");
  const [otherReason, setOtherReason] = useState<OtherReasonValue>("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const Navigate = useNavigate();

  const handleRadioChange = (value: RadioValue) => {
    setSelectedValue(value);
    setShowErrorMessage(false);
  };

  const handleOtherReasonText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOtherReason(event.target.value);
  };

  const handleSummit = async () => {
    if (selectedValue === "other") {
      console.log(`Selected reason for the report: ${selectedValue} (${otherReason})`);
    } else if (selectedValue.length === 0) {
      setShowErrorMessage(true);
    } else {
      console.log("Selected reason for the report:", selectedValue);
      const body = {
        user_id: user,
        post_id: post,
        property_type: property,
        looking_to: look,
        post_report: selectedValue === "other" ? otherReason : selectedValue,
      };

      console.log(body);
      try {
        const response = await axiosInstance.post("/delete/post/report", body);
        console.log(response);
        setShowSuccessDialog(true); // Show the success dialog
      } catch (error) {
        console.log("reportApi", error);
      }
    }
  };

  const handleClose = () => {
    setShowReport(false);
  };

  const handleSuccessClose = () => {
    setShowSuccessDialog(false);
    setShowReport(false); // Close the report popup after success
  };

  return (
    <>
      <Box
        className="msm:w-[95%] sm:w-[60%] lg:w-[40%] xl:[40%]"
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "20px",
          bgcolor: "transparent",
          boxShadow: 24,
          p: 2,
          mt: 3,
          overflow: "auto",
          textAlign: 'center',
        }}
      >
        <header className="relative text-[#202020] text-center pb-2 border-b-[0.5px] border-gray-300">
          <div className="text-md md:text-lg lg:text-xl font-bold">
            Report an Issue
            <span onClick={handleClose} className="absolute top-0 right-0 md:right-2 lg:right-4 text-gray-500 text-2xl cursor-pointer">
              <IoClose />
            </span>
          </div>
          <div className="text-sm md:text-md lg:text-lg font-semibold mt-2">
            Help us maintain a high-quality listing
          </div>
        </header>
        <main>
          <div className="text-xs md:text-sm lg:text-md mt-2 font-normal text-black text-center">
            Please select the reason for reporting this property. Your feedback is important to us.
          </div>
          <div className="msm:ml-0 sm:ml-4 md:ml-4 lg:ml-10">
            {reportReasons.map((report) => (
              <div onClick={() => handleRadioChange(report.value as RadioValue)} className="flex msm:mt-3 lg:mt-3 xl:mt-5 justify-start items-start cursor-pointer" key={report.value}>
                <Radio
                  value={report.value}
                  checked={selectedValue === report.value}
                  sx={{
                    color: "#80848E",
                    "&.Mui-checked": { color: "#2E368F" },
                    "& svg": {
                      fontSize: 20,
                    },
                    marginLeft: 2,
                  }}
                />
                <div className="msm:ml-1 lg:ml-8 sm:ml-8">
                  <h3 className="font-bold text-sm md:text-md lg:text-lg text-left">
                    {report.reason}
                  </h3>
                  <p className="text-xs md:text-sm lg:text-md font-normal text-left">
                    {report.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div
            className={`${selectedValue === "other" ? "max-h-96 opacity-100" : "max-h-0 opacity-0"} transition-all duration-700 my-4 overflow-hidden`}
          >
            <textarea
              onChange={handleOtherReasonText}
              placeholder="Describe the issue in detail"
              className="rounded-lg border-2 transition-colors duration-100 w-full h-[150px] border-gray-300 focus:border-gray-400 outline-none p-2"
            ></textarea>
          </div>
          <div className={`${showErrorMessage && "opacity-100 max-h-8"} opacity-0 max-h-0 overflow-hidden text-red-600 text-sm text-center font-semibold transition-opacity duration-200`}>
            Please select any reason
          </div>
          <div className="flex justify-center msm:mt-0">
            <button
              onClick={handleSummit}
              className="bg-[#1063b5] hover:bg-opacity-95 active:bg-opacity-90 text-[#f5f5f5] px-20 py-3 mx-auto rounded-full"
            >
              Submit Report
            </button>
          </div>
        </main>
      </Box>

      {/* Success Dialog */}
      <Modal open={showSuccessDialog} onClose={handleSuccessClose}>
        <Box sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: '300px',
          textAlign: 'center',
        }}>
          <strong>Thank You!</strong>
          <div className="text-center mt-2">
            <p>We appreciate your feedback.</p>
            <p>Our team will review the issue and take appropriate action.</p>
          </div>
          <button onClick={handleSuccessClose}               className="w-[150px] bg-[#1063b5] text-white rounded-[20px] mt-5 p-2"
          >
            Close
          </button>
        </Box>
      </Modal>
    </>
  );
};

export default ReportPopUp;
