import React from 'react';
import logo from './logo.svg';



import StepperForm from './component/StepperForm';
import { FormProvider1 } from './component/StepperContext';
// import {Color} from './Color'
import { ThemeProvider } from '@mui/material';

// import { PersistGate } from 'redux-persist/integration/react';
// interface app{
//   SteppForm:any[];
// }
interface app {
  title?: string;
}

const Form:React.FC<app>=()=> {
  return (
 
    <FormProvider1>

    {/* // <Provider store={store}>
    //   <PersistGate loading={null} persistor={persistor} > */}
      <div className='flex' >
        {/* <header className="App-header">
          <h1>Welcome to the Property Form</h1>
        </header>
        <main> */}
          <StepperForm />
        {/* </main> */}
      </div>
    
   
    </FormProvider1>
     
    // </ThemeProvider>
  );
}

export default Form;

// import React from 'react';
// import './App.css';
// import StepperForm from './component/StepperForm';
// import { FormProvider } from './component/StepperContext';
// import DeviceInfo from './component/DeviceInfo'; // Import the DeviceInfo component

// interface AppProps {
//   title?: string;
// }

// const App: React.FC<AppProps> = () => {
//   return (
//     <FormProvider>
//       <div className="App">
//         <DeviceInfo /> Add the DeviceInfo component here
//         <StepperForm />
//       </div>
//     </FormProvider>
//   );
// }

// export default App;
