import heroImg from "../../assets/hero.png";

const Hero = () => {
  return (
    <section className="font-K2D font-semibold text-white relative">
        <img className="w-full h-[200px] md:h-[250px] lg:h-full" src={heroImg} alt="" />
        <div className="absolute inset-0 flex flex-col items-center justify-center text-center mb-10 space-y-4">
          <div className="text-2xl md:text-3xl lg:text-4xl">
            Careers at <span className="text-[#FBB337]">Vattara</span>
          </div>
          <div className="text-sm lg:text-[17px]">
            We transform opportunities by solving the foremost real estate
            issues.
          </div>
        </div>
        <div className="w-full bg-[#042C3180] absolute bottom-0 bg-opacity-50 text-center text-xs md:text-sm lg:text-[17px] p-1 md:p-2">
          Join us to drive the future of digital innovation. Collaborate with
          creative and dedicated individuals to develop outstanding digital
          products and solutions.
        </div>
    </section>
  );
};

export default Hero;
