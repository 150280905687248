import React from 'react'
import Map from './Map'
import Search from './Search'
import Home from './Home'

const PropertyDetails = () => {
  return (
    <div className=' '> <Home/></div>
  )
}

export default PropertyDetails