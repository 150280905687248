import React, { useState } from 'react';
import { useStepperContext } from './StepperContext';
import { Card, Typography, Button, Box, IconButton, Modal } from '@mui/material';
import dayjs from 'dayjs';
import BackComponent from './icon/BackComponent';
import defaultimg from "../../images/defaultScroll.png"
import { axiosInstance } from "../../api/APIs";
import { useSelector } from "react-redux";
import { log } from 'console';
import { useNavigate } from "react-router-dom";
import { MdArrowBackIos, MdArrowBackIosNew, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import Lottie from 'lottie-react';
import animationData from "../../../compounts/images/vattara.json"
import Success from '../Sucess';
import { RiVerifiedBadgeFill } from "react-icons/ri";
import CancelIcon from '@mui/icons-material/Cancel';


interface ReviewPageProps {
  onNext: () => void;
  onBack: () => void;
  onReset: () => void;
}

interface PropertyDetails {
  negotiable: boolean;
  property_available_for: string;
  building___project_name__optional_: string;
}

const ReviewPage: React.FC<ReviewPageProps> = ({ onBack }) => {
  const {
    userData,
    propImages,
    costPerBuiltUpArea,
    costPerBuiltUpArea2,
    costPerBuiltUpArea3,
    location
  } = useStepperContext();
  const [showPopup, setShowPopup] = useState(false);
  const [error, seterror] = useState<boolean>(false)
  const navigate = useNavigate()
  const transformKeysToLowerCaseWithUnderscores = (
    data: Record<string, any>
  ): Record<string, any> => {
    return Object.keys(data).reduce((acc, key) => {
      const newKey = key.toLowerCase().replace(/ /g, '_');
      acc[newKey] = data[key];
      return acc;
    }, {} as Record<string, any>);
  };
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  console.log('userData', userData, propImages);

  const postData = async (url: string, data: Record<string, any>) => {
    console.log("body", data)
    try {
      const response = await axiosInstance.post(url, data);


      console.log("body", data)
      console.log("response", response.data)
      setShowPopup(true);
      // navigate("/") 


    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  // Extracting user data
  const property = userData.find((item) => item?.['Property Type'])?.[
    'Property Type'
  ];
  const looking_to = userData.find((item) => item?.['Looking To'])?.[
    'Looking To'
  ];
  const property_type = userData.find((item) => item?.['Property Subtype'])?.[
    'Property Subtype'
  ];
  const available_for = userData.find(
    (item) => item?.['Property available for']
  )?.['Property available for'];
  const rooms = userData.find((item) => item?.['BHK'])?.['BHK'];
  // const project = userData.find((item) => item?.["Building/Project Name(Optional)"])?.["Building/Project(Optional)"];
  const buildingProject = userData
    .find(item => item?.['Building/Project Name(Optional)'])
    ?.['Building/Project Name(Optional)']
    ?.split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ') || '';

  const city = userData.find((item) => item?.['City'])?.[
    'City'
  ];
  const locality = userData.find((item) => item?.['Locality'])?.['Locality'];
  const landMark = userData.find((item) => item?.['Landmark'])?.['Landmark'];
  const Monthly = userData.find((item) => item?.['Monthly Rent'])?.[
    'Monthly Rent'
  ];
  const Lease = userData.find((item) => item?.['Lease Amount'])?.[
    'Lease Amount'
  ];
  const cost = userData.find((item) => item?.['Cost'])?.['Cost'];
  const plotinsq = userData.find((item) => item?.['Plot Area'])?.['Plot Area'];
  const plprice = userData.find((item) => item?.['Plot Area'])?.['Plot Area'];
  const cprice = userData.find((item) => item?.['Price'])?.['Price'];

  const bhk = userData.find((item) => item?.['BHK'])?.['BHK'];
  const builtUpArea = userData.find((item) => item?.['Built up Area'])?.[
    'Built up Area'
  ];
  const BuiltUpArea = userData.find((item) => item?.['Built Up Area'])?.[
    'Built Up Area'
  ];
  const furnishType = userData.find((item) => item?.['Furnish Type'])?.[
    'Furnish Type'
  ];
  const flatFurnishing = userData.find((item) => item?.['Flat Furnishing'])?.[
    'Flat Furnishing'
  ];
  const societyAmenities = userData.find(
    (item) => item?.['Society Amenities']
  )?.['Society Amenities'];
  const monthlyRent = userData.find((item) => item?.['Monthly Rent'])?.[
    'Monthly Rent'
  ];
  console.log(monthlyRent);

  const availableFrom = userData.find((item) => item?.['Available From'])?.[
    'Available From'
  ];
  const securityDeposit = userData.find((item) => item?.['Select'])?.['Select'];
  // const additionalDetails = userData.find((item) => item?.["Additional Details"])?.["Additional Details"];
  const latitude = userData.find((item) => item?.['Latitude'])?.['Latitude'];
  const longitude = userData.find((item) => item?.['Longitude'])?.['Longitude'];
  const negotiable = userData.find((item) => item?.['Negotiable'])?.[
    'Negotiable'
  ];
  const subLocal = userData.find((item) => item?.["Sublocality"])?.["Sublocality"]
  const images = userData.find((item) => item?.['Images'])?.['Images'];

  const state = userData.find((item) => item?.['State'])?.['State'];
  const kblProperty = userData.find((item) => item?.['KBL Property'])?.[
    'KBL Property'
  ];
  const propertyAvailableFor = userData.find(
    (item) => item?.['Property available for']
  )?.['Property available for'];
  const landmarkStreet = userData.find((item) => item?.['Landmark'])?.[
    'Landmark'
  ];
  const leaseAmount = userData.find((item) => item?.['Lease Amount'])?.[
    'Lease Amount'
  ];
  console.log("location", location);

  const AMENITIES = userData.find((item) => item?.['Amenities'])?.['Amenities'];
  const Furnishing = userData.find((item) => item?.['Furnishing'])?.[
    'Furnishing'
  ];
  const Construction = userData.find((item) => item?.['Construction Status'])?.[
    'Construction Status'
  ];

  const Amenitie = userData.find((item) => item?.["AMENITIES"])?.['AMENITIES']
  const price = userData.find((item) => item?.['Cost'])?.['Cost'];
  const Price = userData.find((item) => item?.['Price'])?.['Price'];
  const width = userData.find((item) => item?.['Width'])?.['Width'];
  const Length = userData.find((item) => item?.['Length'])?.['Length'];
  const Plot = userData.find((item) => item?.['Plot Area'])?.['Plot Area'];
  const Status = userData.find((item) => item?.['Property Status'])?.[
    'Property Status'
  ];
  const Patta = userData.find((item) => item?.['Patta Available'])?.[
    'Patta Available'
  ];
  const Plot_Price = userData.find((item) => item?.['Plot Price'])?.[
    'Plot Price'
  ];
  const Possession = userData.find((item) => item?.['Possession Status'])?.[
    'Possession Status'
  ];
  const carpet_area = userData.find((item) => item?.['Carpet Area'])?.[
    'Carpet Area'
  ];
  const floor = userData.find((item) => item?.['Select Your Floor'])?.[
    'Select Your Floor'
  ];
  const strFloor = floor?.join()
  console.log("floor", strFloor)
  const Sfloor = userData.find((item) => item?.['Total Floors'])?.[
    'Total Floors'
  ];
  const facing = userData.find((item) => item?.['Facing'])?.['Facing'];
  const Lifts = userData.find((item) => item?.['Lifts'])?.['Lifts'];
  const parking = userData.find((item) => item?.['Parking'])?.['Parking'];
  const Min = userData.find((item) => item?.['Min. Number of seats'])?.[
    'Min. Number of seats'
  ];
  const Max = userData.find((item) => item?.['Max. Number of seats'])?.[
    'Max. Number of seats'
  ];
  const Washroom = userData.find((item) => item?.['Washroom'])?.['Washroom'];
  const Meeting = userData.find((item) => item?.['Meeting Room'])?.[
    'Meeting Room'
  ];
  const Condition = userData.find((item) => item?.['Property Condition'])?.[
    'Property Condition'
  ];
  const Property_available = userData.find(
    (item) => item?.['Property available for']
  )?.['Property available for'];
  const property_description = userData.find(
    (item) => item?.['Property Description']
  )?.['Property Description'];
  const Suitable = userData.find((item) => item?.['Suitable For'])?.[
    'Suitable For'
  ];
  const Min_Seats = userData.find((item) => item?.['Min.Number of seats'])?.[
    'Min.Number of seats'
  ];
  const Max_Seats = userData.find((item) => item?.['Max.Number of seats'])?.[
    'Max.Number of seats'
  ];
  const measure = userData.find((item) => item?.["measure"])?.["measure"]
  const plot_area_house = userData.find((item) => item?.['Plot Area'])?.['Plot Area']
  const additionalDetails = {
    balcony: userData.find((item) => item?.['Balcony'])?.['Balcony'],
    bathroom: userData.find((item) => item?.['Bathroom'])?.['Bathroom'],
    parking: userData.find((item) => item?.['Parking'])?.['Parking'],
    preferred_tenant_type: userData.find(
      (item) => item?.['Preferred Tenant Type']
    )?.['Preferred Tenant Type'],
    facing: userData.find((item) => item?.['Facing'])?.['Facing'],
    carpet_area: userData.find((item) => item?.['Carpet Area'])?.[
      'Carpet Area'
    ],
    maintenance_charges: userData.find(
      (item) => item?.['Maintenance Charges']
    )?.['Maintenance Charges'],
    servant_room: userData.find((item) => item?.['Servant Room'])?.[
      'Servant Room'
    ],
    property_description: userData.find(
      (item) => item?.['Property Description']
    )?.['Property Description'],
    eligible_for_loan: userData.find((item) => item?.['Loan Availability'])?.[
      'Loan Availability'
    ],
  };
  const formattedAvailableFrom = availableFrom
    ? dayjs(availableFrom).format('MMMM DD, YYYY')
    : undefined;

  const formatPriceToIndianLocale = (price: string) => {
    if (price === undefined || price === null) {
      return '';
    }

    // Convert to string and reverse for easier processing
    let str = price.toString().split('').reverse().join('');
    let formattedStr = '';

    // Add commas every 2 digits for the first segment (hundreds, thousands)
    for (let i = 0; i < str.length; i++) {
      if (i > 2 && (i - 2) % 2 === 1) {
        formattedStr += ',';
      }
      formattedStr += str[i];
    }

    // Reverse back to the original order and return
    return `₹ ${formattedStr.split('').reverse().join('')}`;
  };


  const formatPrice = (value: any) => {

    if (value === null || value === undefined || value === '') {
      return '';
    }


    if (isNaN(value)) {
      return value;
    }

    return `₹ ${new Intl.NumberFormat('en-IN', {
      style: 'decimal',
      maximumFractionDigits: 0,
    }).format(Number(value))}`;
  };

  const ptype = false

  // if (property_type === "Independent House") {
  //   if ((!builtUpArea || !BuiltUpArea) && !Plot) {
  //     return ptype=false
  //   }
  //   else{
  //     return ptype=true
  //   } 
  // }else if((!builtUpArea || !BuiltUpArea) || !Plot) {
  //   return ptype=false
  // }else{
  //   return ptype=true
  // }

  const isPropertyTypeValid = () => {
    if (property_type === "Independent House" && looking_to==="Sell") {
      console.log(" isPropertyTypeValid if")
      return (builtUpArea || BuiltUpArea) && Plot;
    } else {
      console.log("isPropertyTypeValid else")
      return (builtUpArea || BuiltUpArea) || Plot;
    }
  };


  function vaildcarpet() {
    if (property_type === "Industrial Space" || (property_type === "Plot / Land"  )) {
      console.log("if")
      return true
    } else {
      console.log("else")
      return carpet_area
    }
  }
  // const plpprice = Plot_Price?.trim()
  // const ccost = price?.trim()


  const handlePostData = () => {
    const isPriceValid = (monthlyRent && monthlyRent?.trim() !== "₹") || (leaseAmount && leaseAmount?.trim() !== "₹") || (price && price?.trim() !== "₹") || (Price && Price?.trim() !== "₹") || (Plot_Price && Plot_Price?.trim() !== "₹");
    const isValidPropertyType = isPropertyTypeValid();
    const carprt = vaildcarpet()
    console.log("property_type", property_type)
    if (!isPriceValid || !isValidPropertyType || !carprt) {
      console.log(!isPriceValid , !isValidPropertyType, !carprt)
      seterror(true)
      return;
    }

    console.log("Plot_Price", Plot_Price)

    const postData1 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      bhk: bhk,
      built_up_area: builtUpArea,
      furnish_type: furnishType,
      flat_furnishing: Furnishing,
      society_amenities: AMENITIES,
      monthly_rent:
        available_for === 'Rent' ? monthlyRent : '',
      available_from: formattedAvailableFrom,
      security_deposit: securityDeposit,
      additional_details: additionalDetails,
      latitude: location.latitude,
      longitude: location.longitude,
      negotiable: negotiable,
      images: propImages,
      locality: locality,
      city: city,
      sublocality: subLocal,
      property_available_for: available_for,
      landmark_street: landmarkStreet,
      lease_amount:
        available_for === 'Lease' ? leaseAmount : '',
    };

    const postData2 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      bhk: bhk,
      built_up_area: builtUpArea,
      furnish_type: furnishType,
      flat_furnishing: Furnishing,
      society_amenities: AMENITIES,
      additional_details: property_type === 'Plot / Land' ? '' : additionalDetails,
      facing: property_type === 'Plot / Land' ? facing : '',
      latitude: location.latitude,
      longitude: location.longitude,
      negotiable: negotiable,
      images: propImages,
      locality: locality,
      city: city,
      landmark_street: landmarkStreet,
      price: price,
      construction_status:
        Construction === 'Under Construction'
          ? formattedAvailableFrom
          : Construction,
      plot_price: Plot_Price,
      width: width,
      length: Length,
      plot_area: Plot,
      measure: measure,
      property_status: Status,
      patta_available: Patta,
      property_description: property_type === 'Plot / Land' ? property_description : "",
      sublocality: subLocal,
      plot_area_house: property_type === "Apartment" ? null : plot_area_house
    };

    const postData3 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      built_up_area: BuiltUpArea,
      carpet_area: carpet_area,
      monthly_rent:
        available_for === 'Rent' ? monthlyRent : '',
      security_deposit: securityDeposit,
      negotiable: negotiable,
      total_floors: Sfloor,
      select_your_floor: strFloor,
      facing: facing,
      lifts: Lifts,
      parking: parking,
      min__number_of_seats: Min,
      max__number_of_seats: Max,
      washroom: Washroom,
      meeting_room: Meeting,
      property_condition: Condition,
      property_available_for: Property_available || available_for,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      latitude: location.latitude,
      longitude: location.longitude,
      images: propImages,
      locality: locality,
      lease_amount:
        available_for === 'Lease' ? leaseAmount : '',
      city: city,
      available_from: formattedAvailableFrom,
      landmark_street: landmarkStreet,
      property_description: property_description,
      sublocality: subLocal
    };

    const postData4 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      built_up_area: BuiltUpArea,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      suitable_for: Suitable,
      carpet_area: carpet_area,
      negotiable: negotiable,
      security_deposit: formatPrice(securityDeposit),
      monthly_rent:
        available_for === 'Rent' ? monthlyRent : '',
      facing,
      total_floors: Sfloor,
      select_your_floor: strFloor,
      parking: parking,
      washroom: Washroom,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      latitude: location.latitude,
      longitude: location.longitude,
      images: propImages,
      property_available_for: available_for,
      property_description: property_description,
      locality: locality,
      city: city,
      lease_amount:
        available_for === 'Lease' ? leaseAmount : '',
      landmark_street: landmarkStreet,
      sublocality: subLocal
    };

    const postData5 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      built_up_area: BuiltUpArea,
      carpet_area: carpet_area,
      security_deposit: formatPrice(securityDeposit),
      negotiable: negotiable,
      parking: parking,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      monthly_rent:
        available_for === 'Rent' ? monthlyRent : '',
      facing,
      property_available_for: available_for,
      property_description: property_description,
      lease_amount:
        available_for === 'Lease' ? leaseAmount : '',
      latitude: location.latitude,
      longitude: location.longitude,
      images: propImages,
      locality: locality,
      city: city,
      // available_from:formattedAvailableFrom ,
      landmark_street: landmarkStreet,
      sublocality: subLocal
    };

    const postData6 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      plot_area: Plot,
      monthly_rent:
        available_for === 'Rent' ? monthlyRent : '',
      security_deposit: formatPrice(securityDeposit),
      negotiable: negotiable,
      facing,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      latitude: location.latitude,
      longitude: location.longitude,
      images: propImages,
      locality: locality,
      property_available_for: available_for,
      lease_amount:
        available_for === 'Lease' ? leaseAmount : '',
      property_description: property_description,
      city: city,
      //available_from,
      landmark_street: landmarkStreet,
      sublocality: subLocal
    };

    const postData7 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      property_condition: Condition,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      built_up_area: builtUpArea,
      carpet_area: carpet_area,
      negotiable: negotiable,
      price: Price,
      total_floors: Sfloor,
      select_your_floor: strFloor,
      lifts: Lifts,
      parking: parking,
      min_number_of_seats: Min_Seats,
      max_number_of_seats: Max_Seats,
      meeting_room: Meeting,
      washroom: Washroom,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      facing,
      latitude: location.latitude,
      longitude: location.longitude,
      property_description: property_description,
      images: propImages,
      locality: locality,
      city: city,

      landmark_street: landmarkStreet,
      sublocality: subLocal
    };

    const postData8 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      suitable_for: Suitable,
      built_up_area: builtUpArea,
      carpet_area: carpet_area,
      price: Price,
      negotiable: negotiable,
      total_floors: Sfloor,
      select_your_floor: strFloor,
      facing,
      parking: parking,
      washroom: Washroom,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      latitude: location.latitude,
      longitude: location.longitude,
      images: propImages,
      locality: locality,
      property_description: property_description,
      city: city,

      landmark_street: landmarkStreet,
      sublocality: subLocal
    };

    const postData9 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      built_up_area: builtUpArea,
      carpet_area: carpet_area,
      price: Price,
      negotiable: negotiable,
      facing,
      parking: parking,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      property_description: property_description,
      latitude: location.latitude,
      longitude: location.longitude,
      images: propImages,
      locality: locality,
      city: city,
      landmark_street: landmarkStreet,
      sublocality: subLocal
    };

    const postData10 = {
      user_id: loginData.user_id,
      property_subtype: property_type,
      building___project_name__optional_: buildingProject,
      possession_status:
        Possession === 'Under Construction'
          ? formattedAvailableFrom
          : Possession,
      plot_area: Plot,
      price: Price,
      negotiable: negotiable,
      measure: measure,
      facing,
      additional_details: {
        amenities: Array.isArray(Amenitie) ? Amenitie.join(', ') : Amenitie,
      },
      property_description: property_description,
      latitude: location.latitude,
      longitude: location.longitude,
      images: propImages,
      locality: locality,
      city: city,

      landmark_street: landmarkStreet,
      sublocality: subLocal
    };
    console.log('Hello');

    console.log(postData8);
    if (property === 'Residential') {
      // Handling Residential properties
      if (looking_to === 'Rent/Lease') {
        postData('/rent/resRentDetail', postData1);
      } else if (looking_to === 'Sell') {
        postData('/sell/resSellDetail', postData2);
      }
    } else if (property === 'Commercial') {

      if (looking_to === 'Rent/Lease') {
        switch (property_type) {
          case 'Office Space':
            postData('/rent/comOffRent', postData3);
            break;
          case 'Retail Shop':
          case 'Showroom':
            postData('/rent/comShopRent', postData4);
            break;
          case 'Warehouse':
            postData(
              '/rent/comWarehouseRent',
              postData5
            );
            break;
          case 'Industrial Space':
            postData('/rent/comLandRent', postData6);
            break;
          default:
            console.error(
              'Unsupported subtype for Commercial Rent/Lease:',
              property_type
            );
        }
      } else if (looking_to === 'Sell') {
        switch (property_type) {
          case 'Office Space':
            postData(
              '/sell/comOfficeSellPost',
              postData7
            );
            break;
          case 'Retail Shop':
          case 'Showroom':
            postData(
              '/sell/comShopSellPost',
              postData8
            );
            break;
          case 'Warehouse':
            postData(
              '/sell/comWarehouseSellPost',
              postData9
            );
            break;
          case 'Industrial Space':
            postData(
              '/sell/comLandSellPost',
              postData10
            );
            break;
          default:
            console.error(
              'Unsupported subtype for Commercial Sell:',
              property_type
            );
        }
      } else {
        console.error('Unknown transaction type for Commercial:', looking_to);
      }
    } else {
      console.error('Unknown property type:', property);
    }
    //setShowPopup(true);


    setTimeout(() => {
      setShowPopup(false);
    }, 10000);



  };
  const handleClose = () => {
    setShowPopup(false);
  };

  const handleClose2 = () => {
    seterror(false);
  };

  const GetCoverImage = () => {
    const Image = propImages.filter((e) => e.isCover);
    return Image[0]?.uri;
  };
  // const price = data.price || data.cost || data.enter_lease_amount || data.lease_amount || (data.monthly_rent ? `${data.monthly_rent} / Month` : null);
  // const formattedPrice = formatPrice(price);
  return (
    <div className="shadow bg-white rounded-lg flex-col flex">

      <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "10px", alignItems: "center" }}>
        <IconButton
          onClick={onBack}
          sx={{ marginRight: "8px" }} // Space between the icon and the text
        >
          <MdArrowBackIosNew className='w-5 h-5' />
        </IconButton>
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              fontWeight: '700',
              fontFamily: 'K2D',
            }}
          >
            Review Page        </Box>
        </Box>
      </Box>
      <div className='lg:mx-[10%] msm:mx-[1%]'>
        <div className="mb-6 bg-[#f5f4f8] p-1 rounded-2xl">
          <img
            src={GetCoverImage() || defaultimg}
            alt="Cover Image"
            className="w-full lg:h-[350px] msm:h-[250px] sm:h-[350px] rounded-2xl"
          />
          <Box className='flex items-center p-1'>
            <RiVerifiedBadgeFill className="text-[#FBB337] mr-2" />
            <Box className='xl:text-xl font-[#252B5C] font-bolditems-c enter' sx={{ fontSize: { xs: "14px", xl: "18px", lg: "18px", md: "17px", sm: "16px" } }}>{rooms} {property_type === "Plot / Land" ? "Plot" : property_type} for {available_for}
              {looking_to && looking_to !== "Rent/Lease" && ` ${looking_to}`} in {subLocal}</Box>
          </Box>

          <Box className='text-md pt-1 p-1' sx={{ fontSize: { xs: "12px", sm: "13px", md: "13px", lg: "13px", }, color: "#000" }}>
            {buildingProject && buildingProject.trim() ? `${buildingProject}, ` : ''}{locality}
          </Box>
          {/* 
                <Box className='text-md pt-1'sx={{fontSize:{xs:"12px",sm:"15px",md:"17px",lg:"17px",},color:"#000"}}>
                    {userData?.landmark_street ? `${data.landmark_street}, ` : ''}{userData?.district}
                </Box> */}
          <Box className='xl:text-xl msm:text-lg text-black font-semibold p-1'>{Monthly || Lease || cost || cprice || Plot_Price}</Box>

        </div>

        <div className="mb-2">
          <Typography className="text-[15px]  mb-2" sx={{ color: "#1063b5", fontFamily: "K2D", fontWeight: "bold" }}>
            Basic Details
          </Typography>
          <div className="flex flex-wrap gap-2">
            {userData
              .filter((item) => item.formName === 'Basic Details')
              .map((item, index) => {
                console.log("Current Item:", item); // Log the current item
                return (
                  <div key={index} className="w-full">
                    {Object.keys(item).map(
                      (key) =>
                        key !== 'formName' &&
                        key !== 'id' &&
                        key !== 'required' && (
                          <div key={key} className="flex justify-between mb-1">
                            <span className="text-sm text-[#000] font-semibold w-[50%] ">
                              {key === 'Locality' ? 'Locality' : key}:
                            </span>
                            {key === 'Locality' ? (
                              <div className="text-sm text-gray-700 w-[50%] rounded-md" style={{ textAlign: "left" }}>
                                {item[key]}
                              </div>

                            ) : (
                              <span className="text-sm text-gray-700 w-[50%] " style={{ textAlign: "left" }}>
                                {item[key]}
                              </span>

                            )}
                          </div>
                        )
                    )}

                  </div>
                );
              })}
          </div>
          <iframe name="gMap" style={{ width: "100%", height: "80%", marginTop: "10px" }} src={`https://www.google.com/maps/embed/v1/place?q=${location.latitude},${location.longitude}&key=AIzaSyDz2gppwqEkyckilX-FDJkvgEtcoS2OEvE`}></iframe>
          <div className="border  mt-3"></div>

        </div>


        {/* Property Details */}
        <div className="mb-2">
          <Typography className="text-[15px] mb-2" sx={{ color: "#1063b5", fontFamily: "K2D", fontWeight: "bold" }}>
            Property Details
          </Typography>
          <div className="flex flex-wrap gap-2">
            {userData
              .filter((item) => item.formName === 'Property Details')
              .map((item, index) => (
                <div key={index} className="w-full">
                  {Object.keys(item).map((key) =>
                    key !== 'formName' &&
                    key !== 'id' &&
                    key !== 'measure' &&
                    key !== 'required' &&
                    item[key] && (  // Check if the value is not empty
                      <div key={key} className="flex justify-between mb-2">
                        <span className="text-sm text-[#000] font-semibold w-[50%] ">
                          {key}:
                        </span>
                        <span className="text-sm text-gray-700 w-[50%] " style={{ textAlign: "left" }}>
                          {key === 'Select Your Floor' ? strFloor : item[key]}
                        </span>
                      </div>
                    )
                  )}
                </div>
              ))}
          </div>
          <div className="border mt-3"></div>
        </div>


        {/* <hr className="border-gray-300 mb-6" /> */}

        {/* Price Details */}
        <div className="mb-2">
          <Typography className="text-[15px]  mb-2" sx={{ color: "#1063b5", fontFamily: "K2D", fontWeight: "bold" }}>
            Price Details
          </Typography>
          <div className="flex flex-wrap gap-2">
            {userData
              .filter((item) => item.formName === 'Price Details')
              .map((item, index) => (
                <div key={index} className="w-full">
                  {Object.keys(item).map(
                    (key) =>
                      key !== 'formName' &&
                      key !== 'id' &&
                      key !== 'required' && (
                        <div key={key} className="flex justify-between mb-2">
                          <span className="text-sm text-[#000] font-semibold w-[50%] ">
                            {key === 'Property Description'
                              ? 'Property Description'
                              : key}
                            :
                          </span>
                          {key === 'Property Description' ? (
                            <div className="text-sm text-gray-700 bg-white  w-[50%] rounded-md">
                              {item[key]}
                            </div>
                          ) : (
                            <span className="text-sm text-gray-700 w-[50%] ">
                              {item[key]}
                            </span>
                          )}
                        </div>
                      )
                  )}
                </div>
              ))}
          </div>
          <div className="border  mt-3"></div>

        </div>


        {/* Additional Details */}
        {/* Additional Details */}
        <div>
          {userData.some((item) => item.formName === 'Additional Details') && (
            <Typography className="text-[15px]  mb-2" sx={{ color: "#1063b5", fontFamily: "K2D", fontWeight: "bold" }}>
              Additional Details
            </Typography>
          )}
          <div className="flex flex-wrap gap-2">
            {userData
              .filter((item) => item.formName === 'Additional Details')
              .map((item, index) => (
                <div key={index} className="w-full">
                  {Object.keys(item).map(
                    (key) =>
                      key !== 'formName' &&
                      key !== 'id' &&
                      key !== 'measure' &&
                      key !== 'required' && (
                        <div key={key} className="flex justify-between mb-2">
                          <span className="text-sm text-[#000] font-semibold w-[50%] ">
                            {key === 'Property Description' || key === 'AMENITIES'
                              ? key
                              : key}
                            :
                          </span>
                          {key === 'Property Description' ? (
                            <div className="text-xs text-gray-700 bg-white  w-[50%] rounded-md">
                              {item[key]}
                            </div>
                          ) : key === 'AMENITIES' ? (
                            <div className="text-xs text-gray-700 bg-white p-2 w-[50%] rounded-md">
                              {item[key]}
                            </div>
                          ) : (
                            <span className="text-sm text-gray-700  w-[50%] ">
                              {item[key]}
                            </span>
                          )}
                        </div>
                      )
                  )}

                </div>

              ))}

          </div>
          <div className="border  mt-3"></div>

        </div>


        {/* Photos */}
        <div className="mb-2">
          <Typography className="text-[15px]  mb-2" sx={{ color: "#1063b5", fontFamily: "K2D", fontWeight: "bold" }}>
            Photos
          </Typography>
          <div className="grid grid-cols-3 gap-4">
            {propImages &&
              propImages.length > 0 &&
              propImages.map((item, index) => (
                <div key={index} className="relative">
                  <img
                    src={item.uri}
                    alt={`Image ${index}`}
                    className="custom-xxl:w-full custom-xxl:h-[90%] xl:w-full xl:h-[90%] lg:w-full lg:h-[80%] md:w-full md:h-[80%] sm:w-full sm:h-[80%] msm:w-[full] msm:h-[80%] object-cover rounded-md"
                  />
                </div>
              ))}
          </div>
          <Box className=" w-full flex justify-center items-center">
            <button
              onClick={handlePostData}
              className="w-[150px] bg-[#1063b5] text-white rounded-[20px]  mt-6 custom-xxl:ml-[60%] xl:ml-[60%] lg:ml-[65%] md:ml-[60%] sm:ml-[60%] p-2"
            >
              Post
            </button></Box>


        </div>

      </div>
      <Modal

        open={error}
        onClose={handleClose2}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >

        <Box
           sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width:370,
            borderRadius:"10px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: 'center'
          }}>
          <div onClick={() => { seterror(false) }} className='absolute top-1 right-1 cursor-pointer text-xl'> <IconButton className='flex justify-end w-full'>
    <CancelIcon 
                        sx={{  cursor: 'pointer',color:"#000",width:"30px",right:10}} 
                    /></IconButton></div>
<div className='flex relative flex-col justify-center items-center'>

            <p className='text-black font-semibold mb-2'>Kindly provide the information in these fields.</p>
            <table>
              <tr className=' w-full items-start justify-start flex'>
                <td className="text-red-600 mb-1"><li>Property Price</li></td>
              </tr>
              <tr className=' w-full items-start justify-start flex'>
                <td className="text-red-600 mb-1"><li>Rent/Lease</li></td>
              </tr>
              <tr className=' w-full items-start justify-start flex'>
                <td className="text-red-600 mb-1"><li>Built-up Area/Plot Area</li></td>
              </tr>
              {/* <tr>
                <td className="text-red-600">Plot Area</td>
              </tr> */}
              <tr className=' w-full items-start justify-start flex'>
              <td className="text-red-600"><li>Carpet Area</li></td>
              </tr>
            </table>


          </div>
        </Box>
      </Modal>



      <Modal
        open={showPopup}
        onClose={handleClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: '50%',
            // height:"80%",
            bgcolor: 'background.paper',
            boxShadow: 24,

            borderRadius: '8px',
          }}
        >

          <Success />


        </Box>
      </Modal>
    </div>
  );
};

export default ReviewPage;
