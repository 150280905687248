
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUsers } from "../compounts/data";

interface User {
    id: number;
  post_id: number;
  property_type?: any;
  looking_to?: string;
  rent_or_lease?: string;
  company_logo?:string;
  property_available_for?: string;
  images?: { uri: string }[];
  view_post_count: number;
  bhk: string;
  city: string;
  building_name: string;
  landmark_street: string;
  build_up_area?: string;
  built_up_area?: string;
  plot_area?: string;
  PriceperSqft?: string;
  price: number;
  cost?: number;
  monthly_rent?: number;
  enter_lease_amount?: number; 
  lease_amount?: any
  liked?:any
   
}

interface UsersState {
    users: User[] | any;
}

const initialState: UsersState = {
    users: [],
};

const filterSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setUsers: (state, action: PayloadAction<User[]>) => {
            if (Array.isArray(action.payload)) {
                state.users = action.payload;
            } else {
                console.error("Payload is not an array", action.payload);
                state.users = [];
            }
        },
        resetUser: (state) => {
            state.users = [];
        },
    },
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(fetchUsers.fulfilled, (state, action) => {
    //             state.users = action.payload;
    //         })
    //         .addCase(fetchUsers.rejected, (state, action) => {
    //             console.error("Error fetching users:", action.error.message);
    //         });
    // }
});

export const { setUsers,resetUser } = filterSlice.actions;
export default filterSlice.reducer;
