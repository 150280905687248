import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserLoginData {
    user_id?: any;
    name?: string;
    Id?:any;
    phone?: any;
    email?: string;
    company_logo?: string;
    company_name?: string;
    lang?: string;
    category?: string | undefined;
    token?: string; // Ensure token is a string
    encryptedToken?: string; // Add encryptedToken if necessary
}

interface InitialState {
    userLoginData: UserLoginData | null;
}

const initialState: InitialState = {
    userLoginData: {
        token: '',
        Id:null,
        encryptedToken: '',
        user_id:undefined,
        name: "",
        phone:"",
        email: "",
        company_logo:"",
        company_name:"" ,
        lang: "",
        category: "",
        // Other default properties...
    },
};
console.log(initialState)

const userDataSlice = createSlice({
    name: "userLoginData",
    initialState,
    reducers: {
        setUserLoginData: (state, action: PayloadAction<UserLoginData>) => {
            state.userLoginData = action.payload;
        },
        setUpdateToken: (state, action: PayloadAction<string>) => {
            if (state.userLoginData) {
                state.userLoginData.token = action.payload;
            }
        },
        setUpdateEncryptedToken: (state, action: PayloadAction<string>) => {
            if (state.userLoginData) {
                state.userLoginData.encryptedToken = action.payload;
            }
        },
        setUser_Id: (state, action: PayloadAction<UserLoginData>) => {
            if (state.userLoginData) {
                state.userLoginData.user_id = action.payload.user_id;
            }
        },
        resetUserLoginData: (state) => {
            state.userLoginData = initialState.userLoginData;
        },

    },
});

export const { setUserLoginData, setUpdateToken, setUpdateEncryptedToken,resetUserLoginData,setUser_Id } = userDataSlice.actions;

export default userDataSlice.reducer;
