import React from 'react';

const Skeleton: React.FC = () => {
  return (
    <div className="flex animate-pulse">
      <div className="bg-gray-100 dark:bg-gray-300 rounded-xl animate-pulse space-x-4 flex flex-col" style={{ maxHeight: "79vh", width: "60%" }}>
        <div className="flex items-center justify-center mt-28 mx-10 bg-gray-100 dark:bg-gray-200 rounded-xl" style={{ height: "60vh" }}>
        </div>
      </div>

      <div className="ml-5 bg-gray-100 dark:bg-gray-300 rounded-xl animate-pulse flex flex-col" style={{ minHeight: "79vh", width: "40%" }}>
        <p className="dark:bg-slate-200 h-10 w-72 m-5 rounded-lg"></p>
        <div className="flex flex-col items-center px-8">
          <div className="dark:bg-gray-200" style={{ height: "35vh", width: "80%" }}>
          </div>
        </div>

        <div className="bg-slate-100 flex flex-col mx-5 mt-1">
          <p className="dark:bg-slate-200 h-10 w-72 m-5 rounded-lg"></p>
          <p className="dark:bg-slate-200 h-10 w-72 m-5 rounded-lg"></p>
          <p className="dark:bg-slate-200 h-10 w-72 m-5 rounded-lg"></p>
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
