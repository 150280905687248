import whoWeAreBg from "../../assets/who-we-are-bg.png";

const WhoWeAre = () => {
  return (
    <div className="relative">
      <img className="w-full h-[250px] md:h-auto" src={whoWeAreBg} alt="" />
      <div className="bg-black absolute inset-0 opacity-50"></div>
      <div className="font-K2D font-normal absolute inset-0 text-[#ffffff] text-sm md:text-base lg:text-lg flex justify-center px-4 md:px-8 xl:px-32">
        <div className="flex items-center justify-between gap-8">
          <div className="w-1/3 text-right">Who We Are</div>
          <div className="w-1 h-1/2 bg-white bg-opacity-50"></div>
          <div>
            We are a pure-play digital company. We straddle Technology,
            Analytics, Experience, Cloud, MarTech, Operations and Services with
            equal felicity. Our mission is to deliver Digital Impact. This means
            we work across the entire digital layer of our client enterprises.
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
