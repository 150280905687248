import React from "react";
import { useStepperContext } from "./StepperContext";
import { TextField } from "@mui/material";
import { MenuItem } from "@mui/base";

interface DropDown1Props {
  label: string;
  title?: string;
  options?: any;
  inputId?: number;
  formName: string;
  required?: boolean;
  userselectedData?: any;
  initial?: string;
}

const DropBox1: React.FC<DropDown1Props> = ({
  label,
  title,
  options,
  inputId,
  formName,
  required,
  userselectedData,
  initial,
}) => {
  const { addUserData, userData } = useStepperContext();
console.log("where check",options)
  //  const deposit=userData.find((item)=>item?.["Security Deposit"])?.["Security Deposit"]

  const textFieldWidth =
    title === "Security Deposit" || "Select Your Floor" ? "50%" : "100%";
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | { value: unknown }>
  ) => {
    const data = {
      id: inputId,
      [label]: event.target.value,
      formName: formName,
      required: required,
    };
    addUserData(data as any);
  };

  const hasError = userselectedData?.includes(label);

  return (
    <div>
      <TextField
        select
        label={label}
        id={label}
        defaultValue={initial}
        onChange={handleChange}
        className="w-[100%]"
      >
        {options.map((option:any, index:any) => (
          <MenuItem key={index} value={option.id}>
            {option.title}
          </MenuItem>
        ))}
      </TextField>
      {hasError && <div>This field is required</div>}
    </div>
  );
};

export default DropBox1;
