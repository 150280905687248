// src/components/ShareButtons.tsx
import React from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    EmailIcon,
} from 'react-share';

interface ShareButtonsProps {
    post_id: any;
    title: string;
    property_type:any
    looking_to:any
}

const ShareCom: React.FC<ShareButtonsProps> = ({ property_type, title,post_id,looking_to }) => {

    const copyToClipboard = () => {
        navigator.clipboard.writeText(generatedUrl).then(() => {
            alert("Link copied to clipboard!");
        }).catch((err) => {
            console.error("Could not copy text: ", err);
        });
    };
    const generateUrl = () => {
        const postId = encodeURIComponent(post_id);
        const propertyType = encodeURIComponent(property_type);
        const lookingTo = encodeURIComponent(looking_to);

        return `https://vattara.com/property/${postId}/${propertyType}/${lookingTo}`;
    };

    const generatedUrl = generateUrl();


    return (
        <div className="flex space-x-4">
            <FacebookShareButton url={generatedUrl} title={title}>
                <FacebookIcon size={32} round />
            </FacebookShareButton>

            <TwitterShareButton url={generatedUrl} title={title}>
                <TwitterIcon size={32} round />
            </TwitterShareButton>

            <WhatsappShareButton url={generatedUrl} title={title}>
                <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            <EmailShareButton url={generatedUrl} subject={title} body="Check this out!">
                <EmailIcon size={32} round />
            </EmailShareButton>

            <button
                onClick={copyToClipboard}
                className="border border-gray-400 px-3 py-2 rounded bg-white hover:bg-gray-100"
            >
                Copy Link
            </button>
        </div>
    );
};

export default ShareCom;
