// import React, { Children, ReactNode, createContext, useContext, useReducer, useState } from 'react'

// interface StepperContextType{
// currentPage:string;
// navprop:boolean;
// userData:any[];
// ammenties:any[];
// propImages:any[];
// customFormProps:any;
// customFormDetails:any[];
// addCustomProps:(payload:any)=>void;
// addnavprop:(payload:boolean)=>void;
// addCustomFormDetails:(payload:any)=>void
// addCurrentPage:(payload:string)=>void;
// addUserData:(payload:string)=>void;
// addAmmenties:(payload:any)=>void;
// addPropImage:(payload:any)=>void;
// removeUserDataByKeys:(key:string[])=>void;
// resetForm:()=>void;
// }

// interface State {
//     currentPage:string;
//     navprop:boolean;
//     userData:any[];
//     ammenties:any[];
//     propImages:any[];
//     customFormDetails:any[];
//     customFormProps:any;
// }
// export const initialState = {
//     currentPage:'Basic Details',
//     navprop:false,
//     userData:[],
//     ammenties:[],
//     propImages:[],
//     customFormDetails:[],
//     customFormProps:null,
// }

// export const actions={
//     CHANGE_CURRENTPAGE:'CHANGE_CURRENTPAGE',
//     ADD_USER_SELECTEDDATA:'ADD_USER_SELECTEDDATA',
//     ADD_AMMENTIES:"ADD_AMMENTIES",
//     ADD_NAVPROP:"ADD_NAVPROP",
//     ADD_PROPIMAGES:'ADD_PROPIMAGES',
//     ADD_CUSTOMFORM_PROPS:"ADD_CUSTOMFORM_PROPS",
//     REMOVE_USER_DATA_BY_KEYS:'REMOVE_USER_DATA_BY_KEYS',
//     ADD_CUSTOM_FORM_DETAILS:'ADD_CUSTOM_FORM_DETAILS',
//     RESET_FORM:"RESET_FORM",
// }

// export const reducer=(state:State=initialState,action:{type:string;payload?:any}) : State=>{
//     switch(action.type){
//         case actions.CHANGE_CURRENTPAGE:
//             return{...state,currentPage:action.payload};
//             case actions.ADD_USER_SELECTEDDATA:
//                 const userInputId=action.payload.id;
//                 const existingIndex=state.userData.findIndex((user)=>user.id===userInputId)
//                 if(existingIndex===-1){
//                     return {...state,userData:[...state.userData,action.payload]}

//                 }  else{
//              const updatedUserData=[...state.userData];
//              updatedUserData[existingIndex]=action.payload;
//              return{...state,userData:updatedUserData}

//                 }
//                 case actions.ADD_AMMENTIES:
//                     return{...state,ammenties:action.payload}
//                     case actions.REMOVE_USER_DATA_BY_KEYS:
//                         if(!Array.isArray(action.payload)){
//             console.error('Payload for REMOVE_USER_DATA_BY_KEYS must be an array')
//             return state
//         }
//         return{
//             ...state,
//             userData:state.userData.filter((item)=>!action.payload.some((key:string)=>item.hasOwnProperty(key)))
//         }
//         case actions.ADD_PROPIMAGES:
//             return{...state,propImages:action.payload}
//             case actions.ADD_CUSTOMFORM_PROPS:
//                 return{...state,customFormDetails:action.payload}
//                 case actions.ADD_CUSTOM_FORM_DETAILS:
//                     return{...state,customFormDetails:action.payload}
//                     case actions.ADD_NAVPROP:
//                         return{...state,navprop:action.payload}
//                         case actions.RESET_FORM:
//                             return initialState
//                             default:
//                                 return state;
//                         }

//             }
//             const StepperContext=createContext<StepperContextType | undefined>(undefined)
//          export const useStepperContext=():StepperContextType=>{
//             const context=useContext(StepperContext)
//             if(!context){
//                 throw new Error('useFormContext must be used within a FormProvider')
//             }
//             return context
//          }

//          interface FormProviderProps{
//             children:ReactNode;
//          }

//          export const FormProvider=({children} : FormProviderProps):JSX.Element=>{
//             const[state,dispatch]=useReducer(reducer,initialState)
//             const value:StepperContextType={
//                 currentPage:state.currentPage,
//                 userData:state.userData,
//                 ammenties:state.ammenties,
//                 navprop:state.navprop,
//                 propImages:state.propImages,
//                 customFormProps:state.customFormProps,
//                 customFormDetails:state.customFormDetails,

//              addCustomProps:(payload:any)=>{
//                 dispatch({type:actions.ADD_CUSTOMFORM_PROPS,payload})
//              },
//              addCustomFormDetails:(payload:any)=>{
//                 dispatch({type:actions.ADD_CUSTOM_FORM_DETAILS,payload})
//              },
//              addCurrentPage:(payload:any)=>{
//                 dispatch({type:actions.ADD_CUSTOM_FORM_DETAILS,payload})
//              },
//              addUserData:(payload:any)=>{
//                 dispatch({type:actions.ADD_USER_SELECTEDDATA,payload})
//              },

//              addAmmenties:(payload:any)=>{
//                 dispatch({type:actions.ADD_AMMENTIES,payload})
//              },
//              addPropImage:(payload:any)=>{
//                 dispatch({type:actions.ADD_PROPIMAGES,payload})
//              },
//              removeUserDataByKeys:(keys:string[])=>{
//                 dispatch({type:actions.REMOVE_USER_DATA_BY_KEYS,payload:keys})
//              },
//              addnavprop:(payload:any)=>{
//                 dispatch({type:actions.ADD_NAVPROP,payload})
//              },

//              resetForm:()=>{
//                dispatch({type:actions.RESET_FORM})

//              }

//             }

//             return <StepperContext.Provider value={value}>{children }</StepperContext.Provider>
//          }

import React, {
  Children,
  ReactNode,
  createContext,
  useContext,
  useReducer,
  useState,
} from "react";

interface StepperContextType {
  currentPage: string;
  navprop: boolean;
  userData: any[];
  ammenties: any[];
  location:any;
  propImages: any[];
  customFormProps: any;
  customFormDetails: any[];
  addCustomProps: (payload: any) => void;
  addnavprop: (payload: boolean) => void;
  addLocation:(payload:any)=>void;
  addCostPerBuiltUpArea: (payload: boolean) => void;
  addCostPerBuiltUpArea2:(payload: boolean) => void;
  addCostPerBuiltUpArea3:(payload: boolean) => void;
  addCustomFormDetails: (payload: any) => void;
  addCurrentPage: (payload: string) => void;
  addUserData: (payload: string) => void;
  addAmmenties: (payload: any) => void;
  addPropImage: (payload: any) => void;
  removeUserDataByKeys: (key: string[]) => void;
  resetForm: () => void;
  costPerBuiltUpArea: string;
  costPerBuiltUpArea2: string;
  costPerBuiltUpArea3: string;
}

interface State {
  currentPage: string;
  navprop: boolean;
  userData: any[];
  ammenties: any[];
  propImages: any[];
  location:any;
  customFormDetails: any[];
  customFormProps: any;
  costPerBuiltUpArea: string,
  costPerBuiltUpArea2: string,
  costPerBuiltUpArea3: string,
}
export const initialState = {
  currentPage: "Basic Details",
  navprop: false,
  userData: [],
  ammenties: [],
  location:null,
  propImages: [],
  customFormDetails: [],
  customFormProps: null,
  costPerBuiltUpArea: "",
  costPerBuiltUpArea2: "",
  costPerBuiltUpArea3: "",
};

export const actions = {
  CHANGE_CURRENTPAGE: "CHANGE_CURRENTPAGE",
  ADD_USER_SELECTEDDATA: "ADD_USER_SELECTEDDATA",
  ADD_AMMENTIES: "ADD_AMMENTIES",
  ADD_LOCATION:"ADD_LOCATION",
  ADD_NAVPROP: "ADD_NAVPROP",
  ADD_PROPIMAGES: "ADD_PROPIMAGES",
  ADD_CUSTOMFORM_PROPS: "ADD_CUSTOMFORM_PROPS",
  REMOVE_USER_DATA_BY_KEYS: "REMOVE_USER_DATA_BY_KEYS",
  ADD_CUSTOM_FORM_DETAILS: "ADD_CUSTOM_FORM_DETAILS",
  RESET_FORM: "RESET_FORM",
  ADD_COST_PER_BUILT_UP_AREA: "ADD_COST_PER_BUILT_UP_AREA",
  ADD_COST_PER_BUILT_UP_AREA2: "ADD_COST_PER_BUILT_UP_AREA2",
  ADD_COST_PER_BUILT_UP_AREA3: "ADD_COST_PER_BUILT_UP_AREA3",
};

export const reducer = (
  state: State = initialState,
  action: { type: string; payload?: any }
): State => {
  switch (action.type) {
    case actions.CHANGE_CURRENTPAGE:
      return { ...state, currentPage: action.payload };
    case actions.ADD_USER_SELECTEDDATA:
      const userInputId = action.payload.id;
      const existingIndex = state.userData.findIndex(
        (user) => user.id === userInputId
      );
      if (existingIndex === -1) {
        return { ...state, userData: [...state.userData, action.payload] };
      } else {
        const updatedUserData = [...state.userData];
        updatedUserData[existingIndex] = action.payload;
        return { ...state, userData: updatedUserData };
      }
    case actions.ADD_AMMENTIES:
      return { ...state, ammenties: action.payload };
    case actions.REMOVE_USER_DATA_BY_KEYS:
      if (!Array.isArray(action.payload)) {
        console.error("Payload for REMOVE_USER_DATA_BY_KEYS must be an array");
        return state;
      }
      return {
        ...state,
        userData: state.userData.filter(
          (item) =>
            !action.payload.some((key: string) => item.hasOwnProperty(key))
        ),
      };
    case actions.ADD_PROPIMAGES:
      return { ...state, propImages: action.payload };
    case actions.ADD_CUSTOMFORM_PROPS:
      return { ...state, customFormDetails: action.payload };
    case actions.ADD_LOCATION:
        return { ...state, location: action.payload };
    case actions.ADD_CUSTOM_FORM_DETAILS:
      return { ...state, customFormDetails: action.payload };
    case actions.ADD_NAVPROP:
      return { ...state, navprop: action.payload };
    case actions.RESET_FORM:
      return initialState;
    case actions.ADD_COST_PER_BUILT_UP_AREA:
      return { ...state, costPerBuiltUpArea: action.payload };
    case actions.ADD_COST_PER_BUILT_UP_AREA2:
      return { ...state, costPerBuiltUpArea2: action.payload };
    case actions.ADD_COST_PER_BUILT_UP_AREA3:
      return { ...state, costPerBuiltUpArea3: action.payload };
    default:
      return state;
  }
};

const StepperContext = createContext<StepperContextType | undefined>(undefined);
export const useStepperContext = (): StepperContextType => {
  const context = useContext(StepperContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};

interface FormProviderProps {
  children: ReactNode;
}

export const FormProvider1 = ({ children }: FormProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value: StepperContextType = {
    currentPage: state.currentPage,
    userData: state.userData,
    ammenties: state.ammenties,
    navprop: state.navprop,
    location:state.location,
    propImages: state.propImages,
    customFormProps: state.customFormProps,
    customFormDetails: state.customFormDetails,
    costPerBuiltUpArea: state.costPerBuiltUpArea,
    costPerBuiltUpArea2: state.costPerBuiltUpArea2,
    costPerBuiltUpArea3: state.costPerBuiltUpArea3,

    addCustomProps: (payload: any) => {
      dispatch({ type: actions.ADD_CUSTOMFORM_PROPS, payload });
    },
    addCustomFormDetails: (payload: any) => {
      dispatch({ type: actions.ADD_CUSTOM_FORM_DETAILS, payload });
    },
    addCurrentPage: (payload: any) => {
      dispatch({ type: actions.ADD_CUSTOM_FORM_DETAILS, payload });
    },
    addUserData: (payload: any) => {
      dispatch({ type: actions.ADD_USER_SELECTEDDATA, payload });
    },
    addAmmenties: (payload: any) => {
      dispatch({ type: actions.ADD_AMMENTIES, payload });
    },
    addPropImage: (payload: any) => {
      dispatch({ type: actions.ADD_PROPIMAGES, payload });
    },
    removeUserDataByKeys: (keys: string[]) => {
      dispatch({ type: actions.REMOVE_USER_DATA_BY_KEYS, payload: keys });
    },
    addnavprop: (payload: any) => {
      dispatch({ type: actions.ADD_NAVPROP, payload });
    },
    addLocation: (payload: any) => {
      dispatch({ type: actions.ADD_LOCATION, payload });
    },
    addCostPerBuiltUpArea: (payload: any) => {
      dispatch({ type: actions.ADD_COST_PER_BUILT_UP_AREA, payload });
    },
    addCostPerBuiltUpArea2: (payload: any) => {
      dispatch({ type: actions.ADD_COST_PER_BUILT_UP_AREA2, payload });
    },
    addCostPerBuiltUpArea3: (payload: any) => {
      dispatch({ type: actions.ADD_COST_PER_BUILT_UP_AREA3, payload });
    },
    resetForm: () => {
      dispatch({ type: actions.RESET_FORM });
    },
  };
  return (
    <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
  );
};
