import React, { useEffect, useState } from 'react';
import { useStepperContext } from './StepperContext';
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import Map from './Map';

interface TextFieldProps {
  label: string;
  formName: string;
  inputId?: number;
  label2?: string;
  keyboard?: any;
  required?: boolean;
  userselectedData?: any;
  initial: any;
}

const TextBox: React.FC<TextFieldProps> = ({
  label,
  formName,
  inputId,
  label2,
  keyboard,
  required,
  userselectedData,
  initial,
}) => {
  const { addUserData, userData } = useStepperContext();
  const [MapVisible, setMapVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setMapVisible(false);
  const [selectedValue, setSelectedValue] = useState(initial);
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);

  const [measures, setMeasures] = useState('Sq.ft');
  const mydistrict = userData.find((item: any) =>
    item.hasOwnProperty('City')
  )?.['City'];

  const handleMap = () => {
    if (mydistrict !== undefined) {
      setMapVisible(true);
    } else {
      setOpen(true);
    }
  };

  const handleChange = (value: string) => {
    setSelectedValue(value);
    
    const data = {
      id: inputId,
      [label]: value,
      formName: formName,
    };
    const measured = {
      id: 785156,
      measure: measures,
      formName: formName,
    };

    addUserData(data as any);
    addUserData(measured as any);
  };
console.log("userselectedData",userselectedData)
  const hasError = userselectedData?.includes(label);

  const handleMeasure = (event: SelectChangeEvent) => {
    setMeasures(event.target.value);
    const measured = {
      id: 785156,
      measure: event.target.value,
      formName: formName,
    };
    addUserData(measured as any);
  };
  useEffect(() => {
    setIsFieldDisabled(label === 'Locality');
  }, [label]);

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="error"
          sx={{ width: '100%' }}
        >
          Select Your City/Town
        </Alert>
      </Snackbar>

      {/* Flex container for Length and Width fields */}
      {['Length', 'Width'].includes(label) ? (
        <Box
          sx={{
            display: 'flex',
            width:"100%",
            flexDirection:"row",
          }}
        >
          <div className="flex " style={{display:"flex",flexDirection:"row",  position:"relative",          width:"100%",
}}>
            {/* Length TextField */}
          <div>  {label === 'Length' && (
              <TextField
              variant="filled"
              size="small"

                id="Length"
                placeholder="Length"
                value={selectedValue}
                type={keyboard ? keyboard : 'text'}
                label="Length"
                onChange={(e) => handleChange(e.target.value)}
                className="lg:w-[220px] msm:w-[170px]"
           sx={{                  borderRadius: "999px",
                  '& .MuiFilledInput-underline:before': {
                            borderBottom: '0px solid transparent', // Dotted underline
                          },
                          '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
                            borderBottom: '0px solid transparent', // Dotted underline
                          }, }}
                          InputProps={{
                            style: {
                              borderRadius: "999px",
                  fontFamily:"K2D",            backgroundColor: "#F5F4F8",
                            },
                          }}
                          
              />
            )}
</div>
        <div>
              {/* Width TextField */}
            {label === 'Width' && (
              <TextField
              variant="filled"
              id="Width"
                size="small"

                placeholder="Width"
                value={selectedValue}
                type={keyboard ? keyboard : 'text'}
                label="Width"
                onChange={(e) => handleChange(e.target.value)}
                className="lg:w-[220px] msm:w-[170px]"
                sx={{ marginTop:"-64px",marginLeft:{xs:"180px",lg:"260px"}, position:"absolute",      borderRadius: "999px",
                  '& .MuiFilledInput-underline:before': {
                            borderBottom: '0px solid transparent', // Dotted underline
                          },
                          '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
                            borderBottom: '0px solid transparent', // Dotted underline
                          }, }}
                          InputProps={{
                            style: {
                              borderRadius: "999px",
                  fontFamily:"K2D",            backgroundColor: "#F5F4F8",
                            },
                          }}
                          
              />
            )}</div>
          </div>
        </Box>
      ) : (
        <TextField
        variant="filled"
          id={label}
          size="small"

          placeholder={label}
          value={selectedValue}
          type={keyboard ? keyboard : 'text'}
          label={label}
          disabled={isFieldDisabled}

  onChange={(e) => handleChange(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))}
          className="w-[100%] absolute "
          sx={{ width: "100%",            borderRadius: "999px",
            '& .MuiFilledInput-underline:before': {
                      borderBottom: '0px solid transparent', // Dotted underline
                    },
                    '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
                      borderBottom: '0px solid transparent', // Dotted underline
                    }, }}
          InputProps={{
            style: {
              borderRadius: "999px",
              

  fontFamily:"K2D",            backgroundColor: "#F5F4F8",
            },
            endAdornment: (
              <InputAdornment position="end">
                {label === 'Locality' ? (
                  <Button
                    onClick={handleMap}
                    variant="contained"
                    size="small"
                    sx={{ ml: 1,fontFamily:"K2D", backgroundColor:"#0CB7D1",borderRadius:"10px" }}
                  >
                    Choose Location
                  </Button>
                ) : label === 'Plot Area' && label2 === 'yes'? (
                  <>
                    <Box sx={{ minWidth: 65 }}>
                      <FormControl fullWidth>
                        <Select
                          value={measures}
                          variant="standard"
                          onChange={handleMeasure}
                          sx={{ minWidth: 70 }}
                        >
                          <MenuItem value={'Sq.ft'}>Sq.ft</MenuItem>
                          <MenuItem value={'Cent'}>Cent</MenuItem>
                          <MenuItem value={'Acre'}>Acre</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </>
                ) : (
                  label2
                )}
              </InputAdornment>
            ),
          }}
        />
      )}

      <Modal
        open={MapVisible}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="bg-white justify-center items-center custom-xxl:w-[50%] sm:w-[50%] msm:w-full  p-3">
          <Map
            onClose={handleClose}
            formName={formName}
            onLocationSelect={handleChange}
          />
        </Box>
      </Modal>

      {hasError && (
        <Typography sx={{ fontSize:"12px" ,color:"#f44336",marginLeft:"20px"}}>
          *This field is required
        </Typography>
      )}
    </div>
  );
};

export default TextBox;
