import * as React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

interface Props {
  value: any
  setValue: any
}

const BasicRating: React.FC<Props> = ({ value, setValue }) => {


  return (
    <Box
      sx={{
        '& > legend': { mt: 2 },}}
    >

      <Rating
        name="simple-controlled"
        value={value}

        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          '& .MuiRating-icon': {
            fontSize: {xs:"55px",sm:"55px",md:"75px",lg:"75px"}, // Customize the size here
          },
          '& .MuiRating-iconFilled': {
            color: '#0CB7D1',
            // Change this to the color you want
          },
          '& .MuiRating-iconHover': {
            color: '#0CB7D1',
            // Change this to the color you want
          },
        }}
      />
    </Box>
  );
}

export default BasicRating