import React, { useState } from 'react'
import { SortData } from "../data"
import round from "../images/rounded.png"
import unround from "../images/unround.png"
import cancel from '../images/cancel.svg'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel';



type SortData = {
    id: any;
    value: any
}

type SortByProps = {

    setValue: any;
    handleSort: () => void;
};


const SortBy: React.FC<SortByProps> = ({ setValue, handleSort }) => {

    const [Round, setRound] = useState<any>(-1)

    function handleChange(value: any, index: any) {


        if (Round === index) {
            setRound(-1);
            handleSort()
            // handleChildData(null)
            setValue(null)
        } else {
            setRound(index);
            setValue(value)
            handleSort()
            // handleChildData(value)
        }
    }

    function handleClose() {
        handleSort()
    }

    
    return (
        <div className='absolute  bg-white rounded-r-2xl  msm:w-[100%] lg:w-[17%] h-[100%] top-0 left-0 z-40  mt-  flex flex-col '>
            <div className=' '>
                <div className='flex flex-col bg-[#F5F4F8] rounded-tr-2xl  gap-2 '>
                    <div className='flex justify-between mt-2  items-center py-2'>
                        <p className=' ml-3 text-xl font-bold'>Sort by</p>
                        {/* <img onClick={handleClose} className='mr-3 w-6 h-8 cursor-pointer' src={cancel} /> */}
                        <IconButton onClick={handleClose} >
                        <CancelIcon />             </IconButton>

                    </div>
                    <div className='border-1'></div>
                </div>
                {
                    SortData.map((item: SortData, index: any) => (
                        <div className='pt-2 '>
                            <div className='flex items-center justify-between px-3 cursor-pointer'>
                                <p className=' font-semibold'>{item.value}</p>
                                <img onClick={() => { handleChange(item.value, index) }} src={Round == index ? round : unround} />
                            </div>
                            <div className='border-[#000000] border-1 opacity-20  mt-3 mx-2'></div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default SortBy