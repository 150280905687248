import React, { useState } from 'react'
import editIcon from '../../assets/editForm.svg'
import { basics } from "./dataJson";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Basic from './component/Basic';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';


interface AdditionalDetails {
  bathroom: string;
  balcony: string;
  preferred_tenant_type: string;
  parking: string;
  carpet_area: string;
  // Add other fields if necessary
}

interface User {
  name: string;
  mobile_no: string;
  category: string;
  company_logo: string;
  company_name: string;
}

interface PropertyImage {
  filename: string;
  id: number;
  isCover: boolean;
  name: string;
  type: string;
  uri: string;
}

interface Property {
  active: boolean;
  additional_details: AdditionalDetails;
  available_from: string;
  bhk: string;
  building_name: string;
  built_up_area: string;
  city: string;
  createdAt: string;
  delete_post_reason: string;
  district: string;
  enter_lease_amount: string;
  flat_furnishing: string;
  furnish_type: string;
  home_screen_post: boolean;
  id: number;
  images: PropertyImage[];
  kbl_property: boolean;
  landmark_street: string;
  latitude: string;
  liked: boolean;
  longitude: string;
  monthly_rent: string;
  negotiable: string;
  post_approval: string;
  post_id: string;
  propertyID: string;
  property_available_for: string;
  property_type: string;
  res_or_com: string;
  security_deposit: string;
  shareUrl: string;
  society_amenities: any; // Adjust type based on actual data or make it optional
  state: string | null;
  sublocality: string;
  tbl_user_reg: User;
  updatedAt: string;
  user_id: string;
  view_post_count: number;
  looking_to: any
  price: any
  cost: any
  lease_amount: any
  rent_or_lease: any
}

interface DetailsProps {
  PassedData?: Property
  passedData?: Property;
  setFinalValue: any// Use the Property interface here
}



const EditBaisc: React.FC<DetailsProps> = ({ PassedData, setFinalValue }) => {
  // const passedData = useSelector((state: any) => state.usersInfo.users);
  // console.log(passedData)
  const [formVisible, setFormVisible] = useState<any>(false)
  const [FullData, setFullData] = useState<any>(PassedData)


  // console.log("redux check basic",passedData)

  setFinalValue(FullData)
  const handleForm = () => {
    setFormVisible(true);
    console.log("dvcrew")
  };

  const closeModal = () => {
    setFormVisible(false);
  };

  const renderBasicDetails = () => {
    const fullData = FullData[0] || {};

    return Object.keys(basics).map((key) => {
      switch (key) {
        case 'Property Type':
          return { [key]: fullData.res_or_com || '' };
        case 'Looking To':
          return { [key]: fullData.looking_to || fullData.rent_or_lease || fullData.property_available_for || '' };
        case 'Property Subtype':
          return { [key]: fullData.property_type || '' };
        case 'City':
          return { [key]: fullData.district || '' };
        case 'Locality':
          return { [key]: fullData.city || '' };
        case 'Landmark':
          return { [key]: fullData.landmark_street || '' };
        case 'longitude':
          return { [key]: fullData.longitude || "" }
        case "latitude":
          return { [key]: fullData.latitude || '' }
        case "sublocality":
          return { [key]: fullData.sublocality || '' }
        default:
          return { [key]: '' };
      }
    });
  };
  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    // setActiveStep(0);
  };

  // Get basic details
  const basicDetails = renderBasicDetails();

  // Log details to the console
  console.log('Basic Details:', basicDetails);

  return (
    <div className='mt-4 flex flex-col gap- relative p-1'>
  <Typography className="text-[25px]  mb-2" sx={{color:"#1063b5",fontFamily:"K2D",fontWeight:"bold"}}>
    Basic Details
  </Typography>      <img onClick={handleForm} src={editIcon} className='absolute right-0 cursor-pointer' />
      <div className='  mt-3 mb-2'>
        <div className='flex flex-col gap-2 '>
          <div className='flex  '>
            <p className='text-sm text-[#000] font-semibold w-[50%] '>Property Type</p>
            <p className='text-sm text-gray-700 w-[50%] rounded-md" style={{textAlign:"left"}}>
                        {item[key]}'> {FullData[0]?.property_type}</p>
          </div>
          <div className='flex '>
            <p className='text-sm text-[#000] font-semibold w-[50%] '>Looking to</p>
            <p className='text-sm text-gray-700 w-[50%] rounded-md" style={{textAlign:"left"}}>
                        {item[key]}'>{FullData[0]?.looking_to || FullData[0]?.rent_or_lease || FullData[0]?.property_available_for} </p>
          </div>
          <div className='flex '>
            <p className='text-sm text-[#000] font-semibold w-[50%] '>City</p>
            <p className='text-sm text-gray-700 w-[50%] rounded-md" style={{textAlign:"left"}}>
                        {item[key]}'>{FullData[0]?.district} </p>
          </div>
          <div className='flex '>
            <p className='text-sm text-[#000] font-semibold w-[50%] '>Locality</p>
            <p className='text-sm text-gray-700 w-[50%] rounded-md" style={{textAlign:"left"}}>
                        {item[key]}'>{FullData[0]?.city} </p>
          </div>
          <div className='flex '>
            <p className='text-sm text-[#000] font-semibold w-[50%] '>Landmark/Street</p>
            <p className='text-sm text-gray-700 w-[50%] rounded-md" style={{textAlign:"left"}}>
                        {item[key]}'> {FullData[0]?.landmark_street}</p>
          </div>
          <iframe name="gMap" style={{width:"100%",height:"90%"}} src={`https://www.google.com/maps/embed/v1/place?q=${FullData[0].latitude},${FullData[0].longitude}&key=AIzaSyDz2gppwqEkyckilX-FDJkvgEtcoS2OEvE`}></iframe>




        </div>

      </div>
      <div className='border-1 border-[#8E8E8E] mt-3 '></div>

      <Modal
        open={formVisible}
        onClose={closeModal}
        aria-labelledby="basic-modal-title"
        aria-describedby="basic-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            overflowY:"auto",
            transform: 'translate(-50%, -50%)',
            width: { md: "80%", lg: "50%", xs: "95%",xl:"40%" },
            bgcolor: 'background.paper',
            border: '0px solid #fff',
            borderRadius:"20px",
            maxHeight: '90vh',

            boxShadow: 24,
            p: { md: 4, xs: 1 },
          }}
        >
          <Basic onNext={handleNext} onBack={handleBack} FullData={FullData} setFinalValue={setFinalValue} closeModal={closeModal} onReset={handleReset} basicDetails={basicDetails} setFullData={setFullData} />
        </Box>
      </Modal>
    </div>
  )
}

export default EditBaisc