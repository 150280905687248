import React, { useState } from 'react';
import { useStepperContext } from './StepperContext';
import CustomisedButton from './CustomisedButton';
import TextBox from './TextBox';
import CustomButton from './CustomButton';
import CustomBoxButton from './CustomBoxButton';
import PriceTextField from './PriceTextField';
import PriceTextField1 from './PriceTextField1';
import PriceTextField2 from './PriceTextField2';
import DropBox1 from './DropBox1';
import FDropDown from './FDropDown';
import DropDown1 from './DropDown1';
import DatePick from './DatePick';
import { Box, Button, IconButton, Typography } from '@mui/material';
import TextAreaBox from './TextAreaBox';
import AccessButton from './AccessButton';
import MultiSelectorButton2 from '../reusablecomponent/MultiSelectorButton2';
import BackComponent from './icon/BackComponent';
import { MdArrowBackIosNew } from 'react-icons/md';
import MultiSelectorButton from '../reusablecomponent/MultiSelectorButton';



interface AdditionalDetailsProps {
  onNext: () => void;
  onBack: () => void;
  onReset: () => void;
}

interface AdditionalDetail {
  Type: string;
  label: string;
  label2?: string;
  data: any;
  inputID: number;
  required: boolean;
  keyboard?: string;
}

interface UserData {
  [key: string]: any;
}

const AdditionalDetails: React.FC<AdditionalDetailsProps> = ({
  onNext,
  onBack,
  onReset,
}) => {
  const { addUserData, userData, customFormDetails, addCustomFormDetails } =
    useStepperContext();
  const [selectedValues, setSelectedValues] = useState<number[]>([]);
  const [userselectedData, setUserSelectedData] = useState<string[]>([]);
  console.log("add",customFormDetails)
  const generateValidationSchema = (
    customFormDetails: any,
    userData: UserData[]
  ) => {
    const requiredLabels = customFormDetails.additional_details
      .filter((item: any) => item.required)
      .map((item: any) => item.label);

    const existingLabels = requiredLabels.filter(
      (label: string) =>
        !userData.some((item: UserData) => item.hasOwnProperty(label))
    );
    

    if (existingLabels.length > 0) {
      setUserSelectedData(existingLabels);
    } else {
      onNext();
    }
  };

  const getInitialValue = (label: string) => {
    const entry = userData.find((obj: UserData) => obj.hasOwnProperty(label));
    return entry ? entry[label] : '';
  };

  const handleSubmit = () => {
    generateValidationSchema(customFormDetails, userData);
  };

  return (
    <div className=" custom-scrollbar   overflow-Y-scroll p-2">
     <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "10px", alignItems: "center" }}>
  <IconButton 
        onClick={onBack}
        sx={{ marginRight: "8px" }} // Space between the icon and the text
  >
    <MdArrowBackIosNew className='w-5 h-5' />
  </IconButton>
  <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
    <Box
      sx={{
        fontWeight: '700',
        fontFamily: 'K2D',
      }}
    >
        Additional Details
        </Box>
  </Box>
</Box>

      {(customFormDetails as any)?.additional_details?.map(
        (dataItem: AdditionalDetail, index: number) => (
          <div key={index}  className=" lg:mx-[10%] msm:mx-[1%]">
            {dataItem.Type === 'Box Type' && (
              <div className="">
                <Typography className="text-start">{dataItem.label}</Typography>
                <div className="flex ">
                  <Box>
                    <CustomisedButton
                      data={dataItem.data}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Additional Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}

                      initial={getInitialValue(dataItem.label)}
                    />
                  </Box>
                </div>
              </div>
            )}
            {dataItem.Type === 'Box Type2' && (
              <div>
                <Typography className="text-start">{dataItem.label}</Typography>
                <div className="flex gap-7 my-2">
                  <Box>
                    <CustomButton
                      data={dataItem.data}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Additional Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}

                      initial={getInitialValue(dataItem.label)}
                    />
                  </Box>
                </div>
              </div>
            )}
            {dataItem.Type === 'Box Type11' && (
              <div>
                <Typography className="text-start">{dataItem.label}</Typography>
                <div className="flex gap-7 my-2">
                  <Box>
                    <MultiSelectorButton2
                      data={dataItem.data}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Additional Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}

                      initial={getInitialValue(dataItem.label)}
                    />
                  </Box>
                </div>
              </div>
            )}
            {dataItem.Type === 'Custom Box Type' && (
              <div>
                <Typography className="text-start">{dataItem.label}</Typography>
                <div className="flex gap-7 my-2">
                  <Box>
                    <CustomBoxButton
                      data={dataItem.data}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Additional Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}

                      initial={getInitialValue(dataItem.label)}
                    />
                  </Box>
                </div>
              </div>
            )}
            {dataItem.Type === 'Box Type3' && (
              <div>
                <Typography className="text-start">{dataItem.label}</Typography>
                <div className="flex gap-5 my-2">
                  <Box>
                    <AccessButton
                      data={dataItem.data}
                      label={dataItem.label}
                      inputId={dataItem.inputID}
                      formName="Additional Details"
                      required={dataItem.required}
                      userselectedData={userselectedData}

                      initial={getInitialValue(dataItem.label)}
                    />
                  </Box>
                </div>
                <div></div>
              </div>
            )}
            {dataItem.Type === 'Text Field' && (
              <div className="my-6">
                <TextBox
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Additional Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}

                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}
            {dataItem.Type === 'Price Text Field' && (
              <div className="my-6">
                <Typography className="text-start">{dataItem.label}</Typography>
                <PriceTextField
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Additional Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}

                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}
            {dataItem.Type === 'Price Text Field1' && (
              <div>
                <PriceTextField1
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Additional Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  required={dataItem.required}
                  userselectedData={userselectedData}

                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}
            {dataItem.Type === 'Price Text Field2' && (
              <div>
                <PriceTextField2
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Additional Details"
                  label2={dataItem.label2}
                  keyboard={dataItem.keyboard}
                  userselectedData={userselectedData}

                  required={dataItem.required}
                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}
            {dataItem.Type === 'Drop down' && (
              <div>
                <DropBox1
                  label={dataItem.label}
                  options={dataItem.data}
                  inputId={dataItem.inputID}
                  formName="Additional Details"
                  required={dataItem.required}
                  userselectedData={userselectedData}

                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}
            {dataItem.Type === 'FDrop down' && (
              <div>
                <FDropDown
                  label={dataItem.label}
                  options={dataItem.data}
                  inputId={dataItem.inputID}
                  formName="Additional Details"
                  required={dataItem.required}
                  userselectedData={userselectedData}

                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}
            {dataItem.Type === 'Drop down1' && (
              <div>
                <DropDown1
                  label={dataItem.label}
                  options={dataItem.data}
                  inputId={dataItem.inputID}
                  formName="Additional Details"
                  required={dataItem.required}
                  userselectedData={userselectedData}

                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}
            {dataItem.Type === 'calender' && (
              <div>
                <DatePick
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Additional Details"
                  required={dataItem.required}
                  userselectedData={userselectedData}

                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}
            {dataItem.Type === 'Text Area' && (
              <div>
                <Typography className="text-start">{dataItem.label}</Typography>
                <TextAreaBox
                  label={dataItem.label}
                  inputId={dataItem.inputID}
                  formName="Additional Details"
                  required={dataItem.required}
                  userselectedData={userselectedData}

                  initial={getInitialValue(dataItem.label)}
                />
              </div>
            )}

            {dataItem.Type === 'AMENITIES' && (
              <div>
                <Typography className="text-start mt-4 text-lg font-bold ">
                  {dataItem.label}
                </Typography>
                <div className="mt-1">
                  <MultiSelectorButton
                    data={dataItem.data}
                    label={dataItem.label}
                    inputId={dataItem.inputID}
                    formName="Additional Details"
                    required={dataItem.required}
                    userselectedData={userselectedData}

                    initial={getInitialValue(dataItem.label)}
                  />
                </div>
              </div>
            )}
          </div>
        )
      )}

<Box className=" w-full flex justify-center items-center">
   
   <button
     onClick={handleSubmit}
     className="w-[150px] bg-[#1063b5] text-white rounded-[20px]  mt-6 custom-xxl:ml-[60%] xl:ml-[60%] lg:ml-[65%] md:ml-[60%] sm:ml-[60%] p-2"
     >
     Continue
   </button>
   </Box>
    </div>
  );
};

export default AdditionalDetails;
