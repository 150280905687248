import { useParams } from "react-router-dom";
import jobs from "./jobs";
import about from "./about";

const JobDetails = () => {
  const { id } = useParams();
  const jobdetails = jobs.find((item) => item._id === id);

  return (
    <section className="p-6 md:p-8 lg:p-10 font-K2D text-[17px] text-[#404040] leading-relaxed">
      <div className="text-[#2E368F] text-xl md:text-2xl lg:text-3xl font-extrabold mb-6">
        {jobdetails?.jobTitle}
      </div>
      <div className="py-1 md:py-2 lg:py-4">
        <div className="text-lg md:text-xl lg:text-[22px] pb-4">
          {about.title}
        </div>
        <ul>
          {about.description.map((item, i) => (
            <li key={i} className="py-2 text-sm md:text-base lg:text-[19px]">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="py-4 text-sm md:text-base lg:text-[19px]">
        <div>
          <span className="font-semibold">Job Location : </span>
          {jobdetails?.location}
        </div>
        <div>
          <span className="font-semibold">Job Title : </span>
          {jobdetails?.jobTitle}
        </div>
        <div>
          <span className="font-semibold">Years of Experience : </span>
          {jobdetails?.experience}
        </div>
      </div>
      <div className="py-4 text-sm md:text-base lg:text-[19px]">
        <div className="text-lg md:text-xl lg:text-[22px] pb-2">
          Job Description :{" "}
        </div>
        {jobdetails?.description}
      </div>
      <div className="py-4 text-sm md:text-base lg:text-[19px]">
        <div className="text-lg md:text-xl lg:text-[22px] pb-2">
          What you’ll be responsible for :{" "}
        </div>
        <ul className="px-10">
          {jobdetails?.responsibilities?.map((item, i) => (
            <li key={i} className="list-disc">
              {item}
            </li>
          ))}
        </ul>
      </div>
      <button className="bg-[#FBB337] text-[#404040] text-[13px] md:text-sm lg:text-base rounded-full px-7 py-2">
        Apply Now
      </button>
    </section>
  );
};

export default JobDetails;
