export const getResponsiveSettings = (desktopItems:any, tabletItems:any, mobileItems:any) => ({
    desktop: {
      breakpoint: { max: 3000, min: 1000 },
      items: desktopItems,
      slidesToSlide: 1 // Move one image at a time
    },
    tablet: {
      breakpoint: { max: 1000, min: 464 },
      items: tabletItems,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: mobileItems,
      slidesToSlide: 1
    }
  });
  