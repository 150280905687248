import React, { useEffect, useState } from 'react';
import { useStepperContext } from './StepperContext';
import residentialSelectedIcon from '../../images/maki_residential-community.png';
import residentialDefaultIcon from '../../images/Vectorn.png';
import commercialSelectedIcon from '../../images/Vector.png';
import commercialDefaultIcon from '../../images/Vectorw.png';
import { Typography } from '@mui/material';

interface CustomisedButtonProps1 {
  title?: string;
  label: string;
  inputId: number;
  formName: string;
  id?: number;
  isActive?: boolean;
  onClick?: () => void;
  required: boolean;
  generateValidationSchema?: (data: any) => void;
  userselectedData?: any;
  data?: any;
  initial: any;
}

const CustomisedButton1: React.FC<CustomisedButtonProps1> = ({
  label,
  inputId,
  formName,
  data,
  required,
  generateValidationSchema,
  initial,
  userselectedData,
}) => {
  const { addUserData, userData } = useStepperContext();
  const [activeButton, setActiveButton] = useState<number | null>(null);

  useEffect(() => {
    const initValue = data.find((item: any) => item.title === initial);
    setActiveButton(initValue?.id || null);
  }, [initial, data]);

  const handleClick = (item: any) => {
    setActiveButton(item.id);

    const data = {
      id: inputId,
      [label]: item.title,
      formName: formName,
      required: required,
    };
    addUserData(data as any);
  };

  const getIcon = (title: string) => {
    switch (title) {
      case 'Residential':
        return activeButton === 1
          ? residentialSelectedIcon
          : residentialDefaultIcon;
      case 'Commercial':
        return activeButton === 2
          ? commercialDefaultIcon
          : commercialSelectedIcon;
      default:
        return null;
    }
  };

  const hasError = userselectedData?.includes(label);

  return (
    <div className="flex custom-xxl:gap-16 sm:gap-16 msm:gap-4 mt-2 ">
      {data.map((item: any) => (
        <div
          key={item.id}
          style={{fontSize:"15px"}}
          onClick={() => handleClick(item)}
          className={`custom-xxl:w-[200px] md:w-[200px] sm:w-[200px] msm:w-[130px]  p-2 rounded-full flex items-center justify-center  cursor-pointer
            ${
              activeButton === item.id
                ? 'bg-[#0CB7D1] text-white'
                : 'bg-[#F5F4F8] text-black'
            } 
            hover:${
              activeButton === item.id ? 'bg-[#0CB7D1]' : 'bg-blue-gray-100'
            }`}
        >
          {['Residential', 'Commercial'].includes(item.title) &&
            (() => {
              const iconSrc = getIcon(item.title);
              if (iconSrc) {
                return (
                  <img
                    src={iconSrc}
                    alt={item.title}
                    className="custom-xxl:w-6 custom-xxl:h-6  md:w-6 md:h-6  sm:w-6 sm:h-6 msm:w-4 msm:h-4  mr-2"
                  />
                );
              }
              return null;
            })()}
          {item.title}
        </div>
      ))}
      {/* {hasError && (
        <Typography className="text-[#FF3131]">
          This field is required
        </Typography>
      )} */}
    </div>
  );
};

export default CustomisedButton1;
