// ChatScreen.tsx
import React, { useState, useEffect } from 'react';
import ChatList from '../Chat/Chatlist'; // Adjust path as needed
import SingleChat from '../Chat/SingleChat'; // Adjust path as needed
import { useChat } from '../context/ChatContext'; // Adjust path as needed
import { useLocation } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import MNavbar from '../homepage/MNavber';
import { MdArrowBackIos, MdArrowBackIosNew, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";



const ChatScreen: React.FC = () => {
  const { selectedChat, setSelectedChat } = useChat(); // Import setSelectedChat
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [showChatList, setShowChatList] = useState(true);
  const navigate = useNavigate();

  const {
    chatId,
    userId,
    postId,
    username,
    userpic,
    initialMessageText,
    details,
  } = location.state || {};

  useEffect(() => {
    // Detect if screen size is mobile (sm)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480); // Tailwind's sm breakpoint (640px)
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // When a chat is selected, hide the chat list on mobile
  useEffect(() => {
    if (selectedChat && isMobile) {
      setShowChatList(false);
    }
  }, [selectedChat, isMobile]);

  const handleBackToChatList = () => {
    setShowChatList(true); // Show chat list when back is clicked
    setSelectedChat(null); // Clear selectedChat state

  };
  const handleBackToChatList2 = () => {
    navigate(-1);
    setSelectedChat(null); // Clear selectedChat state

  };
  return (
  
        <div className='h-full'>
           <div className=" flex flex-lg-row  lg:mx-[20%] lg:mt-[50px] sm:mt-[40px] gap-0 ">
 
      {/* Chat List Section */}
      <div
        className={`${(!selectedChat || showChatList || !isMobile) ? "block" : "hidden"
          }  border-gray-200 col-span-1 custom-xxl:w-[50%] msm:w-full lg:w-[50%] sm:w-[50%]   lg:rounded-tl-[20px]  lg:rounded-bl-[20px] bg-[#fff] shadow-md`}
      >
        <Box className="flex items-center p-[8px] border-b border-gray-300 w-full h-15 left-1 z-40 " 
      >
     
      <IconButton
                      onClick={()=>navigate(-1)}

    // onClick={handleBackToChatList2}  
         aria-label="Back"
           sx={{
             borderRadius: '50%', // Optional: for a circular button
             '&:hover': {
               backgroundColor: 'rgba(0, 0, 0, 0.08)', // Optional: change background on hover
             },
           }}
         >
           <MdArrowBackIos className='w-8 h-7' />

         </IconButton>
         <div className='font-bold text-lg'>Messages</div>
       </Box> 
       <ChatList />
       <div className={`lg:hidden sm:hidden msm:flex fixed bottom-1  z-50`}>
      <MNavbar />
      </div>
      </div>

      {/* Single Chat Section */}
      <div
        className={`${(!showChatList || !isMobile) ? "block" : "hidden"
          } chat-content custom-xxl:col-span-2 xl:col-span-2 lg:col-span-3 custom-xxl:w-[50%] lg:w-[50%] msm:w-full sm:w-full md:col-span-3 sm:col-span-3 lg:rounded-tr-[20px]  lg:rounded-br-[20px] bg-white shadow-md`}
      >          
        {selectedChat ? (
          <>
            {/* {isMobile && (
              <div>
                <IconButton
                 onClick={handleBackToChatList}
                 >
                  <ArrowBackIcon className="ml-4 text-black" />{' '}
                  <h2 className="ml-4 text-black">ChatList</h2>
                </IconButton>
              </div>
            )} */}
            <SingleChat
              chat={selectedChat}
              initialMessageText={initialMessageText}
              details={details}
              setShowChatList={setShowChatList}
            />
          </>
        ) : (
          <div className="flex items-center justify-center text-gray-500 h-full">
            Select a chat to start messaging
          </div>
        )}
      </div>
    
    </div> </div> 
   
  );
};

export default ChatScreen;
