const jobs = [
  {
    _id: "001",
    jobTitle: "Executive/Senior Executive – 3D visualizer",
    location: "Chennai",
    experience: "1 - 7 years",
    postedOn: "Posted last week",
    description:
      "As an Account Executive in the USA, your primary focus will be on winning B2B deals. You will be responsible for proactively identifying and nurturing new opportunities with both prospects and customers, with the ultimate goal of closing deals and hitting revenue targets. Your attention will be laser-focused on quarter-over-quarter forecasting of the deal pipeline, growth of closed business, pipeline growth, and customer buying strategies.",
    responsibilities: [
      "Focus on new business growth and development by working with prospects/customers to increase their revenue commitment to Xerago through upsells/cross sells and service offerings.",
      "Manage and close a quarterly pipeline of growth for a key set of accounts, including outbound calling, qualifying opportunities, identifying upsells/cross-sells and services, and managing contracts from creation to close.",
      "Provide transparency and visibility into an accurate pipeline and manage it effectively.",
      "Exceed quarterly and annual revenue targets consistently.",
      "Work closely with potential customers to understand their business objectives and develop customized solutions to meet their needs.",
    ],
  },
  {
    _id: "002",
    jobTitle: "Executive/Senior Executive – 3D visualizer",
    location: "Chennai",
    experience: "1 - 7 years",
    postedOn: "Posted last week",
  },
  {
    _id: "003",
    jobTitle: "Executive/Senior Executive – 3D visualizer",
    location: "Chennai",
    experience: "1 - 7 years",
    postedOn: "Posted last week",
  },
  {
    _id: "004",
    jobTitle: "Executive/Senior Executive – 3D visualizer",
    location: "Chennai",
    experience: "1 - 7 years",
    postedOn: "Posted last week",
  },
  {
    _id: "005",
    jobTitle: "Executive/Senior Executive – 3D visualizer",
    location: "Chennai",
    experience: "1 - 7 years",
    postedOn: "Posted last week",
  },
  {
    _id: "006",
    jobTitle: "Executive/Senior Executive – 3D visualizer",
    location: "Chennai",
    experience: "1 - 7 years",
    postedOn: "Posted last week",
  },
  {
    _id: "007",
    jobTitle: "Executive/Senior Executive – 3D visualizer",
    location: "Chennai",
    experience: "1 - 7 years",
    postedOn: "Posted last week",
  },
  {
    _id: "001",
    jobTitle: "Executive/Senior Executive – 3D visualizer",
    location: "Chennai",
    experience: "1 - 7 years",
    postedOn: "Posted last week",
  },
  {
    _id: "001",
    jobTitle: "Executive/Senior Executive – 3D visualizer",
    location: "Chennai",
    experience: "1 - 7 years",
    postedOn: "Posted last week",
  },
  {
    _id: "001",
    jobTitle: "Executive/Senior Executive – 3D visualizer",
    location: "Chennai",
    experience: "1 - 7 years",
    postedOn: "Posted last week",
  },
  {
    _id: "001",
    jobTitle: "Executive/Senior Executive – 3D visualizer",
    location: "Chennai",
    experience: "1 - 7 years",
    postedOn: "Posted last week",
  },
  {
    _id: "001",
    jobTitle: "Executive/Senior Executive – 3D visualizer",
    location: "Chennai",
    experience: "1 - 7 years",
    postedOn: "Posted last week",
  },
  {
    _id: "001",
    jobTitle: "Executive/Senior Executive – 3D visualizer",
    location: "Chennai",
    experience: "1 - 7 years",
    postedOn: "Posted last week",
  },
  {
    _id: "001",
    jobTitle: "Executive/Senior Executive – 3D visualizer",
    location: "Chennai",
    experience: "1 - 7 years",
    postedOn: "Posted last week",
  },
  {
    _id: "001",
    jobTitle: "Executive/Senior Executive – 3D visualizer",
    location: "Chennai",
    experience: "1 - 7 years",
    postedOn: "Posted last week",
  },
];

export default jobs;
