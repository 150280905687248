import { Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Interface } from 'readline'
import { useStepperContext } from './StepperContext'
import { blueGrey } from '@mui/material/colors';
//customboxtype

interface CustomButtonProps{
label:string,
inputId?:number,
formName:string,
title?:string,
required?:boolean,
userselectedData?:any,
data:any,
initial:any

}

const CustomButton:React.FC<CustomButtonProps> = ({label,inputId,formName,title,data,required,userselectedData,initial}) => {

    const{addUserData,userData}=useStepperContext()
    const [activeButton, setActiveButton] = useState<number | null>(null); // State to track the active button id

     useEffect(() => {
    const initValue = data.find((item:any) => (item.title === initial))
    setActiveButton(initValue?.id||null)
  })
    const handleClick=(item:any)=>{
        setActiveButton(item.id)

        const data={
            id:inputId,
            [label]:item.title,
            formName:formName,
            required:required

        }
        addUserData(data as any)
    }
    console.log(userData)
    const hasError=userselectedData?.includes(label)
  return (
    <div className='flex gap-5 '>

       {data.map((item:any)=>( <Button 
       
       className='h-10'
       onClick={()=>handleClick(item)}  sx={{borderRadius:"999px",padding:"20px", bgcolor: activeButton===item.id ? '#0cb7d1' : blueGrey[50], // Active or default background color
          color: activeButton===item.id ? 'white' : 'black', // Text color
          textTransform: 'capitalize', // Capitalize button text
          '&:hover': {
            bgcolor: activeButton===item.id ? '#0cb7d1' : blueGrey[100], // Hover background color
          },}}>
            {item.title}


        </Button>
        ))
}
{hasError && <Typography sx={{ fontSize:"12px" ,color:"#f44336",marginLeft:"20px",marginTop:'5px'}}>
          *This field is required
        </Typography>}

    </div>
  )
}

export default CustomButton

//box2