
import React, { useEffect, useState, useRef } from "react";
import search from '../images/Search.svg';
import { Box, Button, Grow, Snackbar, Alert, Typography, Chip, Dialog, DialogTitle, DialogContent, DialogActions, Modal, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useDispatch } from 'react-redux';
import { setUsers } from '../../slices/filterSlice';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { axiosInstance } from '../api/APIs';
import { districts, capitalizeWords, filterData } from '../data';
import Filter from "../filter/Filter";
import Filtermini from "../filter/Filtermini";
import CancelIcon from '@mui/icons-material/Cancel';
import { useChat } from "../context/ChatContext";

import { IoSearch } from "react-icons/io5";




interface Props {
  setLookingToValue: any;
  setClook: any;
  setdisValue:any
}

const Navbar: React.FC<Props> = ({ setLookingToValue, setClook,setdisValue }) => {
  const [location, setLocation] = useState<string>("Vellore");
  const [cityVisible, setCityVisible] = useState<boolean>(false);
  const [selectedLook, setSelectedLook] = useState<string | null>("Buy");
  const [searchCity, setSearchCity] = useState<string>("");
  const [subCategory, setSubCategory] = useState<string | null>("Buy");
  const [selectedProperties, setSelectedProperties] = useState<any[]>([]);
  const [autocompleteValue, setAutocompleteValue] = useState<any>(null);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]); 
  const [error, setError] = useState<string | null>(""); 
  const [loading, setLoading] = useState<boolean>(false)
  const [filterProperty, setFilterProperty] = useState<string[]>([]);
  const [filterLoaction, setfilterLoaction] = useState<any[]>([])
  const [filterPrice, setFilterPrice] = useState<any>()
  const [kb, setKb] = useState<any>(false)
  const { filterDisValue, setFilterDisValue } = useChat()
  const [selectedCommercial, setSelectedCommercial] = useState<any>([]);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    // setIsModalOpen(false);
    navigate('/Property')
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  const lookData = filterData[0].look;

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [error]);
  const growRef = useRef<HTMLDivElement | null>(null); 

  useEffect(() => {
    setLocation(location);
    setFilterDisValue(location)
    
    const handleClickOutside = (event: MouseEvent) => {
      if (growRef.current && !growRef.current.contains(event.target as Node)) {
        setCityVisible(false); 
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  const handleLocationChange = (value: any) => {
    setCityVisible(false);
    setLocation(value);
    setdisValue(value)
    setFilterDisValue(value)
    setSelectedLocations([]);

  };
  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
    // setSortOpen(false)

  }
  const handleChangeLook = (value: string) => {
    setSelectedLook(value);
    setLookingToValue(value);
    setSelectedCommercial([])
    console.log('navbarvalue',selectedCommercial)
  };

  const handleChangeSub = (value: string) => {
    setSubCategory(value);
    setClook(value);
   
  };

  const handleSearchCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCity(event.target.value);
  };

  function handleClose() {
    setIsModalOpen(false);
  }

  const handleCommercialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedCommercial((prevState:any) => (prevState.includes(value) ? prevState.filter((item:any) => item !== value) : [...prevState, value]));
   
  };

  const handleApply = async () => {
   

    const cityCap = capitalizeWords(searchCity);

    
    const selectedLocationString = [...selectedLocations.map(location => location.label)];
    const body: any = {
      district: location,
      city: selectedLocationString, 
      property_type: 
      selectedCommercial.length === 0
        ? (selectedLook === "Commercial"
          ? ["Showroom", "Office Space", "Retail Shop", "Industrial Space", "Warehouse"]
          : (selectedLook === "Buy" || selectedLook === "Rent" || selectedLook === "Lease"
            ? ["Apartment", "Plot / Land", "Villa", "Independent House"]
            : selectedProperties))
        : selectedCommercial,
      looking_to: selectedLook === "Commercial" ? subCategory : selectedLook,
      maxAmount: 1000000000,
      user_id: loginData.user_id,
      kbl_property: false,
      minAmount: 0,
      min_plot_area: 0,
      max_ploat_area: 500000,
      ready_to_move: 0,
      facing: ["North", "South", "West", "East"],
     
    };


    console.log('Request body sent to API:', body); 

    const url = selectedLook === "Commercial" ? "/filter/commercialFilter" : "/filter/resbuyfilterData";

    try {
      const response = await axiosInstance.post(url, body);
      dispatch(setUsers(response.data.message));
      navigate('/Property');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleBoxClick = () => {
    setCityVisible(!cityVisible);
  };

  const handleSelect = async (place: any) => {
    console.log("naga Selected place:", place); 

    try {
      const address = place?.value?.structured_formatting?.main_text;
      const results = await geocodeByAddress(address);
      const { lat, lng } = await getLatLng(results[0]);

      const addressComponents = results[0].address_components;
      const isWithinDistrict = addressComponents.some(component =>
        component.long_name.toLowerCase() === location.toLowerCase()
      );

      if (isWithinDistrict) {
       
        if (selectedLocations.length < 3) {
          setSelectedLocations(prev => [
            ...prev,
            {
              label: place.value.structured_formatting.main_text,
              place_id: place.value.place_id,
            }
          ]);
          // setAutocompleteValue(place);
          setSearchCity(address);

          setError(""); 
          console.log('Coordinates:', { lat, lng });
        } else {
          setError("You can only select up to 3 locations."); 
        }
      } else {
        setError('Selected locality is not within the selected district.');
      }
    } catch (error) {
      console.error('Error fetching location details:', error);
      setError('Error fetching location details');
    }
  };

  const handleDeleteLocation = (placeId: string) => {
    setSelectedLocations(prev => prev.filter(location => location.place_id !== placeId));
  };
  const isMobile = window.innerWidth <= 768; 


  function handlefilter() {
    navigate('/Property')
  }
  return (
    <div>
      <div className="sm:block msm:hidden">
        <Box sx={{ marginTop: "1%" }} >
          <Box sx={{ display: "flex", flexDirection: "row", width: "100%", height: { sm: "55vh", md: "80vh", lg: "80vh" } }}>
            <Box sx={{
              bottom: 0, width: "100%", display: "flex", justifyContent: "flex-end", flexDirection: "column",
              background: 'linear-gradient(90deg, #E2EFF6, #E7ECEF)', position: "relative",
            }}>
              <img src="https://vattara-img.s3.amazonaws.com/Banner_BG.jpg"
                alt="Building" style={{ width: "100%", height: "100%" }} />
            </Box>

            <Box sx={{ position: "absolute", width: "100%", top: "20%" }}>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <div className="h-[100px] ml-0 mb-7 w-[65%] flex flex-col justify-start sm:ml-[10px] lg:ml-0 custom-xl:ml-[20%]">
                  <Typography sx={{ fontSize: { xs: "15px", sm: "25px", md: "28px", lg: "28px" }, fontWeight: 500, textTransform: "uppercase", lineHeight: 1, fontFamily: "k2d" }}>
                    Your new Guide to get a
                  </Typography>
                  <Typography sx={{ fontSize: { xs: "25px", sm: "35px", md: "50px", lg: "50px" }, fontWeight: 900, textTransform: "uppercase", color: "#1063b5", lineHeight: 1 }}>
                    Comfortable Home
                  </Typography>
                </div>
              </Box>

              <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <div
                  className="h-10 w-full sm:w-[98%] md:w-[95%] lg:w-[65%] xl:w-[65%] custom-xl:w-[50%] custom-xxl:w-[45%] flex justify-start"
                >
                  <Box sx={{ backgroundColor: "#fff", borderRadius: '10px 10px 0 0' }}>
                    {lookData.map((item) => (
                      <Button
                        variant="text"
                        onClick={() => handleChangeLook(item.button)}
                        sx={{
                          padding: '6px 24px',
                          borderRadius: '10px 10px 0 0',
                          fontWeight: "600",
                          fontFamily: "k2d",
                          transition: "all 0.5s ease-in-out",
                          fontSize: { lg: "16.5px", sm: "12px", },
                          borderBottom: selectedLook === item.button
                            ? '4px solid #0cb7d1'
                            : '4px solid transparent',
                          backgroundColor: selectedLook === item.button ? '#Fff' : '#FFFFFF',
                          color: selectedLook === item.button ? '#000' : '#000000',
                          boxShadow: 'none',
                          textTransform: 'none'
                        }}
                      >
                        {item.button}
                      </Button>
                    ))}
                  </Box>
                  <Box sx={{ ml: 10, gap: 2, display: selectedLook === "Commercial" ? "flex" : "none", backgroundColor: "#fff", height: "30px", mt: 2, borderRadius: '10px 10px 0 0', }}>
                    {filterData[0].SubCategory.map((item) => (
                      <Box key={item.id}>
                        <Button
                          variant="text"
                          onClick={() => handleChangeSub(item.button)}
                          sx={{
                            padding: '6px 20px',
                            borderRadius: '10px 10px 0 0',

                            fontWeight: "600",
                            height: "30px",

                            fontFamily: "k2d",
                            transition: "all 0.5s ease-in-out",
                            // fontSize: "18px",
                            fontSize: { xs: "15px", sm: "15px", md: "18px", lg: "18px" },
                            // borderBottom: subCategory === item.button
                            //   ? '4px solid #0cb7d1 '
                            //   : '4px solid transparent',
                            backgroundColor: subCategory === item.button ? '#Fff' : '#FFFFFF',
                            color: subCategory === item.button ? '#000' : '#000000',
                            boxShadow: 'none',
                            textTransform: 'none'

                          }}
                        >

                          <input
                            type="radio"
                            id={item.button} 
                            name="subCategory" 
                            value={item.button}
                            checked={subCategory === item.button} 
                            onChange={() => handleChangeSub(item.button)}
                            style={{ marginRight: '8px' }} 
                          />  {item.button}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </div>
              </Box>
             
              <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <div className="bg-white h-[180px] w-full sm:w-[98%] md:w-[95%] lg:w-[65%] xl:w-[65%] custom-xl:w-[50%] custom-xxl:w-[45%]  flex flex-col justify-center items-center rounded-b-[15px]  rounded-tr-[15px]">

                <div className=" w-[100%] flex justify-center cursor-pointer  bg-black">
          
              </div>

              <div className=" cursor-pointer w-full sm:w-[98%] md:w-[95%] lg:w-[95%] xl:w-[85%] custom-xl:w-[80%] custom-xxl:w-[85%] flex justify-center -mt-6  mb-4">
                  {
                    selectedLook === "Commercial" ? (
                    <div className="flex justify-start gap-5 items-center  bg-white cursor-pointer">
                      {filterData[0].propertyCommercial.map((item: any) => (
                        <div key={item.id} className="flex items-center ">
                        <input
                          type="checkbox"
                          id={`commercial-${item.id}`}
                          value={item.value}
                          checked={selectedCommercial.includes(item.value)}
                          onChange={handleCommercialChange}
                          className="mr-2 cursor-pointer"
                        />
                        <label htmlFor={`commercial-${item.id}`} className="sm:text-[15px] lg:text-[16px] font-medium cursor-pointer">
                          {item.value}
                        </label>
                      </div>
                      ))}
                    </div>
                    ) : (<div className="flex gap-5 justify-start items-center  bg-white">
                      {filterData[0].property.map((item: any) => (
                        <div key={item.id} className="flex items-center">
                        <input
                          type="checkbox"
                          id={`commercial-${item.id}`}
                          value={item.value}
                          checked={selectedCommercial.includes(item.value)}
                          onChange={handleCommercialChange}
                          className="mr-2 cursor-pointer"
                        />
                        <label htmlFor={`commercial-${item.id}`} className="sm:text-[15px] lg:text-[16px] font-medium cursor-pointer">
                          {item.value}
                        </label>
                      </div>
                      ))}
                    </div>)
                  }
                </div>

                  <Box sx={{ height: "38%" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", }}>
                      <Box
                        sx={{
                          justifyContent: "center",
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "#FAFAFA",
                          height: "50px",
                          alignItems: "center",
                          padding: "15px",

                          borderRadius: "42px 0 0 42px",
                          cursor: "pointer"
                        }}
                        onClick={handleBoxClick}
                      >
                        <Typography
                          sx={{
                            color: "#000",
                            fontWeight: "600",
                            fontFamily: "k2d",
                            fontSize: { xs: "16px", sm: "18px", lg: "20px" }, // Adjust font size based on screen size
                          }}
                        >
                          {location}                        </Typography>
                        <Box>
                          {cityVisible ? <KeyboardArrowUpIcon sx={{ color: "#000", fontSize: "inherit" }} /> : <KeyboardArrowDownIcon sx={{ color: "#000", fontSize: "inherit" }} />}

                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          // gap: 2,
                          // marginLeft: "20px",
                          // width:"1200px", // Full width on mobile, fixed width on larger screens
                        }}
                      >
                        <Box
                          className="msm:w-[500px] sm:w-[400px] md:w-[420px] lg:w-[480px] xl:w-[600px] custom-lg:w-[700px] custom-xl:w-[800px]"
                        // sx={{
                        //   width: { xs: "500px", sm: "400px", md: "550px", lg: "450px", xl: "650px" }, // Full width on mobile, fixed width on larger screens
                        // }}
                        >                    <GooglePlacesAutocomplete
                            apiKey={process.env.AIzaSyDz2gppwqEkyckilXFDJkvgEtcoS2OEvE}

                            autocompletionRequest={{
                              componentRestrictions: { country: ['in'] },
                            }}
                            selectProps={{
                              styles: {
                                control: (base: any) => ({
                                  ...base,
                                  border: 'none',
                                  width: "100%",
                                  // width: isMobile ? '100%' : 500, // 500px for desktop, 100% for mobile

                                  cursor: "pointer",
                                  boxShadow: 'none',
                                  '&:hover': { border: 'none' },
                                }),
                                option: (base) => ({
                                  ...base,
                                  cursor: 'pointer',
                                  // marginTop:"80px"

                                  // backgroundColor:"red"
                                }),
                              },
                              value: autocompleteValue,
                              onChange: handleSelect,
                              placeholder: 'Search upto 3 localities...',
                              isClearable: true,
                              formatOptionLabel: (option: any) => option.label || option.description // Show the main_text in the select
                            }}
                            debounce={700}
                          /></Box>

                        <Button
                          sx={{
                            backgroundColor: "#1063b5",

                            color: "#fff",
                            width: "150px",
                            height: "50px",
                            fontFamily: "K2D",
                            fontSize: { xs: "16px", sm: "18px", lg: "20px" }, // Adjust font size based on screen size
                            textTransform: "capitalize",
                            borderRadius: "0 42px 42px 0",
                            ':hover': {
                              backgroundColor: "#02a7e4",

                            }
                          }}
                          onClick={handleApply}
                        >
                                       <img className="h-5 w-5 mr-2" src={search} alt="Search Icon" />
                                       Search
                        </Button>
                        {error && (
                          <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError("")}>
                            <Alert onClose={() => setError("")} severity="error">{error}</Alert>
                          </Snackbar>
                        )}
                      </Box>
                    </Box>

                   
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', }}>
                      {selectedLocations.map((location) => (
                        <Chip
                          key={location.place_id}
                          label={location.label}
                          onDelete={() => handleDeleteLocation(location.place_id)}
                          sx={{ margin: '4px' }}
                        />
                      ))}
                    </Box>

                    <div style={{ height: '60px' }}>
                      <Grow in={cityVisible}>
                        <div ref={growRef} 
                         className="z-30 custom-scrollbar"
                         style={{
                            position: "absolute",
                            height: '200px',
                            // left: '40%',
                            // top: '45%',
                            borderWidth: "1px",
                            borderRadius: "10px",
                            overflowY: 'scroll',
                            backgroundColor: 'white',
                            paddingLeft: 30, display: 'flex',
                            width: "300px",
                            flexDirection: 'column',
                            gap: '8px',
                            transition: "all 0.5s ease-in-out"
                          }}>
                          {districts.map((item) => (
                            <div key={item} onClick={() => handleLocationChange(item)} style={{ color: 'black', padding: '8px 0' }}>
                              {item}
                            </div>
                          ))}
                        </div>
                      </Grow>
                    </div>
                  </Box>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>

      <div className="sm:hidden msm:block mt-[15%] mb-[3%]">
        <p className="text-black font-bold text-center mt-2">Innovate | Elevate | Reside</p>
        <div className="bg-[#E2E6F2] rounded-3xl p-2 mx-1 mt-2">
          <div className="flex gap-3 justify-center items-center mt-1 mb-2">
            {lookData
              .filter(item => item.button !== 'Lease') 
              .map((item) => (
                <div className={`py-2 px-7 rounded-3xl justify-center items-center ${selectedLook === item.button ? 'bg-[#1063b5] text-white' : "bg-[#F5F4F8] text-black"} flex`}>
                  <button onClick={() => handleChangeLook(item.button)}>{item.button}</button>
                </div>
              ))}
          </div>
          <div className="flex relative mt-2">
            <input
              placeholder="Search up to 3 locations"
              className="w-full bg-[#F5F4F8] rounded-3xl h-14 placeholder:text-gray-500 placeholder:ml-4 px-6 py-3 focus:outline-none "
              readOnly

              // value={searchCity}
              onClick={handleOpenModal} // This will now work
            />
            <div onClick={handleOpenModal} className="bg-[#1063b5] absolute top-1 h-12 right-2 px-4 rounded-3xl flex items-center justify-center cursor-pointer">
              <img className="h-5 w-5" src={search} alt="Search Icon" />
            </div>
          </div>
          <div>
          
            <Modal
              open={isModalOpen} onClose={handleClose}
              aria-labelledby="contact-modal-title"
              aria-describedby="contact-modal-description"
            >
              <div >
               
                <Filtermini setLoading={setLoading} handleClose={handleClose} toggleFilterVisibility={handleCloseModal} kb={kb} setFilterProperty={setFilterProperty} setfilterLoaction={setfilterLoaction} setFilterPrice={setFilterPrice} />
              </div>
            </Modal>

          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Navbar;
