
import React, { useEffect, useState } from 'react'
import { useStepperContext } from './StepperContext';
import { Alert, Box, Button, FormControl, InputAdornment, InputLabel, MenuItem, Modal, NativeSelect, Select, SelectChangeEvent, Snackbar, TextField, Typography } from '@mui/material';
import Map from './Map';
import { updateValueInArray } from '../../PostForm/component';
interface TextFieldProps {
  //  title:string;
  label: string;
  //  placeholder:string; 
  formName: string;
  inputId?: number,
  label2?: string,
  keyboard?: any,
  required?: boolean
  userselectedData?: any
  initial: any
  baseData: any
  setDataB: any
}
const TextBox: React.FC<TextFieldProps> = ({ label, formName, inputId, label2, keyboard, required, userselectedData, initial, baseData, setDataB }) => {
  const { addUserData, userData, addLocation, location, sublocality } = useStepperContext()
  const [MapVisible, setMapVisible] = useState(false)
  const [open, setOpen] = useState(false)
  const handleClose = () => setMapVisible(false);
  const [selectedValue, setSelectedValue] = useState(initial);
  const measureIn = baseData.find((item: any) => item?.hasOwnProperty('measure'))?.measure;
  console.log("measure", measureIn)
  const [measures, setMeasures] = useState<any>(measureIn)
  const mydistrict = baseData?.find((item: any) => item.hasOwnProperty('City'))?.['City'];
  const latitude = Number(baseData?.find((item: any) => item.hasOwnProperty("latitude"))?.["latitude"])
  const longitude = Number(baseData?.find((item: any) => item.hasOwnProperty("longitude"))?.["longitude"])
  const built_up_area = baseData?.find((item: any) => item.hasOwnProperty('Plot Area'))?.['Plot Area']
  const [subloaction, setSubLoaction] = useState<any>("")
  const property_type = baseData.find((item: any) => item?.hasOwnProperty('property_type'))?.property_type
  const look_to = baseData.find((item: any) => item?.hasOwnProperty('look_to'))?.['look_to']

  // const Calculated =
  //   (property_type === 'Plot / Land' && measures === 'Cent') || (property_type === 'Industrial Space' && measures === 'Cent')
  //     ? built_up_area * 435
  //     : (property_type === 'Plot / Land' && measures === 'Acre') || (property_type === 'Industrial Space' && measures === 'Acre')
  //       ? built_up_area * 43500
  //       : built_up_area;


  // String(Calculated)



  // console.log("cal", Calculated, property_type, measures)
  //console.log("subbb", subloaction, label) 
  useEffect(() => {
    addLocation({
      latitude: latitude,
      longitude: longitude
    })
  }, [latitude, longitude])



  const handleMap = () => {
    if (mydistrict !== undefined) {
      setMapVisible(true)
    } else {
      setOpen(true)
    }
  }
  const handleChange = (value: string) => {
    setSelectedValue(value);
    const data = {
      id: inputId,
      [label]: value,
      formName: formName,
    }
    const measured = {
      id: 785156,
      measure: measures,
      formName: formName
    }



    const updatedDa = updateValueInArray(baseData, "longitude", location.longitude);
    console.log("new", updatedDa)
    setDataB(updatedDa)

    const updatedDat = updateValueInArray(updatedDa, "latitude", location.latitude);
    console.log("new", updatedDat)
    setDataB(updatedDat)

    const update = updateValueInArray(updatedDat, "sublocality", sublocality)
    setDataB(update)
    console.log("helloup", update)
    const slabel = label ==="Plot Area"? "Built up Area":label
    // const actvalue = look_to === "Sale" && (property_type == "Plot / Land" || property_type == 'Industrial Space') ? Calculated : value
    const updatedData = updateValueInArray(update, slabel, value);
    console.log("newyyy", updatedData)
    setDataB(updatedData)

    console.log("bil", sublocality)

   


    addUserData(data as any)
    addUserData(measured as any)
  }

 

  const hasError = userselectedData?.includes(label)

  const handleMeasure = (event: SelectChangeEvent) => {
    setMeasures(event.target.value);
    const measured = {
      id: 785156,
      measure: event.target.value,
      formName: formName
    }
    addUserData(measured as any)
    const updated = updateValueInArray(baseData, "measure", event.target.value)
    setDataB(updated)
    console.log("newyyy", updated)
  };

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={2000} // Duration in milliseconds (5 seconds)
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="error"
          sx={{ width: '100%' }}
        >
          Select Your City/Town
          </Alert>
      </Snackbar>
      <TextField         variant="filled"

      id={label} placeholder={label}
        value={selectedValue}
        type={keyboard ? keyboard : "text"}
           size="small"
        label={label} onChange={(e) => handleChange(e.target.value)} className='w-[100%]'
        sx={{ width: "100%",            borderRadius: "999px",
          '& .MuiFilledInput-underline:before': {
                    borderBottom: '0px solid transparent', // Dotted underline
                  },
                  '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: '0px solid transparent', // Dotted underline
                  }, }}
         InputProps={{
          style: {
            borderRadius: "999px",
            

fontFamily:"K2D",            backgroundColor: "#F5F4F8",
          },
          endAdornment: (
            <InputAdornment position="end">
              {label === "Locality" ? (
                <Button onClick={handleMap} variant="contained" size="small"                     sx={{ ml: 1,fontFamily:"K2D", backgroundColor:"#0CB7D1",borderRadius:"10px" }}
>
                  Choose Location
                </Button>
              ) : (label === "Plot Area") ? (
                <>
                  <Box sx={{ minWidth: 65 }}>
                    <FormControl fullWidth>

                      <Select
                        value={measures}
                        variant="standard"
                        onChange={handleMeasure}
                        sx={{ minWidth: 70 }}
                      >
                        <MenuItem value={"Sq.ft"}>Sq.ft</MenuItem>
                        <MenuItem value={"Cent"}>Cent</MenuItem>
                        <MenuItem value={"Acre"}>Acre</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </>
              ) : (
                label2
              )}
            </InputAdornment>
          ),
        }}>
      </TextField>

      <div>

        <Modal
          open={MapVisible}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}

          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ bgcolor: "#fff", justifyContent: "center", alignItems: "center", width: { md: "80%", lg: "50%", xs: "95%" }, p: 3 }}>
            <Map onClose={handleClose} setSubLoaction={setSubLoaction} formName={formName} onLocationSelect={handleChange} baseData={baseData} />
          </Box>
        </Modal>
      </div>


      {hasError && <Typography sx={{ color: "#FF3131" }}>This field is required</Typography>}


    </div>
  )
}

export default TextBox