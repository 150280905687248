
import React, { useEffect, useState } from 'react'
import filter from '../../assets/filterBlack.svg'
import sort from '../../assets/sortBlack.svg'
import cancel from '../../assets/cancelBlack.svg'
import exclusive from '../../compounts/images/exclusive.png'
import { axiosInstance } from "../api/APIs";
import { useSelector } from "react-redux";
import heart from "../../compounts/images/Heart.png";
import LikedHeart from "../images/LikedHeart.png";
import loaction from '../../compounts/images/location.png'
import { useDispatch } from 'react-redux';
import { setUsers } from '../../slices/filterSlice';
import SortBy from '../sortBy/SortBy'
import Filter from '../filter/Filter'
import locaation from '../../compounts/images/location.png';
import { Box, CircularProgress, Grid,} from "@mui/material";
import { MdArrowBackIosNew } from "react-icons/md";

import { useLocation } from 'react-router-dom'
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import { log } from 'util'
import { truncateString } from "../ReuseableCompounts/TruncateString";
import star from '../images/star.svg'
import goldtick from '../images/goldtick.png'
import { formatSQPrice } from "../ReuseableCompounts/PriceSQFormat";
import { handleChange, handleLinkClick } from '../ReuseableCompounts/PostDetail';

import { useNavigate } from "react-router-dom";
import DefaultScroll from '../../compounts/images/defaultScroll.png';
import Footer from "../../compounts/Footer/Footer";

import { IconButton,} from '@mui/material';


interface Image {
    id?: string;
    isCover?: boolean;
    isvisible?: boolean;
    name?: string;
    type?: any
    uri?: string;
}

interface Property {
    sublocality: string;
    id: number;
    post_id: number;
    property_type?: any;
    looking_to?: string;
    rent_or_lease?: string;
    property_available_for?: string;
    images?: Image[];
    view_post_count: number;
    bhk: string;
    city: string;
    building_name: string;
    landmark_street: string;
    build_up_area?: string;
    built_up_area?: string;
    plot_area?: string;
    PriceperSqft?: string;
    price: number;
    cost?: number;
    monthly_rent?: number;
    enter_lease_amount?: number;
    lease_amount?: any
    liked?: any
    user_id?: any
    district?: string;
    kbl_property: any

}


const VattaraExclusive = () => {

    const [loading,setLoading]=useState<boolean>(false)
    const loginData = useSelector((item: any) => (item.userData.userLoginData))
    const navigate = useNavigate(

    )
    const [sortedUsers, setSortedUsers] = useState<Property[]>([]);
    const dispatch = useDispatch();
    const users = useSelector((state: any) => state.usersInfo.users);
    const [value, setValue] = useState<any>()
    const [receivedValue, setReceivedValue] = useState<any>(value);
    const [likedState, setLikedState] = useState<Record<number, boolean>>({});
    const [filterProperty, setFilterProperty] = useState<string[]>([]);
    const [filterLoaction, setfilterLoaction] = useState<any[]>([])
    const [filterPrice, setFilterPrice] = useState<any>()
    const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);
    const [sortOpen, setSortOpen] = useState<any>(false)
    const [kb, setKb] = useState<any>(true)
    const location = useLocation()
    const projects = location.state && location.state.data;
    const [loadingCardId, setLoadingCardId] = useState<number | null>(null); // Track loading card ID

    // console.log("get", projects);

    console.log(users);


    useEffect(() => {
        const fetchData = async () => {
            try {
              
                const response = await axiosInstance.get(`/api/vattara/app/ResidentailSell/Property/${loginData.user_id}`);
                console.log(response.data.message);

                const rows = response.data.message;

                dispatch(setUsers(rows));

            } catch (error) {

                console.error('Error fetching data:', error);
            }
        };
        fetchData()
    }, []);

    useEffect(() => {
        setSortedUsers([...users]); // Initialize sortedUsers with users array
        // setCurrentIndexList(Array(users.length).fill(0));

        const initialLikedState = users.reduce((acc: Record<number, boolean>, user: Property) => {
            acc[user.post_id] = user.liked || false;
            return acc;
        }, {});
        setLikedState(initialLikedState);
    }, [users]);


        useEffect(() => {
        const handleChildData = () => {




            if (value === "Price (Low to High)") {
                const sortedItems = [...users].sort((a, b) => {
                    const costA = parseInt(
                        String(
                            a.cost || a.price || (a.monthly_rent == null || a.monthly_rent == "" ? a.lease_amount || a.enter_lease_amount : a.monthly_rent)
                        ).replace(/[^0-9.-]+/g, ""),
                        10
                    );
                    const costB = parseInt(
                        String(
                            b.cost || b.price || (b.monthly_rent == null || b.monthly_rent == "" ? b.lease_amount || b.enter_lease_amount : b.monthly_rent)
                        ).replace(/[^0-9.-]+/g, ""),
                        10
                    );

                    return costA - costB || 0;
                });
                setSortedUsers(sortedItems);

            } else if (value === "Price (High to Low)") {
                const sortedItems = [...users].sort((a, b) => {
                    const costA = parseInt(
                        String(
                            a.cost || a.price || (a.monthly_rent == null || a.monthly_rent == "" ? a.lease_amount || a.enter_lease_amount : a.monthly_rent)
                        ).replace(/[^0-9.-]+/g, ""),
                        10
                    );
                    const costB = parseInt(
                        String(
                            b.cost || b.price || (b.monthly_rent == null || b.monthly_rent == "" ? b.lease_amount || b.enter_lease_amount : b.monthly_rent)
                        ).replace(/[^0-9.-]+/g, ""),
                        10
                    );

                    // Reverse the order
                    return costB - costA || 0; // Add fallback to handle undefined or NaN values
                });
                setSortedUsers(sortedItems);

            } else if (value === "Newest Listings") {
                const sortedItems = [...users].sort((a, b) => {
                    // Check for createdAt property and convert to Date objects
                    const dateA = a.createdAt ? new Date(a.createdAt) : null;
                    const dateB = b.createdAt ? new Date(b.createdAt) : null;

                    // Compare dates
                    return dateA && dateB ? dateB.getTime() - dateA.getTime() : 0; // Add fallback to handle undefined dates
                });
                setSortedUsers(sortedItems);
            } else if (value === "Older Listings") {
                const sortedItems = [...users].sort((a, b) => {
                    const dateA = a.createdAt ? new Date(a.createdAt) : null;
                    const dateB = b.createdAt ? new Date(b.createdAt) : null;

                    // Compare dates in reverse order
                    return dateB && dateA ? dateA.getTime() - dateB.getTime() : 0; // Add fallback to handle undefined dates
                });
                setSortedUsers(sortedItems);
            } else {
                setSortedUsers([...users]);
            }
        };
        handleChildData()
        console.log(sortedUsers);
    }, [value])



    const handleSaved = async (id: number, property: any, look: any, index: any) => {

        if (loginData.user_id === undefined) {
            navigate('/contact')
        } else {
            const newLikedState = !likedState[id];
            setLikedState((prevState) => ({ ...prevState, [id]: newLikedState }));

            const body = {
                post_id: id,
                liked_user_id: loginData.user_id,
                property_type: property,
                rent_or_sell: look,
                liked: newLikedState,
            };

            try {
                const response = await axiosInstance.post('/like/postLike', body);
                console.log('response', response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };
    function valueLabelFormat(value: number) {
        if (value >= 1000000000) {
            return (value / 10000000).toFixed(2) + ' Cr';
        }
        if (value >= 10000000) {
            return (value / 10000000).toFixed(2) + ' Cr';
        }
        if (value >= 1000000) {
            return (value / 100000).toFixed(2) + ' Lac';
        }
        if (value >= 100000) {
            return (value / 100000).toFixed(2) + ' Lac';
        }
        if (value >= 10000) {
            return (value / 1000).toFixed(0) + 'k';
        }
        if (value >= 1000) {
            return (value / 1000).toFixed(1) + 'k';
        }
        return value.toString();
    }



    // Handle string price
    const formatPrice = (price: string | number): string => {
        if (typeof price === 'number') {
            return valueLabelFormat(price);
        }

        // Handle string price
        const numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));
        return valueLabelFormat(numericPrice);
    };


    const toggleFilterVisibility = () => {
        setIsFilterVisible(!isFilterVisible);
        // setSortOpen(false)

    }

    function handleSort() {
        console.log("hello");
        setIsFilterVisible(false);
        setSortOpen(!sortOpen)
    }
    useEffect(() => {
        
        if (isFilterVisible) {
            document.body.style.overflow = 'hidden'; // Disable scroll
        } else {
            document.body.style.overflow = 'auto'; // Enable scroll when the filter is closed
        }
        return () => {
            document.body.style.overflow = 'auto'; // Ensure scrolling is enabled when the component unmounts
        };
    }, [isFilterVisible]);
    

    const gradientBorderStyle = {
        border: '5px solid transparent',
        borderImage: 'linear-gradient(to right, #0C3D5A, #1A82C0) 1',

        borderRadius: '24px'
    };


    return (
        <div className= ' bg-[#e6f7fa] h-full'>
            <div
    className={`transition-all duration-1000 ${sortOpen ? 'fixed inset-0 z-50 flex items-center justify-center' : 'hidden'}`}
    style={{ backgroundColor: sortOpen ? 'rgba(0, 0, 0, 0.5)' : 'transparent',marginTop:"50px" }} // Add a semi-transparent background to dim the content behind the modal
>
    {/* Filter Component */}
    <div
        className=" shadow-lg w-full " // You can adjust the width as needed
    > <SortBy setValue={setValue} handleSort={handleSort} />
          </div>  </div>
            {/* <div className={`transition-all duration-1000  ${isFilterVisible ? 'flex' : 'hidden'}  `} >
                <Filter setLoading={setLoading} toggleFilterVisibility={toggleFilterVisibility} kb={kb} setFilterProperty={setFilterProperty} setfilterLoaction={setfilterLoaction} setFilterPrice={setFilterPrice} />
            </div> */}
            <div
    className={`transition-all duration-1000 ${isFilterVisible ? 'fixed inset-0 z-50 flex items-center justify-center' : 'hidden'}`}
    style={{ backgroundColor: isFilterVisible ? 'rgba(0, 0, 0, 0.5)' : 'transparent' }} // Add a semi-transparent background to dim the content behind the modal
>
    {/* Filter Component */}
    <div
        className=" shadow-lg w-full " // You can adjust the width as needed
    >
        <Filter
            setLoading={setLoading}
            toggleFilterVisibility={toggleFilterVisibility}
            kb={kb}
            setFilterProperty={setFilterProperty}
            setfilterLoaction={setfilterLoaction}
            setFilterPrice={setFilterPrice}
        />
    </div>

    {/* Optional: Overlay that prevents scrolling on the background */}
    {/* <div
        className={`fixed inset-0 bg-black opacity-50 z-40 ${isFilterVisible ? 'block' : 'hidden'}`} 
        onClick={toggleFilterVisibility} // Clicking on the overlay will close the filter
    /> */}
</div>

            <div className='bg-[#fff] shadow-2xl flex items-center justify-left gap-5 fixed z-20 w-full lg:h-[105px] msm:h-[60px] sm:h-[100px] lg:pt-[50px] sm:pt-[40px] msm:pt-[1px] lg:justify-center'>

            <div className="block lg:hidden ">
    <IconButton
        onClick={() => navigate(-1)} // Inline navigation logic
        aria-label="Back"
        sx={{
            borderRadius: '50%', // Optional: for a circular button
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)', // Optional: change background on hover
            },
        }}
    >
        <MdArrowBackIosNew className='w-8 h-7' />
    </IconButton>
</div>
<div className='flex  items-center msm:ml-5 border-2 rounded-3xl border-[#02a7e4] '>
                        <div onClick={handleSort}
        className='bg-[#FFFFFF] text-[#202020] py-2 msm:px-3 lg:px-10 flex gap-2 items-center justify-center rounded-tl-3xl rounded-bl-3xl hover:bg-[#e0f7fa] hover:text-[#005662]' // Change colors on hover
        >
                            <img src={sort}  />
                            <p className="">Sort by</p>
                        </div>
                        <div className='flex border-[#C1C1C1] border-solid border-1 border-l h-10 '></div>

                        <div onClick={toggleFilterVisibility}
        className='bg-[#FFFFFF] text-[#202020] gap-2 flex py-2 msm:px-3 lg:px-10 items-center justify-center rounded-tr-3xl rounded-br-3xl hover:bg-[#e0f7fa] hover:text-[#005662]' // Change colors on hover
        >
                            <img src={filter} />
                            <p>Filter</p>
                        </div>
                    </div>
                    <div className=' border-[#C1C1C1] border-solid border-1 border-l h-10  hidden lg:block sm:block' ></div>
                    <div className=' hidden lg:block sm:block -mt-2 '>
    <p className='text-[#202020]'>You are currently searching a property at</p>
    <div className="flex gap-2  mt-1 flex-row lg:w-full sm:w-[250px]">
    {filterLoaction.map((item) => (
        <div onClick={toggleFilterVisibility} className="flex   items-center border-1 rounded-3xl border-[#c1c1c1] p-1 overflow-x-auto shadow-md cursor-pointer" key={item}>
            <div className="text-[#252B5C] font-semibold text-[10px] truncate lg:w-full ">
                {item}
            </div>
            {/* <img src={cancel} alt="Remove filter w-3 h-3"  className="rounded-3xl" /> */}
        </div>
    ))}
</div>
</div>

                  
                </div>
            <div  style={{backgroundColor:"#e6f7fa",marginBottom:"30px"}} className="msm:pt-[10%] sm:pt-[13%] lg:pt-[100px] custom-lg:pt-[100px] xl:pt-[6.8%] custom-xl:pt-[4%]">
             
          
                <Carousel className=" lg:mx-[20%] xl:mx-[20%] custom-lg:mx-[25%] msm:mx-[0%] sm:mx-[0%] lg:w-[60%] xl:w-[60%] custom-lg:w-[50%] msm:w-[100%] sm:w-[100%]" indicators={true} prevIcon={null} nextIcon={null}>
    {projects && Array.isArray(projects) && projects.length > 0 ? (
        projects.map((item) => {
            // Only render the carousel item if there are images
            if (!item.images || item.images.length === 0) {
                return null; // Skip rendering if no images
            }
            const Bview = "Sview"

            const propertyView = {
                postId: item.post_id,
                property_type: item.property_type,
                looking: item.looking_to || item.rent_or_lease || item.property_available_for,
                userId: loginData.user_id,
                Bview: Bview

            };
            const type = encodeURIComponent(propertyView.property_type);

            const url = `/PropertyDetails/${propertyView.postId}/${type}/${propertyView.looking}/${propertyView.Bview}`;

            return (
                <Carousel.Item key={item.id} interval={2000}>
                    <div className="">
                        <div                             
                            // onClick={() => {  handleChange(propertyView)  }}
                            onClick={(event) => { handleLinkClick(event, url) }}
className="relative flex justify-center rounded-[10px] items-center  md:h-[330px] lg:h-[360px] xl:h-[400px] custom-xl:h-[500px] msm:h-[250px] sm:h-[350px] cursor-pointer">
                            <div className='absolute bottom-0 bg-gradient-to-b from-[transparent] to-[#e6f7fa] py-5 pr-[10px]  w-full'>
                                <div className='text-[#202020] text-end font-bold capitalize lg:text-[25px] msm:text-[18px] ' >{item.bhk} {item.property_type} for {item.looking_to || item.rent_or_lease || item.property_available_for}</div>
                                <div className='text-[#202020]  capitalize text-end font-semibold mt-1  lg:text-[20px] msm:text-[13px]'   >{item.sublocality}, {item.district}</div>
                                <div className='text-[#202020] text-end text-2xl lg:mt-2 msm:mt-1 font-semibold lg:text-[20px] msm:text-[15px]'>{item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent}</div>
                            </div>

                            <img
                                className="object-cover w-full h-full rounded-tl-[10px] rounded-tr-[10px]" 

                                // className="object-cover w-full h-full "
                                src={item.images[0].uri}
                                alt="Project Cover"
                            />
                        </div>
                    </div>
                </Carousel.Item>
            );
        })
    ) : (
       " "
    )}
</Carousel>

                <div className='mb- '>
                    <div className=' lg:mx-[20%] sm:mx-[2%] xl:mx-[20%] custom-lg:mx-[25%] custom-xl:mx-[25%] msm:mx-[1%] mt-4'>
                        <p className='text-[#202020] text-2xl font-bold flex justify-start'>Vattara</p>
                        <p className='text-[#202020] font-semibold text-xl'>Verified Residential Properties for sale</p>
                        <div className='grid sm:grid-cols-3 msm:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4   custom-lg:grid-cols-4  custom-xl:grid-cols-4  gap-2 mt-4 '>
                            {
                                sortedUsers && Array.isArray(sortedUsers) && sortedUsers.length > 0 ? (
                                    sortedUsers.map((item, index) => {
                                        const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
                                        const formattedPrice = formatPrice(price);
                                        const coverimg = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
                                        const locality = truncateString(`${item.city ? `${item.city}, ` : ''}${item.district}`, 26);
                                        const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
                                        const priceSQTV = formatSQPrice(sqtv);
    const Bview = "Sview"

                                        const propertyView = {
                                            postId: item.post_id,
                                            property_type: item.property_type,
                                            looking: item.looking_to || item.rent_or_lease || item.property_available_for,
                                            userId: loginData.user_id,                Bview: Bview


                                        }
                                        const type = encodeURIComponent(propertyView.property_type);

                                        const url = `/PropertyDetails/${propertyView.postId}/${type}/${propertyView.looking}/${propertyView.Bview}`;

                                        return (
                                         
                                            <Box className=' relative bg-[#FFFFFF] border-[#1063b5] border-opacity-30 border-2 p-1 rounded-2xl' sx={{    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",    cursor: 'pointer'  // Add the cursor pointer here

                             }}>
                                        
                                             <div className='relative '>
                                            
                                             <div className='absolute right-0 mr-2 top-3 text-white  px-2 rounded-2xl' style={{background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)',fontSize:"13px" }}>
                                             {item.looking_to || item.rent_or_lease || item.property_available_for}
                                                                         </div>           
                                                                         <Link to={url} onClick={(event) => { handleLinkClick(event, url) }}>
                                                                         <img 
                                                                        //  onClick={() => {  handleChange(propertyView)  }}
                              className='w-full h-[140px] sm:h-[180px] md:h-[180px] lg:h-[180px] xl:h-[180px] flex justify-center items-center object-cover rounded-[15px]'
                              src={typeof coverimg === 'object' && coverimg?.uri ? coverimg.uri : DefaultScroll}/></Link>
                                                                     <img
                                                        onClick={() => handleSaved(item.post_id, item.property_type, item.looking_to || item.rent_or_lease || item.property_available_for, index)}
                                                         src={likedState[item.post_id] ? LikedHeart : heart} className='absolute top-3 left-3 text-2xl bg-[#D9D9D9] rounded-3xl w-6 h-6'

                                                     />    <div className={`${item.kbl_property ? "flex" : "hidden"}  items-center  absolute bottom-0  p-1 left-0`} style={{borderTopRightRadius:"15px",borderBottomLeftRadius:"15px" ,background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)' }}>
                                                 <img className="w-4 h-4" src={star} />
                                                 <div className="text-white mr-1" style={{fontSize:"8px"}}>VATTARA VERIFIED</div>
                                                </div>
                                                
                                             </div>
                                             <Box sx={{ width: { sm: "100%", md: "100%", lg: "100%", xl: "100%",xs:"95%" }}}>
                                             <Link to={url} onClick={(event) => { handleLinkClick(event, url) }}>
                                             <div className='flex gap-1 items-left' style={{alignItems:"center"}} >
                                                                             <img src={goldtick} className="w-5 h-5" style={{marginLeft:"-3px"}} />
                                                                             <div className='msm:text-[11px] sm:text-[15px] md:text-[15px] hover:text-blue-600' style={{ fontWeight: 700 }}>{item.bhk} {item.property_type === "Plot / Land" ? "Plot" : item.property_type}</div>
                                                                             </div></Link>
                                                                         <div className='flex gap-1 items-center'>
                                                                             <img className="h-4 w-4" src={locaation} style={{marginLeft:"-3px"}} />
                                                                             <div className='msm:text-[11px] sm:text-[14px] md:text-[14px] capitalize overflow-hidden whitespace-nowrap text-ellipsis'>
                                                                             {item.sublocality}, {item.district}</div>                                                                         </div>
                                                                         <div className='flex items-center w-full'>
                                                                             <Box sx={{ fontSize: {xs:"13px",lg:"15px",md:"15px",sm:"14px"}, fontWeight: 800 ,width:{xs:"80px",md:"100px",lg:"100px"}}}>₹ {formattedPrice}</Box>
                                                                             <div className='border-[#404040] h-6 border-l'></div>
                                                                             <div className='ml-1'>
                                                                             <Box sx={{ fontSize: {xs:"10px",lg:"15px",sm:"11px",md:"15px"} }}  className='flex items-center text-sm font-sm text-[#404040]' >
                                                                                     <div className='truncate'>{item.built_up_area || item.plot_area}</div>
                                                                                     {item.built_up_area || item.plot_area ? <div className='text-sm'>sq.ft</div> : ""}
                                                                                 </Box>
                                                                                 <Box sx={{ fontSize: {xs:"10px",lg:"13px",sm:"11px",md:"15px"} }} className='flex items-center text-sm font-sm text-[#404040]'>
                                                                                     {item.PriceperSqft ? <div>₹ {priceSQTV}</div> : ""}
                                                                                     {item.PriceperSqft ? <div>/sq.ft</div> : ""}
                                                                                 </Box>
                                                                             </div>
                                                                         </div>
                                                                     </Box>
                                                                     </Box>
                                        )
                                    })
                                ) : (
                                    <p className=''>No properties available.</p>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default VattaraExclusive