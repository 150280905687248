import React, { useContext, useState } from 'react';
import Basic from './Basic';
import PropertyDetails from './PropertyDetails';
import Price from './Price';
import AdditionalDetails from './AdditionalDetails';
import UploadPhoto from './UploadPhoto';
import Review from './Review';
import { Button, Step, StepConnector, StepLabel, Stepper, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useStepperContext } from './StepperContext';
import { styled, useTheme } from '@mui/material/styles';
import image from "../../../compounts/images/support.png";
import { axiosInstance } from '../../api/APIs';
import { useSelector } from 'react-redux';
import userDataSlice from '../../../slices/userDataSlice';
import postimage from '../../../compounts/images/post background.png';



interface StepperFormProps {
  label?: React.ReactNode;
  description?: React.ReactNode;
  required?: boolean;
  labelText?: any
}

const StepperForm: React.FC<StepperFormProps> = () => {
  const { addUserData, userData } = useStepperContext();
  const theme = useTheme();
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  const property = userData.find((item) => item?.['Property Subtype'])?.[
    'Property Subtype'
  ];
  console.log("On", property)
  const [activeStep, setActiveStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const steps: StepperFormProps[] = [
    {
      labelText: "Basic Details",
      label: (
        <span className="text-white text-lg  ">
          {"Basic Details"}
        </span>
      ),
      description: (
        <Basic onNext={handleNext} onBack={handleBack} onReset={handleReset} />
      ),
      required: true,
    },
    {
      labelText: "Property Details",
      label: (
        <span className="text-white text-lg  ">
          {"Property Details"}
        </span>
      ),
      description: (
        <PropertyDetails
          onNext={handleNext}
          onBack={handleBack}
          onReset={handleReset}
        />
      ),
    },
    {
      labelText: "Price",
      label: (
        <span className="text-white text-lg  ">{"Price"}</span>
      ),
      description: (
        <Price onNext={handleNext} onBack={handleBack} onReset={handleReset} />
      ),
    },
    {
      labelText: "Additional Details",
      label: (
        <span className="text-white text-lg  ">
          {"Additional Details"}
        </span>
      ),
      description: (
        <AdditionalDetails
          onNext={handleNext}
          onBack={handleBack}
          onReset={handleReset}
        />
      ),
    },
    {
      labelText: "Upload Photos",
      label: (
        <span className="text-white text-lg  ">
          {"Upload Photos"}
        </span>
      ),
      description: (
        <UploadPhoto
          onNext={handleNext}
          onBack={handleBack}
          onReset={handleReset}
        />
      ),
    },
    {
      labelText: "Review",
      label: (
        <span className="text-white text-lg  ">
          {"Review"}
        </span>
      ),
      description: (
        <Review onNext={handleNext} onBack={handleBack} onReset={handleReset} />
      ),
    },
  ];

 async function handlehelp() {

    try {
      const response = await axiosInstance.post("/user/UserHelp/Update",{user_id:loginData.user_id})
      console.log(response)
    } catch (e) {

    }
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirm = () => {
    handlehelp();
    handleCloseModal();
  };


  const filteredSteps =
    property === 'Plot / Land'
      ? steps.filter((step) => step.labelText !== 'Additional Details')
      : steps;

  console.log("Yup", filteredSteps)

  const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    // Add padding to the connector line
    paddingLeft: '10px', // Adjust margin to create space around the connector
   
  }));


  return (
    <div
      style={{ display: 'flex',width:"100%",justifyContent:"center" ,height:"100vh",backgroundColor:"#f5f4f8",}}
    >
<div className="bg-[#f5f4f8] flex flex-row  sm:w-[90%] msm:w-[98%] lg:mt-[60px] msm:mt-[20px] sm:mt-[50px]">
{/* Stepper and Side Divs Hidden on Screens 1440px or Smaller */}
        <div 
        className="hidden sm:hidden lg:block custom-xxl:w-[15%] custom-xl:w-[15%] custom-lg:w-[15%] xl:w-[15%] lg:w-[15%]  bg-[#1063B5]   rounded-t-3xl text-white h-full "
        >
  
        <h1 className="relative top-[20px] left-[20px] text-[20px] font-semibold ">
            Post Property
          </h1>
          <Stepper
  activeStep={activeStep}
  orientation="vertical"
  connector={<CustomStepConnector />} // Use the custom connector

  style={{
    paddingLeft:"10px",
    // borderRight: '1px solid black',
    position: 'relative',
    top: '100px',
  }}
>
  {filteredSteps.map((step, index) => (
    <Step key={index}>
      <StepLabel
        sx={{
          backgroundColor: activeStep === index ? '#fff' : 'transparent', // Background color for active step
          color: activeStep === index ? '#000' : '#FFF', // Change text color based on active step
          fontSize: '1rem',
          fontWeight: 'bold',
          fontFamily:"K2D",
          paddingLeft:"10px",
          borderTopLeftRadius:"30px",
          borderBottomLeftRadius:"30px",
          // padding: '8px',
        }}
        className="hidden custom-lg:flex"
      >
        {step.labelText}
      </StepLabel>
    </Step>
  ))}
</Stepper>

        </div>

        {/* Main Content Div, Hidden on Screens Larger than 1440px */}
        <div
          className=" bg-customWhite h-full rounded-t-3xl custom-xxl:w-[55%] custom-xl:w-[55%] custom-lg:w-[55%] xl:w-[55%] lg:w-[55%] msm:w-[100%]  overflow-scroll lg:mx-[2%] msm:mx-[0%]"
          style={{
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', // Adjust the values for shadow as needed
          }}
        >
          <div>{filteredSteps[activeStep]?.description}</div>
        </div>
        <div className=" hidden sm:hidden lg:block custom-xxl:w-[25%] custom-xl:w-[25%] custom-lg:w-[25%] xl:w-[25%] lg:w-[25%]  bg-[#1063B5] rounded-t-3xl  ">
         <div className='flex justify-center items-center flex-col'> <img
            src={image}
            alt="description"
            style={{ width: '90%', marginTop: '50px' }}
          />

          <h1 className="text-white ">Need guidance from our team</h1>

          <div className="mt-2">
            <h5 className="text-white text-sm ">
              For Advertising properties
              <span className="text-white text-base">?</span>{' '}
            </h5>
          </div>

          <Button
              onClick={handleOpenModal}
              style={{ width: '200px', backgroundColor: 'transparent', color: '#FBB337', borderRadius: '10px', fontFamily: 'K2D', marginTop: '30px', border: '1px solid #FBB337' }}
            >
              Connect with us
            </Button></div>
        </div>
      </div>
      {/* Modal for Confirmation */}
      <Dialog open={openModal} onClose={handleCloseModal} PaperProps={{ style: { borderRadius: 20 } }}>
        <DialogTitle sx={{fontSize:"15px",fontFamily:"K2D"}}>Connect with Us</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{fontFamily:"K2D"}}>
            Would you like to continue? We would be happy to assist you.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">Cancel</Button>
          <Button onClick={handleConfirm} color="primary">OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StepperForm;
