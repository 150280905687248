function valueLabelFormat(value: number) {
    // Rounding logic for formatting
    if (value < 1000) {
        return value.toString(); // Directly return numbers less than 1000
    }

    if (value < 100000) {
        return Math.round(value / 1000) + 'k'; // Round and add 'k' for thousands
    }

    if (value >= 1000000000) {
        return Math.round(value / 10000000) + ' Cr'; // Rounded to nearest whole number for Crores
    }
    if (value >= 10000000) {
        return Math.round(value / 10000000) + ' Cr'; // Rounded to nearest whole number for Crores
    }
    if (value >= 1000000) {
        return Math.round(value / 100000) + ' L'; // Rounded to nearest whole number for Lakhs
    }
    if (value >= 100000) {
        return Math.round(value / 100000) + ' L'; // Rounded to nearest whole number for Lakhs
    }

    return value.toString(); // Fallback for other values
}

// Handle string price
export const formatPrice = (price:any): string => {
    if (typeof price === 'number') {
        console.log("before",price)
        return valueLabelFormat(price);
    }

    // Handle string price
    const numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));
    console.log("after",numericPrice)
    return valueLabelFormat(numericPrice);
    
};
