import React, { useState } from 'react'
import BasicRating from './BasicRating'
import { useSelector } from "react-redux";
import { axiosInstance } from "../../compounts/api/APIs";
import cancel from '../images/cancel.png'
import tick from '../images/thanks.png'
import { Box, IconButton } from '@mui/material';

interface inter {
    setOpen: any
}

const RateingBox: React.FC<inter> = ({ setOpen }) => {

    const [RateValue, setRateValue] = useState<any>()
    const [value, setValue] = React.useState<number | null>(0);
    const loginData = useSelector((state: any) => state.userData.userLoginData)
    const [thank, setThank] = useState<any>(false)
    function handleOtherReasonText(event: React.ChangeEvent<HTMLTextAreaElement>) {
        setRateValue(event.target.value)
    }
    async function handleSubmit() {
        const body = {
            user_id: loginData.user_id,
            Rating_count: value,
            describe_issuse: RateValue

        }
        console.log(body);

        try {
            const response = await axiosInstance.post("/user/ratingUpdate", body)
            console.log(response);
            setThank(true)
        } catch (error) {

        }
    }
    function handleClose() {
        setOpen(false)
    }



    return (
        // <div >
        //     <div
        //         className={`bg-white p-5 rounded-[19px] shadow-lg transition-opacity duration-300 absolute left-[28%] top-[15%]  z-20 transform     outline-none
        //        ${thank ? "hidden" : ""} `}> <img onClick={handleClose} className='absolute right-3 top-3' src={cancel} />
        //         <div className='flex flex-col justify-center items-center gap-4'>

        //             <p className='text-xl font-bold'>Rate Our App</p>
        //             <p className='text-lg font-medium'>Enjoying Our App?</p>
        //         </div>
        //         <div className='flex justify-center items-center font-medium mt-3'>
        //             <p>We'd love to hear your feedback! Your rating helps us improve and provide a better experience.</p>

        //         </div>
        //         <div className='flex flex-col justify-center items-center mt-4'>
        //             <p className='mb-3 font-medium'>How would you rate your experience so far?</p>
        //             <BasicRating value={value} setValue={setValue} />
        //         </div>
        //         <div className='flex flex-col justify-center items-center mt-5'><textarea
        //             onChange={handleOtherReasonText}
        //             placeholder="Describe the issue in detail"
        //             className="rounded-lg border-2 transition-colors duration-100 w-[85%] h-[150px] placeholder: placeholder:text-center placeholder:items-center border-gray-300 focus:border-gray-400 outline-none p-2"
        //         ></textarea>
        //         </div>
        //         <div className=' mt-5 flex justify-center items-center '>
        //             <p onClick={handleSubmit} className='flex bg-[#2E368F] py-3 px-32 rounded-3xl text-white justify-center'>Submit</p>
        //         </div>
        //     </div>
        //     <div className={`bg-white p-5  rounded-[19px] shadow-lg transition-opacity duration-300 absolute left-[35%] top-[30%]  z-20 transform     outline-none
        //        ${thank ? "" : "hidden"} `}>
        //          <img onClick={handleClose} className='absolute right-3 top-3' src={cancel} />
        //         <img src={tick} />
        //         <div className='flex flex-col justify-center items-center mt-4'>
        //             <p className='text-xl font-bold'>Thank You for Your Feedback!</p>
        //             <p className='font-semibold'>“Find Your Perfect Place”</p>
        //         </div>

        //     </div>
        // </div>
        <Box
      sx={{
        // display: showVerification === "Number" ? 'flex' : 'none',
        display:"flex",
        alignItems: 'center',
        justifyContent:"center",
        position: 'absolute',
        height:"100%",
        width:"100%",
  
        zIndex: 10,
      }}
    >
      <Box
        sx={{
          bgcolor: 'white',
        //   border: '0.5px solid',
          borderColor: 'gray.300',
          borderRadius: '20px',
          padding:"20px",
          mx: { xs: 4, md: 10 }, // Responsive margins
        //   width: { xs: '90vw', sm: '70vw', md: '100vw', lg: '70vw', xl: '60vw' },
        //   height: { xs: '600px',sm:"600px",md:"600px",lg:"700px"},

          display: thank ? 'none' : 'block',
          flexDirection: 'column',
          gap: 2,          outline: 'none',

          position: 'relative',
          boxShadow: 3, // Add box shadow here

        }}
      >
             <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 3, top: 1 }}>
                 <img src={cancel} alt="Close" />
             </IconButton>
             <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
                 <Box sx={{fontSize: { xs: '15px', sm: '15px', md: '25px', lg: '25px', xl: '25px' }}} fontWeight="bold">Rate Our App</Box>
                 <Box fontWeight="medium">Enjoying Our App?</Box>
             </Box>
             <Box display="flex" sx={{font: { xs: '8px', sm: '13px', md: '25px', lg: '25px', xl: '25px' }}}  justifyContent="center" alignItems="center" fontWeight="medium" mt={2} textAlign="center">
                 <Box>We'd love to hear your feedback! Your rating helps us improve and provide a better experience.</Box>
             </Box>
             <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={1} textAlign="center">
                 <Box component="p" mb={2} fontWeight="medium">How would you rate your experience so far?</Box>
                 <BasicRating value={value} setValue={setValue} />
             </Box>
             <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={5}>
                 <textarea
                     onChange={handleOtherReasonText}
                     placeholder="Describe the issue in detail"
                     className="rounded-lg border-2 transition-colors duration-100 w-[85%] h-[150px] placeholder:text-center border-gray-300 focus:border-gray-400 outline-none p-2"
                 ></textarea>
             </Box>
             <Box mt={5} display="flex" justifyContent="center" alignItems="center">
                 <Box
                     onClick={handleSubmit}
                     sx={{
                         display: 'flex',
                         bgcolor: '#1063B5',
                         py: 2,
                         px: 8,
                         borderRadius: '100px',
                         color: 'white',
                         justifyContent: 'center',
                         cursor: 'pointer',
                     }}
                 >
                     Submit
                 </Box>
             </Box>
         </Box>
         <Box
             sx={{
                 bgcolor: 'white',
                 p: 5,
                 borderRadius: '19px',
                 boxShadow: 3,
                 transition: 'opacity 0.3s',
                 position: 'absolute',
                 left: '35%',
                 top: '30%',
                 zIndex: 20,
                 display: thank ? 'block' : 'none',
                 outline: 'none',
             }}
         >
             <IconButton onClick={handleClose} sx={{ position: 'absolute', right: 3, top: 3 }}>
                 <img src={cancel} alt="Close" />
             </IconButton>
             <img src={tick} alt="Thank You" />
             <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={4}>
                 <Box component="h2" fontSize="xl" fontWeight="bold">Thank You for Your Feedback!</Box>
                 <Box component="p" fontWeight="semibold">“Find Your Perfect Place”</Box>
             </Box>
         </Box>
     </Box>
    )
}

export default RateingBox