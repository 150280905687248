import { InputAdornment, TextField } from '@mui/material'
import React from 'react'
import { useStepperContext } from './StepperContext'
import { updateValueInArray } from '../../PostForm/component'
interface PriceTextField1Props {
    label: string,
    formName: string,
    inputId?: number
    label2?: string
    keyboard?: any,
    required?: boolean
    userselectedData?: any,
    initial?: any
    baseData?: any
    setDataB?: any
}

const PriceTextField1: React.FC<PriceTextField1Props> = ({ label, baseData, setDataB, formName, inputId, label2, keyboard, initial, required, userselectedData }) => {

    const { addUserData } = useStepperContext()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
        
        const updatedData = updateValueInArray(baseData,label,event.target.value)
        setDataB(updatedData)
        console.log("price field", updatedData)
        
        const data = {
            id: inputId,
            [label]: event.target.value,
            formName: formName

        }
        addUserData(data as any)
    }
    const hasError = userselectedData?.includes(label)
    return (
        <div className='w-[100%]'>
            <TextField id={label}
                defaultValue={initial}
                type={keyboard ? keyboard : "text"}
                label={label} onChange={handleChange} variant="filled"
                size="small"
          sx={{                  borderRadius: "999px",
            width:'100%',
                 '& .MuiFilledInput-underline:before': {
                           borderBottom: '0px solid transparent', // Dotted underline
                         },
                         '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
                           borderBottom: '0px solid transparent', // Dotted underline
                         }, }} className='w-[100%]'
                InputProps={{
                    style: {
                        borderRadius: "999px",
                 fontFamily:"K2D",            backgroundColor: "#F5F4F8",
                      },

                    endAdornment: <InputAdornment position="start">{label2}</InputAdornment>,
                }} >


            </TextField>



        </div>
    )
}

export default PriceTextField1