import React, { useEffect } from 'react';
import animis from './postcompletion (3).json';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';

const Success = () => {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/');
        }, 3000);
    }, [navigate]);

    const animi = {
        animationData: animis,
    };

    return (
        <div className="bg-transparent w-full flex justify-center items-center">
            <div className="flex flex-col justify-center items-center bg-white rounded-2xl p-6 shadow-lg max-w-sm w-full">
                <div>
                    <Lottie
                        animationData={animi.animationData}
                        loop={true}
                        autoplay={true}
                        style={{ width: '100%', height: 'auto' }}
                    />
                </div>
                <p className="text-[#1063b5] text-center font-semibold text-lg md:text-xl">Congratulations!</p>
                <p className="text-black text-center font-medium text-sm md:text-base">Your listing is under review!</p>
            </div>
        </div>
    );
};

export default Success;
