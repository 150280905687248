import React, { useState, useEffect } from "react";
import { Box, Skeleton,IconButton } from '@mui/material';

import PropertiesComponent from "./PropertiesComponent";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../src/compounts/api/APIs";
import { MdArrowBackIosNew } from "react-icons/md";
import { Link } from 'react-router-dom';
import { MdArrowBackIos, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";


interface Property {
  id: number;
  post_id: number;
  property_type?: any;
  looking_to?: string;
  rent_or_lease?: string;
  property_available_for?: string;
  images?: { uri: string }[];
  view_post_count: number;
  bhk: string;
  city: string;
  building_name: string;
  landmark_street: string;
  build_up_area?: string;
  built_up_area?: string;
  plot_area?: string;
  PriceperSqft?: string;
  price: number;
  cost?: number;
  monthly_rent?: number;
  enter_lease_amount?: number;
  lease_amount?: any
  liked?: any
}


const PropertiesCard: React.FC = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState<"Commercial" | "Residential">("Residential");
  const [value, setValue] = useState<"Buy" | "Lease" | "Rent">("Buy");
  const [position, setPosition] = useState<boolean>(true);
  const [filteredData, setFilteredData] = useState<Property[]>([]);
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  const [render, setRender] = useState<any>(false)
  const [loading, setLoading] = useState<any>(false)
  console.log("hello", loginData, render);

  const token = loginData.token
  const fetchData = async (type: "Residential" | "Commercial", category: "Buy" | "Lease" | "Rent") => {
    setLoading(true)
    const body = {
      liked_user_id: loginData.user_id,
      property_sub_type: type,
      looking_to: category
    };
    try {
      const response = await axiosInstance.post("/like/GetlikePost", body);
      console.log(response.data); // Check the structure of response.data

      setFilteredData(response.data.message);
      setLoading(false)

    } catch (error) {
      console.error("Error fetching data:", error); 
      setFilteredData([]);
    }
  };


  useEffect(() => {
    // Function to handle page focus
    const handleFocus = () => {
      // Optional: You can toggle render or directly call fetchData if needed
      // setRender((prevRender: any) => !prevRender); // This will toggle the state to trigger re-render
      // Or call fetchData directly if preferred
      // fetchData(activeButton, value);
    };
  
    // Add event listener for focus
    // window.addEventListener('focus', handleFocus);
  
    // Fetch data initially when component mounts
    fetchData(activeButton, value);
  
    // Cleanup event listener when the component unmounts
    // return () => {
    //   window.removeEventListener('focus', handleFocus);
    // };
  }, [activeButton, value, render]);

  
  const handleCategoryChange = (newValue: "Buy" | "Lease" | "Rent") => {
    setValue(newValue);
  };

  const handleButtonClick = (buttonType: "Commercial" | "Residential") => {
    setPosition(buttonType === "Residential");
    setActiveButton(buttonType);
    setValue("Buy"); // Default to "Buy" when changing category
  };

  const handleclick = () => {
    navigate("/profile");
  };

  return (
    <div className=' xl:mt-20 lg:mt-9 sm:mt-9 md:mt-10  mb-4 flex justify-center'>
<div className=" msm:w-[99%] sm:w-[99%] lg:w-[70%] xl:w-[70%] custom-lg:w-[70%] custom-xl:w-[70%] "> 
<Box          className="fixed h-15  z-40 bg-[#fff]  lg:mt-10 lg:pt-2  sm:mt-10 md:flex flex-col  msm:w-[99%] sm:w-[99%] lg:w-[71%] xl:w-[71%] custom-lg:w-[71%] custom-xl:w-[71%] top-0"
 >

      <div className="flex-row flex items-center"> <IconButton
      onClick={() => navigate(-1)} // Inline navigation logic
      aria-label="Back"
      sx={{
        borderRadius: '50%', // Optional: for a circular button
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)', // Optional: change background on hover
        },
      }}
    >
      <MdArrowBackIos className='w-8 h-7 ' color="#000" />
    </IconButton>
    <div className="font-bold">
Saved Properties    </div></div>
        <div className=" lg:flex lg:flex-row msm:flex msm:flex-col sm:flex-row msm:gap-3 lg:gap-3 items-center my-2 ">
          <div className="relative msm:w-[80%] lg:w-[400px] lg:mx-[5%] bg-[#F5F4F8] rounded-full  p-1">
            <div
              id="btn"
              className={`absolute top-0 h-full w-1/2 rounded-full transition-all duration-300 ${position
                ? "bg-[#1063b5] text-white left-0"
                : "bg-[#1063b5] text-white left-1/2"
                }`}
            ></div>
            <button
              type="button"
              className={`w-1/2 p-1 text-center  outline-none bg-transparent cursor-pointer relative z-10 ${position ? "text-white" : "text-black"
                }`}
              onClick={() => handleButtonClick("Residential")}
            >
              Residential
            </button>
            <button
              type="button"
              className={`w-1/2 p-1 text-center outline-none bg-transparent cursor-pointer relative z-10 ${!position ? "text-white" : "text-black"
                }`}
              onClick={() => handleButtonClick("Commercial")}
            >
              Commercial
            </button>
          </div>
          <div className="border-1 border-[#E9E9E9] h-10 lg:flex md:hidden msm:hidden"></div>

          <div className="gap-4 flex ">
            <button
              className={`lg:w-[120px] msm:w-[100px] h-10 rounded-3xl ${value === "Buy"
                ? "bg-[#1063b5] text-white"
                : "bg-[#F5F4F8] text-black"
                }`}
              onClick={() => handleCategoryChange("Buy")}
            >
              Buy
            </button>
            <button
              className={`lg:w-[120px]  msm:w-[100px] h-10 rounded-3xl ${value === "Lease"
                ? "bg-[#1063b5] text-white"
                : "bg-[#F5F4F8] text-black"
                }`}
              onClick={() => handleCategoryChange("Lease")}
            >
              Lease
            </button>
            <button
              className={`lg:w-[120px]  msm:w-[100px] h-10 rounded-3xl ${value === "Rent"
                ? "bg-[#1063b5] text-white"
                : "bg-[#F5F4F8] text-black"
                }`}
              onClick={() => handleCategoryChange("Rent")}
            >
              Rent
            </button>
          </div>
        </div>


        </Box>
        <div className="msm:pt-[160px] sm:pt-[115px] lg:pt-[70px] xl:pt-[90px]">  
        {loading ?
          <div>
            <p>loading...</p>
            <Skeleton animation="wave" sx={{ height: 200 }} />
            <Skeleton animation="wave" sx={{ height: 200 }} />
            <Skeleton animation="wave" sx={{ height: 200 }} /></div> :
            <PropertiesComponent data={filteredData} render={render} setRender={setRender} /> 
          
        }
      </div>
</div>
    </div>
  );
};

export default PropertiesCard;
