
import { useStepperContext } from "./component/StepperContext";
import gas from "../furnishings/Gas.png";
import Dinning from "../furnishings/Diningtable.png";
import Micro from "../furnishings/Microwave.png";
import washing from "../furnishings/Washingmachine.png";
 import fridge from "../furnishings/Fridge.png";
import gaspipe from "../furnishings/gaspipe.png";
import tv from "../furnishings/Television.png";
import purewater from "../furnishings/purwater.png";
import cupboard from "../furnishings/Cupboard.png";
import Ac from "../furnishings/Ac.png";
import Sofa from "../furnishings/Sofa.png";
import Garden from "../furnishings/Garden.png";

import Lift from "../furnishings/Lift.png";
import Sports from "../furnishings/Sports.png";
import CommunityHALL from "../furnishings/Hall.png";
import SwimingPool from "../furnishings/Pool.png";
import Kids from "../furnishings/Kids.png";
import Gym from "../furnishings/Gym.png";
import Watersupply from "../furnishings/WaterSupply.png";
import internet from "../furnishings/Intercom.png";
import CCTV from "../furnishings/CCTV.png";
import Gate from "../furnishings/Gate.png";
import Pets from "../furnishings/Pets.png";
import Generator from "../furnishings/Generator.png";
import Club from "../furnishings/Club.png";
import furnishing from "../furnishings/Amenities/furnish.png";
import dggenerator from "../furnishings/Amenities/011-generator 2.png";
import firespray from "../furnishings/Amenities/012-fire-extinguisher 2.png";
import reception from "../furnishings/Amenities/013-computer 2.png";
import watersupply from "../furnishings/Amenities/013-water-system.png";
import Air from "../furnishings/Amenities/ACC.png";
import inetrnat from "../furnishings/Amenities/ineternet.png";
import occupancy from "../furnishings/Amenities/014-certificate 2-1.png";
import firenoc from "../furnishings/Amenities/014-certificate 2.png";
import cafe from "../furnishings/Amenities/cafe.png";
import camera from "../furnishings/Amenities/camera.png";
import pan from "../furnishings/Amenities/pantry.png";
import oxygen from "../furnishings/Amenities/oxyegen.png";
import water from "../furnishings/Amenities/Layer 17.png";

import ups from "../furnishings/Amenities/ups.png";
import gener from "../furnishings/Amenities/014-electric-generator.png";
import security from "../furnishings/Amenities/security.png";
import vastu from "../furnishings/Amenities/vastu.png";
import firesensor from "../furnishings/Amenities/fire sensor.png";

// export interface FieldData {
//     id: string;
//     icon?: JSX.Element; // JSX.Element for holding JSX
//     title: string;
// }

// export interface FormData {
//     formName: string;
//     fields: {
//         type: string;
        
//         label: string;
//         inputId: number;
//         required: boolean;
//         title: string;
//     }[];
// }
export const basic_details={
fields: [
      {
        type: "button",
        data: [
          { id: 1, title: "Residential" },
          { id: 2, title: "Commercial" },
        ],
        label: "Property Type",
        inputId: 321523735,
        required: true,
      },
      {
        type: "button",
        data: [
          { id: 1, title: "Sell" },
          { id: 2, title: "Rent/Lease" },
        ],
        label: "Looking To",
        inputId: 423265156,
        required: true,
      },
      // {
      //   type: "Drop Down",
      //   data: [
      //   ],
      //   label: "Select Property Type",
      //   title: "Property type",
      //   inputId: 12345,
      //   required: true,
      // },
      {
        type:"DropDownBox",
        label:"Property Subtype",
        title:"select property type",
        data:[
          {
            id: 1,
            title: 'Plot / Land',
          },
          {
            id: 2,
            title: 'Independent House',
          },
          {
            id: 3,
            title: 'Apartment',
          },
          {
            id: 4,
            title: 'Villa',
          },
          {
            id: 5,
            title: 'Retail Shop',
          },
          {
            id: 6,
            title: 'Office Space',
          },
          {
            id: 7,
            title: 'Showroom',
          },
          {
            id: 8,
            title: 'Warehouse',
          },
          {
            id: 9,
            title: 'Industrial Space',
          },
        ],
        inputId:4353652,
        required:true

      },
      {
        type: "DropDown",
        // data: [{ id: 1, title: "adyar" }],
        label: "City",
        title: "Locate Your Property",
        inputId: 324251627,
        required: true,
      },
      {
        type: "textField",
        // data: [],
        label: "Locality",
        // title: "Locality",
        inputId: 24352352,
        required: true,
      },
      {
        type: "textField",
        // data: [],
        label: "Landmark",
        // title: "Landmark/Street",
        inputId: 436242761,
        required: false,
      },
    ],
}
export const Residentialrent = {
  additional_details: [
    {
      Type: 'Box Type',
      data: [
        {
          id: 1,
          title: '1',
        },
        {
          id: 2,
          title: '2',
        },
        {
          id: 3,
          title: '3',
        },
        {
          id: 4,
          title: '3+',
        },
      ],
      label: 'Bathroom',
      inputID: 20379,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Box Type',
      data: [
        {
          id: 1,
          title: '0',
        },
        {
          id: 2,
          title: '1',
        },
        {
          id: 3,
          title: '2',
        },
        {
          id: 4,
          title: '3',
        },
        {
          id: 5,
          title: '3+',
        },
      ],
      label: 'Balcony',
      inputID: 98564,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Box Type11',
      data: [
        {
          id: 14,
          title: 'Family',
        },
        {
          id: 15,
          title: 'Bachelors',
        },
        {
          id: 16,
          title: 'Corporate',
        },
      ],
      label: 'Preferred Tenant Type',
      inputID: 41938,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Parking',
      inputID: 80594,
      required: false,
      isvisible: true,
    },
   

    {
      Type: 'Text Field',
      data: [],
      label: 'Carpet Area',
      label2: 'Sq.ft',
      keyboard: 'number',
      inputID: 75491,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Price Text Field',
      data: [],
      label: 'Maintenance Charges',
      maxlen: 12,
      keyboard: 'number',
      label3: 'per month',
      inputID: 93058,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type3',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Loan Availability',
    //   inputID: 93158,
    //   required: false,
    //   isvisible: true,
    // },
   
    {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'North',
        },
        {
          id: 2,
          title: 'East',
        },
        {
          id: 3,
          title: 'West',
        },
        {
          id: 4,
          title: 'South',
        },
      ],
      label: 'Facing',
      inputID: 57934,
      required: false,
      isvisible: true,
    },

    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Servant Room',
      inputID: 67539,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Area',
      data: [],
      label: 'Property Description',
      inputID: 46823,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'UploadPhotos',
    },
    {
      Type: 'Form Button',
      label: 'Back',
      PrevPage: 'PriceDetails',
    },
  ],
  price_details: [
    {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'Rent',
        },
        {
          id: 2,
          title: 'Lease',
        },
      ],
      label: 'Property available for',
      inputID: 78956,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Price Text Field4',
      data: [],
      label: 'Monthly Rent',
      keyboard: 'number',
      maxlen: 11,
      inputID: 75428,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Price Text Field5',
      data: [],
      label: 'Lease Amount',
      maxlen: 11,
      keyboard: 'number',
      inputID: 75422,
      required: true,
      isvisible: true,
    },

    {
      Type: 'calendar1',
      data: [],
      label: 'Available From',
      inputID: 60937,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Drop down5',
      data: [
        {
          id: 1,
          title: '1 Month',
        },
        {
          id: 2,
          title: '2 Months',
        },
        {
          id: 3,
          title: '3 Months',
        },
        {
          id: 4,
          title: '4 Months',
        },
        {
          id: 5,
          title: '5 Months',
        },
        {
          id: 6,
          title: '6 Months',
        },
        {
          id: 7,
          title: '7 Months',
        },
        {
          id: 8,
          title: '8 Months',
        },
        {
          id: 9,
          title: '9 Months',
        },
        {
          id: 10,
          title: '10 Months',
        },
        {
          id: 11,
          title: '11 Months',
        },
        {
          id: 12,
          title: '12 Months',
        },
        // {
        //   id: 13,
        //   title: 'Custom',
        // },
      ],
      title: 'Security Deposit',
      label: 'Select',
      // icon: <Arrowicon />,
      inputID: 80214,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type4',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Negotiable',
      inputID: 406502,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'Additional Details',
    },
  ],
  property_details: [
    {
      Type: 'Text Field',
      data: [],
      label: 'Building/Project(Optional)',
    
      inputID: 98234,
      required: false,
      isvisible: true,
    },
    
    {
      Type: 'Box Type',
      data: [
        {
          id: 1,
          title: '1RK',
        },
        {
          id: 2,
          title: '1BHK',
        },
        {
          id: 3,
          title: '2BHK',
        },
        {
          id: 4,
          title: '3BHK',
        },
        {
          id: 5,
          title: '3BHK+',
        },
      ],
      label: 'BHK',
      inputID: 54876,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      data: [],
      label: 'Built up Area',
      keyboard: 'number',
      label2: 'Sq.ft',
      inputID: 30291,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type',
      data: [
        {
          id: 1,
          title: 'Fully Furnished',
        },
        {
          id: 2,
          title: 'Semi Furnished',
        },
        {
          id: 3,
          title: 'Unfurnished',
        },
      ],
      label: 'Furnish Type',
      inputID: 58742,
      required: true,
      isvisible: true,
     }
   
    ,
    
    {
      Type: 'Form Button',
      label: 'Next, add price details',
      NextButton: 'PriceDetails',
    },
    {
      Type: 'Form Button',
      label: 'PrevPage',
      NextButton: 'BasicDetails',
    },
    {
      Type: 'Furnishing',
      data: [
        { title: 'Dining Table', image: Dinning,id: 1 },
        { title: 'Microwave',image: Micro, id: 2 },
        { title: 'Sofa',image:Sofa , id: 3 },
        { title: 'Stove',image: gas, id: 4 },
        { title: 'Cupboard',image: cupboard, id: 5 },
        { title: 'Fridge', image:fridge,id: 6 },
        { title: 'Television',image: tv, id: 7 },
        { title: 'Water Purifier',image: purewater, id: 8 },
        { title: 'AC',image: Ac, id: 9 },
        { title: 'Washing Machine',image: washing, id: 10 },
        { title: 'Gas Pipe',image: gaspipe, id: 11 },
      ],
      label: 'Furnishing',
      inputID: 98765,
      required: false,
      isvisible: true,
    },
    {
      Type: 'AMENITIES',
      data: [
        { id: 1, title: 'Lift' , image: Lift},
        { id: 2, title: 'Sports', image: Sports },
        { id: 3, title: 'Community Hall', image: CommunityHALL },
        { id: 4, title: 'Swimming Pool', image: SwimingPool },
        { id: 5, title: 'Kids Area' , image: Kids},
        { id: 6, title: 'Gym', image: Gym},
        { id: 7, title: 'Water Supply' , image: Watersupply},
        { id: 8, title: 'Intercom', image: internet },
        { id: 9, title: 'CCTV', image: CCTV },
        { id: 10, title: 'Gated Community', image: Gate },
        { id: 11, title: 'Pet Allowed', image: Pets },
        { id: 12, title: 'Power Back Up', image: Generator },
        { id: 13, title: 'Club House' , image: Club},
        { id: 14, title: 'Garden' , image: Garden},
      ],  
          label: 'Amenities',

      inputID: 897673,
      required: false,
      isvisible: true,
    },
  ],
  id: 8,
};

export const Residentialsell = {
  additional_details: [
    {
      Type: 'Box Type',
      data: [
        {
          id: 1,
          title: '1',
        },
        {
          id: 2,
          title: '2',
        },
        {
          id: 3,
          title: '3',
        },
        {
          id:5,
          title: '3+',
        },
      ],
      label: 'Bathroom',
      inputID: 543,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Box Type',
      data: [
        {
          id: 1,
          title: '0',
        },
        {
          id: 2,
          title: '1',
        },
        {
          id: 3,
          title: '2',
        },
        {
          id:4,
          title: '3',
        },
        {
          id:5,
          title: '3+',
        },
        
      ],
      label: 'Balcony',
      inputID: 222,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Parking',
      inputID: 412669,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 0,
    //       title: '0',
    //     },
    //     {
    //       id: 1,
    //       title: '1',
    //     },
    //     {
    //       id: 2,
    //       title: '2',
    //     },
    //     {
    //       id: 3,
    //       title: '3',
    //     },
    //     {
    //       id: 4,
    //       title: '3+',
    //     },
    //   ],
    //   label: 'Covered Parking',
    //   inputID: 576,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 0,
    //       title: '0',
    //     },
    //     {
    //       id: 1,
    //       title: '1',
    //     },
    //     {
    //       id: 2,
    //       title: '2',
    //     },
    //     {
    //       id: 3,
    //       title: '3',
    //     },
    //     {
    //       id: 4,
    //       title: '3+',
    //     },
    //   ],
    //   label: 'Open Parking',
    //   inputID: 554,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'Box Type4',
      data: [
        {
          id: 1,
          title: 'Family',
        },
        {
          id: 2,
          title: 'Bachelors',
        },
        {
          id: 3,
          title: 'Corporate',
        },
      ],
      label: 'Preferred Tenant Type',
      inputID: 598,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      data: [],
      label: 'Carpet Area',
      keyboard: 'number',
      label2: 'Sq.ft',
      inputID: 610,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'North',
        },
        {
          id: 2,
          title: 'East',
        },
        {
          id: 3,
          title: 'West',
        },
        {
          id: 4,
          title: 'South',
        },
      ],
      label: 'Facing',
      inputID: 231,
      required: false,
      isvisible: true,
    },

    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Servant Room',
      inputID: 565,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Loan Availability',
      inputID: 93158,
      required: false,
      isvisible: true,
    },
   
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'RERA ID',
    //   inputID: 614,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'Text Area',
      data: [],
      label: 'Property Description',
      inputID: 613,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'UploadPhotos',
    },
  ],
  price_details: [
    {
      Type: 'Price Text Field',
      data: [],
      label: 'Cost',
      keyboard: 'number',
      inputID: 128,
      maxlen: 16,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Negotiable',
      inputID: 406505,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'Ready to Move',
        },
        {
          id: 2,
          title: 'Under Construction',
        },
      ],
      label: 'Construction Status',
      inputID: 129,
      required: true,
      isvisible: true,
    },
    {
      Type: 'calendar2',
      // data: [],
      label: 'Available From',
      inputID: 60937,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'Additional Details',
    },
  ],
  property_details: [
    {
      Type: 'Text Field',
      data: [],
      label: 'Building/Project(Optional)',
      inputID: 235,
      required: true,
      isvisible: false,
    },
   
    {
      Type: 'Box Type',
      data: [
        {
          id: 1,
          title: '1RK',
        },
        {
          id: 2,
          title: '1BHK',
        },
        {
          id: 3,
          title: '2BHK',
        },
        {
          id: 4,
          title: '3BHK',
        },
        {
          id: 5,
          title: '3BHK+',
        },
      ],
      label: 'BHK',
      inputID: 234,
      required: true,
      isvisible: true,
    },

    {
      Type: 'Text Field Built',
      data: [],
      label: 'Built up Area',
      keyboard: 'number',
      label2: 'Sq.ft',
      inputID: 237,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Text Field Built',
      data: [],
      label: 'Plot Area',
      keyboard: 'number',
      label2: 'Sq.ft',
      inputID: 234566,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type',
      data: [
        {
          id: 1,
          title: 'Fully Furnished',
        },
        {
          id: 2,
          title: 'Semi Furnished',
        },
        {
          id: 3,
          title: 'Unfurnished',
        },
      ],
      label: 'Furnish Type',
      inputID: 330,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Form Button',
      label: 'Next, add price details',
      NextButton: 'PriceDetails',
    },
    {
      Type: 'Furnishing',
      data: [
        { title: 'Dining Table', image: Dinning,id: 1 },
        { title: 'Microwave',image: Micro, id: 2 },
        { title: 'Sofa',image:Sofa , id: 3 },
        { title: 'Stove',image: gas, id: 4 },
        { title: 'Cupboard',image: cupboard, id: 5 },
        { title: 'Fridge', image:fridge,id: 6 },
        { title: 'Television',image: tv, id: 7 },
        { title: 'Water Purifier',image: purewater, id: 8 },
        { title: 'AC',image: Ac, id: 9 },
        { title: 'Washing Machine',image: washing, id: 10 },
        { title: 'Gas Pipe',image: gaspipe, id: 11 },
      ],
      label: 'Furnishing',
      inputID: 98765,
      required: false,
      isvisible: true,
    },
    {
      Type: 'AMENITIES',
      data: [
        { id: 1, title: 'Lift' , image: Lift},
        { id: 2, title: 'Sports', image: Sports },
        { id: 3, title: 'Community Hall', image: CommunityHALL },
        { id: 4, title: 'Swimming Pool', image: SwimingPool },
        { id: 5, title: 'Kids Area' , image: Kids},
        { id: 6, title: 'Gym', image: Gym},
        { id: 7, title: 'Water Supply' , image: Watersupply},
        { id: 8, title: 'Intercom', image: internet },
        { id: 9, title: 'CCTV', image: CCTV },
        { id: 10, title: 'Gated Community', image: Gate },
        { id: 11, title: 'Pet Allowed', image: Pets },
        { id: 12, title: 'Power Back Up', image: Generator },
        { id: 13, title: 'Club House' , image: Club},
        { id: 14, title: 'Garden' , image: Garden},
      ],  
          label: 'Amenities',

      inputID: 897673,
      required: false,
      isvisible: true,
    },
    
  ],
  id: 11,
};

export const ResidentialSellPlot = {
  additional_details: null,
  price_details: [
    {
      Type: 'Price Text Field',
      data: [],
      label: 'Plot Price',
      keyboard: 'number',
      inputID: 91,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Negotiable',
      inputID: 406510,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Area',
      data: [],
      label: 'Property Description',
      inputID: 468232,
      required: false,
      isvisible: true,
    },
   
    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'UploadPhotos',
    },
  ],
  property_details: [
    {
      Type: 'Text Field',
      data: [],
      label: 'Building/Project(Optional)',
      inputID: 455,
      required: false,
      isvisible: true,
    },
   
    {
      Type: 'Text Field',
      data: [],
      label: 'Plot Area',
      keyboard: 'number',
      inputID: 477,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      data: [],
      label: 'Length',
      keyboard: 'number',
      label2: 'ft.',
      inputID: 435,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      data: [],
      label: 'Width',
      label2: 'ft.',
      keyboard: 'number',
      inputID: 39,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Box Type',
      data: [
        {
          id: 1,
          title: 'DTCP Approved',
        },
        {
          id: 2,
          title: 'Panchayat Approved',
        },
        {
          id: 3,
          title: 'Un Approved',
        },
        {
          id: 3,
          title: 'CMDA Approved',
        },
      ],
      label: 'Property Status',
      inputID: 444,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Patta Available',
      inputID: 30,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'North',
        },
        {
          id: 2,
          title: 'East',
        },
        {
          id: 3,
          title: 'West',
        },
        {
          id: 4,
          title: 'South',
        },
      ],
      label: 'Facing',
      inputID: 57914,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Form Button',
      label: 'Next, add price details',
      NextButton: 'PriceDetails',
    },
  ],
  id: 12,
};


export const CommerRentRetailnShow = {
  additional_details: [
    {
      Type: 'AMENITIES',
      data: [
        {
          id: 2,
          title: 'Furnishing',
          image: furnishing,
        },
        {
          id: 1,
          title: 'CCTV',
          image: camera,
  
        },
        {
          id: 3,
          title: 'UPS',
          image: ups ,
  
        },
        {
          id: 4,
          title: 'Power backup',
          image: gener,
  
        },
        {
          id: 34,
          title: 'Water Supply',
          image: watersupply,
  
        },
        {
          id: 12,
          title: 'Cafeteria',
          image: cafe,
  
        },
        {
          id: 6,
          title: 'Internet Connectivity',
          image: inetrnat,
  
        },
        {
          id: 7,
          title: 'Oxygen Duct',
          image: oxygen,
  
        },
        {
          id: 8,
          title: 'Vastu Complaint',
          image: vastu,
  
        },
        {
          id: 11,
          title: 'Security Personnel',
          image: security,
  
        },
        {
          id: 13,
          title: 'Water Storage',
          image: water,
        },
        {
          id: 9,
          title: 'Fire sensors',
          image: firesensor,
        },
        {
          id: 5,
          title: 'Central Air Conditioning',
          image: Air,
        },
        {
          id: 16,
          title: 'Pantry',
          image: pan,
        },
        {
          id: 17,
          title: 'Fire NOC Certified',
          image:firenoc ,
        },
        {
          id: 14,
          title: 'DG Availability',
          image:dggenerator,
        },
        {
          id: 10,
          title: 'Fire extinguishers',
          image: firespray,
        },
        {
          id: 15,
          title: 'Reception Area',
          image: reception,
        },
        {
          id: 18,
          title: 'Occupancy Certificate',
          image: occupancy,
        },
      ],
      label: 'AMENITIES',
      inputID: '98765011',
      required: false,
      isvisible: true,
    },

    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'UploadPhotos',
    },
  ],
  price_details: [
    {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'Rent',
        },
        {
          id: 2,
          title: 'Lease',
        },
      ],
      label: 'Property available for',
      inputID: 78956,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Price Text Field4',
      data: [],
      label: 'Monthly Rent',
      keyboard: 'number',
      maxlen: 11,
      inputID: 75428,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Price Text Field5',
      data: [],
      label: 'Lease Amount',
      maxlen: 11,
      keyboard: 'number',
      inputID: 75422,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type4',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Negotiable',
      inputID: 406512,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'calendar1',
    //   data: [],
    //   label: 'Available From',
    //   inputID: 60937,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: 'Drop down5',
      data: [
        {
          id: 1,
          title: '1 Month',
        },
        {
          id: 2,
          title: '2 Months',
        },
        {
          id: 3,
          title: '3 Months',
        },
        {
          id: 4,
          title: '4 Months',
        },
        {
          id: 5,
          title: '5 Months',
        },
        {
          id: 6,
          title: '6 Months',
        },
        {
          id: 7,
          title: '7 Months',
        },
        {
          id: 8,
          title: '8 Months',
        },
        {
          id: 9,
          title: '9 Months',
        },
        {
          id: 10,
          title: '10 Months',
        },
        {
          id: 11,
          title: '11 Months',
        },
        {
          id: 12,
          title: '12 Months',
        },
        // {
        //   id: 13,
        //   title: 'Custom',
        // },
      ],
      title: 'Security Deposit',
      label: 'Select',
      // icon: <Arrowicon />,
      inputID: 80214,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Text Area',
      data: [],
      label: 'Property Description',
      inputID: 46825,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'Additional Details',
    },
  ],
  property_details: [
    {
      Type: 'Text Field',
      data: [],
      label: 'Building/Project(Optional)',
      inputID: 406039,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Locality',
    //   inputID: 406400,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: 'Custom Box Type2',
      data: [
        {
          id: 1,
          title: 'Ready to Move',
        },
        {
          id: 2,
          title: 'Under Construction',
        },
      ],
      label: 'Possession Status',
      title: 'POSSESSION INFO',
      inputID: 462241,
      required: true,
      isvisible: true,
    },
    {
      Type: 'calendar2',
      // data: [],
      label: 'Available From',
      inputID: 60937,
      required:false,
      isvisible: true,
    },
    {
      Type: 'Box Type11',
      data: [
        {
          id: 1,
          title: 'Jewellery',
        },
        {
          id: 2,
          title: 'Gym',
        },
        {
          id: 3,
          title: 'Grocery',
        },
        {
          id: 4,
          title: 'Clinic',
        },
        {
          id: 5,
          title: 'Footwear',
        },
        {
          id: 6,
          title: 'Electronics',
        },
        {
          id: 7,
          title: 'Clothing',
        },
        {
          id: 8,
          title: 'Others',
        },
      ],
      label: 'Suitable For',
      title: 'ABOUT THE PROPERTY',
      inputID: 460403,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'North',
        },
        {
          id: 2,
          title: 'East',
        },
        {
          id: 3,
          title: 'West',
        },
        {
          id: 4,
          title: 'South',
        },
      ],
      label: 'Facing',
      inputID: 23116,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      data: [],
      label: 'Built Up Area',
      keyboard: 'number',
      label2: 'Sq.ft',
      inputID: 406404,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      data: [],
      label: 'Carpet Area',
      keyboard: 'number',
      label2: 'Sq.ft',
      inputID: 406405,
      required: true,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Entrance Width in feet',
    //   label2: 'ft.',
    //   inputID: 406046,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Celling Height in feet',
    //   label2: 'ft.',
    //   inputID: 406470,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Price Text Field',
    //   data: [],
    //   label: 'Expected Rent',
    //   title: 'PRICE DETAILS',
    //   keyboard: 'number-pad',
    //   inputID: 406500,
    //   required: true,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Negotiable',
    //   inputID: 406502,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Price Text Field',
    //   data: [],
    //   label: 'Security Deposit',
    //   keyboard: 'number-pad',
    //   inputID: 406051,
    //   required: false,
    //   isvisible: true,
    // },

    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Tax & Govt. Charge included?',
    //   inputID: 464213,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Electricity charges included?',
    //   inputID: 47544,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Lock-in-Period',
    //   inputID: 493655,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Price Text Field',
    //   data: [],
    //   label: 'Expected Rent Increase',
    //   keyboard: 'number-pad',
    //   inputID: 465656,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'FDrop down',
      data: [],
      label: 'Total Floors',
      // icon: <Arrowicon />,
      title: 'FLOORS AVAILABLE',
      inputID: 498507,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Drop down1',
      data: [],
      label: 'Select Your Floor',
      // icon: <Arrowicon />,
      inputID: 496758,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Parking',
      inputID: 412659,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Public Parking',
    //   inputID: 436660,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Washroom',
      title: 'FACILITIES',
      inputID: 465681,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Public Washroom',
    //   inputID: 446652,
    //   required: false,
    //   isvisible: true,
    // },

    {
      Type: 'Form Button',
      label: 'Next, add price details',
      NextButton: 'PriceDetails',
    },
  ],
  id: 22,
};

export const CommercialrentOffice = {
  additional_details: [
 {
      Type: 'AMENITIES',
      data: [
        {
          id: 2,
          title: 'Furnishing',
          image: furnishing,
        },
        {
          id: 1,
          title: 'CCTV',
          image: camera,
  
        },
        {
          id: 3,
          title: 'UPS',
          image: ups ,
  
        },
        {
          id: 4,
          title: 'Power backup',
          image: gener,
  
        },
        {
          id: 34,
          title: 'Water Supply',
          image: watersupply,
  
        },
        {
          id: 12,
          title: 'Cafeteria',
          image: cafe,
  
        },
        {
          id: 6,
          title: 'Internet Connectivity',
          image: inetrnat,
  
        },
        {
          id: 7,
          title: 'Oxygen Duct',
          image: oxygen,
  
        },
        {
          id: 8,
          title: 'Vastu Complaint',
          image: vastu,
  
        },
        {
          id: 11,
          title: 'Security Personnel',
          image: security,
  
        },
        {
          id: 13,
          title: 'Water Storage',
          image: water,
        },
        {
          id: 9,
          title: 'Fire sensors',
          image: firesensor,
        },
        {
          id: 5,
          title: 'Central Air Conditioning',
          image: Air,
        },
        {
          id: 16,
          title: 'Pantry',
          image: pan,
        },
        {
          id: 17,
          title: 'Fire NOC Certified',
          image:firenoc ,
        },
        {
          id: 14,
          title: 'DG Availability',
          image:dggenerator,
        },
        {
          id: 10,
          title: 'Fire extinguishers',
          image: firespray,
        },
        {
          id: 15,
          title: 'Reception Area',
          image: reception,
        },
        {
          id: 18,
          title: 'Occupancy Certificate',
          image: occupancy,
        },
      ],
      label: 'AMENITIES',
      inputID: '98765011',
      required: false,
      isvisible: true,
    },

    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'UploadPhotos',
    },
  ],
  price_details: [
    {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'Rent',
        },
        {
          id: 2,
          title: 'Lease',
        },
      ],
      label: 'Property available for',
      inputID: 78956,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Price Text Field4',
      data: [],
      label: 'Monthly Rent',
      keyboard: 'number',
      maxlen: 11,
      inputID: 75428,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Price Text Field5',
      data: [],
      label: 'Lease Amount',
      maxlen: 11,
      keyboard: 'number',
      inputID: 75422,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type4',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Negotiable',
      inputID: 406522,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'calendar1',
    //   data: [],
    //   label: 'Available From',
    //   inputID: 60937,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: 'Drop down5',
      data: [
        {
          id: 1,
          title: '1 Month',
        },
        {
          id: 2,
          title: '2 Months',
        },
        {
          id: 3,
          title: '3 Months',
        },
        {
          id: 4,
          title: '4 Months',
        },
        {
          id: 5,
          title: '5 Months',
        },
        {
          id: 6,
          title: '6 Months',
        },
        {
          id: 7,
          title: '7 Months',
        },
        {
          id: 8,
          title: '8 Months',
        },
        {
          id: 9,
          title: '9 Months',
        },
        {
          id: 10,
          title: '10 Months',
        },
        {
          id: 11,
          title: '11 Months',
        },
        {
          id: 12,
          title: '12 Months',
        },
        // {
        //   id: 13,
        //   title: 'Custom',
        // },
      ],
      title: 'Security Deposit',
      label: 'Select',
      // icon: <Arrowicon />,
      inputID: 80214,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Text Area',
      data: [],
      label: 'Property Description',
      inputID: 46885,
      required: false,
      isvisible: true,
    },

    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'Additional Details',
    },
  ],
  property_details: [
    {
      Type: 'Text Field',
      data: [],
      label: 'Building/Project(Optional)',
      inputID: 400801,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Locality',
    //   inputID: 481231,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: 'Custom Box Type2',
      data: [
        {
          id: 1,
          title: 'Ready to Move',
        },
        {
          id: 2,
          title: 'Under Construction',
        },
      ],
      label: 'Possession Status',
      title: 'POSSESSION INFO',
      inputID: 748124,
      required: true,
      isvisible: true,
    },
    {
      Type: 'calendar2',
      // data: [],
      label: 'Available From',
      inputID: 60937,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Box Type',
      data: [
        {
          id: 1,
          title: 'Ready to Use',
        },
        {
          id: 2,
          title: 'Bare Shell',
        },
      ],
      label: 'Property Condition',
      title: 'ABOUT THE PROPERTY',
      inputID: 9653214,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      data: [],
      label: 'Built Up Area',
      keyboard: 'number',
      label2: 'Sq.ft',
      inputID: 480100,
      required: true,
      isvisible: true,
    },
    // {
    //   Type: 'Price Text Field',
    //   data: [],
    //   label: 'Expected Rent',
    //   keyboard: 'number-pad',

    //   title: 'PRICE DETAILS',
    //   inputID: 9212485,
    //   required: true,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Negotiable',
    //   inputID: 281247,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Price Text Field',
    //   data: [],
    //   label: 'Security Deposit',
    //   keyboard: 'number-pad',
    //   // title: 'PRICE DETAILS',
    //   inputID: 9210985,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'FDrop down',
      data: [],
      label: 'Total Floors',
      // icon: <Arrowicon />,
      title: 'FLOORS AVAILABLE',
      inputID: 498150,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Drop down1',
      data: [],
      label: 'Select Your Floor',
      // icon: <Arrowicon />,
      inputID: 496800,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'North',
        },
        {
          id: 2,
          title: 'East',
        },
        {
          id: 3,
          title: 'West',
        },
        {
          id: 4,
          title: 'South',
        },
      ],
      label: 'Facing',
      inputID: 23111,
      required: false,
      isvisible: true,
    },

    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //     {
    //       id: 3,
    //       title: 'Yes',
    //     },
    //   ],
    //   label: 'DG & UPS charges included?',
    //   inputID: 212307,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Electricity charges included?',
    //   inputID: 481263,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Water charges included?',
    //   inputID: 4221063,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Lock-in-Period',
    //   inputID: 45552,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Price Text Field',
    //   data: [],
    //   label: 'Expected Rent Increase',
    //   keyboard: 'number-pad',

    //   inputID: 4912002,
    //   required: false,
    //   isvisible: true,
    // },

    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Number of staircase',
    //   keyboard: 'number-pad',
    //   title: 'LIFT & STAIRCASES',
    //   inputID: 391257,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Passengers Lifts',
    //   keyboard: 'number-pad',
    //   inputID: 121284,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Service Lifts',
    //   keyboard: 'number-pad',
    //   inputID: 491257,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Lifts',
      inputID: 845988,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Parking',
      inputID: 845129,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Washroom',
      inputID: 4857120,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      label: 'Carpet Area',
      keyboard: 'number',

      label2: 'Sq.ft',
      inputID: 638292,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Meeting Room',
      inputID: 4843121,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Public Parking',
    //   inputID: 583127,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'Text Field',
      data: [],
      label: 'Min. Number of seats',
      keyboard: 'number',

      title: 'FACILITIES',
      inputID: 394127,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      data: [],
      label: 'Max. Number of seats',
      keyboard: 'number',

      inputID: 412739,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Number of cabins',
    //   keyboard: 'number-pad',
    //   inputID: 485129,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Number of Meeting Rooms',
    //   keyboard: 'number-pad',

    //   inputID: 41239,
    //   required: false,
    //   isvisible: true,
    // },

    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Public washroom',
    //   inputID: 481201,
    //   required: false,
    //   isvisible: true,
    // },

    {
      Type: 'Form Button',
      label: 'Next, add additional details',
      NextButton: 'PriceDetails',
    },
  ],
  id: 17,
};

export const CommercialrentWareh = {
  additional_details: [
 {
      Type: 'AMENITIES',
      data: [
        {
          id: 2,
          title: 'Furnishing',
          image: furnishing,
        },
        {
          id: 1,
          title: 'CCTV',
          image: camera,
  
        },
        {
          id: 3,
          title: 'UPS',
          image: ups ,
  
        },
        {
          id: 4,
          title: 'Power backup',
          image: gener,
  
        },
        {
          id: 34,
          title: 'Water Supply',
          image: watersupply,
  
        },
        {
          id: 12,
          title: 'Cafeteria',
          image: cafe,
  
        },
        {
          id: 6,
          title: 'Internet Connectivity',
          image: inetrnat,
  
        },
        {
          id: 7,
          title: 'Oxygen Duct',
          image: oxygen,
  
        },
        {
          id: 8,
          title: 'Vastu Complaint',
          image: vastu,
  
        },
        {
          id: 11,
          title: 'Security Personnel',
          image: security,
  
        },
        {
          id: 13,
          title: 'Water Storage',
          image: water,
        },
        {
          id: 9,
          title: 'Fire sensors',
          image: firesensor,
        },
        {
          id: 5,
          title: 'Central Air Conditioning',
          image: Air,
        },
        {
          id: 16,
          title: 'Pantry',
          image: pan,
        },
        {
          id: 17,
          title: 'Fire NOC Certified',
          image:firenoc ,
        },
        {
          id: 14,
          title: 'DG Availability',
          image:dggenerator,
        },
        {
          id: 10,
          title: 'Fire extinguishers',
          image: firespray,
        },
        {
          id: 15,
          title: 'Reception Area',
          image: reception,
        },
        {
          id: 18,
          title: 'Occupancy Certificate',
          image: occupancy,
        },
      ],
      label: 'AMENITIES',
      inputID: '98765011',
      required: false,
      isvisible: true,
    },
    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'UploadPhotos',
    },
  ],
  price_details: [
    {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'Rent',
        },
        {
          id: 2,
          title: 'Lease',
        },
      ],
      label: 'Property available for',
      inputID: 78956,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Price Text Field4',
      data: [],
      label: 'Monthly Rent',
      keyboard: 'number',
      maxlen: 11,
      inputID: 75428,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Price Text Field5',
      data: [],
      label: 'Lease Amount',
      maxlen: 11,
      keyboard: 'number',
      inputID: 75422,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type4',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Negotiable',
      inputID: 406513,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'calendar1',
    //   data: [],
    //   label: 'Available From',
    //   inputID: 60937,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: 'Drop down5',
      data: [
        {
          id: 1,
          title: '1 Month',
        },
        {
          id: 2,
          title: '2 Months',
        },
        {
          id: 3,
          title: '3 Months',
        },
        {
          id: 4,
          title: '4 Months',
        },
        {
          id: 5,
          title: '5 Months',
        },
        {
          id: 6,
          title: '6 Months',
        },
        {
          id: 7,
          title: '7 Months',
        },
        {
          id: 8,
          title: '8 Months',
        },
        {
          id: 9,
          title: '9 Months',
        },
        {
          id: 10,
          title: '10 Months',
        },
        {
          id: 11,
          title: '11 Months',
        },
        {
          id: 12,
          title: '12 Months',
        },
        // {
        //   id: 13,
        //   title: 'Custom',
        // },
      ],
      title: 'Security Deposit',
      label: 'Select',
      // icon: <Arrowicon />,
      inputID: 80214,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Text Area',
      data: [],
      label: 'Property Description',
      inputID: 468993,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'Additional Details',
    },
  ],
  property_details: [
    {
      Type: 'Text Field',
      label: 'Building/Project(Optional)',
      inputID: 698241,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   label: 'Locality',
    //   inputID: 104826,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: 'Custom Box Type2',
      data: [
        {
          id: 1,
          title: 'Ready to Move',
        },
        {
          id: 2,
          title: 'Under Construction',
        },
      ],
      label: 'Possession Status',
      title: 'POSSESSION INFO',
      inputID: 105412,
      required: true,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   label: 'Age of Property (In years)',
    //   keyboard: 'number-pad',
    //   inputID: 475839,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'calendar2',
      // data: [],
      label: 'Available From',
      inputID: 60937,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      label: 'Built Up Area',
      keyboard: 'number',
      label2: 'Sq.ft',
      inputID: 928371,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      label: 'Carpet Area',
      keyboard: 'number',

      label2: 'Sq.ft',
      inputID: 638291,
      required: true,
      isvisible: true,
    },
    // {
    //   Type: 'Price Text Field',
    //   label: 'Expected Rent',
    //   keyboard: 'number-pad',

    //   title: 'PRICE DETAILS',
    //   inputID: 822425,
    //   required: true,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Negotiable',
    //   inputID: 719384,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Price Text Field',
    //   label: 'Security Deposit',
    //   keyboard: 'number-pad',
    //   inputID: 964732,
    //   required: false,
    //   isvisible: true,
    // },

    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Electricity charges included?',
    //   inputID: 102837,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   label: 'Lock-in-Period',
    //   inputID: 539292,
    //   required: true,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Price Text Field',
    //   label: 'Expected Rent Increase',
    //   keyboard: 'number-pad',

    //   inputID: 627381,
    //   required: true,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Drop down',
    //   data: [],
    //   label: 'Total Floors',
    //   // icon: <Arrowicon />,
    //   title: 'FLOORS AVAILABLE',
    //   inputID: 153437,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Drop down',
    //   data: [],
    //   label: 'Select Your Floor',
    //   // icon: <Arrowicon />,

    //   title: 'Your Floor',
    //   inputID: 151237,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Parking',
      // title: 'PARKING',
      inputID: 1500137,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'North',
        },
        {
          id: 2,
          title: 'East',
        },
        {
          id: 3,
          title: 'West',
        },
        {
          id: 4,
          title: 'South',
        },
      ],
      label: 'Facing',
      inputID: 23112,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Public Parking',
    //   inputID: 150157,
    //   required: false,
    //   isvisible: true,
    // },

    {
      Type: 'Form Button',
      label: 'Next, add price details',
      NextButton: 'PriceDetails',
    },
  ],
  id: 15,
};

export const CommercialIndSpace = {
  additional_details: [
    {
      Type: 'AMENITIES',
      data: [
        {
          id: 2,
          title: 'Furnishing',
          image: furnishing,
        },
        {
          id: 1,
          title: 'CCTV',
          image: camera,
  
        },
        {
          id: 3,
          title: 'UPS',
          image: ups ,
  
        },
        {
          id: 4,
          title: 'Power backup',
          image: gener,
  
        },
        {
          id: 34,
          title: 'Water Supply',
          image: watersupply,
  
        },
        {
          id: 12,
          title: 'Cafeteria',
          image: cafe,
  
        },
        {
          id: 6,
          title: 'Internet Connectivity',
          image: inetrnat,
  
        },
        {
          id: 7,
          title: 'Oxygen Duct',
          image: oxygen,
  
        },
        {
          id: 8,
          title: 'Vastu Complaint',
          image: vastu,
  
        },
        {
          id: 11,
          title: 'Security Personnel',
          image: security,
  
        },
        {
          id: 13,
          title: 'Water Storage',
          image: water,
        },
        {
          id: 9,
          title: 'Fire sensors',
          image: firesensor,
        },
        {
          id: 5,
          title: 'Central Air Conditioning',
          image: Air,
        },
        {
          id: 16,
          title: 'Pantry',
          image: pan,
        },
        {
          id: 17,
          title: 'Fire NOC Certified',
          image:firenoc ,
        },
        {
          id: 14,
          title: 'DG Availability',
          image:dggenerator,
        },
        {
          id: 10,
          title: 'Fire extinguishers',
          image: firespray,
        },
        {
          id: 15,
          title: 'Reception Area',
          image: reception,
        },
        {
          id: 18,
          title: 'Occupancy Certificate',
          image: occupancy,
        },
      ],
      label: 'AMENITIES',
      inputID: '98765011',
      required: false,
      isvisible: true,
    },

    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'UploadPhotos',
    },
  ],
  price_details: [
    {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'Rent',
        },
        {
          id: 2,
          title: 'Lease',
        },
      ],
      label: 'Property available for',
      inputID: 78956,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Price Text Field4',
      data: [],
      label: 'Monthly Rent',
      keyboard: 'number',
      maxlen: 11,
      inputID: 75428,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Price Text Field5',
      data: [],
      label: 'Lease Amount',
      maxlen: 11,
      keyboard: 'number',
      inputID: 75422,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type4',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Negotiable',
      inputID: 406514,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'calendar1',
    //   data: [],
    //   label: 'Available From',
    //   inputID: 60937,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: 'Drop down5',
      data: [
        {
          id: 1,
          title: '1 Month',
        },
        {
          id: 2,
          title: '2 Months',
        },
        {
          id: 3,
          title: '3 Months',
        },
        {
          id: 4,
          title: '4 Months',
        },
        {
          id: 5,
          title: '5 Months',
        },
        {
          id: 6,
          title: '6 Months',
        },
        {
          id: 7,
          title: '7 Months',
        },
        {
          id: 8,
          title: '8 Months',
        },
        {
          id: 9,
          title: '9 Months',
        },
        {
          id: 10,
          title: '10 Months',
        },
        {
          id: 11,
          title: '11 Months',
        },
        {
          id: 12,
          title: '12 Months',
        },
        {
          id: 13,
          title: 'Custom',
        },
      ],
      title: 'Security Deposit',
      label: 'Select',
      // icon: <Arrowicon />,
      inputID: 80214,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Text Area',
      data: [],
      label: 'Property Description',
      inputID: 4682553,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'Additional Details',
    },
  ],
  property_details: [
    {
      Type: 'Text Field',
      label: 'Building/Project(Optional)',
      inputID: 7171,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'North',
        },
        {
          id: 2,
          title: 'East',
        },
        {
          id: 3,
          title: 'West',
        },
        {
          id: 4,
          title: 'South',
        },
      ],
      label: 'Facing',
      inputID: 231,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   label: 'Locality',
    //   inputID: 5010212,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: 'Custom Box Type2',
      data: [
        {
          id: 1,
          title: 'Ready to Move',
        },
        {
          id: 2,
          title: 'Under Construction',
        },
      ],
      label: 'Possession Status',
      title: 'POSSESSION INFO',
      inputID: 105412,
      required: true,
      isvisible: true,
    },
    {
      Type: 'calendar2',
      // data: [],
      label: 'Available From',
      inputID: 60937,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      label: 'Plot Area',
      title: 'ABOUT THE PROPERTY',
      keyboard: 'number',
      label2: 'Sq.ft',
      inputID: 105017,
      required: true,
      isvisible: true,
    },
    // {
    //   Type: 'Price Text Field',
    //   label: 'Expected Rent',
    //   title: 'PRICE DETAILS',
    //   keyboard: 'number-pad',

    //   inputID: 50102,
    //   required: true,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Price Text Field',
    //   label: 'Security Deposit',
    //   keyboard: 'number-pad',
    //   inputID: 50103,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Negotiable',
    //   inputID: 50104,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Electricity charges included?',
    //   inputID: 50105,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   label: 'Lock-in-Period',
    //   inputID: 50106,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Price Text Field',
    //   label: 'Expected Rent Increase',
    //   keyboard: 'number-pad',
    //   inputID: 50107,
    //   required: false,
    //   isvisible: true,
    // },

    {
      Type: 'Form Button',
      label: 'Next, add additional details',
      NextButton: 'PriceDetails',
    },
  ],
  id: 16,
};

export const CommercialSellRetailnShow = {
  additional_details: [
    {
      Type: 'AMENITIES',
      data: [
        {
          id: 2,
          title: 'Furnishing',
          image: furnishing,
        },
        {
          id: 1,
          title: 'CCTV',
          image: camera,
  
        },
        {
          id: 3,
          title: 'UPS',
          image: ups ,
  
        },
        {
          id: 4,
          title: 'Power backup',
          image: gener,
  
        },
        {
          id: 34,
          title: 'Water Supply',
          image: watersupply,
  
        },
        {
          id: 12,
          title: 'Cafeteria',
          image: cafe,
  
        },
        {
          id: 6,
          title: 'Internet Connectivity',
          image: inetrnat,
  
        },
        {
          id: 7,
          title: 'Oxygen Duct',
          image: oxygen,
  
        },
        {
          id: 8,
          title: 'Vastu Complaint',
          image: vastu,
  
        },
        {
          id: 11,
          title: 'Security Personnel',
          image: security,
  
        },
        {
          id: 13,
          title: 'Water Storage',
          image: water,
        },
        {
          id: 9,
          title: 'Fire sensors',
          image: firesensor,
        },
        {
          id: 5,
          title: 'Central Air Conditioning',
          image: Air,
        },
        {
          id: 16,
          title: 'Pantry',
          image: pan,
        },
        {
          id: 17,
          title: 'Fire NOC Certified',
          image:firenoc ,
        },
        {
          id: 14,
          title: 'DG Availability',
          image:dggenerator,
        },
        {
          id: 10,
          title: 'Fire extinguishers',
          image: firespray,
        },
        {
          id: 15,
          title: 'Reception Area',
          image: reception,
        },
        {
          id: 18,
          title: 'Occupancy Certificate',
          image: occupancy,
        },
      ],
      label: 'AMENITIES',
      inputID: '98765011',
      required: false,
      isvisible: true,
    },

    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'UploadPhotos',
    },
  ],
  price_details: [
    {
      Type: 'Price Text Fieldcom',
      data: [],
      label: 'Price',
      keyboard: 'number',
      inputID: 128,
      maxlen: 16,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Negotiable',
      inputID: 406505,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Area',
      data: [],
      label: 'Property Description',
      inputID: 4116823,
      required: false,
      isvisible: true,
    },

    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'Additional Details',
    },
  ],
  property_details: [
    {
      Type: 'Text Field',
      data: [],
      label: 'Building/Project(Optional)',
      inputID: 847316,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Locality',
    //   inputID: 934201,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: 'Custom Box Type2',
      data: [
        {
          id: 1,
          title: 'Ready to Move',
        },
        {
          id: 2,
          title: 'Under Construction',
        },
      ],
      label: 'Possession Status',
      title: 'POSSESSION INFO',
      inputID: 4622410,
      required: true,
      isvisible: true,
    },
    {
      Type: 'calendar2',
      // data: [],
      label: 'Available From',
      inputID: 60937,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Box Type11',
      data: [
        {
          id: 1,
          title: 'Jewellery',
        },
        {
          id: 2,
          title: 'Gym',
        },
        {
          id: 3,
          title: 'Grocery',
        },
        {
          id: 4,
          title: 'Clinic',
        },
        {
          id: 5,
          title: 'Footwear',
        },
        {
          id: 6,
          title: 'Electronics',
        },
        {
          id: 7,
          title: 'Clothing',
        },
        {
          id: 8,
          title: 'Others',
        },
      ],
      label: 'Suitable For',
      title: 'ABOUT THE PROPERTY',
      inputID: 725490,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Text Field Built',
      data: [],
      label: 'Built up Area',
      keyboard: 'number',
      label2: 'Sq.ft',
      inputID: 627431,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      data: [],
      label: 'Carpet Area',
      keyboard: 'number',

      label2: 'Sq.ft',
      inputID: 301945,
      required: true,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Entrance Width in feet',
    //   keyboard: 'number-pad',

    //   title: 'ft.',
    //   inputID: 106238,
    //   required: true,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Ceiling Height in feet',
    //   keyboard: 'number-pad',

    //   title: 'ft.',
    //   inputID: 490857,
    //   required: true,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Price Text Field',
    //   data: [],
    //   label: 'Price',
    //   keyboard: 'number-pad',
    //   title: 'PRICE DETAILS',
    //   inputID: 809256,
    //   required: true,
    //   isvisible: true,
    // },

    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Tax & Govt. Charge included?',
    //   inputID: 728519,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'FDrop down',
      data: [],
      label: 'Total Floors',
      // icon: <Arrowicon />,
      title: 'FLOORS AVAILABLE',
      inputID: 498150,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Drop down1',
      data: [],
      label: 'Select Your Floor',
      // icon: <Arrowicon />,
      inputID: 496800,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Parking',
      inputID: 735196,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Public Parking',
    //   inputID: 619540,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'North',
        },
        {
          id: 2,
          title: 'East',
        },
        {
          id: 3,
          title: 'West',
        },
        {
          id: 4,
          title: 'South',
        },
      ],
      label: 'Facing',
      inputID: 2317,
      required: false,
      isvisible: true,
    },

    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Washroom',
      inputID: 408715,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Private Washroom',
    //   inputID: 790671,
    //   required: false,
    //   isvisible: true,
    // },

    {
      Type: 'Form Button',
      label: 'Next, add price details',
      NextButton: 'PriceDetails',
    },
  ],
  id: 18,
};
export const CommercialSellOff = {
  additional_details: [
    {
      Type: 'AMENITIES',
      data: [
        {
          id: 2,
          title: 'Furnishing',
          image: furnishing,
        },
        {
          id: 1,
          title: 'CCTV',
          image: camera,
  
        },
        {
          id: 3,
          title: 'UPS',
          image: ups ,
  
        },
        {
          id: 4,
          title: 'Power backup',
          image: gener,
  
        },
        {
          id: 34,
          title: 'Water Supply',
          image: watersupply,
  
        },
        {
          id: 12,
          title: 'Cafeteria',
          image: cafe,
  
        },
        {
          id: 6,
          title: 'Internet Connectivity',
          image: inetrnat,
  
        },
        {
          id: 7,
          title: 'Oxygen Duct',
          image: oxygen,
  
        },
        {
          id: 8,
          title: 'Vastu Complaint',
          image: vastu,
  
        },
        {
          id: 11,
          title: 'Security Personnel',
          image: security,
  
        },
        {
          id: 13,
          title: 'Water Storage',
          image: water,
        },
        {
          id: 9,
          title: 'Fire sensors',
          image: firesensor,
        },
        {
          id: 5,
          title: 'Central Air Conditioning',
          image: Air,
        },
        {
          id: 16,
          title: 'Pantry',
          image: pan,
        },
        {
          id: 17,
          title: 'Fire NOC Certified',
          image:firenoc ,
        },
        {
          id: 14,
          title: 'DG Availability',
          image:dggenerator,
        },
        {
          id: 10,
          title: 'Fire extinguishers',
          image: firespray,
        },
        {
          id: 15,
          title: 'Reception Area',
          image: reception,
        },
        {
          id: 18,
          title: 'Occupancy Certificate',
          image: occupancy,
        },
      ],
      label: 'AMENITIES',
      inputID: '98765011',
      required: false,
      isvisible: true,
    },

    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'UploadPhotos',
    },
  ],
  price_details: [
    {
      Type: 'Price Text Fieldcom',
      data: [],
      label: 'Price',
      keyboard: 'number',
      inputID: 128,
      maxlen: 16,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Negotiable',
      inputID: 406505,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Area',
      data: [],
      label: 'Property Description',
      inputID: 46823777,
      required: false,
      isvisible: true,
    },

    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'Additional Details',
    },
  ],
  property_details: [
    {
      Type: 'Text Field',
      data: [],
      label: 'Building/Project(Optional)',
      inputID: 123456,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Locality',
    //   inputID: 654321,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: 'Custom Box Type2',
      data: [
        {
          id: 1,
          title: 'Ready to Move',
        },
        {
          id: 2,
          title: 'Under Construction',
        },
      ],
      label: 'Possession Status',
      inputID: 46224100,
      required: true,
      isvisible: true,
    },
    {
      Type: 'calendar2',
      // data: [],
      label: 'Available From',
      inputID: 60937,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Box Type',
      data: [
        {
          id: 1,
          title: 'Ready to Use',
        },
        {
          id: 2,
          title: 'Bare Shell',
        },
      ],
      label: 'Property Condition',
      title: 'ABOUT THE PROPERTY',
      inputID: 9890834,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Text Field Built',
      data: [],
      label: 'Built up Area',
      keyboard: 'number',
      label2: 'Sq.ft',
      inputID: 345678,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      data: [],
      label: 'Carpet Area',
      keyboard: 'number',
      label2: 'Sq.ft',
      inputID: 3209370,
      required: true,
      isvisible: true,
    },

    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'DG & UPS charges included?',
    //   inputID: 432109,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Electricity Charges included?',
    //   inputID: 210987,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'FDrop down',
      data: [],
      label: 'Total Floors',
      // icon: <Arrowicon />,
      title: 'FLOORS AVAILABLE',
      inputID: 498150,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Drop down1',
      data: [],
      label: 'Select Your Floor',
      // icon: <Arrowicon />,
      inputID: 496800,
      required: false,
      isvisible: true,
    },
          {
            Type: 'Custom Box Type',
            data: [
              {
                id: 1,
                title: 'North',
              },
              {
                id: 2,
                title: 'East',
              },
              {
                id: 3,
                title: 'West',
              },
              {
                id: 4,
                title: 'South',
              },
            ],
            label: 'Facing',
            inputID: 2314,
            required: false,
            isvisible: true,
          },
    // {
    //   Type: 'Drop down',
    //   data: [],
    //   label: 'Select Your Floor',
    //   icon: <Arrowicon />,
    //   inputID: 543210,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Number of staircase',
    //   keyboard: 'number-pad',
    //   label2: 'LIFT & STAIRCASES',
    //   inputID: 543560,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Passengers Lifts',
    //   keyboard: 'number-pad',
    //   inputID: 500860,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Service Lifts',
    //   keyboard: 'number-pad',
    //   inputID: 860100,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Parking',
      inputID: 876500,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Public Parking',
    //   inputID: 8001110,
    //   required: false,
    //   isvisible: true,
    // },

    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Number of cabins',
    //   keyboard: 'number-pad',
    //   inputID: 301230,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Number of Meeting Rooms',
    //   keyboard: 'number-pad',

    //   inputID: 12003450,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Washroom',
      inputID: 321098,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Public Washroom',
    //   title: 'PARKING',
    //   inputID: 876543,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Lifts',
      inputID: 845988,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Meeting Room',
      inputID: 5003843,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      data: [],
      label: 'Min.Number of seats',
      keyboard: 'number',
      inputID: 300010,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      data: [],
      label: 'Max.Number of seats',
      keyboard: 'number',
      inputID: 3987610,
      required: false,
      isvisible: true,
    },

    {
      Type: 'Form Button',
      label: 'Next, add additional details',
      NextButton: 'PriceDetails',
    },
  ],
  id: 14,
};

export const CommercialSellWare = {
  additional_details: [
    {
      Type: 'AMENITIES',
      data: [
        {
          id: 2,
          title: 'Furnishing',
          image: furnishing,
        },
        {
          id: 1,
          title: 'CCTV',
          image: camera,
  
        },
        {
          id: 3,
          title: 'UPS',
          image: ups ,
  
        },
        {
          id: 4,
          title: 'Power backup',
          image: gener,
  
        },
        {
          id: 34,
          title: 'Water Supply',
          image: watersupply,
  
        },
        {
          id: 12,
          title: 'Cafeteria',
          image: cafe,
  
        },
        {
          id: 6,
          title: 'Internet Connectivity',
          image: inetrnat,
  
        },
        {
          id: 7,
          title: 'Oxygen Duct',
          image: oxygen,
  
        },
        {
          id: 8,
          title: 'Vastu Complaint',
          image: vastu,
  
        },
        {
          id: 11,
          title: 'Security Personnel',
          image: security,
  
        },
        {
          id: 13,
          title: 'Water Storage',
          image: water,
        },
        {
          id: 9,
          title: 'Fire sensors',
          image: firesensor,
        },
        {
          id: 5,
          title: 'Central Air Conditioning',
          image: Air,
        },
        {
          id: 16,
          title: 'Pantry',
          image: pan,
        },
        {
          id: 17,
          title: 'Fire NOC Certified',
          image:firenoc ,
        },
        {
          id: 14,
          title: 'DG Availability',
          image:dggenerator,
        },
        {
          id: 10,
          title: 'Fire extinguishers',
          image: firespray,
        },
        {
          id: 15,
          title: 'Reception Area',
          image: reception,
        },
        {
          id: 18,
          title: 'Occupancy Certificate',
          image: occupancy,
        },
      ],
      label: 'AMENITIES',
      inputID: '98765011',
      required: false,
      isvisible: true,
    },

    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'UploadPhotos',
    },
  ],
  price_details: [
    {
      Type: 'Price Text Fieldcom',
      data: [],
      label: 'Price',
      keyboard: 'number',
      inputID: 128,
      maxlen: 16,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Negotiable',
      inputID: 406505,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Area',
      data: [],
      label: 'Property Description',
      inputID: 4680023,
      required: false,
      isvisible: true,
    },

    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'Additional Details',
    },
  ],
  property_details: [
    {
      Type: 'Text Field',
      data: [],
      label: 'Building/Project(Optional)',
      inputID: 320917,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Locality',
    //   inputID: 149026,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: 'Custom Box Type2',
      data: [
        {
          id: 1,
          title: 'Ready to Move',
        },
        {
          id: 2,
          title: 'Under Construction',
        },
      ],
      label: 'Possession Status',
      title: 'POSSESSION INFO',
      inputID: 273409,
      required: true,
      isvisible: true,
    },
    {
      Type: 'calendar2',
      // data: [],
      label: 'Available From',
      inputID: 60937,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   data: [],
    //   label: 'Age of Poperty(In years)',
    //   keyboard: 'number',
    //   inputID: 901267,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: 'Text Field Built',
      data: [],
      label: 'Built up Area',
      title: 'ABOUT THE PROPERTY',
      keyboard: 'number',
      label2: 'Sq.ft',
      inputID: 560192,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      data: [],
      label: 'Carpet Area',
      keyboard: 'number',
      label2: 'Sq.ft',
      inputID: 320937,
      required: true,
      isvisible: true,
    },

    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Tax & Govt charges included?',
    //   inputID: 791034,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Water Charges included?',
    //   inputID: 345678,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Drop down',
    //   data: [],
    //   label: 'Total Floors',
    //   // icon: <Arrowicon />,
    //   title: 'FLOORS AVAILABLE',
    //   inputID: 850914,
    //   required: false,
    //   isvisible: true,
    // },
    // {
    //   Type: 'Drop down',
    //   data: [],
    //   label: 'Select Your Floor',
    //   inputID: 603219,
    //   // icon: <Arrowicon />,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Parking',
      title: 'PARKING',
      inputID: 450963,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Private Washroom',
    //   inputID: 907621,
    //   required: false,
    //   isvisible: true,
    // },
    {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'North',
        },
        {
          id: 2,
          title: 'East',
        },
        {
          id: 3,
          title: 'West',
        },
        {
          id: 4,
          title: 'South',
        },
      ],
      label: 'Facing',
      inputID: 2315,
      required: false,
      isvisible: true,
    },

    {
      Type: 'Form Button',
      label: 'Next, add additional details',
      NextButton: 'PriceDetails',
    },
  ],
  id: 20,
};

export const CommercialSellIndSp = {
  additional_details: [
    {
      Type: 'AMENITIES',
      data: [
        {
          id: 2,
          title: 'Furnishing',
          image: furnishing,
        },
        {
          id: 1,
          title: 'CCTV',
          image: camera,
  
        },
        {
          id: 3,
          title: 'UPS',
          image: ups ,
  
        },
        {
          id: 4,
          title: 'Power backup',
          image: gener,
  
        },
        {
          id: 34,
          title: 'Water Supply',
          image: watersupply,
  
        },
        {
          id: 12,
          title: 'Cafeteria',
          image: cafe,
  
        },
        {
          id: 6,
          title: 'Internet Connectivity',
          image: inetrnat,
  
        },
        {
          id: 7,
          title: 'Oxygen Duct',
          image: oxygen,
  
        },
        {
          id: 8,
          title: 'Vastu Complaint',
          image: vastu,
  
        },
        {
          id: 11,
          title: 'Security Personnel',
          image: security,
  
        },
        {
          id: 13,
          title: 'Water Storage',
          image: water,
        },
        {
          id: 9,
          title: 'Fire sensors',
          image: firesensor,
        },
        {
          id: 5,
          title: 'Central Air Conditioning',
          image: Air,
        },
        {
          id: 16,
          title: 'Pantry',
          image: pan,
        },
        {
          id: 17,
          title: 'Fire NOC Certified',
          image:firenoc ,
        },
        {
          id: 14,
          title: 'DG Availability',
          image:dggenerator,
        },
        {
          id: 10,
          title: 'Fire extinguishers',
          image: firespray,
        },
        {
          id: 15,
          title: 'Reception Area',
          image: reception,
        },
        {
          id: 18,
          title: 'Occupancy Certificate',
          image: occupancy,
        },
      ],
      label: 'AMENITIES',
      inputID: '98765011',
      required: false,
      isvisible: true,
    },

    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'UploadPhotos',
    },
  ],
  price_details: [
    {
      Type: 'Price Text Fieldcom',
      data: [],
      label: 'Price',
      keyboard: 'number',
      inputID: 128,
      maxlen: 16,
      required: true,
      isvisible: true,
    },
    {
      Type: 'Box Type3',
      data: [
        {
          id: 1,
          title: 'Yes',
        },
        {
          id: 2,
          title: 'No',
        },
      ],
      label: 'Negotiable',
      inputID: 406505,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Area',
      data: [],
      label: 'Property Description',
      inputID: 468778823,
      required: false,
      isvisible: true,
    },

    {
      Type: 'Form Button',
      label: 'Next',
      NextButton: 'Additional Details',
    },
  ],
  property_details: [
    {
      Type: 'Text Field',
      label: 'Building/Project(Optional)',
      inputID: 7171,
      required: false,
      isvisible: true,
    },
    // {
    //   Type: 'Text Field',
    //   label: 'Locality',
    //   inputID: 77707,
    //   required: true,
    //   isvisible: true,
    // },
    {
      Type: 'Custom Box Type2',
      data: [
        {
          id: 1,
          title: 'Ready to Move',
        },
        {
          id: 2,
          title: 'Under Construction',
        },
      ],
      label: 'Possession Status',

      inputID: 462201,
      required: true,
      isvisible: true,
    },
    {
      Type: 'calendar2',
      // data: [],
      label: 'Available From',
      inputID: 60937,
      required: false,
      isvisible: true,
    },
    {
      Type: 'Text Field',
      label: 'Plot Area',
      title: 'ABOUT THE PROPERTY',
      keyboard: 'number',
      label2: 'Sq.ft',
      inputID: 105017,
      required: true,
      isvisible: true,
    }, {
      Type: 'Custom Box Type',
      data: [
        {
          id: 1,
          title: 'North',
        },
        {
          id: 2,
          title: 'East',
        },
        {
          id: 3,
          title: 'West',
        },
        {
          id: 4,
          title: 'South',
        },
      ],
      label: 'Facing',
      inputID: 2318,
      required: false,
      isvisible: true,
    },

    // {
    //   Type: 'Box Type',
    //   data: [
    //     {
    //       id: 1,
    //       title: 'Yes',
    //     },
    //     {
    //       id: 2,
    //       title: 'No',
    //     },
    //   ],
    //   label: 'Tax & Govt charges included?',
    //   inputID: 50105,
    //   required: false,
    //   isvisible: true,
    // },

    {
      Type: 'Form Button',
      label: 'Next, add additional details',
      NextButton: 'PriceDetails',
    },
  ],
  id: 23,
};

// export const amenities = {
//   furnishing: [
//     {
//       Type: 'AMENITIES',
//       data: [
//         { name: 'Dining Table', icon: getFurinshIcon('Dining Table') },
//         { name: 'Microwave', icon: getFurinshIcon('Microwave') },
//         { name: 'Sofa', icon: getFurinshIcon('Sofa') },
//         { name: 'Stove', icon: getFurinshIcon('Stove') },
//         { name: 'Cupboard', icon: getFurinshIcon('Cupboard') },
//         { name: 'Fridge', icon: getFurinshIcon('Fridge') },
//         { name: 'Television', icon: getFurinshIcon('Television') },
//         { name: 'Water Purifier', icon: getFurinshIcon('Water Purifier') },
//         { name: 'AC', icon: getFurinshIcon('AC') },
//         { name: 'Washing Machine', icon: getFurinshIcon('Washing Machine') },
//         { name: 'Gas Pipe', icon: getFurinshIcon('Gas Pipe') },
//       ],
//       label: 'Furnishing',
//       inputID: '909260',
//       required: false,
//       isvisible: true,
//     },
//   ],
// };


// export const data = [

//   {
//     formName: "Property Details",
//     fields: [
      
//       {
//         type: "textField",
      
//         label: "Building/Project/Society(Optional)",
//         inputId: 34256,
//         required: true,
//       },
//       {
//         type: "textField",
        
//         label: "Locality",
//         inputId: 435262,
//         required: true,
//       },
//       {
//         type: "button",
//         data: [
//           {
//             id: "1",
//             title: "1 Rk",
//           },
//           {
//             id: "2",
//             title: "1BHK",
//           },
//           {
//             id: "3",
//             title: "2BHK",
//           },
//           {
//             id: "4",
//             title: "3BHK",
//           },
//           {
//             id: "5",
//             title: "3+BHK",
//           },
//         ],
//         label: "BHK",
//         inputId: "12398",
//         required: "true",
//       },
//       {
//         type: "textField",
//         data: [],
//         label: "Build Up Area",
//         inputId: "234152",
//         required: "true",
//       },{
//          type:'button',
//          data:[
//            {
//             id:"1",
//             title:"Furnished"
//            },{
//             id:'2',
//             title:'Semi Furnished'
//            },{
//             id:"3",
//             title:"Unfurnished"
//            }

//          ],
//          label:"Furnishing Type",
//          inputId:12132415,
//          required:true



//       }
//     ],
//   },
//   {
//     formName: "Price Details",
//     fields: [
//       {
//         type: "button",
//         data: [
//           {
//             id: "1",
//             title: "Rent",
//           },
//           {
//             id: "2",
//             title: "Lease",
//           },
//         ],
//         label: "Property Available For",
//         inputId: "2314526",
//         required: "true",
//       },
//       {
//         type: "textField",
//         data: [],
//         label: "Monthly Rent",
//         inputId: "3425271",
//         required: "true",
//       },
//         {
//         type: "date",
//         data: [],
//         label: "Avaliable from",
//         inputId: "53637282",
//         required: "true",
//       },
//       // {
//       //   type: "textField",
//       //   data: [],
//       //   label: "Enter the lease Amount",
//       //   inputId: "5363728",
//       //   required: "true",
//       // },
    

//       {
//         type: "DropDown",
//         data: [],
//         label: "Security Deposit",
       
//         inputId: "213425367",
//         required: "true",
//       },
//       {
//         type: "textField",
//         data: [],
//         label: "Enter Amount",
//         inputId: "762535",
//         required: "true",
//       },
//     ],
//   },
//   {
//     formName: "Additional Details",
//     fields: [
//       {
//         type: "button",
//         data: [
//           {
//             id: "1",
//             title: "1",
//           },
//           {
//             id: "2",
//             title: "2",
//           },
//           {
//             id: "3",
//             title: "3",
//           },
//         ],
//         label: "bathroom",
//         inputId: "3425161",
//         required: "true",
//       },
//       {
//         type: "button",
//         data: [
//           {
//             id: "1",
//             title: "Family",
//           },
//           { id: "2", title: "Bachelors" },
//           { id: "3", title: "Company" },
//         ],
//         label: "Prefered Tenant Type",
//         inputId: "425332767",
//         required: "true",
//       },
//       {
//         type: "button",
//         data: [
//           {
//             id: "1",
//             title: "0",
//           },
//           { id: "2", title: "1" },
//           { id: "3", title: "2" },
//           { id: "4", title: "3" },
//         ],
//         label: "Balcony",
//         inputId: "53643737",
//         required: "true",
//       },
//       {
//         type: "textField",
//         data: [],
//         label: "Carpet Area",
//         inputId: "543737829",
//         required: "true",
//       },
//       {
//         type: "textField",
//         data: [],
//         label: "Maintenance Charge",
//         inputId: "52472383628",
//         required: "true",
//       },
//       {
//         type: "button",
//         data: [
//           {
//             id: "1",
//             title: "North",
//           },
//           { id: "2", title: "East" },
//           { id: "3", title: "West" },
//           { id: "4", title: "South" },
//         ],
//         label: "Facing",
//         inputId: "627383528",
//         required: "true",
//       },
//       {
//         type: "button",
//         data: [
//           {
//             id: "1",
//             title: "yes",
//           },
//           {
//             id: "2",
//             title: "No",
//           },
//         ],
//         label: "Parking",
//         inputId: "32426727",
//         required: "true",
//       },
//       {
//         type: "button",
//         data: [
//           {
//             id: "1",
//             title: "yes",
//           },
//           {
//             id: "2",
//             title: "No",
//           },
//         ],
//         label: "Serving Room",
//         inputId: "526287816",
//         required: "true",
//       },
//       {
//         type: "Area",
//         data: [],
//         label: "Property Description",
//         inputId: "6728231812",
//       },
//     ],
//   },
// ];

// export const Furnish={

//   Furnishing:[

//   { 
//     Type:"Amen",
//     label:"Furnishing",
//        data:[   
//            { id:10 ,  title: 'Dining Table', },
//           { id: 20, title: 'Microwave' },
//            {id: 30,  title: 'Sofa',  },
//            { id: 40, title: 'Stove', },
//            { id: 50, title: 'Cupboard',  },
//           {id: 60,  title: 'Fridge', },
//           {id:70 ,  title: 'Television', },
//           {id:80 ,  title: 'Water Purifier', },
//           { id: 90, title: 'AC', },
//            {id:100 ,  title: 'Washing Machine',  },
//            { id:110 , title: 'Gas Pipe',  },
//           ],
         
//           inputID:14363636},
//           { 
//   Type:"Amen",
//   label:'Amenities',
//    data:[
      

//              {id:1 , title: 'Lift',  },
//            { id:2 , title: 'Sports',  },
//            { id:3 , title: 'Community Hall', },
//            {id:4 ,  title: 'Swimming Pool',  },
//            { id:5 , title: 'Kids Area',  },
//            { id:6 , title: 'Gym', },
//            {id: 7,  title: 'Water Supply',  },
//            {id:8 ,  title: 'Intercom',  },
//            {id: 9,  title: 'CCTV', },
//            { id:10 , title: 'Gated Community', },
//            { id:11 , title: 'Pet Allowed',  },
//            {id:12 ,  title: 'Power Back Up',  },
//            {id:13 ,  title: 'Club House',  },
//            {id:14 ,  title: 'Garden', },



//           ], 
//          inputID:82635363}
        
//         ] }

export const Furnish = {
  items: [
    { id: 1, label: "Furnishing", inputID: 562015, data: [
      { id: 1, title: 'Dining Table' },
      { id: 2, title: 'Microwave' },
      { id: 3, title: 'Sofa' },
      { id: 4, title: 'Stove' },
      { id: 5, title: 'Cupboard' },
      { id: 6, title: 'Fridge' },
      { id: 7, title: 'Television' },
      { id: 8, title: 'Water Purifier' },
      { id: 9, title: 'AC' },
      { id: 10, title: 'Washing Machine' },
      { id: 11, title: 'Gas Pipe' }
    ] },
    { id: 2, label: "Amenities", inputID: 8566210, data: [
      { id: 1, title: 'Lift' },
      { id: 2, title: 'Sports' },
      { id: 3, title: 'Community Hall' },
      { id: 4, title: 'Swimming Pool' },
      { id: 5, title: 'Kids Area' },
      { id: 6, title: 'Gym' },
      { id: 7, title: 'Water Supply' },
      { id: 8, title: 'Intercom' },
      { id: 9, title: 'CCTV' },
      { id: 10, title: 'Gated Community' },
      { id: 11, title: 'Pet Allowed' },
      { id: 12, title: 'Power Back Up' },
      { id: 13, title: 'Club House' },
      { id: 14, title: 'Garden' },
      {id:15, title: 'Vastu' },
    ] }
  ]
};



export const ImageTagsR = {
  Exterior: [
    { id: 1, label: 'Front View' },
    { id: 2, label: 'Entrance' },
    { id: 3, label: 'Backyard / Garden' },
    { id: 4, label: 'Parking Area' },
  ],
  Interior: [
    { id: 1, label: 'Living Room' },
    { id: 2, label: 'Dining Room' },
    { id: 3, label: 'Kitchen' },
    { id: 4, label: 'Bedroom' },
    { id: 5, label: 'Bathroom' },
    { id: 6, label: 'Hallways' },
    { id: 7, label: 'Stairs' },
    { id: 8, label: 'Elevator' },
  ],
  Amenities: [
    { id: 1, label: 'Gym' },
    { id: 2, label: 'Lounge' },
    { id: 3, label: 'Terrace / Balcony' },
  ],
  Miscellaneous: [
    { id: 1, label: 'Floor Plan' },
    { id: 2, label: 'View from Property' },
    { id: 3, label: 'others' },
  ],
};

export const ImageTagsC = {
  Exterior: [
    { id: 1, label: 'Front View' },
    { id: 2, label: 'Entrance' },
    { id: 3, label: 'Backyard / Garden' },
    { id: 4, label: 'Parking Area' },
  ],
  Interior: [
    { id: 1, label: 'Lobby / Reception' },
    { id: 2, label: 'Hallways' },
    { id: 3, label: 'Work Area / Office Space' },
    { id: 4, label: 'Meeting / Conference Room' },
    { id: 5, label: 'Bathrooms / Restrooms' },
    { id: 6, label: 'Storage Area' },
    { id: 7, label: 'Stairs' },
    { id: 8, label: 'Elevator' },
  ],
  Amenities: [
    { id: 1, label: 'Gym' },
    { id: 2, label: 'Lounge' },
    { id: 3, label: 'Terrace / Balcony' },
  ],
  Miscellaneous: [
    { id: 1, label: 'Floor Plan' },
    { id: 2, label: 'View from Property' },
    { id: 3, label: 'others' },
  ],
};

        
     
        
      
