




import React from "react";
import SVGComponent from "../../Icons/SVGComponent";
import LocationComponent from "../../Icons/Location";
import gridam from "../../assets/crown.svg";
// import image from "../../images/imageflart.png";
import VerifyComponent from "../../Icons/Verify";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { axiosInstance } from "../api/APIs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { truncateString } from "../ReuseableCompounts/TruncateString";
import star from '../images/star.svg'
import goldtick from '../images/goldtick.png'
import location from '../../compounts/images/location.png';
import heart from "../../compounts/images/Heart.svg";
import LikedHeart from "../images/LikedHeart.png";
import { formatPrice } from "../ReuseableCompounts/PriceFormat";
import { formatSQPrice } from "../ReuseableCompounts/PriceSQFormat";
import { handleChange, handleLinkClick } from '../ReuseableCompounts/PostDetail';
import { useSelector } from 'react-redux'
import DefaultScroll from '../../compounts/images/defaultScroll.png';
import { margin } from "@mui/system";
import { Link } from "react-router-dom";


interface Listing {
  id: number;
  price: string;
  size: string;
  size1: string;
  text: string;
  text1: string;
  image: string;
  mobile: string;
}
interface image {
  id: number;
  isCover: any;
  isvisible: any;
  name: any;
  uri: any;

}

interface rows {
  sublocality: String;
  id: number;
  post_id: number;
  property_type?: any;
  looking_to?: string;
  rent_or_lease?: string;
  property_available_for?: string;
  images?: image[];
  view_post_count: number;
  bhk: string;
  city: string;
  building_name: string;
  landmark_street: string;
  build_up_area?: string;
  built_up_area?: string;
  plot_area?: string;
  PriceperSqft?: string;
  price: number;
  cost?: number;
  monthly_rent?: number;
  enter_lease_amount?: number;
  lease_amount?: any
  liked?: any
  user_id?: any
  district?: string;
  kbl_property: any








}



const PropertyCard: React.FC = () => {
  const loginData = useSelector((state: any) => state.userData.userLoginData);
  const [cart, setCart] = useState<rows[]>([])
  const [loadingCardId, setLoadingCardId] = useState<number | null>(null);

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/api/vattara/app/homescreen/vattaraProperty")
        console.log(response.data.rows)
        setCart(response.data.rows)

      } catch (error) {
        console.log(error);

      }
    }
    fetchData()
  }, [])

  function handleNavigate() {
    navigate("/exclusive", { state: { data: cart } })
  }






  const gradientBorderStyle = {
    border: '2.46px solid transparent', 
    borderRadius: '20px', 
    borderImage: 'linear-gradient(135deg, rgba(0, 0, 128, 1) 0%, rgba(248, 248, 248, 0.5) 50%, rgba(255, 255, 255, 0) 100%) 1', // Gradient border
  };



  return (
    < Box className="" sx={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
      <div className="relative w-full sm:w-full md:w-full lg:w-[80%] xl:w-[60%] xs:w-[95%] custom-xl:w-[50%] custom-xxl:w-[45%] msm:mx-[1%] sm:mx-[1%] lg:mx-0">
        <div className="flex justify-between items-center mt-6 mb-3">
          <div className='msm:text-[15px] sm:text-xl md:text-xl font-bold text-[#202020]'>Vattara verified properties</div>
          <div className="text-[#006CE7] font-semibold text-lg cursor-pointer" onClick={handleNavigate}>explore</div>
        </div>

        <Grid container spacing={2}  >
          {cart && cart.length > 0 ? (
            cart.map((item, index) => {

              const price = item.price || item.cost || item.enter_lease_amount || item.lease_amount || item.monthly_rent;
              const formattedPrice = formatPrice(price);
              const coverimg = item.images && item.images.length > 0 ? item.images.find(img => img.isCover) || "" : "";
              const locality = truncateString(item.city, 20)
              const sqtv: any = item.PriceperSqft ? item.PriceperSqft : "";
              const priceSQTV = formatSQPrice(sqtv);
              const Bview = "Bview"
              const propertyView = {
                postId: item.post_id,
                property_type: item.property_type,
                looking: item.looking_to || item.rent_or_lease || item.property_available_for,
                userId: loginData.user_id,
                Bview: Bview
              }

              const type = encodeURIComponent(propertyView.property_type);
            const url = `/PropertyDetails/${propertyView.postId}/${type}/${propertyView.looking}/${propertyView.Bview}`;



              return (
               
                <Grid item xs={6} sm={4} md={4} lg={3} key={index} sx={{ marginBottom: "10px", cursor: "pointer" }}>
                  <Box className='relative bg-[#FFFFFF] border-[#1063b5] border-opacity-30 border-2 p-1 rounded-2xl' sx={{
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                  }}>
                    {loadingCardId === item.post_id && (
                      <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 z-10 rounded-2xl">
                        <CircularProgress color="inherit" />
                      </div>
                    )}
                    <img className="absolute right-0 z-10 -top-6" src={gridam} />
                    <div className='relative '>

                      <div className='absolute right-0 mr-2 top-3 text-white  px-2 rounded-2xl' style={{ background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)', fontSize: "13px" }}>
                        {item.looking_to || item.rent_or_lease || item.property_available_for}
                      </div>
                      <Link to={url} onClick={(event) => { handleLinkClick(event, url) }}>

                      <img 
                      // onClick={() => { handleChange(propertyView) }}
                        className='w-full h-[140px] sm:h-[180px] md:h-[180px] lg:h-[180px] xl:h-[180px] flex justify-center items-center object-cover rounded-[15px]'
                        src={typeof coverimg === 'object' && coverimg?.uri ? coverimg.uri : DefaultScroll} />
                        </Link>
                      <div className={`${item.kbl_property ? "flex" : "hidden"}  items-center  absolute bottom-0  p-1 left-0`} style={{ borderTopRightRadius: "15px", borderBottomLeftRadius: "15px", background: 'linear-gradient(90deg, #1063b5,#02a7e4, #0cb7d1)' }}>
                        <img className="w-4 h-4" src={star} />
                        <div className="text-white mr-1" style={{ fontSize: "8px" }}>VATTARA VERIFIED</div>
                      </div>

                    </div>
                    <Box sx={{ width: { sm: "100%", md: "100%", lg: "100%", xl: "100%", xs: "95%" } }}>
                    <Link to={url} onClick={(event) => { handleLinkClick(event, url) }}>
                    <div className='flex gap-1 items-left' style={{ alignItems: "center" }} >
                        <img src={goldtick} className="w-5 h-5" style={{ marginLeft: "-3px" }} />
                        <div className='msm:text-[11px] sm:text-[15px] md:text-[15px] lg:text-[14px]  hover:text-blue-600' style={{ fontWeight: 700 }}>{item.bhk} {item.property_type}</div>
                      </div></Link>
                      <div className='flex gap-1 items-left'>
                        <img className="h-4 w-4" src={location} style={{ marginLeft: "-3px" }} />
                        <div className='msm:text-[11px] sm:text-[14px] md:text-[14px] capitalize overflow-hidden whitespace-nowrap text-ellipsis '>
                          {item.sublocality}, {item.district}</div>                                            </div>
                      <div className='flex items-center w-full'>
                        <Box sx={{ fontSize: { xs: "13px", lg: "15px", md: "15px", sm: "14px" }, fontWeight: 800, width: { xs: "80px", md: "100px", lg: "100px" } }}>₹ {formattedPrice}</Box>
                        <div className='border-[#404040] h-6 border-l'></div>
                        <div className='ml-1'>
                          <Box sx={{ fontSize: { xs: "10px", lg: "15px", sm: "11px", md: "15px" } }} className='flex items-center text-sm font-sm text-[#404040]' >
                            <div className='truncate'>{item.built_up_area || item.plot_area}</div>
                            {item.built_up_area || item.plot_area ? <div className='text-sm ml-1'>sq.ft</div> : ""}
                          </Box>
                          <Box sx={{ fontSize: { xs: "10px", lg: "13px", sm: "11px", md: "15px" } }} className='flex items-center text-sm font-sm text-[#404040]'>
                            {item.PriceperSqft ? <div>₹ {priceSQTV}</div> : ""}
                            {item.PriceperSqft ? <div>/sq.ft</div> : ""}
                          </Box>
                        </div>
                      </div>
                    </Box>
                  </Box>
                </Grid>
               
              )
            })
          ) : (
            <Box sx={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
              <div className="text-center text-gray-500" style={{ marginLeft: "100px" }}>No listings available.</div>

            </Box>
          )}
        </Grid>

      </div>
    </Box>);
};

export default PropertyCard;




